import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import TrendBarChart from './TrendBarChart';

type Props = { data: any; isLoading: boolean };

const VelocityCol = ({ data, isLoading }: Props) => {
  if (!data && !isLoading) {
    return (
      <Flex direction="column" gap={4}>
        <Text color="text.secondary2" fontFamily="heading" fontWeight="normal">
          <Text fontSize="sm" as="span">
            0
          </Text>{' '}
          <Text as="span" fontSize="xxs">
            Tasks per Day
          </Text>
        </Text>
        <Box height="150px" width="200px" />
        <Flex align="center" gap={1}>
          <Box
            mt={'-4px'}
            height="8px"
            width="8px"
            borderRadius="full"
            bg="success"
          />
          <Text color="text.primary" fontWeight="medium" fontFamily="heading">
            <Text fontSize="sm" as="span">
              0
            </Text>{' '}
            <Text as="span" fontSize="xxs" fontWeight="normal">
              Days Avg. Cycle Time
            </Text>
          </Text>
        </Flex>
      </Flex>
    );
  }
  return (
    <Flex direction="column" gap={4}>
      {isLoading ? (
        <Skeleton height="18px" width="50%" />
      ) : (
        <>
          <Text
            color="text.secondary2"
            fontFamily="heading"
            fontWeight="normal"
          >
            <Text fontSize="sm" as="span">
              {data?.average || '0'}
            </Text>{' '}
            <Text as="span" fontSize="xxs">
              {'Tasks per Day'}
            </Text>
          </Text>
        </>
      )}
      {isLoading ? (
        <Skeleton height="150px" width="200px" />
      ) : (
        <Box height="150px" width="200px">
          <TrendBarChart
            data={data?.data && JSON.parse(data?.data)}
            color="blue"
          />
        </Box>
      )}

      {isLoading ? (
        <Skeleton height="18px" width="45%" />
      ) : (
        <Flex align="center" gap={1}>
          <Box
            mt={'-4px'}
            height="8px"
            width="8px"
            borderRadius="full"
            bg="success"
          />

          <Text color="text.primary" fontWeight="medium" fontFamily="heading">
            <Text fontSize="sm" as="span">
              {data?.cycleTime || '0'}
            </Text>{' '}
            <Text as="span" fontSize="xxs" fontWeight="normal">
              {'Days Avg. Cycle Time'}
            </Text>
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default VelocityCol;
