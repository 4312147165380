import ScopeDetails from '../../ScopeDetails';

type Props = {
  data: any;
  isLoading: boolean;
};

const ScopeCol = ({ data, isLoading }: Props) => {
  return <ScopeDetails data={data} isLoading={isLoading} />;
};

export default ScopeCol;
