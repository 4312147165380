import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export const getSummaryByTypeGraphql = async (
  type: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetHomePageSummaryDetailByType(
        $orgId: String!
        $type: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
      ) {
        HomePageSummaryDetailByType(
          type: $type
          filter: {
            orgId: $orgId
            teamId: $teamId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
          }
        ) {
          summary {
            count
            title
            subTitle
            link
            category
          }
          previousPeriodStat {
            percentChange
            improved
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      type,
      teamId,
      startDate,
      endDate,
      sprintId,
    }
  );

export const getInsightCardDataGraphql = async (
  type: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetHomePageInsightCardData(
        $orgId: String!
        $type: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
      ) {
        HomePageInsightCardData(
          type: $type
          filter: {
            orgId: $orgId
            teamId: $teamId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
          }
        ) {
          title
          subTitle
          link
          data {
            count
            title
            subTitle
            link
            category
          }
          chartDataState
          chartError {
            message
            link
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      type,
      teamId,
      startDate,
      endDate,
      sprintId,
    }
  );

export const getHomePageMetricByTypeGraphql = async (
  type: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetHomePageMetricByType(
        $type: String!
        $orgId: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
      ) {
        HomePageMetricByType(
          metricType: $type
          filter: {
            orgId: $orgId
            teamId: $teamId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
          }
        ) {
          chart {
            chartDataState
            chartError {
              message
              link
            }

            chartMetadata {
              chartType
              chartTitle
              xlabel
              ylabel
              xAxis
              yAxis
              info
              chartKey
              isFavMetric
              zsImage
              source
            }
            average
            averageLabel
            data
            keys {
              key
              name
              color
            }
            previousPeriodStat {
              prevAvgValue
              percentChange
              improved
              reason
            }
            tags
            highlight
            relatedMetrics
            performance
          }
          insights
          link
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      type,
      teamId,
      startDate,
      endDate,
      sprintId,
    }
  );

export const getContributorDetailGraphql = async (
  type: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetHomePageMetricByTypeDetails(
        $type: String!
        $orgId: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
      ) {
        HomePageMetricByTypeDetails(
          metricType: $type
          filter: {
            orgId: $orgId
            teamId: $teamId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
          }
        ) {
          chart {
            chartMetadata {
              chartType
              chartTitle
              xlabel
              ylabel
              xAxis
              yAxis
              info
              chartKey
              isFavMetric
            }
            data
            keys {
              key
              name
              color
            }
          }
          insights
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      type,
      teamId,
      startDate,
      endDate,
      sprintId,
    }
  );

export const getContributorGraphql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetContributorDetails(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $teamId: String!
      ) {
        ContributorDetails(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            teamId: $teamId
          }
        ) {
          data {
            name
            email
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      sprintId,
      teamId,
    }
  );

export const getContributorInfiniteGraphql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetContributorDetailsV2(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $teamId: String!
        $pageNumber: Int
        $pageSize: Int
      ) {
        ContributorDetailsV2(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            teamId: $teamId
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            name
            email
          }
          totalPages
          totalCount
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
    }
  );

export const getWorkingAgreementDetails = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query WorkingAgreementDetails(
        $orgId: String!
        $timeZone: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $teamId: String!
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        WorkingAgreementDetails(
          filter: {
            orgId: $orgId
            timeZone: $timeZone
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            teamId: $teamId
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            type
            name
            description
            updatedDate
            team
            link
            assignee
            project
          }
          totalCount
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
    }
  );

export const issueThresholdsGql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  projectsFilter: string[],
  ticketType: string,
  showForecast: boolean
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetAllIssueThresholds(
        $orgId: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $projectsFilter: [String]!
        $ticketType: String!
        $showForecast: Boolean
      ) {
        AllIssueThresholds(
          filter: {
            orgId: $orgId
            teamId: $teamId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            type: $ticketType
            projectsFilter: $projectsFilter
            showForecast: $showForecast
          }
        ) {
          threshold {
            title
            value
            color
            desc
            target
          }
          chartKey
          info
          title
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      teamId,
      startDate,
      endDate,
      ticketType,
      sprintId,
      projectsFilter,
      showForecast: showForecast || false,
    }
  );

export const qualityThresholdsGql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  projectsFilter: string[]
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetAllQualityThresholds(
        $orgId: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $projectsFilter: [String]!
      ) {
        AllQualityThresholds(
          filter: {
            orgId: $orgId
            teamId: $teamId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
        ) {
          threshold {
            title
            value
            color
            desc
            target
          }
          chartKey
          info
          title
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      teamId,
      startDate,
      endDate,
      sprintId,
      projectsFilter,
    }
  );

export const gitThresholdsGql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  projectsFilter: string[]
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetAllGitThresholds(
        $orgId: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $projectsFilter: [String]!
      ) {
        AllGitThresholds(
          filter: {
            orgId: $orgId
            teamId: $teamId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            projectsFilter: $projectsFilter
          }
        ) {
          threshold {
            title
            value
            color
            desc
            target
          }
          chartKey
          info
          title
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      teamId,
      startDate,
      endDate,
      projectsFilter,
      sprintId,
    }
  );

export const doraThresholdsGql = async (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetAllDoraThresholds(
        $orgId: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
      ) {
        AllDoraThresholds(
          filter: {
            orgId: $orgId
            teamId: $teamId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
          }
        ) {
          threshold {
            title
            value
            color
            desc
            target
            tag
          }
          chartKey
          info
          title
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      teamId,
      startDate,
      endDate,
      sprintId,
    }
  );

export const sprintMetricGql = async (
  startDate: string,
  endDate: string,
  teamId: string,
  projectsFilter: string[],
  sprintId: string,
  sprintIds: string[],
  pageSize: number,
  pageNumber: number,
  bySp: boolean,
  status: string,
  type: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetSprintMetricsWithStatus(
        $teamId: String!
        $sprintId: String
        $sprintIds: [String]
        $startDate: String!
        $endDate: String!
        $orgId: String!
        $pageSize: Int
        $pageNumber: Int
        $bySp: Boolean
        $status: String!
        $type: String
        $projectsFilter: [String]
      ) {
        SprintMetricsWithStatus(
          filter: {
            orgId: $orgId
            teamId: $teamId
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            sprintIds: $sprintIds
            status: $status
            type: $type
            projectsFilter: $projectsFilter
          }
          bySp: $bySp
          pageSize: $pageSize
          pageNumber: $pageNumber
        ) {
          reports {
            sprintDetail {
              orgId
              sprintId
              sprintName
              sprintDuration
              startDate
              endDate
              status
              overCommitmentRate
              scopeCreepRate
              blockedIssues
            }
            sprintPlanning {
              tasks {
                name
                value
              }
              planningAccuracy
            }
            sprintProgress {
              tasks {
                status
                value
                color
              }
            }
            sprintTaskDist {
              tasksDistribution {
                status
                value
                color
              }
            }
            sprintVelocity {
              chart
            }
          }
          currentPage
          totalPages
          chartDataState
          zs
          chartError {
            message
            link
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      teamId,
      sprintId,
      sprintIds,
      startDate,
      endDate,
      pageSize,
      pageNumber,
      bySp,
      status,
      type,
      projectsFilter,
    }
  );

export const metricsOverviewGraphql = async (
  teamId: string,
  startDate: string,
  endDate: string,
  category: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query getMetricsOverview(
        $category: String!
        $startDate: String!
        $endDate: String!
        $orgId: String!
        $teamId: String!
      ) {
        MetricsOverview(
          category: $category
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
          }
        ) {
          title
          link
          metricsOverviewList {
            chartMetadata {
              info
              chartKey
            }
            threshold {
              color
              value
              title
              tag
            }
            previousPeriodStat {
              prevAvgValue
              percentChange
              improved
              reason
            }
            data
            keys {
              key
              name
              color
            }
          }
          overviewError {
            message
            link
          }
          investmentRows {
            name
            dist
            value
          }
          zsImage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      teamId,
      startDate,
      endDate,
      category,
    }
  );

export const signalsGraphql = async (
  teamId: string,
  startDate: string,
  endDate: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTopSignals(
        $orgId: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
      ) {
        TopSignals(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
          }
          onlyTopSignals: false
        ) {
          category
          score
          message
          link
          color
          tooltip
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      teamId,
      startDate,
      endDate,
    }
  );

export const customMetricGraphql = async (
  startDate: string,
  endDate: string,
  customFieldName: string,
  teamId: string,
  sprintId: string,
  granularity: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetCustomMetrics(
        $customFieldName: String!
        $granularity: String!
        $filter: Filter!
      ) {
        CustomMetrics(
          customFieldName: $customFieldName
          granularity: $granularity
          filter: $filter
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            xAxis
            yAxis
            info
            chartKey
            isFavMetric
            showBreakdown
            dataType
            detailEntity
          }
          average
          averageLabel
          data
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          chartDataState
          chartError {
            message
            link
          }
          configLink
        }
      }
    `,
    {
      customFieldName: customFieldName,
      granularity: granularity,
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        teamId: teamId,
        sprintId: sprintId,
      },
    }
  );

export const cicdPipelineGraphql = async (
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  pageNumber: number,
  pageSize: number,
  projectsFilter: string[],
  type: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPipelineReport(
        $orgId: String!
        $timeZone: String!
        $teamId: String!
        $startDate: String!
        $endDate: String!
        $sprintId: String!
        $pageNumber: Int
        $pageSize: Int
        $projectsFilter: [String]
        $type: String
      ) {
        PipelineReport(
          filter: {
            orgId: $orgId
            timeZone: $timeZone
            startDate: $startDate
            endDate: $endDate
            sprintId: $sprintId
            teamId: $teamId
            projectsFilter: $projectsFilter
            type: $type
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          pipelineReport {
            pipelineDetail {
              name
              type
              env
              repo
            }
            totalRuns
            successRate
            avgTimeInSecs
            recentRuns {
              status
              id
              link
              date
              durationInSecs
            }
            stagesAvgDuration {
              name
              value
            }
          }
          currentPage
          totalPages
          chartDataState
          chartError {
            message
            link
          }
          zs
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      teamId,
      startDate,
      endDate,
      sprintId,
      pageNumber,
      pageSize,
      projectsFilter,
      type,
    }
  );

export const getCopilotAcceptanceByLanguage = async (
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  branches: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query CopilotAcceptanceByLanguage($filter: Filter!) {
        CopilotAcceptanceByLanguage(filter: $filter) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            zlabel
            xAxis
            yAxis
            zAxis
            info
            chartKey
            isFavMetric
            source
            zsImage
            helpDoc
            showBreakdown
            dataType
            detailEntity
          }
          average
          averageLabel
          data
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          reason
          chartDataState
          chartError {
            message
            link
          }
          threshold {
            title
            value
            color
            desc
            target
            tag
          }
          attributes {
            key
            value
          }
          configLink
        }
      }
    `,
    {
      filter: {
        orgId: localStorage.getItem('orgId'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        teamId,
        startDate,
        endDate,
        sprintId,
        branches,
      },
    }
  );

export const getCopilotActiveUserCount = async (
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  granularity: string,
  breakdown: string,
  branches: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query CopilotActiveUserCount(
        $granularity: String
        $breakdown: String
        $filter: Filter!
      ) {
        CopilotActiveUserCount(
          granularity: $granularity
          breakdown: $breakdown
          filter: $filter
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            zlabel
            xAxis
            yAxis
            zAxis
            info
            chartKey
            isFavMetric
            source
            zsImage
            helpDoc
            showBreakdown
            dataType
            detailEntity
          }
          average
          averageLabel
          data
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          reason
          chartDataState
          chartError {
            message
            link
          }
          threshold {
            title
            value
            color
            desc
            target
            tag
          }
          attributes {
            key
            value
          }
          configLink
        }
      }
    `,
    {
      granularity,
      breakdown,
      filter: {
        orgId: localStorage.getItem('orgId'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        teamId,
        startDate,
        endDate,
        sprintId,
        branches,
      },
    }
  );

export const getLinesAccepted = async (
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  granularity: string,
  breakdown: string,
  branches: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query CopilotLineAccepted(
        $granularity: String
        $breakdown: String
        $filter: Filter!
      ) {
        CopilotLineAccepted(
          granularity: $granularity
          breakdown: $breakdown
          filter: $filter
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            zlabel
            xAxis
            yAxis
            zAxis
            info
            chartKey
            isFavMetric
            source
            zsImage
            helpDoc
            showBreakdown
            dataType
            detailEntity
          }
          average
          averageLabel
          data
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          reason
          chartDataState
          chartError {
            message
            link
          }
          threshold {
            title
            value
            color
            desc
            target
            tag
          }
          attributes {
            key
            value
          }
          configLink
        }
      }
    `,
    {
      granularity,
      breakdown,
      filter: {
        orgId: localStorage.getItem('orgId'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        teamId,
        startDate,
        endDate,
        sprintId,
        branches,
      },
    }
  );

export const getChatAcceptance = async (
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  granularity: string,
  breakdown: string,
  branches: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query CopilotChatAcceptance(
        $granularity: String
        $breakdown: String
        $filter: Filter!
      ) {
        CopilotChatAcceptance(
          granularity: $granularity
          breakdown: $breakdown
          filter: $filter
        ) {
          chartMetadata {
            chartType
            chartTitle
            xlabel
            ylabel
            zlabel
            xAxis
            yAxis
            zAxis
            info
            chartKey
            isFavMetric
            source
            zsImage
            helpDoc
            showBreakdown
            dataType
            detailEntity
          }
          average
          averageLabel
          data
          keys {
            key
            name
            color
            chartType
          }
          previousPeriodStat {
            prevAvgValue
            percentChange
            improved
            reason
          }
          tags
          highlight
          relatedMetrics
          performance
          reason
          chartDataState
          chartError {
            message
            link
          }
          threshold {
            title
            value
            color
            desc
            target
            tag
          }
          attributes {
            key
            value
          }
          configLink
        }
      }
    `,
    {
      granularity,
      breakdown,
      filter: {
        orgId: localStorage.getItem('orgId'),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        teamId,
        startDate,
        endDate,
        sprintId,
        branches,
      },
    }
  );

export const testSuiteGraphql = async (
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  pageNumber: number,
  pageSize: number,
  projectsFilter: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query TestReport($filter: Filter!, $pageSize: Int, $pageNumber: Int) {
        TestReport(
          filter: $filter
          pageSize: $pageSize
          pageNumber: $pageNumber
        ) {
          testSuiteReports {
            testSuiteDetail {
              projectId
              name
              repo
              type
              env
            }
            testStats {
              avgTotalTests
              avgTotalPassedTest
              avgFailedTests
              avgSkippedTests
            }
            flakyTests {
              count
              tests
            }
            successRate
            avgTimeInSecs
            recentRuns {
              id
              status
              link
              branch
              commit
              date
              durationInSecs
              totalTests
              passedTests
              failedTests
              skippedTests
            }
          }
          currentPage
          totalPages
          chartDataState
          chartError {
            message
            link
          }
          zs
        }
      }
    `,
    {
      filter: {
        orgId: localStorage.getItem('orgId'),
        startDate,
        endDate,
        teamId,
        project: projectsFilter,
        searchTerm: '',
        reviewer: '',
        author: '',
        sprintId,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      pageSize,
      pageNumber,
    }
  );
