import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { LineChartWithReferenceLine } from '../LineChartWithReferenceLine';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  graphData: any;
  referenceLines: any;
  keys: any;
};

const ChartExpandModal = ({
  isOpen,
  onClose,
  graphData,
  referenceLines,
  keys,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />

      <ModalContent>
        <ModalHeader mb={10}>Scope, Effort, and Projection</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="100%" h="60vh">
            <LineChartWithReferenceLine
              graphData={graphData}
              referenceLines={referenceLines}
              keys={keys}
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ChartExpandModal;
