export function IssueIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="8.88889"
        cy="8.88889"
        r="8.13889"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.5"
      />
      <circle
        cx="8.88902"
        cy="8.88997"
        r="3.69444"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default IssueIcon;
