import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormikForm } from './FormikForm';
import { useContributorsMutation } from '../MergeContributorModal/api';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  contributorData: any;
  queryKeys: any;
};

export const EditEmailModal = ({
  isOpen,
  onClose,
  contributorData,
  queryKeys,
}: Props) => {
  const queryClient = useQueryClient();
  const {
    mutate: handleEditContributorEmail,
    isLoading: handleEditContributorEmailLoading,
  } = useContributorsMutation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      motionPreset="slideInBottom"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={'4px'}
        boxShadow="0px 0px 14px rgba(0, 0, 0, 0.1)"
        fontFamily={'inter'}
      >
        <ModalCloseButton onClick={onClose} />
        <ModalBody w="full" p={4} mt={3}>
          <FormikForm
            onClose={onClose}
            currentEmail={contributorData?.email}
            loader={handleEditContributorEmailLoading}
            onSaveAction={(values) => {
              handleEditContributorEmail(
                {
                  accounts: contributorData?.contributorAccountList?.map(
                    (el: any) => el.key.account
                  ),
                  email: values.email,
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries([queryKeys.contributorList]);
                    onClose();
                  },
                }
              );
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditEmailModal;
