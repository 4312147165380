import { Box, Divider, Flex, keyframes, Spinner, Text } from '@chakra-ui/react';
import { EditPencilIcon } from '@devd-client/devd/components';
import moment from 'moment';

interface TargetProjectionCardProps {
  targetDate: string;
  projectedEndDate: string;
  remainingDuration: number;
  remainingEffort: number;
  activeCard: 'target' | 'developers' | 'avgAllocation' | 'scope';
  setActiveCard: (
    card: 'target' | 'developers' | 'avgAllocation' | 'scope'
  ) => void;
  isLoading: boolean;
}

const shimmer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const TargetProjectionCard = ({
  targetDate,
  projectedEndDate,
  remainingDuration,
  remainingEffort,
  activeCard,
  setActiveCard,
  isLoading,
}: TargetProjectionCardProps) => {
  const getDelayText = () => {
    if (!targetDate || !projectedEndDate) return 'N/A';

    const target = moment(targetDate);
    const projected = moment(projectedEndDate);

    if (projected.isSameOrBefore(target)) {
      return 'On Time';
    }

    let diffDays = projected.diff(target, 'days');

    const months = Math.floor(diffDays / 30);
    diffDays %= 30;

    const weeks = Math.floor(diffDays / 7);
    const days = diffDays % 7;

    const parts = [];
    if (months > 0)
      parts.push(`${months} ${months === 1 ? 'month' : 'months'}`);
    if (weeks > 0) parts.push(`${weeks} ${weeks === 1 ? 'week' : 'weeks'}`);
    if (days > 0) parts.push(`${days} ${days === 1 ? 'day' : 'days'}`);

    return parts.length > 0 ? `${parts.join(' ')} late` : 'On Time';
  };

  return (
    <Flex
      rounded={'md'}
      minH={'100px'}
      bg={activeCard === 'target' ? '#4d4dff05' : 'white'}
      border={'1px solid'}
      borderColor={activeCard === 'target' ? '#4d4dff' : 'text.tertiary'}
      boxShadow={
        activeCard === 'target'
          ? '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)'
          : 'none'
      }
      direction={'column'}
      gap={6}
      p={4}
      position={'relative'}
      overflow={'hidden'}
    >
      {isLoading && (
        <Box
          position={'absolute'}
          top={0}
          left={0}
          right={0}
          bottom={0}
          background={
            'linear-gradient(90deg, transparent, rgba(255,255,255,0.8), transparent)'
          }
          animation={`${shimmer} 1.2s infinite ease-in-out`}
        />
      )}
      {getDelayText() === 'N/A' ? null : (
        <Box
          bg={
            projectedEndDate &&
            moment(projectedEndDate).isAfter(moment(targetDate))
              ? '#FF52301A'
              : '#71E03A1A'
          }
          color={
            projectedEndDate &&
            moment(projectedEndDate).isAfter(moment(targetDate))
              ? '#FF5230'
              : '#71E03A'
          }
          fontSize={'12px'}
          py={1}
          px={2}
          rounded={'32px'}
          w={'fit-content'}
        >
          {getDelayText()}
        </Box>
      )}
      <Flex justify={'space-between'} color={'text.gray.300'}>
        <Flex direction={'column'}>
          <Text fontSize={'xs'} color={'text.secondary2'}>
            Target
          </Text>
          <Flex fontSize={'sm'} fontWeight={'medium'} align={'center'} gap={1}>
            {targetDate && targetDate !== 'N/A'
              ? moment(targetDate).format('DD MMM YYYY')
              : 'N/A'}{' '}
            {/* <EditPencilIcon /> */}
          </Flex>
        </Flex>
        <Flex direction={'column'}>
          <Text fontSize={'xs'} color={'text.secondary2'}>
            Projection {isLoading && <Spinner ml={1} size={'xs'} />}
          </Text>
          <Text fontSize={'sm'} fontWeight={'medium'}>
            {projectedEndDate
              ? moment(projectedEndDate).format('DD MMM YYYY')
              : 'N/A'}
          </Text>
        </Flex>
      </Flex>
      <Flex direction={'column'} color={'text.gray.300'} gap={2} mt={'auto'}>
        <Flex justify={'space-between'} fontSize={'xs'}>
          <Text color={'text.secondary2'}>
            Remaining Duration {isLoading && <Spinner ml={1} size={'xs'} />}
          </Text>
          <Text fontWeight={'medium'}>
            {remainingDuration !== undefined && remainingDuration !== null
              ? remainingDuration + ' days'
              : 'N/A'}
          </Text>
        </Flex>
        <Divider bg={'text.tertiary'} />
        <Flex justify={'space-between'} fontSize={'xs'}>
          <Text color={'text.secondary2'}>
            Remaining Effort {isLoading && <Spinner ml={1} size={'xs'} />}
          </Text>
          <Text fontWeight={'medium'}>
            {remainingEffort !== undefined && remainingEffort !== null
              ? remainingEffort + ' person weeks'
              : 'N/A'}
          </Text>
        </Flex>
      </Flex>
      <Text
        color={'primary'}
        fontSize={'xs'}
        onClick={() => setActiveCard('target')}
        cursor={'pointer'}
      >
        View Scope, Effort & Projection
      </Text>
    </Flex>
  );
};

export default TargetProjectionCard;
