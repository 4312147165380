import {
  useChartTableGql,
  useMetricsTableStore,
} from '@devd-client/devd/components';
import { FilterByType } from '../../../utils/metricTableTypes';
import { orderColumns } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export function useIssueTableQuery(
  startDate: string,
  endDate: string,
  metric: string,
  sprintId: string
) {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const metricFilterBy = useMetricsTableStore();

  const metricData = useChartTableGql(
    startDate,
    endDate,
    metric,
    {
      teamId: metricFilterBy.teamId,
      sprintId: sprintId || '',
      author: metricFilterBy.currentAssignee,
      type: metricFilterBy.type,
      projectsFilter: [],
      status: metricFilterBy.filterStatus,
      initiativeId:
        pathname.split('/')[1] === 'deliverables'
          ? searchParams.get('id') || searchParams.get('deliverableId')
          : null,
      deliverableType:
        pathname.split('/')[1] === 'deliverables'
          ? searchParams.get('type') || searchParams.get('deliverableType')
          : null,
      issueViewFilters: {
        issueType: metricFilterBy.issueType,
        issueStatusType:
          metricFilterBy.issueStatusType === 'all'
            ? null
            : metricFilterBy.issueStatusType,
        projectIds: metricFilterBy.projectIds ?? [],
        issueStatus: metricFilterBy.issueStatus,
        issuePriority: metricFilterBy.issuePriority,
        isDueDatePresent: metricFilterBy.isDueDatePresent,
        issueDueDate: metricFilterBy.issueDueDate,
        inactiveIssues: metricFilterBy.inactiveIssues,
        backtrackIssues: metricFilterBy.backtrackIssues,
        reopenedIssues: metricFilterBy.reopenedIssues,
        isPriority: metricFilterBy.isPriority,
        isLongCycleTime: metricFilterBy.isLongCycleTime,
        isDelayed: metricFilterBy.isDelayed,
        isBlocked: metricFilterBy.isBlocked,
        cycleTimeSpread: metricFilterBy.cycleTimeSpread,
        issueAge:
          metricFilterBy.issueAge === 'moreThreeMonth'
            ? ''
            : metricFilterBy.issueAge,
        changingRequirements: metricFilterBy.changingRequirements,
        stage: metricFilterBy.stage,
        assignees: metricFilterBy.assignees,
        issueIds: metricFilterBy.issueIds,
      },
    },
    0,
    20,
    '',
    true,
    metricFilterBy.isQueryReady
  );
  return { ...metricData };
}

export default useIssueTableQuery;
