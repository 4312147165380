import { useState } from 'react';
import {
  Button,
  Divider,
  Flex,
  FormControl,
  Grid,
  GridItem,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import {
  Calendar,
  IssueFilterModal,
  Loader,
  TextInput,
  useToastHook,
} from '@devd-client/devd/components';
import { Field, Formik } from 'formik';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import {
  configs,
  getFormattedDate,
  propsConfig,
} from '../../helpers/Initiatives.utils';
import { useEditInitiative, usePostInitiative } from '../../apis';
import { validationSchema } from './validationSchema';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

const getInitialValues = (initiativeState: any) => {
  if (!initiativeState) {
    return {
      name: '',
      desc: '',
      startDate: undefined,
      plannedDate: undefined,
      contributionTracking: 'jira',
      issueFilterConditions: {
        conditions: [],
      },
    };
  }

  const startDate = initiativeState?.startDate
    ? moment(initiativeState.startDate).toDate()
    : undefined;
  const plannedDate = initiativeState?.plannedDate
    ? moment(initiativeState.plannedDate).toDate()
    : undefined;

  return {
    name: initiativeState?.name,
    desc: initiativeState?.desc,
    startDate: startDate,
    plannedDate: plannedDate,
    contributionTracking: initiativeState?.contributionTracking,
    issueFilterConditions: initiativeState?.issueFilterConditions || {
      conditions: [],
    },
  };
};

interface InitiativeTrackingProps {
  setInitiativeId: (name: string) => void;
  isEdit?: boolean;
  initiativeState?: any;
  onNext: () => void;
}

const InitiativeTracking = ({
  setInitiativeId,
  isEdit,
  initiativeState,
  onNext,
}: InitiativeTrackingProps) => {
  const { colors } = useTheme();
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const issueFilterModal = useDisclosure();
  const [issueFilterConditions, setIssueFilterConditions] = useState({
    conditions: initiativeState?.issueFilterConditions?.conditions || null,
  });

  const {
    mutate: handlePostInitiative,
    isLoading: handlePostInitiativeLoading,
  } = usePostInitiative();
  const {
    mutate: handleEditInitiative,
    isLoading: handleEditInitiativeLoading,
  } = useEditInitiative();
  const onSubmit = (values: any) => {
    const payload = {
      name: values.name,
      desc: values.desc,
      startDate: getFormattedDate(values.startDate),
      plannedDate: getFormattedDate(values.plannedDate),
      contributionTracking: values.contributionTracking,
      issueFilterConditions: issueFilterConditions,
    };

    if (isEdit && initiativeState?.id) {
      handleEditInitiative(
        { ...payload, id: initiativeState.id },
        {
          onSuccess: (data) => {
            newToast({
              message: 'Initiative updated successfully!',
              status: 'success',
            });
            queryClient.invalidateQueries(['deliverableData']);
            onNext();
          },
          onError: () => {
            newToast({
              message: 'Something went wrong!',
              status: 'error',
            });
          },
        }
      );
    } else {
      handlePostInitiative(payload, {
        onSuccess: (data) => {
          setInitiativeId(data?.dto?.initiative?.id);
          newToast({
            message: 'Initiative created successfully!',
            status: 'success',
          });
          onNext();
        },
        onError: () => {
          newToast({
            message: 'Something went wrong!',
            status: 'error',
          });
        },
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues(initiativeState)}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(props) => {
          const {
            isSubmitting,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
            values,
          } = props;

          return (
            <>
              <form onSubmit={handleSubmit}>
                <Grid templateColumns="repeat(2, 1fr)" gap={6} w="60%">
                  <GridItem colSpan={2}>
                    <TextInput
                      name="name"
                      label="Initiative Name"
                      placeholder="Initiative Name"
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <TextInput
                      name="desc"
                      label="Description"
                      inputType="textarea"
                      placeholder="Initiative Description"
                      autoFocus={initiativeState?.focusedField === 'desc'}
                    />
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        Start Date
                      </Text>

                      <InputGroup zIndex={100}>
                        <InputLeftElement pointerEvents="none">
                          <Calendar
                            height={'16px'}
                            width={'16px'}
                            color={colors.text.secondary2}
                          />
                        </InputLeftElement>
                        <SingleDatepicker
                          name="startDate"
                          date={values.startDate}
                          onDateChange={(date) =>
                            setFieldValue('startDate', date)
                          }
                          propsConfigs={{
                            ...propsConfig,
                            inputProps: {
                              ...propsConfig.inputProps,
                              paddingLeft: '36px',
                              placeholder: 'Select start date',
                            },
                          }}
                          configs={configs}
                        />
                      </InputGroup>
                      {errors['startDate'] && touched['startDate'] && (
                        <Text color={'error'}>
                          {String(errors['startDate'])}
                        </Text>
                      )}
                    </Stack>
                  </GridItem>

                  <GridItem colSpan={1}>
                    <Stack>
                      <Text
                        fontSize="sm"
                        fontFamily="heading"
                        fontWeight="semibold"
                        color="text.secondary"
                        lineHeight="17px"
                      >
                        End Date
                      </Text>
                      <InputGroup zIndex={100}>
                        <InputLeftElement pointerEvents="none">
                          <Calendar
                            height={'16px'}
                            width={'16px'}
                            color={colors.text.secondary2}
                          />
                        </InputLeftElement>
                        <SingleDatepicker
                          name="plannedDate"
                          date={values.plannedDate}
                          onDateChange={(date) =>
                            setFieldValue('plannedDate', date)
                          }
                          propsConfigs={{
                            ...propsConfig,
                            inputProps: {
                              ...propsConfig.inputProps,
                              paddingLeft: '36px',
                              placeholder: 'Select end date',
                            },
                          }}
                          configs={configs}
                        />
                      </InputGroup>
                      {errors['plannedDate'] && touched['plannedDate'] && (
                        <Text color={'error'}>
                          {String(errors['plannedDate'])}
                        </Text>
                      )}
                    </Stack>
                  </GridItem>

                  {/* <GridItem colSpan={2}>
                    <Field name={'contributionTracking'}>
                      {({ field }: any) => (
                        <FormControl id={'contributionTracking'}>
                          <RadioGroup
                            {...field}
                            bg="gray.50"
                            borderRadius="4px"
                            p={3}
                            value={values.contributionTracking}
                            onChange={(value) => {
                              setFieldValue('contributionTracking', value);
                            }}
                            id={'contributionTracking'}
                            {...props}
                          >
                            <Radio value="jira">
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                color="text.primary"
                                fontWeight="medium"
                              >
                                Issue Management{' '}
                                <Text
                                  as="span"
                                  fontFamily="heading"
                                  fontSize="sm"
                                  color="text.secondary"
                                  fontWeight="normal"
                                >
                                  - Track Contribution from issue management
                                  assignee.
                                </Text>
                              </Text>
                            </Radio>
                            <Radio value="git">
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                color="text.primary"
                                fontWeight="medium"
                              >
                                Git{' '}
                                <Text
                                  as="span"
                                  fontFamily="heading"
                                  fontSize="sm"
                                  color="text.secondary"
                                  fontWeight="normal"
                                >
                                  - Track Contribution from git pull request.
                                  Works only if issue id mention in PR title.
                                </Text>
                              </Text>
                            </Radio>
                          </RadioGroup>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem> */}

                  <GridItem colSpan={2}>
                    <Text
                      fontFamily="heading"
                      fontSize="lg"
                      fontWeight="semibold"
                      color="text.primary"
                      mb={2}
                    >
                      Issue Filter Conditions
                    </Text>

                    <Button
                      variant="primary"
                      size="sm"
                      onClick={issueFilterModal.onOpen}
                    >
                      {`Configure >`}
                    </Button>
                    {errors.issueFilterConditions &&
                      touched.issueFilterConditions && (
                        <Text color="error" fontSize="sm" mt={2}>
                          Issue Filter Conditions are required
                        </Text>
                      )}
                  </GridItem>
                </Grid>
                <Divider my={4} />
                <Flex gap={2}>
                  <Button isDisabled={true} variant={'filled'}>
                    Previous
                  </Button>
                  <Button
                    type="submit"
                    variant={'filled'}
                    isDisabled={
                      handlePostInitiativeLoading || handleEditInitiativeLoading
                    }
                  >
                    Save & Next
                  </Button>
                </Flex>
              </form>
              {issueFilterModal.isOpen && (
                <IssueFilterModal
                  isOpen={issueFilterModal.isOpen}
                  onClose={issueFilterModal.onClose}
                  loading={false}
                  buttonText="Save"
                  forInitiatives
                  issueFilterConditions={issueFilterConditions}
                  onSubmitAction={(payload) => {
                    setIssueFilterConditions({ ...payload });
                    setFieldValue('issueFilterConditions', { ...payload });
                    issueFilterModal.onClose();
                  }}
                />
              )}
            </>
          );
        }}
      </Formik>
      {(handlePostInitiativeLoading || handleEditInitiativeLoading) && (
        <Loader />
      )}
    </>
  );
};

export default InitiativeTracking;
