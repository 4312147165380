import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Box,
  useTheme,
  Flex,
} from '@chakra-ui/react';
import {
  SearchIcon,
  Speedometer,
  TeamsIcon,
  useToastHook,
} from '@devd-client/devd/components';
import { useCallback, useRef, useState } from 'react';
import { toPng } from 'html-to-image';
import Logo from './DD-Logo.svg';
import moment from 'moment';

interface UpdateInitiativeStatusModalProps {
  summaryData: any;
  deliverableName: string;
  deliverableType: string;
  isOpen: boolean;
  onClose: () => void;
}

const ScenarioPlannerSummary = ({
  summaryData,
  deliverableName,
  deliverableType,
  isOpen,
  onClose,
}: UpdateInitiativeStatusModalProps) => {
  const { colors } = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [mergedImage, setMergedImage] = useState<string | null>(null);
  const [imageDownloadLoading, setImageDownloadLoading] = useState(false);
  const [newToast] = useToastHook();
  const downloadChart = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    setImageDownloadLoading(true);

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const img = document.createElement('img') as HTMLImageElement;
        img.src = dataUrl;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          if (!context) return;
          canvas.width = img.width;
          canvas.height = img.height;

          context.drawImage(img, 0, 0);
          const logoImg = document.createElement('img') as HTMLImageElement;
          logoImg.src = Logo;
          logoImg.onload = () => {
            const logoWidth = logoImg.width / 4;
            const logoHeight = logoImg.height / 4;
            const logoX = canvas.width - logoWidth - 60;
            const logoY = 140;

            context.drawImage(logoImg, logoX, logoY, logoWidth, logoHeight);

            const mergedDataUrl = canvas.toDataURL('image/png');
            setMergedImage(mergedDataUrl);

            const link = document.createElement('a');
            link.download = `scenarioPlanner.png`;
            link.href = mergedDataUrl;
            link.click();
            setImageDownloadLoading(false);
          };
        };
        newToast({
          message: 'Scenario Planner Summary Downloaded Successfully.',
          status: 'success',
        });
      })
      .catch((err) => {
        setImageDownloadLoading(false);
        newToast({
          message: 'Something went wrong while downloading.',
          status: 'error',
        });
      });
  }, [ref]);

  const beforeData =
    summaryData?.find((item: any) => item.columnId === 'Before') ?? {};
  const afterData =
    summaryData?.find((item: any) => item.columnId === 'After') ?? {};

  const beforeDevelopers = beforeData?.activeContributors ?? 0;
  const afterDevelopers = afterData?.activeContributors ?? 0;

  const beforeAttention = beforeData?.averageAttention ?? 0;
  const afterAttention = afterData?.averageAttention ?? 0;

  const beforeScope =
    (beforeData?.scope ?? 0) - (beforeData?.completedScope ?? 0);
  const afterScope = (afterData?.scope ?? 0) - (afterData?.completedScope ?? 0);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      blockScrollOnMount={false}
      returnFocusOnClose={false}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent borderRadius="md" shadow="xl">
        <ModalHeader fontSize={'md'} pb={0}>
          Scenario Planner
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody ref={ref} bg={'white'}>
          <Text
            fontWeight={'semibold'}
            fontSize={'sm'}
            color={'text.gray.300'}
            pt={3}
            pb={6}
          >
            {deliverableName}
            <span
              style={{ color: colors.text.gray[100], fontWeight: 'normal' }}
            >
              {' '}
              ({deliverableType})
            </span>
          </Text>
          <VStack spacing={2} align="stretch">
            <Flex align="center" gap={2}>
              <TeamsIcon width={18} height={18} color="#898893" />
              <Text>
                {afterDevelopers !== beforeDevelopers
                  ? afterDevelopers > beforeDevelopers
                    ? `Increase in developers count by ${
                        afterDevelopers - beforeDevelopers
                      }`
                    : `Decrease in developers count by ${
                        beforeDevelopers - afterDevelopers
                      }`
                  : 'No change in contributors'}
              </Text>
            </Flex>

            <Flex align="center" gap={2}>
              <SearchIcon width={18} height={18} color="#898893" />
              <Text>
                {afterAttention !== beforeAttention
                  ? afterAttention > beforeAttention
                    ? `Increase in average attention by ${
                        afterAttention - beforeAttention
                      }%`
                    : `Decrease in average attention by ${
                        beforeAttention - afterAttention
                      }%`
                  : 'No change in average attention'}
              </Text>
            </Flex>

            <Flex align="center" gap={2}>
              <Speedometer width={18} height={18} color="#898893" />
              <Text>
                {afterScope !== beforeScope
                  ? afterScope > beforeScope
                    ? `Increase in issue count by ${afterScope - beforeScope}`
                    : `Decrease in issue count by ${beforeScope - afterScope}`
                  : 'No change in deliverable scope'}
              </Text>
            </Flex>
          </VStack>
          <Flex my={4}>
            <VStack
              flex={1}
              spacing={3}
              display={'flex'}
              direction={'column'}
              align={'start'}
              color={'text.gray.300'}
            >
              <Box
                borderBottom={'1px solid #EDECED'}
                py={3}
                w={'full'}
                color={'transparent'}
                bg={'#FBFAFB'}
              >
                Empty
              </Box>
              <Box
                borderBottom={'1px solid #EDECED'}
                pb={3}
                w={'full'}
                fontWeight={'medium'}
                bg={'#FFFEFF'}
                pl={3}
              >
                Contributors
              </Box>
              <Box
                borderBottom={'1px solid #EDECED'}
                pb={3}
                w={'full'}
                fontWeight={'medium'}
                bg={'#FFFEFF'}
                pl={3}
              >
                Avg Attention
              </Box>
              <Box
                borderBottom={'1px solid #EDECED'}
                pb={3}
                w={'full'}
                fontWeight={'medium'}
                bg={'#FFFEFF'}
                pl={3}
              >
                Remaining Scope
              </Box>
              <Box
                borderBottom={'1px solid #EDECED'}
                pb={3}
                w={'full'}
                fontWeight={'medium'}
                bg={'#FFFEFF'}
                pl={3}
              >
                Target
              </Box>
              <Box
                borderBottom={'1px solid #EDECED'}
                pb={3}
                w={'full'}
                fontWeight={'medium'}
                bg={'#FFFEFF'}
                pl={3}
              >
                Projection
              </Box>
              <Box
                borderBottom={'1px solid #EDECED'}
                pb={3}
                w={'full'}
                fontWeight={'medium'}
                bg={'#FFFEFF'}
                pl={3}
              >
                Remaining Effort
              </Box>
            </VStack>
            {summaryData?.map((item: any) => (
              <VStack
                spacing={3}
                flex={1}
                display={'flex'}
                direction={'column'}
                align={'start'}
                key={item.columnId}
              >
                <Box
                  borderBottom={'1px solid #EDECED'}
                  py={3}
                  w={'full'}
                  bg={'#FBFAFB'}
                  fontWeight={'medium'}
                  position={'relative'}
                  color={'text.gray.300'}
                >
                  {item.columnId}
                </Box>
                <Box
                  borderBottom={'1px solid #EDECED'}
                  pb={3}
                  w={'full'}
                  bg={'#FFFEFF'}
                >
                  {item.activeContributors || 0}
                </Box>
                <Box
                  borderBottom={'1px solid #EDECED'}
                  pb={3}
                  w={'full'}
                  bg={'#FFFEFF'}
                >
                  {item.averageAttention || 0}
                </Box>
                <Box
                  borderBottom={'1px solid #EDECED'}
                  pb={3}
                  w={'full'}
                  bg={'#FFFEFF'}
                >
                  {item.scope - item.completedScope || 0}
                </Box>
                <Box
                  borderBottom={'1px solid #EDECED'}
                  pb={3}
                  w={'full'}
                  bg={'#FFFEFF'}
                >
                  {item?.targetDate
                    ? moment(item.targetDate).format('DD MMM YYYY')
                    : '-'}
                </Box>
                <Flex
                  borderBottom={'1px solid #EDECED'}
                  pb={3}
                  w={'full'}
                  bg={'#FFFEFF'}
                  gap={3}
                  align={'center'}
                >
                  <Text>
                    {item?.projectedEndDate
                      ? moment(item.projectedEndDate).format('DD MMM YYYY')
                      : '-'}
                  </Text>
                </Flex>
                <Box
                  borderBottom={'1px solid #EDECED'}
                  pb={3}
                  w={'full'}
                  bg={'#FFFEFF'}
                >
                  {item.remainingEffort || 0}
                </Box>
              </VStack>
            ))}
          </Flex>
        </ModalBody>

        <ModalFooter gap={3}>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
              onClose();
            }}
            _hover={{ bg: 'gray.50' }}
          >
            Go Back
          </Button>
          <Button
            variant="filled"
            size="sm"
            type="submit"
            onClick={downloadChart}
          >
            Export Plan (.PNG)
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ScenarioPlannerSummary;
