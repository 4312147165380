import {
  CompletedStatusIcon,
  HourGlassIcon,
  InProgressStatusIcon,
  NotStartedStatusIcon,
} from '@devd-client/devd/components';
import moment from 'moment';

export const getColor = (delay: number) =>
  delay > 20
    ? '#D83B01'
    : delay === 0
    ? '#16A416'
    : delay <= 20
    ? '#718096'
    : '';

export const projectTabList = [
  {
    id: 'timeline',
    title: 'Epic Timeline',
  },
  {
    id: 'issues',
    title: 'Issues',
  },
  {
    id: 'teams',
    title: 'Teams',
  },
  {
    id: 'contributors',
    title: 'Contributors',
  },
  {
    id: 'sprint',
    title: 'Sprint',
  },
  {
    id: 'changeLog',
    title: 'Change Log',
  },
  {
    id: 'updates',
    title: 'Updates',
  },
];

export const updatesModalStatusOptions = [
  { value: 'On Track', text: 'On Track', color: '#1DA44A' },
  { value: 'At Risk', text: 'At Risk', color: '#FFAD00' },
  { value: 'Off Track', text: 'Off Track', color: '#E65100' },
];

export const propsConfig = {
  dateNavBtnProps: {
    fontWeight: 'normal',
  },
  dayOfMonthBtnProps: {
    defaultBtnProps: {
      fontWeight: 'normal',
      _hover: {
        background: 'primary',
        fontWeight: 'normal',
        color: 'white',
      },
    },
    isInRangeBtnProps: {
      color: 'primary',
    },
    selectedBtnProps: {
      background: 'primary',
      fontWeight: 'normal',
      color: 'white',
    },
  },
  inputProps: {
    size: 'md',
    width: '100%',
  },
  popoverCompProps: {
    popoverContentProps: {
      background: 'white',
      boxShadow: 'lg',
      border: 'none',
    },
  },
};

export const configs = {
  dayNames: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
};

export const getFormattedDate = (date: Date) => {
  const formattedDate =
    date.toLocaleDateString('en-US', { year: 'numeric' }) +
    '-' +
    date.toLocaleDateString('en-US', { month: '2-digit' }) +
    '-' +
    date.toLocaleDateString('en-US', { day: '2-digit' });
  return formattedDate;
};

export const convertToDate = (dateStr: string) => {
  return moment(dateStr, 'DD/MM/YYYY').format('YYYY-MM-DD');
};

export const camelCaseToCapitalize = (str: string) =>
  str
    ?.replace(/([a-z])([A-Z])/g, '$1 $2')
    ?.replace(/-/g, ' ')
    ?.split(' ')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(' ');

export const statusOptions = [
  { label: 'All', value: '' },
  { label: 'Not Started', value: 'NotStarted' },
  {
    label: 'Completed',
    value: 'Completed',
  },
  {
    label: 'In Progress',
    value: 'InProgress',
  },
];

export const colors = [
  '#0095E6',
  '#49CC93',
  '#EC4899',
  '#5F50A9',
  '#FFC532',
  '#FF7C65',
];

export const generateId = (prefix: string) => {
  return `${prefix}_${Math.random().toString(36).substring(2, 9)}`;
};

export const convertDDMMYYYYToDate = (
  dateString?: string | null
): Date | null => {
  if (!dateString) return null;

  const [day, month, year] = dateString.split('/');
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getLabels = (label: string, value: number) => {
  return label === 'epicCount'
    ? value > 1
      ? 'Epics'
      : 'Epic'
    : label === 'taskCount'
    ? value > 1
      ? 'Tasks'
      : 'Task'
    : label === 'bugCount'
    ? value > 1
      ? 'Bugs'
      : 'Bug'
    : '';
};

export const getStatusIcon = (status: string) => {
  switch (status) {
    case 'InProgress':
      return <InProgressStatusIcon />;
    case 'Completed':
      return <CompletedStatusIcon />;
    case 'NotStarted':
      return <NotStartedStatusIcon />;
    default:
      return <HourGlassIcon />;
  }
};

export const viewSwitchObj = [
  {
    tab: 'INITIATIVE',
    tabLabel: 'Initiatives',
    tooltipText: 'Initiatives',
    TabIcon: null,
  },
  {
    tab: 'EPIC',
    tabLabel: 'Epics',
    tooltipText: 'Epics',
    TabIcon: null,
  },
  {
    tab: 'RELEASE',
    tabLabel: 'Releases',
    tooltipText: 'Releases',
    TabIcon: null,
  },
];

export function parseLinks(text: string) {
  if (!text) return '';
  return text
    .replace(/\[|\]/g, '')
    .split('|')
    .map((link) =>
      link.trim().match(/(https?:\/\/|www\.)[^\s]+/)
        ? `<a href="${
            link.trim().startsWith('www.')
              ? 'https://' + link.trim()
              : link.trim()
          }" target="_blank" rel="noopener noreferrer" style="color: #4D4DFF; text-decoration: underline;">${link.trim()}</a>`
        : link.trim()
    )
    .join(' ');
}

export const formattedDeliverableType = (deliverableType: string) => {
  return (
    deliverableType
      ?.toLowerCase()
      ?.replace(/_/g, ' ')
      ?.charAt(0)
      ?.toUpperCase() +
    deliverableType?.toLowerCase()?.replace(/_/g, ' ')?.slice(1)
  );
};
