import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  Spinner,
  useOutsideClick,
} from '@chakra-ui/react';
import { useGetMembers } from '../../apis';
import { useDebounce } from '@devd-client/devd/components';
import { Owner } from '../../helpers/Initiatives.types';
import { useInView } from 'react-intersection-observer';

interface OwnersSelectProps {
  selectedOwners: string[];
  onOwnersChange: (owners: string[]) => void;
  placeholder?: string;
}

export const OwnersSelect = ({
  selectedOwners,
  onOwnersChange,
  placeholder = 'Search owners...',
}: OwnersSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(searchQuery, 500);
  const ref = useRef<HTMLDivElement>(null);
  const [pageNo, setPageNo] = useState(0);
  const { ref: bottomRef, inView } = useInView();
  const [allMembers, setAllMembers] = useState<any[]>([]);

  useOutsideClick({
    ref: ref,
    handler: () => setIsOpen(false),
  });

  const { data: membersData, isLoading } = useGetMembers({
    pageNo,
    pageSize: 20,
    searchTerm: debouncedSearch,
  });

  useEffect(() => {
    if (membersData?.dto) {
      if (pageNo === 0) {
        setAllMembers(membersData.dto);
      } else {
        setAllMembers((prev) => [...prev, ...membersData.dto]);
      }
    }
  }, [membersData?.dto]);

  useEffect(() => {
    if (isOpen) {
      setPageNo(0);
    }
  }, [isOpen]);

  useEffect(() => {
    setPageNo(0);
  }, [debouncedSearch]);

  useEffect(() => {
    if (inView && !isLoading && membersData?.dto?.length === 20) {
      setPageNo((prev) => prev + 1);
    }
  }, [inView, isLoading, membersData?.dto?.length]);

  const owners = useMemo(() => {
    const transformedOwners =
      allMembers?.map((member: any) => ({
        id: member?.id,
        name: member?.name,
        email: member?.ofcEmail,
      })) || [];
    return transformedOwners;
  }, [allMembers]);

  const filteredOwners = useMemo(() => {
    const filtered = owners?.filter(
      (owner: any) => !selectedOwners?.includes(owner?.email)
    );
    return filtered;
  }, [owners, selectedOwners]);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const handleSelectOwner = (owner: Owner) => {
    onOwnersChange([...selectedOwners, owner?.email]);
    setSearchQuery('');
  };

  const handleRemoveOwner = (email: string) => {
    onOwnersChange(
      selectedOwners.filter((selectedEmail) => selectedEmail !== email)
    );
  };

  const isNotLastPage = membersData?.dto?.length === 20;

  return (
    <Box position="relative" ref={ref}>
      <Flex
        border="1px solid"
        borderColor="#E0E0E0"
        borderRadius="4px"
        p={2}
        flexWrap="wrap"
        gap={2}
        onClick={() => setIsOpen(true)}
        cursor="text"
        minH="40px"
        width={'556px'}
      >
        {selectedOwners?.map((email) => {
          return (
            <Tag
              key={email}
              size="md"
              borderRadius="full"
              variant="solid"
              bg={'primary'}
              color="white"
            >
              <TagLabel>{email}</TagLabel>
              <TagCloseButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveOwner(email);
                }}
              />
            </Tag>
          );
        })}
        <Input
          variant="unstyled"
          placeholder={selectedOwners?.length === 0 ? placeholder : ''}
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          size="sm"
          width="auto"
          flex={1}
          minW="120px"
        />
      </Flex>

      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          left={0}
          right={0}
          mt={2}
          maxH="200px"
          overflowY="auto"
          backgroundColor="white"
          borderRadius="4px"
          boxShadow="md"
          zIndex={1000}
          border="1px solid"
          borderColor="#E0E0E0"
        >
          {filteredOwners?.length === 0 && !isLoading ? (
            <Text my={4} color="text.secondary" textAlign="center">
              No results found
            </Text>
          ) : (
            <>
              {filteredOwners?.map((owner: Owner) => (
                <Box
                  key={owner?.id}
                  p={2}
                  cursor="pointer"
                  _hover={{ backgroundColor: '#E0E0E0' }}
                  onClick={() => handleSelectOwner(owner)}
                  borderBottom="1px solid"
                  borderColor="#E0E0E0"
                >
                  <Text color="text.secondary">{owner?.name}</Text>
                  <Text fontSize="sm" color="text.secondary2">
                    {owner?.email}
                  </Text>
                </Box>
              ))}
              {isLoading && (
                <Flex justify="center" my={4}>
                  <Spinner size="sm" />
                </Flex>
              )}
            </>
          )}
          {isNotLastPage ? <Box ref={bottomRef} h="20px" /> : null}
        </Box>
      )}
    </Box>
  );
};
