import { useDisclosure, useOutsideClick } from '@chakra-ui/react';
import { useAuth } from '@devd-client/devd/auth';
import {
  durationData,
  removeEmptyQueryParams,
  useDashboardStore,
  useFetchSprint,
  useQueryState,
} from '@devd-client/devd/components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFetchRepo } from '../api';
import { useSprintList } from '../graphql';
import { ticketTypeFilterOptions } from '../helpers/home.utils';
import moment from 'moment';

export const useSprintDashboard = () => {
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [statusId, setStatusId] = useQueryState('status');
  const [ticket, setTicket] = useQueryState('ticket');

  const [currentPage, setCurrentPage] = useState(0);
  const [appState, dispatch] = useAuth();

  const viewSwitchObj = [
    {
      tab: 'Count',
      tooltipText: 'Task Count',
      TabIcon: '',
    },
    {
      tab: 'Points',
      tooltipText: 'Story Point',
      TabIcon: '',
    },
  ];

  const [sprintIds, setSprintIds] = useState<any>([]);
  const [periodOptions, setPeriodOptions] = useState<any>(durationData);
  const [viewToggle, setViewToggle] = useState<string>(viewSwitchObj[0].tab);
  const [status, setStatus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const slideDrawer = useDisclosure();
  const [blockedIssues, setBlockedIssues] = useState<any>([]);
  const [repos, setRepos] = useState([]);
  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();
  const { data: repoList, isLoading: repoLoading } = useFetchRepo('jira');

  const [ticketType, setTicketType] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const [filterBy, setFilterBy] = useState<{
    value: string;
    label: string;
  }>({ value: 'team', label: 'Team' });

  const { data: sprintData, isLoading: isLoadingSprintData } = useFetchSprint();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSprints, setFilteredSprints] = useState<any[]>([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const debouncedSearch = useCallback(
    (term: string) => {
      const handler = setTimeout(() => {
        if (sprintData && term) {
          const filtered = sprintData.filter((sprint: any) =>
            sprint.sprintName?.toLowerCase().includes(term.toLowerCase())
          );
          setFilteredSprints(filtered);
          setIsPopoverOpen(filtered?.length > 0);
        } else {
          setFilteredSprints([]);
          setIsPopoverOpen(false);
        }
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    },
    [sprintData]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      setSprintIds([]);
      setSprintSelected(false);
    }
  };

  useOutsideClick({
    ref: popoverRef,
    handler: () => setIsPopoverOpen(false),
  });

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const [sprintSelected, setSprintSelected] = useState(false);
  const handleSprintSelect = (sprint: any) => {
    setSprintIds(sprint.sprintId);
    setSprintSelected(true);
    setIsPopoverOpen(false);
  };

  const { data, isFetching: sprintListLoading } = useSprintList(
    selected.startDate,
    selected.endDate,
    selectedTeam.value,
    filterBy.value === 'team' ? [] : repos.map((el: any) => el.value),
    sprintIds?.length > 0 ? '' : selected.sprintId || sprintIds[0],
    selected.duration === 'Sprint' || sprintSelected ? sprintIds : [],
    20,
    currentPage,
    viewToggle === 'Points' ? true : false,
    status === false ? '' : 'completed',
    ticketType?.length === 1 ? ticketType[0].value : 'all'
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [
    selected.startDate,
    selected.endDate,
    selectedTeam.value,
    selected.duration,
    viewToggle,
    status,
    ticketType,
  ]);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Organisation'
        ? setTeam('Organisation')
        : setTeam(selectedTeam.value);
    }

    if (ticketType) {
      ticketType?.length > 1
        ? setTicket('all')
        : setTicket(ticketType[0]?.value);
    }

    if (typeof status !== 'undefined') {
      status === false
        ? removeEmptyQueryParams({ status: '' })
        : setStatusId('completed');
    }
  }, [selected, selectedTeam.value, status, ticketType]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Organisation'
          ? { value: '', label: 'Organisation' }
          : { value: team, label: team }
      );

    statusId && setStatus(true);

    ticket && ticket === 'others'
      ? setTicketType([ticketTypeFilterOptions[2]])
      : ticket === 'bug'
      ? setTicketType([ticketTypeFilterOptions[1]])
      : ticket === 'task'
      ? setTicketType([ticketTypeFilterOptions[0]])
      : ticket === 'all'
      ? setTicketType(ticketTypeFilterOptions)
      : setTicketType([]);

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom' ||
              el.duration === 'Today' ||
              el.duration === 'Month' ||
              el.duration === 'Quarter'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  return {
    data,
    isHovered,
    setIsHovered,
    ticketType,
    setTicketType,
    filterBy,
    setFilterBy,
    selectedTeam,
    setSelectedTeam,
    appState,
    dispatch,
    repos,
    setRepos,
    repoList,
    popoverRef,
    isPopoverOpen,
    searchTerm,
    filteredSprints,
    handleSearchChange,
    setIsPopoverOpen,
    handleSprintSelect,
    formatDate,
    sprintIds,
    setSprintIds,
    viewToggle,
    viewSwitchObj,
    setViewToggle,
    status,
    setStatus,
    currentPage,
    setCurrentPage,
    sprintListLoading,
    slideDrawer,
    blockedIssues,
    setBlockedIssues,
  };
};
