import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import {
  AppTooltip,
  Chart,
  useDashboardStore,
} from '@devd-client/devd/components';
import { useCopilotAcceptanceByLanguage } from '../../graphql';
import { CopilotDataCard } from './CopilotDataCard';
import { CopilotLoadingComponent } from './CopilotLoadingComponent';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { useTheme } from '@chakra-ui/react';

type Props = {
  branches: string;
};

export const AcceptanceByLanguage = ({ branches }: Props) => {
  const { selectedTeam, selected } = useDashboardStore();
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { colors } = useTheme();
  const { data: copilotAcceptanceByLanguageData, isFetching: copilotFetching } =
    useCopilotAcceptanceByLanguage(
      selectedTeam.value,
      selected.startDate,
      selected.endDate,
      selected.sprintId,
      branches
    );

  if (copilotFetching)
    return (
      <CopilotLoadingComponent
        title={'Acceptance Rate By Language'}
        extraCard
      />
    );

  return (
    <Flex
      flexDirection={'column'}
      mt={4}
      p={4}
      bg="white"
      borderRadius="md"
      border="1px solid #E0E0E0"
    >
      <Flex align={'center'} gap={1} mb={4}>
        <Text fontSize="lg" fontWeight="semibold" color={'text.secondary'}>
          {copilotAcceptanceByLanguageData?.chartMetadata?.chartTitle}
        </Text>
        {copilotAcceptanceByLanguageData?.chartMetadata?.info && (
          <AppTooltip
            content={
              <Text
                fontSize="sm"
                color="text.secondary"
                fontWeight="normal"
                fontFamily="heading"
              >
                {copilotAcceptanceByLanguageData?.chartMetadata?.info}
              </Text>
            }
            placement="bottom"
            trigger="hover"
          >
            <MdInfoOutline size={16} color={colors.text.secondary} />
          </AppTooltip>
        )}
      </Flex>
      <Flex>
        {copilotAcceptanceByLanguageData?.chartDataState !==
          'NO_INTEGRATION' && (
          <Flex direction="column" mr={0} w="250px" justifyContent={'center'}>
            <CopilotDataCard
              title="Total Acceptances Rate"
              number={`${copilotAcceptanceByLanguageData?.data?.totalAcceptanceRate} %`}
            />
            <CopilotDataCard
              title="Total Acceptances"
              number={copilotAcceptanceByLanguageData?.data?.totalAcceptances}
            />
            <CopilotDataCard
              title="Total Suggestions"
              number={copilotAcceptanceByLanguageData?.data?.totalSuggestions}
            />
          </Flex>
        )}

        <Box
          w={
            copilotAcceptanceByLanguageData?.chartDataState !== 'NO_INTEGRATION'
              ? '80%'
              : '100%'
          }
        >
          {(copilotAcceptanceByLanguageData?.chartDataState ===
            'NOT_CONFIGURED' ||
            copilotAcceptanceByLanguageData?.chartDataState ===
              'NO_INTEGRATION' ||
            copilotAcceptanceByLanguageData?.chartDataState === 'ERROR') && (
            <Box w="fit-content" ml={'auto'} mb={8}>
              <AppTooltip
                trigger="hover"
                content={
                  <Flex>
                    <Text
                      fontFamily="heading"
                      fontSize="sm"
                      color="text.primary"
                      mr={2}
                    >
                      {copilotAcceptanceByLanguageData?.chartError?.message}
                    </Text>
                    {copilotAcceptanceByLanguageData?.chartError?.link && (
                      <Link
                        to={`${copilotAcceptanceByLanguageData?.chartError?.link}`}
                      >
                        <Text
                          color="primary"
                          fontFamily="heading"
                          fontSize="sm"
                          textDecoration={'underline'}
                        >
                          Configure
                        </Text>
                      </Link>
                    )}
                  </Flex>
                }
              >
                <Box>
                  <Button
                    size={'sm'}
                    color={'error'}
                    border="1px"
                    borderColor={'error'}
                    backgroundColor={'white'}
                    fontFamily="heading"
                  >
                    {'why no data?'}
                  </Button>
                </Box>
              </AppTooltip>
            </Box>
          )}
          {copilotAcceptanceByLanguageData?.chartDataState ===
          'NO_INTEGRATION' ? (
            <Box
              w="full"
              height="300px"
              display="flex"
              justifyContent={'center'}
              alignItems="center"
              position="relative"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Image
                height="100%"
                src={
                  copilotAcceptanceByLanguageData?.chartMetadata?.zsImage || ''
                }
                style={{ position: 'relative' }}
              />
              {isHovered && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  backgroundColor="rgba(0, 0, 0, 0.4)"
                  borderRadius="4px"
                >
                  {copilotAcceptanceByLanguageData?.chartMetadata?.source && (
                    <Button
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      onClick={() => navigate('/settings/integrations')}
                    >
                      Connect{' '}
                      {copilotAcceptanceByLanguageData?.chartMetadata
                        ?.source === 'ISSUE TRACKING'
                        ? 'Jira'
                        : copilotAcceptanceByLanguageData?.chartMetadata
                            ?.source === 'REPOSITORY'
                        ? 'Github'
                        : ''}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          ) : (
            copilotAcceptanceByLanguageData &&
            !copilotFetching && (
              <Box flex="1">
                <Chart
                  data={{
                    ...copilotAcceptanceByLanguageData?.data,
                    data: Object.fromEntries(
                      Object.entries(
                        copilotAcceptanceByLanguageData?.data?.data || {}
                      ).filter(
                        ([_, value]) =>
                          (value as { acceptanceRate: string })
                            ?.acceptanceRate !== '0.00'
                      )
                    ),
                  }}
                  chartMetadata={copilotAcceptanceByLanguageData?.chartMetadata}
                  keys={copilotAcceptanceByLanguageData?.chartMetadata?.keys}
                />
              </Box>
            )
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
