import { useCallback, useState } from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from 'recharts';
import CustomTooltip from './components/customTooltip/CustomTooltip';
import './chart.css';
import { useNavigate } from 'react-router';
import {
  CFR_CHART_KEYS,
  ISSUE_CHART_KEYS,
  PR_HEALTH_INDEX_CHARTS,
  splitTime,
} from '../shared/utils';
import MetricSidebar from './components/MetricSidebar';
import moment from 'moment';
import { Box, useTheme } from '@chakra-ui/react';

interface ComboChartRechartsProps {
  chartref?: any;
  data: any;
  keys?: any;
  chartMetadata?: any;
  showLabel?: boolean;
  selectedTeam: string;
  hideBtn?: boolean;
  slant?: boolean;
  granularSlant?: boolean;
  forDetails?: boolean;
  ticketType?: string;
  queryKey?: string;
  repos?: string[];
  selected: any;
  showCustomLegend?: boolean;
  showXLabel?: boolean;
}

export const ComboChartRecharts = ({
  chartref,
  data,
  keys,
  chartMetadata,
  showLabel,
  selectedTeam,
  hideBtn,
  slant,
  granularSlant,
  forDetails,
  ticketType,
  queryKey,
  repos,
  selected,
  showCustomLegend = true,
  showXLabel,
}: ComboChartRechartsProps) => {
  const { colors } = useTheme();
  const [hoveredLabel, setHoveredLabel] = useState<any>(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState<any>(null);
  const [currentChartMeta, setCurrentChartMeta] = useState<any>(null);

  const params = new URLSearchParams(window.location.search);
  const team = params.get('team');

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  const handleClick = (label: any) => {
    const labelId = data?.find(
      (el: any) => el.xLabelName === label.value
    ).xLabelId;

    navigate(`sprint/${labelId}${team && `?team=${team}`}`);
  };

  const handleMouseEnter = (label: any) => {
    setHoveredLabel(label);
  };

  const handleMouseLeave = () => {
    setHoveredLabel(null);
  };

  const renderCustomTick = (props: any) => {
    const { x, y, payload } = props;
    const { value } = payload;
    const isHovered = value === hoveredLabel;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={4}
          fill={colors.primary}
          fontSize={7.5}
          fontWeight={isHovered ? 700 : 600}
          cursor="pointer"
          textAnchor="end"
          textDecoration={'underline'}
          onMouseEnter={() => handleMouseEnter(value)}
          onMouseLeave={handleMouseLeave}
          transform="rotate(-20)"
        >
          {value}
        </text>
      </g>
    );
  };

  const mergeHealthMax = Array.isArray(data)
    ? Math.max(...data.map((entry: any) => entry.mergeHealth))
    : 0;

  const xDomain = [0, mergeHealthMax < 100 ? 100 : mergeHealthMax];

  const foundData = data?.find(
    (d: any) => d?.startDate === clickedData?.payload?.startDate
  );
  const count = foundData?.[clickedData?.metricKey];

  const CustomLegend = ({ keys }: { keys: any[] }) => (
    <div
      style={{
        position: 'absolute',
        right: '0',
        top: '0%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0 20px',
      }}
    >
      {keys?.map((entry) => {
        const isLine =
          entry?.chartType === 'line_chart' ||
          entry?.chartType === 'dashed_line_chart';
        const isBar =
          entry?.chartType === 'bar_chart' ||
          entry?.chartType === 'dashed_bar_chart';
        return (
          <div
            key={entry?.key}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
              whiteSpace: 'nowrap',
            }}
          >
            {isLine ? (
              <svg width="20" height="3" style={{ marginRight: '8px' }}>
                <line
                  x1="0"
                  y1="1.5"
                  x2="20"
                  y2="1.5"
                  stroke={entry?.color}
                  strokeWidth="3"
                  strokeDasharray={
                    entry?.chartType === 'dashed_line_chart' ? '2 2' : 'none'
                  }
                />
              </svg>
            ) : isBar ? (
              <svg width="20" height="20" style={{ marginRight: '8px' }}>
                <circle
                  cx="10"
                  cy="10"
                  r="5"
                  fill={entry?.color}
                  fillOpacity={
                    entry?.chartType === 'dashed_bar_chart' ? 0.2 : 1
                  }
                  stroke={
                    entry?.chartType === 'dashed_bar_chart'
                      ? entry?.color
                      : 'none'
                  }
                  strokeDasharray={
                    entry?.chartType === 'dashed_bar_chart' ? '2 2' : 'none'
                  }
                />
              </svg>
            ) : null}
            <span style={{ color: '#666666', fontSize: '11.5px' }}>
              {entry?.name}
            </span>
          </div>
        );
      })}
    </div>
  );

  return (
    <Box style={{ position: 'relative', width: '100%', height: '100%' }}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          ref={chartref}
          data={data}
          margin={{
            top: 10,
            right: showCustomLegend ? 216 : 35,
            left: showLabel ? 0 : slant ? 108 : -5,
            bottom: forDetails ? 20 : -45,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />

          <XAxis
            dataKey={chartMetadata.xAxis}
            axisLine={false}
            tickLine={false}
            style={{
              fontSize: 7.5,
              fontWeight: 500,
              cursor: slant ? 'pointer' : undefined,
            }}
            angle={slant || granularSlant ? -60 : undefined}
            textAnchor={granularSlant || slant ? 'end' : 'middle'}
            interval={slant || granularSlant ? 0 : undefined}
            onClick={slant ? handleClick : undefined}
            tick={slant ? renderCustomTick : undefined}
          />

          <XAxis
            xAxisId="dashed"
            label={
              showXLabel
                ? {
                    value: chartMetadata.xlabel,
                    fontSize: 11,
                    fontWeight: 400,
                    position: 'insideBottom',
                    dy: -20,
                  }
                : {}
            }
            axisLine={false}
            tickLine={false}
            tick={false}
            dataKey={chartMetadata.xAxis}
          />

          <YAxis
            orientation="left"
            tickFormatter={
              chartMetadata.ylabel === 'Hour'
                ? splitTime
                : (e) => {
                    return e;
                  }
            }
            label={
              !showLabel
                ? {}
                : {
                    value: chartMetadata.ylabel,
                    angle: -90,
                    position: 'insideLeft',
                    fontSize: 11,
                    fontWeight: 400,
                  }
            }
            axisLine={false}
            tickLine={false}
            style={{ fontSize: 11, fontWeight: 400 }}
          />

          <YAxis
            orientation="right"
            domain={xDomain}
            tickFormatter={(value) => `${value}%`}
            yAxisId={chartMetadata.zAxis}
            dataKey={chartMetadata.zAxis}
            label={
              !showLabel
                ? {}
                : {
                    value: chartMetadata.zlabel,
                    angle: -270,
                    position: 'outsideRight',
                    fontSize: 11,
                    fontWeight: 400,
                    dx: 20,
                  }
            }
            axisLine={false}
            tickLine={false}
            style={{ fontSize: 11, fontWeight: 400 }}
          />

          <Tooltip
            content={
              <CustomTooltip
                handleModalOpen={handleModalOpen}
                setClickedData={setClickedData}
                unit={chartMetadata.zlabel}
                onClickAction={() => {
                  setCurrentChartMeta(chartMetadata);
                }}
                hideBtn={hideBtn}
              />
            }
            wrapperStyle={{ outline: 'none' }}
            position={{ y: -30 }}
            allowEscapeViewBox={{ x: false, y: false }}
          />

          {showLabel && !showCustomLegend && (
            <Legend
              verticalAlign="middle"
              layout="vertical"
              iconType="circle"
              iconSize={11}
              align="right"
              wrapperStyle={{
                right: -8,
                fontSize: 11.5,
                top: 0,
              }}
            />
          )}

          {keys
            ?.sort((a: any, b: any) => a.chartType.localeCompare(b.chartType))
            ?.map((el: any) =>
              el.key === 'yRollingAvg' ? (
                <Line
                  key={el.key}
                  type="monotone"
                  dataKey={el.key}
                  name={el.name}
                  stroke={el.color}
                  dot={false}
                  activeDot={false}
                  strokeWidth={2}
                  strokeDasharray="3 6"
                />
              ) : el.chartType === 'line_chart' ? (
                <Line
                  yAxisId={chartMetadata.zAxis}
                  key={el.key}
                  dataKey={el.key}
                  name={el.name}
                  stroke={el.color}
                  strokeWidth={2}
                  dot={{
                    fill: el.color,
                    stroke: el.color,
                    strokeWidth: 3,
                    r: 0,
                  }}
                />
              ) : el.chartType === 'dashed_line_chart' ? (
                <Line
                  yAxisId={chartMetadata.zAxis}
                  key={el.key}
                  dataKey={el.key}
                  name={el.name}
                  stroke={el.color}
                  strokeWidth={2}
                  strokeDasharray={'3 3'}
                  dot={{
                    fill: el.color,
                    stroke: el.color,
                    strokeWidth: 3,
                    r: 0,
                  }}
                  opacity={0.7}
                />
              ) : el.chartType === 'bar_chart' ? (
                <Bar
                  key={el.key}
                  dataKey={el.key}
                  name={el.name}
                  barSize={16}
                  fill={el.color}
                  style={{ cursor: 'pointer' }}
                  onClick={(event: any, payload: any) => {
                    handleModalOpen();
                    setClickedData({
                      name: el.name,
                      metricKey: event?.tooltipPayload[0]?.dataKey,
                      payload: {
                        startDate: event.startDate,
                        endDate: event.endDate,
                      },
                    });
                    setCurrentChartMeta(chartMetadata);
                  }}
                />
              ) : el.chartType === 'dashed_bar_chart' ? (
                <Bar
                  key={el.key}
                  dataKey={el.key}
                  name={el.name}
                  barSize={16}
                  fill={el.color}
                  fillOpacity={0.2}
                  strokeDasharray={'3 3'}
                  stroke={el.color}
                  xAxisId={'dashed'}
                />
              ) : (
                <></>
              )
            )}
        </ComposedChart>
      </ResponsiveContainer>
      {showCustomLegend && <CustomLegend keys={keys} />}
      {[
        ...ISSUE_CHART_KEYS,
        ...PR_HEALTH_INDEX_CHARTS,
        ...CFR_CHART_KEYS,
      ].includes(currentChartMeta?.chartKey) &&
        isOpen && (
          <MetricSidebar
            querykey={queryKey}
            openDrawer={isOpen}
            handleDrawerClose={handleModalClose}
            heading={chartMetadata.chartTitle}
            subHeading={`${
              Array.isArray(clickedData)
                ? moment(clickedData[0]?.payload?.startDate).format('DD MMM')
                : moment(clickedData?.payload?.startDate).format('DD MMM')
            } - ${
              Array.isArray(clickedData)
                ? moment(clickedData[0]?.payload?.endDate).format('DD MMM')
                : moment(clickedData?.payload?.endDate).format('DD MMM')
            }, ${clickedData?.name} ${
              chartMetadata?.chartTitle === 'PR Review Rate'
                ? ''
                : 'Issue Count'
            } (${count})`}
            payload={clickedData}
            currentChartMeta={{
              ...currentChartMeta,
              chartKey:
                clickedData.name === 'Deployments'
                  ? 'DEPLOYMENT_FREQUENCY_DISTRIBUTION'
                  : clickedData.name === 'Change Failure'
                  ? 'CHANGE_HEALTH'
                  : currentChartMeta.chartKey,
            }}
            filterBy={{
              teamId: selectedTeam,
              status: clickedData?.name?.toLowerCase(),
              type:
                chartMetadata.chartKey === 'ISSUE_THROUGHPUT'
                  ? ticketType
                  : clickedData?.metricKey,
              projectsFilter: repos,
              sprintId: selected.sprintId,
            }}
            selectedTeam={selectedTeam}
          />
        )}
    </Box>
  );
};
