export function NoTeamIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_10167_15023)">
        <path
          d="M11.9279 9.14305C10.9117 9.14305 10.0879 8.31921 10.0879 7.30297C10.0879 6.28672 10.9117 5.46289 11.9279 5.46289C12.9442 5.46289 13.7681 6.28672 13.7681 7.30297C13.7681 8.31921 12.9442 9.14305 11.9279 9.14305Z"
          stroke={props.color || '#626266'}
          stroke-width="1.14286"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.25586 11.9835C9.50933 11.7156 9.80265 11.4862 10.1268 11.3041C10.6772 10.9949 11.2978 10.8325 11.9292 10.8325C12.5605 10.8325 13.1812 10.9949 13.7316 11.3041C13.9696 11.4379 14.1911 11.5971 14.3924 11.7784"
          stroke={props.color || '#626266'}
          stroke-width="1.14286"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.97552 8.55309C4.7162 8.55309 3.69531 7.5322 3.69531 6.27288C3.69531 5.01356 4.7162 3.99268 5.97552 3.99268C7.23484 3.99268 8.25572 5.01356 8.25572 6.27288C8.25572 7.5322 7.23484 8.55309 5.97552 8.55309Z"
          stroke={props.color || '#626266'}
          stroke-width="1.14286"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.2466 15.0645C10.1329 13.7026 9.79355 12.807 9.60748 12.4917C9.21134 11.8182 8.6546 11.2615 7.99076 10.8753C7.32693 10.489 6.57832 10.2861 5.81692 10.2861C5.05551 10.2861 4.30692 10.489 3.64308 10.8753C3.11301 11.1837 2.65121 11.6008 2.28494 12.0993L2.05273 12.4468"
          stroke={props.color || '#626266'}
          stroke-width="1.14286"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.99888 15.4289C3.8962 15.4289 0.570312 12.1031 0.570312 8.00035C0.570312 3.89766 3.8962 0.571777 7.99888 0.571777C12.1016 0.571777 15.4275 3.89766 15.4275 8.00035C15.4275 12.1031 12.1016 15.4289 7.99888 15.4289Z"
          stroke={props.color || '#626266'}
          stroke-width="1.14286"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10167_15023">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NoTeamIcon;
