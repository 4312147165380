import * as React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Tooltip,
  Flex,
  Skeleton,
  Stack,
  Button,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';
import NoData from './NoData';
import Loader from '../appLoader';
import TableContainer from './DataGrid.styled';
import '../shared/css/styles.css';
import { useInView } from 'react-intersection-observer';

type Sticky = 'firstCol' | 'secondCol98' | 'none';

type DataGridProps<Data extends object> = {
  team?: string | undefined;
  data: Data[];
  columns: ColumnDef<Data, any>[];
  maxH?: string;
  showLoader: boolean;
  skeletonLoader?: boolean;
  checkedItem?: (string | number)[];
  setCheckedItem?: any;
  sticky: Sticky;
  addMemberModal?: any;
  page?: string;
  appState?: any;
  teamLength?: number;
  plan?: string;
  useMaxH?: boolean;
  infiniteScroll?: boolean;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  isFetchingNextPage?: boolean;
  useTdHeight?: boolean;
  setAddMemberModalOpenedFor?: (value: string) => void;
  setInitialRender?: (value: boolean) => void;
};

export function DataGrid<Data extends object>({
  page,
  appState,
  addMemberModal,
  team,
  data,
  columns,
  showLoader = false,
  skeletonLoader,
  maxH,
  checkedItem,
  setCheckedItem,
  sticky,
  teamLength,
  plan,
  infiniteScroll = false,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  useMaxH = false,
  useTdHeight,
  setAddMemberModalOpenedFor,
  setInitialRender,
}: DataGridProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [selections, setSelections] = React.useState<any>([]);
  const [rowSelection, setRowSelection] = React.useState<any>([]);
  const [expanded, setExpanded] = React.useState({});

  const { ref, inView } = useInView();

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row: any) => row.subTeams,
    state: {
      sorting,
      rowSelection,
      expanded,
    },
  });

  const handleRowClick = (e: React.MouseEvent, row: any) => {
    const target = e.target as any;

    if (target.tagName !== 'INPUT' || target.type !== 'checkbox') {
      return;
    }

    let selection = [...selections];
    const rowData = row.original;
    const name = rowData.name;

    const keyIndex = selection.indexOf(row.id);

    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      // selection.splice(0, selection.length);
      selection.push(row.id);
    }

    setSelections(selection);

    if (setCheckedItem) {
      let checkedNames = [...(checkedItem || [])];
      const nameIndex = checkedNames.indexOf(name);
      if (nameIndex >= 0) {
        checkedNames = [
          ...checkedNames.slice(0, nameIndex),
          ...checkedNames.slice(nameIndex + 1),
        ];
      } else {
        checkedNames.push(name);
      }
      setCheckedItem(checkedNames);
    }
  };

  React.useEffect(() => {
    if (infiniteScroll) {
      if (inView) {
        if (hasNextPage) {
          fetchNextPage?.();
        }
      }
    }
  }, [inView]);

  return (
    <>
      <TableContainer maxH={maxH} useMaxH={useMaxH}>
        {skeletonLoader ? (
          <Stack spacing={3}>
            {new Array(12).fill(null)?.map((_, index: number) => (
              <Skeleton key={index} height="48px" width="full" />
            ))}
          </Stack>
        ) : (
          <Table
            className={
              sticky === 'firstCol'
                ? 'sticky-table-column1'
                : sticky === 'secondCol98'
                ? 'sticky-table-column1 secondCol'
                : ''
            }
          >
            <Thead
              position={'sticky'}
              bg="#fff"
              top={0}
              zIndex={2}
              boxShadow="0 1px 2px rgba(0,0,0,0.05)"
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const meta: any = header.column.columnDef.meta;

                    return (
                      <Tooltip
                        key={header.id}
                        aria-label="table header"
                        label={flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        placement="bottom"
                        isDisabled={header.id === 'editAndArchive'}
                      >
                        <Th
                          key={header.id}
                          textTransform="none"
                          onClick={header.column.getToggleSortingHandler()}
                          isNumeric={meta?.isNumeric}
                          boxShadow="inset 0 0 0 0.2px rgba(0,0,0,0.1)"
                          fontSize="xs"
                          fontFamily="heading"
                          fontWeight="semibold"
                          color="text.secondary"
                          {...{
                            colSpan: header.colSpan,
                          }}
                        >
                          <Flex
                            align="center"
                            {...{
                              style: {
                                width: header.getSize(),
                              },
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                            <Box as="div" pl={2}>
                              {header.column.getIsSorted() ? (
                                header.column.getIsSorted() === 'desc' ? (
                                  <GoTriangleDown aria-label="sorted descending" />
                                ) : (
                                  <GoTriangleUp aria-label="sorted ascending" />
                                )
                              ) : null}
                            </Box>
                          </Flex>
                        </Th>
                      </Tooltip>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr
                  bg={
                    row.depth === 0
                      ? ''
                      : row.depth === 1
                      ? '#F6F7F9'
                      : undefined
                  }
                  // bg={row && selections.includes(`${row.id}`) && 'primaryBg'}
                  key={row.id}
                  _hover={{
                    backgroundColor:
                      row && selections.includes(`${row.id}`)
                        ? 'primaryBg'
                        : '#E3E6EA',
                  }}
                  onClick={(e) => handleRowClick(e, row)}
                >
                  {row.getVisibleCells().map((cell) => {
                    const meta: any = cell.column.columnDef.meta;

                    return (
                      <Td
                        key={cell.id}
                        fontFamily="heading"
                        color="text.primary"
                        fontSize="sm"
                        fontWeight="medium"
                        height={useTdHeight ? '124px' : undefined}
                        isNumeric={meta?.isNumeric}
                        boxShadow="inset 0 0 0 0.2px rgba(0,0,0,0.1)"
                        {...{
                          style: {
                            width: cell.column.getSize(),
                          },
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}

        {!showLoader &&
          !skeletonLoader &&
          table.getRowModel().rows.length < 1 && (
            <NoData
              teamLength={teamLength}
              plan={plan}
              appState={appState}
              page={page}
              addMemberModal={addMemberModal}
              setAddMemberModalOpenedFor={setAddMemberModalOpenedFor}
              team={team}
              setInitialRender={setInitialRender}
            />
          )}
        {showLoader && !skeletonLoader && <Loader />}
        {(hasNextPage || isFetchingNextPage) && (
          <Flex mt={6} height="20px" justify="center" align="center">
            <Spinner />{' '}
            <Text
              ml={2}
              fontFamily="heading"
              color="text.secondary3"
              fontSize="sm"
            >
              Loading more data
            </Text>
          </Flex>
        )}
        <Button ref={ref} visibility={'hidden'}>
          hidden
        </Button>
      </TableContainer>
    </>
  );
}
