import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  Input,
  IconButton,
  InputGroup,
  Tooltip,
} from '@chakra-ui/react';
import {
  ISelect,
  PlusIcon,
  TeamMenu,
  TrashIcon,
  useToastHook,
} from '@devd-client/devd/components';
import { Formik, Form, FieldArray, getIn, FormikHelpers } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { generateId } from '../../../helpers/Initiatives.utils';
import AddMembersModal from '../components/AddMembersModal';
import { AllocationFormValues } from '../../../helpers/Initiatives.types';
import { validationSchema } from './validationSchema';
import { useGetAllocation, useUpdateAllocationMutation } from '../../../apis';

interface AddAllocationProps {
  initiativeId: string;
  isEdit?: boolean;
  onPrevious: () => void;
}

const getInitialValues = (
  isEdit: boolean,
  allocationData: { dto?: any }
): AllocationFormValues => {
  const defaultValues: AllocationFormValues = {
    allocations: [],
  };

  // Return default empty values if not in edit mode or no data
  if (!isEdit || !allocationData?.dto) {
    return defaultValues;
  }

  return {
    allocations: allocationData?.dto?.map((item: any) => ({
      id: generateId('allocation'),
      team: {
        value: item.team || '',
        label: item.team || '',
        isdisabled: false,
      },
      memberCount: item.memberCount || 0,
      members: Array.isArray(item?.membersAllocation)
        ? item?.membersAllocation?.map((memberAlloc: any) => ({
            id: memberAlloc?.member,
            name: memberAlloc?.memberName || memberAlloc?.member,
            selected: true,
            allocation: memberAlloc?.allocation?.toString() || '',
          }))
        : [],
    })),
  };
};

export const AddAllocation = ({
  initiativeId,
  isEdit,
  onPrevious,
}: AddAllocationProps) => {
  const [activeModalIndex, setActiveModalIndex] = useState<number | null>(null);
  const [newToast] = useToastHook();
  const { mutate: updateAllocation, isLoading } = useUpdateAllocationMutation();
  const { data: allocationData } = useGetAllocation(initiativeId);
  const formRef = useRef<any>(null);

  const handleSubmit = async (
    values: AllocationFormValues,
    { setSubmitting }: FormikHelpers<AllocationFormValues>
  ) => {
    try {
      const payload = values?.allocations?.map((allocation) => ({
        team: allocation?.team?.value,
        memberCount: allocation?.memberCount || 0,
        membersAllocation: allocation?.members
          ?.filter((member) => member?.selected)
          ?.map((member) => ({
            member: member?.id,
            allocation: parseInt(member?.allocation) || 0,
          })),
      }));

      updateAllocation(
        {
          initiativeId: initiativeId,
          allocationList: payload,
        },
        {
          onSuccess: () => {
            newToast({
              message: 'Allocation updated successfully!',
              status: 'success',
            });
          },
          onError: (error) => {
            newToast({
              message: 'Something went wrong!',
              status: 'error',
            });
          },
        }
      );
    } catch (error) {
      console.error('Error submitting allocations:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const formKey = useMemo(
    () =>
      `${initiativeId}-${
        allocationData?.dto ? JSON.stringify(allocationData?.dto) : 'empty'
      }`,
    [initiativeId, allocationData]
  );

  const initialValues = useMemo(
    () => getInitialValues(isEdit || false, allocationData),
    [isEdit, allocationData]
  );

  useEffect(() => {
    if (formRef.current) {
      formRef.current.resetForm({ values: initialValues });
    }
  }, [allocationData, initialValues]);

  return (
    <Formik
      key={formKey}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({ values, isSubmitting, setFieldValue, errors, touched }) => (
        <Form ref={formRef}>
          <FieldArray name="allocations">
            {({ push: pushAllocation, remove: removeAllocation }) => (
              <>
                {values?.allocations?.map((allocation, index) => (
                  <Box key={allocation?.id} mb={6}>
                    <Flex gap={4} alignItems="flex-start">
                      <Flex flexDirection="column" gap={1}>
                        <Text
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.gray.300"
                        >
                          Team
                        </Text>
                        <Box>
                          <TeamMenu
                            selectedTeam={allocation?.team}
                            handleSelectedTeam={(selectedTeam: ISelect) => {
                              const isTeamAlreadySelected =
                                values.allocations.some(
                                  (alloc, idx) =>
                                    idx !== index &&
                                    alloc.team.value === selectedTeam.value
                                );

                              if (isTeamAlreadySelected) {
                                newToast({
                                  message: `${selectedTeam.label} team is already selected in another allocation`,
                                  status: 'error',
                                });
                                return;
                              }

                              setFieldValue(`allocations.${index}.members`, []);
                              setFieldValue(
                                `allocations.${index}.team`,
                                selectedTeam
                              );
                            }}
                            fullHeight
                            hideShowDetails
                          />
                          {getIn(touched, `allocations.${index}.team`) &&
                            getIn(
                              errors,
                              `allocations.${index}.team.value`
                            ) && (
                              <Text color="error" fontSize="sm" mt={1}>
                                {getIn(
                                  errors,
                                  `allocations.${index}.team.value`
                                )}{' '}
                              </Text>
                            )}
                        </Box>
                      </Flex>

                      <Flex flexDirection="column" gap={1}>
                        <Text
                          fontSize="sm"
                          fontWeight="medium"
                          color="text.gray.300"
                        >
                          No. of Members
                        </Text>
                        <Box>
                          <InputGroup>
                            <Input
                              value={
                                allocation?.memberCount > 0
                                  ? allocation?.memberCount
                                  : ''
                              }
                              height="40px"
                              width="220px"
                              border="1px solid"
                              borderColor="#E0E0E0"
                              borderRadius="4px"
                              backgroundColor="white"
                              type="number"
                              min="0"
                              placeholder="0"
                              onChange={(e) => {
                                const newCount =
                                  e.target.value === ''
                                    ? 0
                                    : parseInt(e.target.value);
                                setFieldValue(
                                  `allocations.${index}.memberCount`,
                                  newCount
                                );
                              }}
                            />
                          </InputGroup>
                          {getIn(touched, `allocations.${index}.memberCount`) &&
                            getIn(
                              errors,
                              `allocations.${index}.memberCount`
                            ) && (
                              <Text color="error" fontSize="sm" mt={1}>
                                {getIn(
                                  errors,
                                  `allocations.${index}.memberCount`
                                )}
                              </Text>
                            )}
                        </Box>
                      </Flex>

                      <Button
                        variant="custom-outline"
                        onClick={() => setActiveModalIndex(index)}
                        mt="24px"
                        isDisabled={
                          allocation?.memberCount === 0 ||
                          !allocation?.team?.value
                        }
                      >
                        Add Members
                      </Button>
                      <Tooltip
                        label="Remove Allocation"
                        placement="right"
                        hasArrow
                      >
                        <IconButton
                          variant="ghost"
                          onClick={() => removeAllocation(index)}
                          aria-label="Remove"
                          icon={<TrashIcon />}
                          mt="24px"
                        />
                      </Tooltip>
                    </Flex>

                    {activeModalIndex === index && (
                      <AddMembersModal
                        isOpen={true}
                        onClose={() => setActiveModalIndex(null)}
                        team={allocation?.team?.value}
                        selectedMembers={allocation?.members}
                        maxAllowedMembers={allocation?.memberCount || 0}
                        onSubmit={(selectedMembers) => {
                          setFieldValue(
                            `allocations.${activeModalIndex}.members`,
                            selectedMembers
                          );
                          setActiveModalIndex(null);
                        }}
                        initiativeId={initiativeId}
                        otherTeamAllocations={(values.allocations || [])
                          ?.filter((_, idx) => idx !== index)
                          ?.map((alloc) => ({
                            team: alloc?.team?.value || '',
                            members: alloc?.members || [],
                          }))}
                      />
                    )}
                  </Box>
                ))}

                <Button
                  mt={4}
                  backgroundColor="#F9F8F8"
                  leftIcon={<PlusIcon color="#2A2A2F" />}
                  border="1px solid #E0E0E0"
                  fontSize={'sm'}
                  fontWeight={'semibold'}
                  color={'text.gray.300'}
                  borderRadius="4px"
                  width="fit-content"
                  onClick={() =>
                    pushAllocation({
                      id: generateId('allocation'),
                      team: { value: '', label: '', isdisabled: false },
                      memberCount: 0,
                      members: [],
                    })
                  }
                >
                  Add New Allocation
                </Button>
              </>
            )}
          </FieldArray>

          <Divider my={4} />

          <Flex gap={2}>
            <Button variant={'filled'} onClick={onPrevious}>
              Previous
            </Button>
            <Button
              type="submit"
              variant={'filled'}
              isLoading={isSubmitting || isLoading}
              isDisabled={isSubmitting || isLoading}
            >
              Save
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
