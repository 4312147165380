export function VelocityIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_2210_374)">
        <path
          d="M3.33331 8.00016C3.33331 5.42283 5.42265 3.3335 7.99998 3.3335M10.9999 5.00016L7.99992 8.00016M14.6666 8.00016C14.6666 11.6821 11.6819 14.6668 7.99998 14.6668C4.31808 14.6668 1.33331 11.6821 1.33331 8.00016C1.33331 4.31826 4.31808 1.3335 7.99998 1.3335C11.6819 1.3335 14.6666 4.31826 14.6666 8.00016ZM8.66665 8.00016C8.66665 8.36835 8.36817 8.66683 7.99998 8.66683C7.63179 8.66683 7.33331 8.36835 7.33331 8.00016C7.33331 7.63197 7.63179 7.3335 7.99998 7.3335C8.36817 7.3335 8.66665 7.63197 8.66665 8.00016Z"
          stroke={props.color || '#626266'}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2210_374">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
