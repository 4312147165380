// React imports

import {
  useState,
  useMemo,
  Fragment,
  ReactElement,
  useCallback,
  useRef,
  Children,
  useEffect,
} from 'react';

// chakra UI imports
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Tooltip,
  Flex,
  Skeleton,
  Stack,
  Text,
  Spinner,
  useTheme,
} from '@chakra-ui/react';

// tanstack imports
import {
  useReactTable,
  flexRender,
  getFilteredRowModel,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  FilterFn,
  getExpandedRowModel,
  Row,
  OnChangeFn,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';

// Components
import NoData from './NoData';
import {
  Loader,
  useMetricsTableStore,
  useQueryState,
} from '@devd-client/devd/components';

// styles and icons
import './styles.css';
import {
  MdArrowRight,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import {
  FileCodeIcon,
  GitMergeIcon,
  HourGlassIcon,
  SortIcon,
  StopWatchIcon,
  LinkBroken,
} from '@devd-client/devd/components';
import NoIntegration from './NoData/noIntegration';
import { useLocation } from 'react-router';
import useMetricTableHelpers from '../Filters/hooks/useMetricTableHelpers';

type Sticky = 'firstCol' | 'secondCol98' | 'none';
interface DataGridProps {
  data: any;
  skeletonLoader?: boolean;
  showLoader?: boolean;
  isFetching: boolean;
  isError: boolean;
  isLoading: boolean;
  fetchNextPage: () => void;
  startDate: any;
  endDate: any;
  totalDBrows: number;
  columns: any;
  isFetched: boolean;
  isIgnorePrOrIssueLoading?: boolean;
  integration: { type: string; active: boolean };
}

// Below is what displays the breakdown row.
const ExpandableContent = ({ row }: { row: Row<any> }) => {
  return (
    <Box p={2}>
      <Flex align="center">
        {row.original?.breakdowns?.map((item: any, index: number) => {
          const negativePattern = /-\d+/;
          return (
            <Fragment key={index}>
              <Flex align="center" mr={2}>
                <Tooltip
                  hasArrow
                  label={
                    item.value
                      ? item.title === 'Waiting Time'
                        ? item.title
                        : negativePattern.test(item.value ?? '-00d')
                        ? `No ${item.title} Time`
                        : `${item.title} Time`
                      : item.title === 'Waiting Time'
                      ? `No ${item.title}`
                      : `No ${item.title} Time`
                  }
                >
                  <Flex align={'center'}>
                    <Box ml={index !== 0 ? 3 : 'auto'}>
                      {!item.value ||
                      negativePattern.test(item.value ?? '-00d') ? (
                        <LinkBroken fill={'white'} />
                      ) : item.icon === 'wait_time' ? (
                        <HourGlassIcon />
                      ) : item.icon === 'coding_time' ? (
                        <FileCodeIcon />
                      ) : item.icon === 'pickup_time' ? (
                        <StopWatchIcon />
                      ) : item.icon === 'merge_time' ? (
                        <GitMergeIcon />
                      ) : (
                        <></>
                      )}
                    </Box>

                    {index < 4 && (
                      <Text
                        color="text.secondary2"
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="normal"
                        minW={
                          !negativePattern.test(item.value ?? '-00d')
                            ? 'max-content'
                            : 30
                        }
                        ml={item.value && index !== 0 ? 1 : 'auto'}
                      >
                        {negativePattern.test(item.value ?? '-00d')
                          ? ''
                          : item.value}
                      </Text>
                    )}
                  </Flex>
                </Tooltip>
              </Flex>

              {index < 3 &&
                (item.value ? (
                  <Box position="relative">
                    <Box className="dashed-line variant1" w={200} />
                    <Box position="absolute" left={195} top={'-11px'}>
                      <MdArrowRight size={24} color={'#8F8F91'} />
                    </Box>
                  </Box>
                ) : (
                  <Box position="relative">
                    <Box className="dashed-line variant1" w={240} />
                    <Box position="absolute" left={230} top={'-11px'}>
                      <MdArrowRight size={24} color={'#8F8F91'} />
                    </Box>
                  </Box>
                ))}
            </Fragment>
          );
        })}
      </Flex>
    </Box>
  );
};

export default function DataGrid({
  data,
  isFetching,
  isError,
  isLoading,
  fetchNextPage,
  startDate,
  endDate,
  totalDBrows,
  showLoader = false,
  skeletonLoader = false,
  isIgnorePrOrIssueLoading = false,
  columns,
  isFetched,
  integration,
}: DataGridProps) {
  const { colors } = useTheme();
  const { scrollToTop } = useMetricTableHelpers();
  // const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<string | object>();
  const [rowSelection, setRowSelection] = useState<any>([]);
  const [expanded, setExpanded] = useState({});
  const location = useLocation();

  //The virtualizer needs to know the scrollable container element
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const totalDBRowCount = totalDBrows ?? 0;
  const totalFetched = data?.length;

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 1000px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 1000 &&
          !isFetching &&
          totalFetched < totalDBRowCount
          // && !isError
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const table = useReactTable({
    columns,
    data,
    //     filterFns: localFilterFns,
    //     globalFilterFn: localFilterFns.fuzzy, //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
    getCoreRowModel: getCoreRowModel(),
    // getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSubRows: (row: any) => row.breakdown,
    state: {
      // sorting,
      globalFilter,
      rowSelection,
      expanded,
    },
    // manualSorting: true, //use pre-sorted row model instead of sorted row model
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setGlobalFilter,
    // onSortingChange: setSorting,
    onExpandedChange: setExpanded,
  });

  // //scroll to top of table when sorting changes
  // const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
  //   setSorting(updater);
  //   if (table.getRowModel().rows.length) {
  //     rowVirtualizer.scrollToIndex?.(0);
  //   }
  // };

  // //   sever side sorting
  // useEffect(() => {
  //   if (sorting.length > 0) {
  //     handleMetricFilterBy('sorting', sorting[0].id, sorting[0].desc);
  //   } else {
  //     handleMetricFilterBy('sorting', 'none', false);
  //   }
  // }, [sorting]);

  // //since this table option is derived from table row model state, we're using the table.setOptions utility
  // table.setOptions((prev) => ({
  //   ...prev,
  //   onSortingChange: handleSortingChange,
  // }));

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  useEffect(() => {
    rowVirtualizer.scrollToIndex?.(0);
  }, [scrollToTop]);

  const metricFilterBy = useMetricsTableStore();

  return integration.active ? (
    <Box
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      ref={tableContainerRef}
      style={{ overflow: 'auto', position: 'relative', height: '80vh' }}
      w={'full'}
    >
      {!isFetched &&
        !['contributors'].includes(location.pathname.split('/')[1]) && (
          <Loader />
        )}
      {isIgnorePrOrIssueLoading && <Loader />}
      {!isFetched &&
        ['contributors'].includes(location.pathname.split('/')[1]) && (
          <Spinner
            position={'absolute'}
            zIndex={100}
            top="40%"
            left="50%"
            thickness="6px"
            speed="0.8s"
            emptyColor="gray.200"
            size="xl"
            color={colors.primary}
          />
        )}

      {skeletonLoader ? (
        <Stack spacing={3}>
          {new Array(12).fill(null)?.map((_, index: number) => (
            <Skeleton key={index} height="48px" width="full" />
          ))}
        </Stack>
      ) : (
        <Table style={{ display: 'grid' }}>
          <Thead
            bg="#FBFBFB"
            style={{ display: 'grid', position: 'sticky', top: 0, zIndex: 1 }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr
                key={headerGroup.id}
                style={{ display: 'flex', width: '100%' }}
              >
                {headerGroup.headers.map((header) => {
                  const meta: any = header.column.columnDef.meta;
                  return ['prDetail', 'teams', 'reviewer'].includes(
                    header.id
                  ) ? (
                    // <Tooltip
                    //   label={
                    //     header.id === 'prDetail'
                    //       ? 'Sort by PR Size'
                    //       : header.id === 'teams'
                    //       ? 'Sort by No. of Teams'
                    //       : 'No. of reviewers'
                    //   }
                    //   hasArrow
                    //   placement="bottom-start"
                    // >
                    <Th
                      style={{
                        display: 'flex',
                        width: header.getSize(),
                      }}
                      textTransform="none"
                      // onClick={
                      //   header.id !== 'author'
                      //     ? header.column.getToggleSortingHandler()
                      //     : () => {
                      //         return;
                      //       }
                      // }
                      // cursor={header.id !== 'author' ? 'pointer' : 'auto'}
                      isNumeric={meta?.isNumeric}
                      fontSize="sm"
                      bg="#FBFBFB"
                      fontFamily="heading"
                      fontWeight="medium"
                      letterSpacing={0}
                      color="text.secondary2"
                      {...{
                        key: header.id,
                        colSpan: header.colSpan,
                      }}
                    >
                      <Flex
                        align="center"
                        userSelect={'none'}
                        {...{
                          style: {
                            width: header.getSize(),
                          },
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}

                        {/* {header.id !== 'author' ? (
                            <Box as="div" pl={2} userSelect={'initial'}>
                              {header.column.getIsSorted() ? (
                                header.column.getIsSorted() === 'desc' ? (
                                  <MdKeyboardArrowDown
                                    aria-label="sorted descending"
                                    color={'#2A2A2F'}
                                  />
                                ) : (
                                  <MdKeyboardArrowUp
                                    aria-label="sorted ascending"
                                    color={'#2A2A2F'}
                                  />
                                )
                              ) : (
                                <Flex direction="column">
                                  <SortIcon />
                                </Flex>
                              )}
                            </Box>
                          ) : (
                            <></>
                          )} */}
                      </Flex>
                    </Th>
                  ) : (
                    // </Tooltip>
                    <Th
                      style={{
                        display: 'flex',
                        width: header.getSize(),
                      }}
                      textTransform="none"
                      // onClick={
                      //   header.id !== 'author'
                      //     ? header.column.getToggleSortingHandler()
                      //     : () => {
                      //         return;
                      //       }
                      // }
                      // cursor={header.id !== 'author' ? 'pointer' : 'auto'}
                      isNumeric={meta?.isNumeric}
                      fontSize="sm"
                      bg="#FBFBFB"
                      fontFamily="heading"
                      fontWeight="medium"
                      letterSpacing={0}
                      color="text.secondary2"
                      {...{
                        key: header.id,
                        colSpan: header.colSpan,
                      }}
                    >
                      <Flex
                        align="center"
                        userSelect={'none'}
                        {...{
                          style: {
                            width: header.getSize(),
                          },
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}

                        {/* {header.id !== 'author' ? (
                          <Box as="div" pl={2} userSelect={'initial'}>
                            {header.column.getIsSorted() ? (
                              header.column.getIsSorted() === 'desc' ? (
                                <MdKeyboardArrowDown
                                  aria-label="sorted descending"
                                  color={'#2A2A2F'}
                                />
                              ) : (
                                <MdKeyboardArrowUp
                                  aria-label="sorted ascending"
                                  color={'#2A2A2F'}
                                />
                              )
                            ) : (
                              <Flex direction="column">
                                <SortIcon />
                              </Flex>
                            )}
                          </Box>
                        ) : (
                          <></>
                        )} */}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody
            style={{
              display: 'grid',
              height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              position: 'relative', //needed for absolute positioning of rows
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<any>;
              return (
                <Fragment key={row.id}>
                  <Tr
                    data-index={virtualRow.index}
                    ref={(node) => rowVirtualizer.measureElement(node)}
                    key={row.id}
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                      width: '100%',
                    }}
                    bg={
                      row.depth === 0
                        ? ''
                        : row.depth === 1
                        ? '#F6F7F9'
                        : undefined
                    }
                    borderBlockEnd={'1px solid'}
                    borderColor={'#EDF2F7'}
                    paddingBottom={1}
                    // borderBottom="none"
                  >
                    {row.getVisibleCells().map((cell) => {
                      const meta: any = cell.column.columnDef.meta;
                      return (
                        <Td
                          key={cell.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: cell.column.getSize(),
                          }}
                          fontFamily="heading"
                          color="text.primary"
                          fontSize="sm"
                          verticalAlign={'top'}
                          fontWeight="medium"
                          isNumeric={meta?.isNumeric}
                          border={'none'}
                          pb={metricFilterBy.tableType === 'PR' ? 5 : 3}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}

                    {metricFilterBy.tableType === 'PR' ? (
                      <Td
                        pt={0}
                        pb={0}
                        width={'75%'}
                        top={100}
                        right={0}
                        position={'absolute'}
                        border={'none'}
                      >
                        <ExpandableContent row={row} />
                      </Td>
                    ) : (
                      <></>
                    )}
                  </Tr>
                </Fragment>
              );
            })}
          </Tbody>
        </Table>
      )}
      {!isFetching &&
        !showLoader &&
        !skeletonLoader &&
        table.getRowModel().rows.length < 1 && <NoData />}
      {isFetched && isFetching && !skeletonLoader && (
        <Flex justifyContent={'center'} my={10}>
          <Spinner
            thickness="6px"
            speed="0.8s"
            emptyColor="gray.200"
            size="xl"
            color={colors.primary}
          />
        </Flex>
      )}
    </Box>
  ) : (
    <NoIntegration integration={integration} />
  );
}
