export function InProgressStatusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="8"
        cy="8"
        r="7"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-dasharray="3 3"
      />
      <path
        d="M3 8C3 8.98891 3.29325 9.95561 3.84265 10.7779C4.39206 11.6001 5.17295 12.241 6.08658 12.6194C7.00021 12.9978 8.00555 13.0969 8.97545 12.9039C9.94536 12.711 10.8363 12.2348 11.5355 11.5355C12.2348 10.8363 12.711 9.94536 12.9039 8.97545C13.0969 8.00555 12.9978 7.00021 12.6194 6.08658C12.241 5.17295 11.6001 4.39206 10.7779 3.84265C9.95561 3.29325 8.98891 3 8 3L8 8L3 8Z"
        fill={props.color || '#2A2A2F'}
      />
    </svg>
  );
}
