import { Box, Flex } from '@chakra-ui/react';
import TaskDistributionItem from './TastDistributionItem';

type Props = { data: any };

const TaskDistribution = ({ data }: Props) => {
  return (
    <Flex w="full" gap={2} direction="row">
      <TaskDistributionItem
        status="To Do"
        item={data?.todoData}
        fillColor="#DBDBFF"
      />

      <Box height="112px" width="1px" bg="text.tertiary" />

      <TaskDistributionItem
        status="In Progress"
        item={data?.inProgressData}
        fillColor="#9494FF"
      />

      <Box height="112px" width="1px" bg="text.tertiary" />

      <TaskDistributionItem
        status="Done"
        item={data?.doneData}
        fillColor="#4D4DFF"
      />
    </Flex>
  );
};

export default TaskDistribution;
