export function PrIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_6899_201)">
        <path
          d="M1.08594 2.92969C1.08594 3.41868 1.28019 3.88765 1.62596 4.23342C1.97173 4.57919 2.44069 4.77344 2.92969 4.77344C3.41868 4.77344 3.88765 4.57919 4.23342 4.23342C4.57919 3.88765 4.77344 3.41868 4.77344 2.92969C4.77344 2.44069 4.57919 1.97173 4.23342 1.62596C3.88765 1.28019 3.41868 1.08594 2.92969 1.08594C2.44069 1.08594 1.97173 1.28019 1.62596 1.62596C1.28019 1.97173 1.08594 2.44069 1.08594 2.92969Z"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.08594 13.0703C1.08594 13.5593 1.28019 14.0283 1.62596 14.374C1.97173 14.7198 2.44069 14.9141 2.92969 14.9141C3.41868 14.9141 3.88765 14.7198 4.23342 14.374C4.57919 14.0283 4.77344 13.5593 4.77344 13.0703C4.77344 12.5813 4.57919 12.1124 4.23342 11.7666C3.88765 11.4208 3.41868 11.2266 2.92969 11.2266C2.44069 11.2266 1.97173 11.4208 1.62596 11.7666C1.28019 12.1124 1.08594 12.5813 1.08594 13.0703Z"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.92969 4.77344V11.2266"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.2266 13.0703C11.2266 13.3124 11.2743 13.5522 11.3669 13.7759C11.4596 13.9996 11.5954 14.2028 11.7666 14.374C11.9378 14.5452 12.141 14.6811 12.3647 14.7737C12.5884 14.8664 12.8282 14.9141 13.0703 14.9141C13.3124 14.9141 13.5522 14.8664 13.7759 14.7737C13.9996 14.6811 14.2028 14.5452 14.374 14.374C14.5452 14.2028 14.6811 13.9996 14.7737 13.7759C14.8664 13.5522 14.9141 13.3124 14.9141 13.0703C14.9141 12.8282 14.8664 12.5884 14.7737 12.3647C14.6811 12.141 14.5452 11.9378 14.374 11.7666C14.2028 11.5954 13.9996 11.4596 13.7759 11.3669C13.5522 11.2743 13.3124 11.2266 13.0703 11.2266C12.8282 11.2266 12.5884 11.2743 12.3647 11.3669C12.141 11.4596 11.9378 11.5954 11.7666 11.7666C11.5954 11.9378 11.4596 12.141 11.3669 12.3647C11.2743 12.5884 11.2266 12.8282 11.2266 13.0703Z"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.0703 11.2266V6.0026C13.0703 5.59906 12.9908 5.19947 12.8364 4.82665C12.682 4.45383 12.4556 4.11507 12.1703 3.82972C11.8849 3.54438 11.5462 3.31803 11.1734 3.1636C10.8005 3.00917 10.4009 2.92969 9.9974 2.92969H7.53906"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.38281 1.08594L7.53906 2.92969L9.38281 4.77344"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6899_201">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PrIcon;
