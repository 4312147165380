import { Box, Flex, Skeleton, Tag, Text } from '@chakra-ui/react';
import {
  camelCaseToCapitalize,
  getStatusIcon,
} from '../../../helpers/Initiatives.utils';
import moment from 'moment';

type Props = {
  calculatedStatus: any;
  status: any;
  isLoading: boolean;
};

const StatusCol = ({ calculatedStatus, status, isLoading }: Props) => {
  return (
    <Flex gap={4} direction="column">
      <Flex align="center" gap={2}>
        {isLoading ? (
          <Skeleton height="20px" width="60%" />
        ) : (
          <Flex gap={1} align="center">
            {getStatusIcon(status)}
            <Text fontSize="sm" color="text.primary" fontWeight={'medium'}>
              {camelCaseToCapitalize(status)}
            </Text>
          </Flex>
        )}

        {/* <Text
          borderBottom={'2px dashed'}
          borderColor="text.secondary2"
          color="text.secondary2"
          fontWeight="normal"
        >
          <Text fontSize="sm" as="span">
            {'160'}
          </Text>{' '}
          <Text as="span" fontSize="xxs">
            {'hours'}
          </Text>
        </Text> */}
      </Flex>
      {isLoading ? (
        <Skeleton height="18px" width="45%" rounded={'md'} />
      ) : (
        <Box>
          <Tag
            size="sm"
            style={{
              backgroundColor: `${calculatedStatus?.status?.color}90`,
            }}
            fontSize="xxs"
          >
            {camelCaseToCapitalize(calculatedStatus?.status?.label)}
          </Tag>
          {calculatedStatus?.projectedEndDate ? (
            <Flex
              fontSize={'xs'}
              color={'text.secondary2'}
              mt={4}
              gap={1}
              align="center"
            >
              <Text fontWeight="medium">Projected End:</Text>
              <Text>
                {moment(calculatedStatus?.projectedEndDate).format(
                  'MMM DD, YYYY'
                )}
              </Text>
            </Flex>
          ) : null}
        </Box>
      )}
    </Flex>
  );
};

export default StatusCol;
