import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { TextInput, useToastHook } from '@devd-client/devd/components';
import { useEditInitiative } from '../../../apis';
import { useQueryClient } from '@tanstack/react-query';
import { getFormattedDate } from '../../../helpers/Initiatives.utils';
import { validationSchema } from './validationSchema';

interface EditInitiativeDescriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  initiativeState: any;
}

const EditInitiativeDescriptionModal = ({
  isOpen,
  onClose,
  initiativeState,
}: EditInitiativeDescriptionModalProps) => {
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const { mutate: handleEditInitiative, isLoading } = useEditInitiative();

  const onSubmit = (values: { desc: string }) => {
    const payload = {
      name: initiativeState.name,
      desc: values.desc,
      startDate: getFormattedDate(new Date(initiativeState.startDate)),
      plannedDate: getFormattedDate(new Date(initiativeState.plannedDate)),
      contributionTracking: initiativeState.contributionTracking,
      issueFilterConditions: initiativeState.issueFilterConditions,
    };
    handleEditInitiative(
      { ...payload, id: initiativeState.id },
      {
        onSuccess: () => {
          newToast({
            message: 'Initiative description updated successfully!',
            status: 'success',
          });
          queryClient.invalidateQueries(['deliverableData']);
          setTimeout(() => {
            onClose();
          }, 200);
        },
        onError: () => {
          newToast({
            message: 'Something went wrong!',
            status: 'error',
          });
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Initiative Description</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{ desc: initiativeState?.desc || '' }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <ModalBody>
                <TextInput
                  name="desc"
                  label="Description"
                  inputType="textarea"
                  placeholder="Initiative Description"
                  autoFocus
                />
              </ModalBody>
              <ModalFooter gap={2}>
                <Button variant="ghost" onClick={onClose} size="sm">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="filled"
                  isLoading={isLoading}
                  size="sm"
                >
                  Save
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default EditInitiativeDescriptionModal;
