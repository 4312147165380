export function SubTask(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M104 8H24C15.1634 8 8 15.1634 8 24V104C8 112.837 15.1634 120 24 120H104C112.837 120 120 112.837 120 104V24C120 15.1634 112.837 8 104 8Z"
        fill="#4BAEE8"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.4 36C37.0745 36 36 37.0745 36 38.4V65.6C36 66.9255 37.0745 68 38.4 68H65.6C66.9255 68 68 66.9255 68 65.6V38.4C68 37.0745 66.9255 36 65.6 36H38.4ZM28 38.4C28 32.6562 32.6562 28 38.4 28H65.6C71.3438 28 76 32.6562 76 38.4V65.6C76 71.3438 71.3438 76 65.6 76H38.4C32.6562 76 28 71.3438 28 65.6V38.4Z"
        fill="white"
      />
      <path
        d="M89.6 56H62.4C58.8654 56 56 58.8654 56 62.4V89.6C56 93.1346 58.8654 96 62.4 96H89.6C93.1346 96 96 93.1346 96 89.6V62.4C96 58.8654 93.1346 56 89.6 56Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62.4 60C61.0745 60 60 61.0745 60 62.4V89.6C60 90.9255 61.0745 92 62.4 92H89.6C90.9255 92 92 90.9255 92 89.6V62.4C92 61.0745 90.9255 60 89.6 60H62.4ZM52 62.4C52 56.6562 56.6562 52 62.4 52H89.6C95.3438 52 100 56.6562 100 62.4V89.6C100 95.3438 95.3438 100 89.6 100H62.4C56.6562 100 52 95.3438 52 89.6V62.4Z"
        fill="white"
      />
    </svg>
  );
}

export default SubTask;
