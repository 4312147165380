export function Defect(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M104 8H24C15.1634 8 8 15.1634 8 24V104C8 112.837 15.1634 120 24 120H104C112.837 120 120 112.837 120 104V24C120 15.1634 112.837 8 104 8Z"
        fill="#E5493A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M103.389 65.6592L103.445 65.6188L91.37 49.4313L91.3137 49.4717C90.5811 48.4843 89.4622 47.8125 88.15 47.8125C86.5722 47.8125 85.244 48.7433 84.5839 50.0626L84.5436 50.0464L76.4775 66.2663L63.7102 34.1664L63.6941 34.1745C63.1064 32.6772 61.6655 31.625 59.975 31.625C58.1315 31.625 56.6503 32.8876 56.1674 34.5792L56.103 34.5549L42.8446 81.2073L31.3734 58.1401L31.3331 58.1563C30.673 56.837 29.3447 55.9063 27.775 55.9063C25.5532 55.9063 23.75 57.7112 23.75 59.9531C23.75 60.6006 23.9351 61.1915 24.2088 61.7418L24.1686 61.758L40.2686 94.133L40.3088 94.1168C40.969 95.4361 42.2972 96.375 43.875 96.375C45.7104 96.375 47.1916 95.1124 47.6746 93.4208L47.747 93.437L60.611 48.1605L72.3398 77.638H72.3478C72.9436 79.1272 74.3764 80.1875 76.075 80.1875C77.6448 80.1875 78.973 79.2486 79.6331 77.9293L79.6734 77.9455L88.8343 59.5242L96.9969 70.4669L97.0533 70.4264C97.7939 71.4139 98.9048 72.0938 100.225 72.0938C102.447 72.0938 104.25 70.2808 104.25 68.0469C104.25 67.1323 103.896 66.331 103.389 65.6592Z"
        fill="white"
      />
    </svg>
  );
}

export default Defect;
