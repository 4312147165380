export function ActivityLogIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M2.73145 4.3118C2.73145 4.73094 2.89795 5.13291 3.19432 5.42928C3.4907 5.72566 3.89267 5.89216 4.3118 5.89216C4.73094 5.89216 5.13291 5.72566 5.42928 5.42928C5.72566 5.13291 5.89216 4.73094 5.89216 4.3118C5.89216 3.89267 5.72566 3.4907 5.42928 3.19432C5.13291 2.89795 4.73094 2.73145 4.3118 2.73145C3.89267 2.73145 3.4907 2.89795 3.19432 3.19432C2.89795 3.4907 2.73145 3.89267 2.73145 4.3118Z"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5791 11.6862C9.5791 12.2451 9.8011 12.7811 10.1963 13.1762C10.5914 13.5714 11.1274 13.7934 11.6862 13.7934C12.2451 13.7934 12.7811 13.5714 13.1762 13.1762C13.5714 12.7811 13.7934 12.2451 13.7934 11.6862C13.7934 11.1274 13.5714 10.5914 13.1762 10.1963C12.7811 9.8011 12.2451 9.5791 11.6862 9.5791C11.1274 9.5791 10.5914 9.8011 10.1963 10.1963C9.8011 10.5914 9.5791 11.1274 9.5791 11.6862Z"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.52637 4.57506C8.52637 5.34347 8.83162 6.08042 9.37497 6.62377C9.91833 7.16712 10.6553 7.47238 11.4237 7.47238C12.1921 7.47238 12.9291 7.16712 13.4724 6.62377C14.0158 6.08042 14.321 5.34347 14.321 4.57506C14.321 3.80664 14.0158 3.06969 13.4724 2.52634C12.9291 1.98299 12.1921 1.67773 11.4237 1.67773C10.6553 1.67773 9.91833 1.98299 9.37497 2.52634C8.83162 3.06969 8.52637 3.80664 8.52637 4.57506Z"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.67773 11.4237C1.67773 12.1921 1.98299 12.9291 2.52634 13.4724C3.06969 14.0158 3.80664 14.321 4.57506 14.321C5.34347 14.321 6.08042 14.0158 6.62377 13.4724C7.16712 12.9291 7.47238 12.1921 7.47238 11.4237C7.47238 10.6553 7.16712 9.91833 6.62377 9.37497C6.08042 8.83162 5.34347 8.52637 4.57506 8.52637C3.80664 8.52637 3.06969 8.83162 2.52634 9.37497C1.98299 9.91833 1.67773 10.6553 1.67773 11.4237Z"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ActivityLogIcon;
