import { Flex, Box, Icon, Text } from '@chakra-ui/react';
import { Bug, Epic, Task } from '@devd-client/devd/components';
import { getLabels } from '../../../../helpers/Initiatives.utils';

const iconMap: Record<string, any> = {
  epicCount: Epic,
  taskCount: Task,
  bugCount: Bug,
};

const TaskDistributionItem = ({ item, status, fillColor }: any) => {
  return (
    <Flex direction="column">
      <Flex gap={1} align="center">
        <Box height="12px" width="12px" bg={fillColor} />
        <Text fontWeight="semibold" fontSize="sm" fontFamily="heading">
          {status}
        </Text>
      </Flex>

      <Box my={2} height="1px" width="full" bg={'text.tertiary'} />

      <Flex direction="column" gap={2}>
        {item &&
          Object?.entries(item)
            ?.filter(([key]) => key !== 'totalCount')
            ?.map(([key, value]: any) => (
              <Flex key={key} align="center" gap={2}>
                <Icon as={iconMap[key]} boxSize={4} />
                <Text
                  as="span"
                  fontSize="sm"
                  color="text.secondary2"
                  fontFamily="heading"
                  fontWeight="medium"
                >
                  {value}
                </Text>{' '}
                <Text
                  fontSize="xs"
                  color="text.secondary2"
                  fontFamily="heading"
                  fontWeight="medium"
                >
                  {getLabels(key, value)}
                </Text>
              </Flex>
            ))}
      </Flex>
    </Flex>
  );
};

export default TaskDistributionItem;
