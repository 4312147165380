import { Icon, IconProps } from '@chakra-ui/react';

export function WrongIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <g clipPath="url(#clip0_3509_675)">
        <path
          d="M10 6.00004L6.00001 10M6.00001 6.00004L10 10M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00001 14.6667C4.31811 14.6667 1.33334 11.6819 1.33334 8.00004C1.33334 4.31814 4.31811 1.33337 8.00001 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z"
          fill="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3509_675">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
