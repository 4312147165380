import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { TreeMapChart } from '@devd-client/devd/components';
import { useDeliverableIssueTypeDistGql } from '../../../graphql';
import { useState } from 'react';

interface IssueTypeCardProps {
  initiativeId: string;
  type: string;
}

export const IssueTypeCard = ({ initiativeId, type }: IssueTypeCardProps) => {
  const [distributionType, setDistributionType] = useState('issueCount');
  const { data, isLoading } = useDeliverableIssueTypeDistGql(
    initiativeId,
    distributionType,
    type,
    ''
  );

  return (
    <Flex
      maxW={'100%'}
      minW={'100%'}
      minH={'244px'}
      border={'1px solid'}
      borderColor={'text.tertiary'}
      rounded={'4px'}
      pt={4}
      px={4}
      pb={2}
      direction={'column'}
      gap={2}
    >
      <Flex justify={'space-between'}>
        <Text fontSize={'sm'} fontWeight={'semibold'} color={'text.gray.300'}>
          Issue Type Distribution
        </Text>
        <Menu>
          <MenuButton
            as={Button}
            size="sm"
            rightIcon={<ChevronDownIcon />}
            variant="outline"
            borderColor="gray.200"
            bg="white"
            _hover={{
              borderColor: 'blue.500',
              bg: 'gray.50',
            }}
            w="140px"
            fontSize="sm"
            fontWeight={'medium'}
          >
            {distributionType === 'issueCount' && 'Issue Count'}
            {distributionType === 'cycleTime' && 'Cycle Time'}
            {distributionType === 'storyPoint' && 'Story Point'}
          </MenuButton>
          <MenuList
            border="1px solid"
            borderColor="gray.200"
            boxShadow="sm"
            minW={'unset'}
            w={'140px'}
            py={1}
          >
            <MenuItem
              bg={distributionType === 'issueCount' ? 'blue.50' : 'white'}
              _hover={{
                bg: distributionType === 'issueCount' ? 'blue.100' : 'gray.50',
              }}
              fontSize="sm"
              onClick={() => setDistributionType('issueCount')}
            >
              Issue Count
            </MenuItem>
            <MenuItem
              bg={distributionType === 'cycleTime' ? 'blue.50' : 'white'}
              _hover={{
                bg: distributionType === 'cycleTime' ? 'blue.100' : 'gray.50',
              }}
              fontSize="sm"
              onClick={() => setDistributionType('cycleTime')}
            >
              Cycle Time
            </MenuItem>
            <MenuItem
              bg={distributionType === 'storyPoint' ? 'blue.50' : 'white'}
              _hover={{
                bg: distributionType === 'storyPoint' ? 'blue.100' : 'gray.50',
              }}
              fontSize="sm"
              onClick={() => setDistributionType('storyPoint')}
            >
              Story Points
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      {isLoading ? (
        <Skeleton h={'164px'} w={'100%'} mt={2} />
      ) : !data || Object.keys(data)?.length === 0 ? (
        <Flex h={'164px'} w={'100%'} mt={2} justify="center" align="center">
          <Text color="text.secondary">No data found</Text>
        </Flex>
      ) : (
        <Box h={'164px'} w={'100%'} mt={2}>
          <TreeMapChart data={data} />
        </Box>
      )}
    </Flex>
  );
};
