import { Box, Flex, Skeleton, Spinner, Text } from '@chakra-ui/react';
import { OverviewCard } from './OverviewCard';
import { ScopeCard } from './ScopeCard';
import { EffortCard } from './EffortCard';
import { IssueTypeCard } from './IssueTypeCard';
import { SprintDeliveryCard } from './SprintDeliveryCard';
import { useGetInitiativeUpdateStatus } from '../../../apis';
import { updatesModalStatusOptions } from '../../../helpers/Initiatives.utils';
import { SignalCard, useQueryState } from '@devd-client/devd/components';
import { useDeliverableSignalsGql } from '../../../graphql';

interface OverviewTabProps {
  setActiveTab: (tab: number) => void;
  initiativeState: any;
  isLoadingInitiativeState?: boolean;
  scopeData: any;
}

export const OverviewTab = ({
  setActiveTab,
  initiativeState,
  isLoadingInitiativeState,
  scopeData,
}: OverviewTabProps) => {
  const [type] = useQueryState('type');
  const { data: signalData, isLoading: signalLoading } =
    useDeliverableSignalsGql(initiativeState?.id, type, '');
  const { data: updateStatus, isLoading: updateStatusLoading } =
    useGetInitiativeUpdateStatus(initiativeState?.id);

  return (
    <Flex
      mt={2}
      maxW={'1200px'}
      gap={4}
      sx={{
        flexDirection: 'column',
        '@media screen and (min-width: 1495px)': {
          flexDirection: 'row',
        },
      }}
    >
      <Flex
        maxW={'864px'}
        wrap={'wrap'}
        gap={'16px'}
        justify={'start'}
        h={'fit-content'}
      >
        {type === 'INITIATIVE' ? (
          <OverviewCard
            title="Initiative Status Update"
            content={updateStatus?.dto[0]?.summary}
            label={updateStatus?.dto[0]?.status}
            labelColor={
              updatesModalStatusOptions?.find(
                (option: any) => option.value === updateStatus?.dto[0]?.status
              )?.color || 'text.tertiary'
            }
            readMoreBtn
            setActiveTab={setActiveTab}
            isLoading={updateStatusLoading || isLoadingInitiativeState}
            type={type}
          />
        ) : null}
        <OverviewCard
          title="Project Description"
          content={initiativeState?.desc}
          setActiveTab={setActiveTab}
          initiativeState={initiativeState}
          isLoading={isLoadingInitiativeState}
          type={type}
        />
        <ScopeCard scopeData={scopeData} isLoading={isLoadingInitiativeState} />
        <EffortCard
          initiativeId={initiativeState?.id}
          type={type}
          setActiveTab={setActiveTab}
        />
        <IssueTypeCard initiativeId={initiativeState?.id} type={type} />
        {/* <SprintDeliveryCard initiativeId={initiativeState?.id} type={type} /> */}
      </Flex>
      <Flex minW={'300px'} maxW={'320px'} direction={'column'} gap={'12px'}>
        <Text fontSize={'sm'} fontWeight={'semibold'} color={'text.gray.300'}>
          Signals
        </Text>
        <Flex direction={'column'} gap={'16px'}>
          {signalLoading ? (
            new Array(4).fill(0).map((_: any, index: number) => (
              <Box position={'relative'}>
                <Skeleton key={index} minW="300px" minH="140px" />
                <Spinner position={'absolute'} top={'42%'} left={'42%'} />
              </Box>
            ))
          ) : signalData?.length > 0 ? (
            signalData?.map((item: any, index: number) => (
              <SignalCard item={item} index={index} />
            ))
          ) : (
            <Text fontSize={'sm'} color={'text.secondary2'}>
              No Signals Found
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
