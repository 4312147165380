import styled from 'styled-components';

type ContainerProps = {
  maxH?: string;
  useMaxH?: boolean;
};

const Container = styled.div<ContainerProps>`
  margin: 0 auto;
  width: 100%;
  overflow-x: auto;
  height: ${({ maxH, useMaxH }) => (useMaxH ? 'auto' : maxH || '400px')};
  max-height: ${({ useMaxH }) => (useMaxH ? '600px' : 'auto')};
  position: relative;
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

const TableContainer = ({
  children,
  maxH,
  useMaxH,
}: {
  children: React.ReactNode;
  maxH?: string;
  useMaxH?: boolean;
}) => {
  return (
    <Container maxH={maxH} useMaxH={useMaxH}>
      {children}
    </Container>
  );
};

export default TableContainer;
