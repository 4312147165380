import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { OverviewTab } from './OverviewTab';
import { useEffect, useState } from 'react';
import { TeamTab } from './TeamTab';
import { SprintTab } from './SprintTab';
import { IssueTab } from './IssueTab';
import { ChangelogTab } from './ChangelogTab';
import { UpdatesTab } from './UpdatesTab';
import { useQueryState } from '@devd-client/devd/components';
import { useSearchParams } from 'react-router-dom';
import { ContributorTab } from './ContributorTab';
interface TabData {
  tab: string;
  tabPannel?: React.ReactNode;
}

interface InitativeTabsProps {
  initiativeState: any;
  scopeData: any;
  isLoadingInitiativeState?: boolean;
}

export const InitiativeTabs = ({
  initiativeState,
  scopeData,
  isLoadingInitiativeState,
}: InitativeTabsProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [chartKey, setChartKey] = useQueryState('chartKey');
  const [metric, setMetric] = useQueryState('metric');
  const [metricKey, setMetricKey] = useQueryState('metricKey');
  const [firstOption, setFirstOption] = useQueryState('firstOption');
  const [secondOption, setSecondOption] = useQueryState('secondOption');
  const [teamId, setTeamId] = useQueryState('teamId');
  const [status, setStatus] = useQueryState('status');
  const [ticketType, setTicketType] = useQueryState('ticketType');
  const [type] = useQueryState('type');
  const tabData: TabData[] = [
    {
      tab: 'Overview',
      tabPannel: (
        <OverviewTab
          setActiveTab={setActiveTab}
          initiativeState={initiativeState}
          isLoadingInitiativeState={isLoadingInitiativeState}
          scopeData={scopeData}
        />
      ),
    },
    // { tab: 'Insights' },
    // { tab: 'Planner' },
    // {
    //   tab: 'Life Cycle Explorer',
    // },
    {
      tab: 'Team',
      tabPannel: <TeamTab initiativeId={initiativeState?.id} type={type} />,
    },
    {
      tab: 'Contributors',
      tabPannel: (
        <ContributorTab initiativeId={initiativeState?.id} type={type} />
      ),
    },
    {
      tab: 'Sprint',
      tabPannel: <SprintTab initiativeId={initiativeState?.id} />,
    },
    {
      tab: 'Issues',
      tabPannel: <IssueTab />,
    },
    /* {
      tab: 'Changelog',
      tabPannel: <ChangelogTab initiativeId={initiativeState?.id} />,
    }, */
    ...(type === 'INITIATIVE'
      ? [
          {
            tab: 'Updates',
            tabPannel: <UpdatesTab initiativeId={initiativeState?.id} />,
          },
        ]
      : []),
  ];

  useEffect(() => {
    const tabFromUrl = searchParams.get('tab');
    if (tabFromUrl) {
      const tabIndex = tabData.findIndex(
        (tab) => tab.tab.toLowerCase() === tabFromUrl.toLowerCase()
      );
      if (tabIndex !== -1) {
        setActiveTab(tabIndex);
      }
    }
  }, [searchParams]);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('tab', tabData[index].tab);
    setSearchParams(newSearchParams);
    setChartKey(null);
    setMetric(null);
    setMetricKey(null);
    setFirstOption(null);
    setSecondOption(null);
    setTeamId(null);
    setStatus(null);
    setTicketType(null);
  };

  return (
    <Tabs mt={6} index={activeTab} onChange={handleTabChange}>
      <TabList>
        {tabData?.map((tab) => (
          <Tab
            key={tab.tab}
            fontSize={'sm'}
            fontWeight="medium"
            color="text.secondary"
            _selected={{
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'primary',
            }}
          >
            {tab.tab}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {tabData?.map((tab, index) => (
          <TabPanel key={tab.tab}>
            {activeTab === index && tab.tabPannel}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
