import { Box, Flex, keyframes, Text } from '@chakra-ui/react';
import { useToastHook } from '@devd-client/devd/components';
import { useEffect } from 'react';

interface DevelopersCardProps {
  activeCard: 'target' | 'developers' | 'avgAllocation' | 'scope';
  setActiveCard: (
    card: 'target' | 'developers' | 'avgAllocation' | 'scope'
  ) => void;
  isLoading: boolean;
  developerCount: number;
}

const shimmer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const DevelopersCard = ({
  activeCard,
  setActiveCard,
  developerCount,
  isLoading,
}: DevelopersCardProps) => {
  const [newToast] = useToastHook();

  useEffect(() => {
    if (developerCount === 0 && !isLoading) {
      newToast({
        message:
          'At least one contributor is required to complete the deliverable. Please add contributors to plan the scenario.',
        status: 'warning',
        duration: 5000,
      });
    }
  }, [developerCount, isLoading]);

  return (
    <Flex
      rounded={'md'}
      minH={'100px'}
      bg={activeCard === 'developers' ? '#4d4dff05' : 'white'}
      border={'1px solid'}
      borderColor={activeCard === 'developers' ? '#4d4dff' : 'text.tertiary'}
      boxShadow={
        activeCard === 'developers'
          ? '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)'
          : 'none'
      }
      direction={'column'}
      gap={6}
      p={4}
      color={'text.primary'}
      position={'relative'}
      overflow={'hidden'}
    >
      {isLoading && (
        <Box
          position={'absolute'}
          top={0}
          left={0}
          right={0}
          bottom={0}
          background={
            'linear-gradient(90deg, transparent, rgba(255,255,255,0.8), transparent)'
          }
          animation={`${shimmer} 1.2s infinite ease-in-out`}
        />
      )}
      <Flex direction={'column'} gap={2}>
        <Text color={'text.gray.300'} fontWeight={'semibold'} fontSize={'sm'}>
          Contributors
        </Text>
        <Text fontSize={'sm'} color={'text.secondary2'}>
          On average, how many engineers will work on development of the
          deliverable?
        </Text>
      </Flex>
      <Flex direction={'column'} gap={1} mt={'auto'}>
        <Text fontSize={'2xl'} color={'text.gray.300'} fontWeight={'semibold'}>
          {developerCount}
        </Text>
        <Text fontSize={'xs'} color={'text.secondary2'}>
          Average, previous 4 weeks
        </Text>
      </Flex>
      <Text
        color={'primary'}
        fontSize={'xs'}
        onClick={() => setActiveCard('developers')}
        cursor={'pointer'}
      >
        Manage Contributors
      </Text>
    </Flex>
  );
};

export default DevelopersCard;
