import TeamsTable from './TeamsTable';

interface TeamTabProps {
  initiativeId: string;
  type: string;
}

export const TeamTab = ({ initiativeId, type }: TeamTabProps) => {
  return <TeamsTable initiativeId={initiativeId} type={type} />;
};
