export function HourGlassIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      {...props}
    >
      <path
        d="M3.99967 12.3335H7.99967M2.39967 1.3335H9.59967C9.97304 1.3335 10.1597 1.3335 10.3023 1.40616C10.4278 1.47007 10.5298 1.57206 10.5937 1.6975C10.6663 1.84011 10.6663 2.02679 10.6663 2.40016V3.78317C10.6663 4.10929 10.6663 4.27236 10.6295 4.42581C10.5968 4.56185 10.543 4.69191 10.4699 4.81121C10.3874 4.94576 10.2721 5.06107 10.0415 5.29167L8.08726 7.24592C7.82324 7.50993 7.69124 7.64193 7.64178 7.79415C7.59827 7.92805 7.59827 8.07228 7.64178 8.20617C7.69124 8.35839 7.82324 8.4904 8.08725 8.75441L10.0415 10.7087C10.2721 10.9393 10.3874 11.0546 10.4699 11.1891C10.543 11.3084 10.5968 11.4385 10.6295 11.5745C10.6663 11.728 10.6663 11.891 10.6663 12.2172V13.6002C10.6663 13.9735 10.6663 14.1602 10.5937 14.3028C10.5298 14.4283 10.4278 14.5303 10.3023 14.5942C10.1597 14.6668 9.97304 14.6668 9.59967 14.6668H2.39967C2.02631 14.6668 1.83962 14.6668 1.69701 14.5942C1.57157 14.5303 1.46959 14.4283 1.40567 14.3028C1.33301 14.1602 1.33301 13.9735 1.33301 13.6002V12.2172C1.33301 11.891 1.33301 11.728 1.36985 11.5745C1.40251 11.4385 1.45638 11.3084 1.52949 11.1891C1.61194 11.0546 1.72724 10.9393 1.95785 10.7087L3.91209 8.75441C4.17611 8.4904 4.30811 8.35839 4.35757 8.20617C4.40108 8.07228 4.40108 7.92805 4.35757 7.79415C4.30811 7.64193 4.1761 7.50992 3.91209 7.24592L1.95785 5.29167C1.72725 5.06107 1.61194 4.94576 1.52949 4.81121C1.45638 4.69191 1.40251 4.56185 1.36985 4.42581C1.33301 4.27236 1.33301 4.10929 1.33301 3.78317V2.40016C1.33301 2.02679 1.33301 1.84011 1.40567 1.6975C1.46959 1.57206 1.57157 1.47007 1.69701 1.40616C1.83962 1.3335 2.02631 1.3335 2.39967 1.3335Z"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
