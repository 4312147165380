import { Flex, Grid } from '@chakra-ui/react';
import TargetProjectionCard from './TargetProjectionCard';
import DevelopersCard from './DevelopersCard';
import AvgAllocationCard from './AvgAllocationCard';
import ScopeCard from './ScopeCard';
import DetailsBottom from './DetailsBottom';
import {
  useContributorsAllocation,
  useDeliverableScenarioGql,
} from '../../graphql';
import { useEffect, useState } from 'react';
import ScenarioPlannerSummary from './modals/ScenarioPlannerSummary';
import { formattedDeliverableType } from '../../helpers/Initiatives.utils';

type Props = {
  deliverableId: string;
  deliverableType: string;
  deliverableName: string;
  isOpen: boolean;
  onClose: () => void;
};

const ScenarioPlannerBody = ({
  deliverableId,
  deliverableType,
  deliverableName,
  ...props
}: Props) => {
  const {
    data: contributorsAllocationData,
    isLoading: isContributorsAllocationLoading,
  } = useContributorsAllocation(deliverableId, deliverableType, '', 'active');
  const [averageAttention, setAverageAttention] = useState<number | null>(null);
  const [remainingScope, setRemainingScope] = useState<number | null>(null);
  const [developersCount, setDevelopersCount] = useState<number | null>(null);
  const [excludedDevelopers, setExcludedDevelopers] = useState<string[]>([]);
  const [activeCard, setActiveCard] = useState<
    'target' | 'developers' | 'avgAllocation' | 'scope'
  >('target');

  const { data: deliverableScenarioData, isFetching } =
    useDeliverableScenarioGql(
      deliverableId,
      deliverableType,
      averageAttention,
      remainingScope,
      developersCount
    );

  const [initialValues, setInitialValues] = useState<{
    targetDate: string;
    projectedEndDate: string | null;
    remainingDuration: number | null;
    remainingEffort: number | null;
    activeContributors: number | null;
    averageAttention: number;
    scope: number;
    completedScope: number;
  }>({
    targetDate: 'N/A',
    projectedEndDate: null,
    remainingDuration: null,
    remainingEffort: null,
    activeContributors: null,
    averageAttention: 1,
    scope: 0,
    completedScope: 0,
  });

  useEffect(() => {
    if (deliverableScenarioData && initialValues.targetDate === 'N/A') {
      setInitialValues({
        targetDate: deliverableScenarioData?.targetDate || '',
        projectedEndDate: deliverableScenarioData?.projectedEndDate || null,
        remainingDuration: deliverableScenarioData?.remainingDuration || null,
        remainingEffort: deliverableScenarioData?.remainingEffort || null,
        activeContributors: deliverableScenarioData?.activeContributors || null,
        averageAttention: deliverableScenarioData?.averageAttention || 0,
        scope: deliverableScenarioData?.scope || 0,
        completedScope: deliverableScenarioData?.completedScope || 0,
      });
    }
  }, [deliverableScenarioData]);

  return (
    <Flex direction="column" gap={4}>
      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <TargetProjectionCard
          targetDate={initialValues.targetDate}
          projectedEndDate={deliverableScenarioData?.projectedEndDate}
          remainingDuration={deliverableScenarioData?.remainingDuration}
          remainingEffort={deliverableScenarioData?.remainingEffort}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          isLoading={isFetching}
        />
        <DevelopersCard
          isLoading={isFetching}
          developerCount={deliverableScenarioData?.activeContributors || 0}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
        />
        <AvgAllocationCard
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          initialValue={initialValues.averageAttention}
          averageAttention={averageAttention}
          setAverageAttention={setAverageAttention}
          isLoading={isFetching}
        />
        <ScopeCard
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          setRemainingScope={setRemainingScope}
          initialValue={initialValues.scope - initialValues.completedScope}
          scope={initialValues.scope}
        />
      </Grid>
      <DetailsBottom
        activeCard={activeCard}
        excludedDevelopers={excludedDevelopers}
        setExcludedDevelopers={setExcludedDevelopers}
        data={contributorsAllocationData}
        contributorsAllocationLoading={isContributorsAllocationLoading}
        isLoading={isFetching}
        graphData={deliverableScenarioData?.data}
        referenceLines={deliverableScenarioData?.referenceLineData}
        keys={deliverableScenarioData?.keys}
        setDevelopersCount={setDevelopersCount}
      />
      {props.isOpen && (
        <ScenarioPlannerSummary
          {...props}
          summaryData={
            deliverableScenarioData
              ? [
                  { columnId: 'Before', ...initialValues },
                  { columnId: 'After', ...deliverableScenarioData },
                ]
              : []
          }
          deliverableName={deliverableName}
          deliverableType={formattedDeliverableType(deliverableType)}
        />
      )}
    </Flex>
  );
};

export default ScenarioPlannerBody;
