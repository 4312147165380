import { Box, Grid, GridItem, Skeleton, Spinner, Text } from '@chakra-ui/react';
import { AuthActionTypes, getUser } from '@devd-client/devd/auth';
import {
  PageHeader,
  PageToolbar,
  TeamMenu,
  MetaTags,
  MetricInfoItems,
  MetricDashboard,
  useAiAssistantStore,
  Loader,
  DateFilter,
  ISelect,
} from '@devd-client/devd/components';
import { Select } from 'chakra-react-select';

import { FC, useEffect, useState } from 'react';
import { Chatbox } from '../components/Chatbox';
import { useGitDashboard } from '../hooks/useGitDashboard';

const filterOptions = [
  { value: 'team', label: 'Team' },
  { value: 'repo', label: 'Repo' },
];

export const Git: FC = () => {
  const { isOpen } = useAiAssistantStore();
  const [hideBanner, setHideBanner] = useState(false);
  const {
    isLargerThan1820,
    isLargeThan2120,
    selected,
    selectedTeam,
    setSelectedTeam,
    filterBy,
    setFilterBy,
    repos,
    setRepos,
    appState,
    dispatch,
    clickedTab,
    setClickedTab,
    type,
    setType,
    repoList,
    repoLoading,
    QUERY_KEYS,
    data,
    isFetching,
  } = useGitDashboard();

  const [inputWidth, setInputWidth] = useState('220px');

  useEffect(() => {
    if (repoList?.dto?.length) {
      const longestLabel = repoList.dto.reduce(
        (max: string, repo: any) =>
          repo.name.length > max.length ? repo.name : max,
        ''
      );

      const approximateWidth = longestLabel.length * 8;
      setInputWidth(`${Math.min(approximateWidth, 800)}px`);
    }
  }, [repoList]);

  return (
    <>
      <MetaTags title="Git | Dashboard | Devdynamics" />

      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <Box>
          <PageHeader
            headingText={'Git'}
            mb={'10px'}
            hideBanner={hideBanner}
            // helpDocLink={helpDocLink}
          />
          <PageToolbar
            leftComp={
              <>
                <Box mr={3}>
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                    mb={2}
                  >
                    Filter By
                  </Text>
                  <Select
                    placeholder="Select Ticket Type"
                    useBasicStyles
                    onChange={(e: any) => {
                      setFilterBy(e);
                    }}
                    size="sm"
                    value={filterBy}
                    options={filterOptions}
                  />
                </Box>
                {filterBy.value === 'team' && (
                  <TeamMenu
                    selectedTeam={selectedTeam}
                    handleSelectedTeam={(selectedTeam: ISelect) => {
                      setSelectedTeam(selectedTeam);
                      setHideBanner(true);
                    }}
                    AuthActionTypes={AuthActionTypes}
                    getUser={getUser}
                    appState={appState}
                    dispatch={dispatch}
                  />
                )}

                {filterBy.value === 'repo' && (
                  <Box mr={3} maxWidth="800px" width={inputWidth}>
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                      mb={2}
                    >
                      Repository
                    </Text>
                    <Select
                      placeholder="Select Repo"
                      isMulti={true}
                      useBasicStyles
                      onChange={(e: any) => {
                        setRepos(e);
                        setHideBanner(true);
                      }}
                      size="sm"
                      value={repos}
                      options={repoList?.dto?.map((repo: any) => ({
                        value: repo.key,
                        label: repo.name,
                      }))}
                    />
                  </Box>
                )}
              </>
            }
            rightComp={
              <DateFilter
                getUser={getUser}
                appState={appState}
                dispatch={dispatch}
                AuthActionTypes={AuthActionTypes}
              />
            }
          />
        </Box>

        <Grid
          my={4}
          templateColumns={
            isLargeThan2120
              ? 'repeat(6, 1fr)'
              : isLargerThan1820
              ? 'repeat(5, 1fr)'
              : 'repeat(4, 1fr)'
          }
          gap={4}
        >
          {isFetching
            ? Array(isLargeThan2120 ? 6 : isLargerThan1820 ? 5 : 4)
                .fill(null)
                .map((_, index) => (
                  <Box
                    key={index}
                    position={'relative'}
                    minWidth="305px"
                    height="109px"
                  >
                    <Skeleton minWidth="305px" height="109px" key={index} />
                    <Spinner position={'absolute'} top={'40%'} left={'43%'} />
                  </Box>
                ))
            : data?.map((item: any, idx: number) => (
                <GridItem key={idx}>
                  <MetricInfoItems
                    item={item}
                    type={type}
                    setType={setType}
                    clickedTab={clickedTab}
                    setClickedTab={setClickedTab}
                  />
                </GridItem>
              ))}
        </Grid>

        <MetricDashboard
          selected={selected}
          selectedTeam={filterBy.value === 'repo' ? '' : selectedTeam.value}
          setType={setType}
          type={type}
          repos={
            filterBy.value === 'team' ? [] : repos?.map((el: any) => el.value)
          }
          queryKey={QUERY_KEYS.allGitThreshold}
        />
      </Box>
      {isOpen && <Chatbox />}
      {repoLoading && <Loader />}
    </>
  );
};

export default Git;
