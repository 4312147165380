import * as React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Tooltip,
  Flex,
  Skeleton,
  Stack,
} from '@chakra-ui/react';

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getExpandedRowModel,
} from '@tanstack/react-table';
import NoData from './NoData';
import Loader from '../appLoader';
import TableContainer from './NewDataGrid.styled';
import './styles.css';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { SortIcon } from '../icons';

type Sticky = 'firstCol' | 'secondCol98' | 'none';

type DataGridProps<Data extends object> = {
  team?: string | undefined;
  data: Data[];
  columns: ColumnDef<Data, any>[];
  maxH?: string;
  useMaxH?: boolean;
  showLoader: boolean;
  skeletonLoader?: boolean;
  checkedItem?: (string | number)[];
  setCheckedItem?: any;
  sticky: Sticky;
  addMemberModal?: any;
  noDataView?: React.ReactNode;
  hideHeaderTooltip?: boolean;
};

export function NewDataGrid<Data extends object>({
  data,
  columns,
  showLoader = false,
  skeletonLoader = false,
  maxH,
  useMaxH,
  checkedItem,
  setCheckedItem,
  sticky,
  noDataView,
  hideHeaderTooltip = false,
}: DataGridProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [rowSelection, setRowSelection] = React.useState<any>([]);
  const [expanded, setExpanded] = React.useState({});

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row: any) => (row ? row.breakdown || [] : []),
    state: {
      sorting,
      rowSelection,
      expanded,
    },
  });

  return (
    <TableContainer maxH={maxH} useMaxH={useMaxH}>
      {skeletonLoader ? (
        <Stack spacing={3}>
          {new Array(12).fill(null)?.map((_, index: number) => (
            <Skeleton key={index} height="48px" width="full" />
          ))}
        </Stack>
      ) : (
        <Table
          className={
            sticky === 'firstCol'
              ? 'sticky-table-column1'
              : sticky === 'secondCol98'
              ? 'sticky-table-column1 secondCol'
              : ''
          }
        >
          <Thead position={'sticky'} bg="#FBFBFB" top={0} zIndex={2}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const meta: any = header.column.columnDef.meta;
                  return (
                    <Tooltip
                      key={header.id}
                      aria-label="table header"
                      label={flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      placement="bottom"
                      isDisabled={
                        header.id === 'editAndArchive' || hideHeaderTooltip
                      }
                    >
                      <Th
                        textTransform="none"
                        onClick={header.column.getToggleSortingHandler()}
                        isNumeric={meta?.isNumeric}
                        fontSize="sm"
                        bg="#FBFBFB"
                        fontFamily="heading"
                        fontWeight="medium"
                        letterSpacing={0}
                        color="text.secondary2"
                        {...{
                          key: header.id,
                          colSpan: header.colSpan,
                        }}
                      >
                        <Flex
                          align="center"
                          userSelect={'none'}
                          {...{
                            style: {
                              width: header.getSize(),
                            },
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          <Box as="div" pl={2} userSelect={'initial'}>
                            {header.column.getCanSort() ? (
                              header.column.getIsSorted() ? (
                                header.column.getIsSorted() === 'desc' ? (
                                  <MdKeyboardArrowDown
                                    aria-label="sorted descending"
                                    color="#2A2A2F"
                                  />
                                ) : (
                                  <MdKeyboardArrowUp
                                    aria-label="sorted ascending"
                                    color="#2A2A2F"
                                  />
                                )
                              ) : (
                                <Flex direction="column">
                                  <SortIcon />
                                </Flex>
                              )
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Flex>
                      </Th>
                    </Tooltip>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <React.Fragment key={row.id}>
                <Tr
                  bg={
                    row.depth === 0
                      ? ''
                      : row.depth === 1
                      ? '#F6F7F9'
                      : undefined
                  }
                  _hover={{
                    backgroundColor: '#E3E6EA',
                  }}
                  borderBottom="none"
                >
                  {row.getVisibleCells().map((cell) => {
                    const meta: any = cell.column.columnDef.meta;
                    return (
                      <Td
                        fontFamily="heading"
                        color="text.primary"
                        fontSize="sm"
                        verticalAlign={'top'}
                        fontWeight="medium"
                        isNumeric={meta?.isNumeric}
                        border={'none'}
                        {...{
                          key: cell.id,
                          style: {
                            width: cell.column.getSize(),
                          },
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              </React.Fragment>
            ))}
          </Tbody>
        </Table>
      )}

      {!showLoader &&
        !skeletonLoader &&
        table.getRowModel().rows.length < 1 && (
          <NoData noDataView={noDataView} />
        )}
      {showLoader && !skeletonLoader && <Loader />}
    </TableContainer>
  );
}
