export function Currency(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5166_3837)">
        <path
          d="M5.66634 9.7777C5.66634 10.6368 6.36279 11.3333 7.2219 11.3333H8.66634C9.58682 11.3333 10.333 10.5871 10.333 9.66659C10.333 8.74611 9.58682 7.99992 8.66634 7.99992H7.33301C6.41253 7.99992 5.66634 7.25373 5.66634 6.33325C5.66634 5.41278 6.41253 4.66659 7.33301 4.66659H8.77745C9.63656 4.66659 10.333 5.36303 10.333 6.22214M7.99967 3.66659V4.66659M7.99967 11.3333V12.3333M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z"
          stroke={props.color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5166_3837">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Currency;
