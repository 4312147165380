import {
  Avatar,
  Box,
  Flex,
  AvatarGroup as Group,
  Icon,
  Text,
  Tooltip,
  useTheme,
} from '@chakra-ui/react';
import AppTooltip from '../appTooltip';
import { NoTeamIcon } from '../icons';

type Props = {
  items: string[];
};

export const AvatarGroup = ({ items }: Props) => {
  const colors = [
    { bg: '#FCE8EE', text: '#E01E5A' },
    { bg: '#EBF9FD', text: '#36C5F0' },
    { bg: '#E8F6ED', text: '#1DA44A' },
    { bg: '#FFEFCC', text: '#FFAD00' },
  ];

  const { colors: themeColors } = useTheme();
  return (
    <Flex>
      {items?.length > 0 ? (
        <AppTooltip
          content={
            <Flex direction="column" gap={2}>
              {items?.map((item: any, index: number) => (
                <Flex align="center" gap={1}>
                  <Avatar
                    size="xs"
                    name={item?.trim()}
                    bg={colors[index % colors.length].bg}
                    color={colors[index % colors.length].text}
                  />
                  <Text
                    fontFamily={'heading'}
                    color="text.primary"
                    fontSize={14}
                  >
                    {item}
                  </Text>
                </Flex>
              ))}
            </Flex>
          }
          trigger="hover"
        >
          <Group size="sm" w={'fit-content'} max={Math.min(2, items.length)}>
            {items?.map((team: any, index: number) => (
              <Avatar
                bg={colors[index % colors.length].bg}
                color={colors[index % colors.length].text}
                name={team?.trim()}
              />
            ))}
          </Group>
        </AppTooltip>
      ) : (
        <Tooltip label="Not Added To Team" hasArrow>
          <Box w={7} height={7} mt={1} ml={5}>
            <NoTeamIcon height={24} width={24} color={themeColors.error} />
          </Box>
        </Tooltip>
      )}
    </Flex>
  );
};
