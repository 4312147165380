import { Box, Flex, useTheme } from '@chakra-ui/react';
import { splitTime } from '@devd-client/devd/components';

import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
} from 'recharts';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  padding: 10px;
  width: fit-content;
  background-color: #fff;
  outline: none;
  border: none;
  border-radius: 4px;
  pointer-events: all;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
`;

const StyledTooltipHeading = styled.p`
  font-size: 10px;
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
`;

const StyledToopTipText = styled.p`
  color: ${({ theme }) => theme.colors.text.primary};
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: 14px;
  margin-left: 2px;
  /* margin-bottom: 2px; */
`;

export const Unit = styled.span`
  color: ${({ theme }) => theme.colors.text.primary};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: 11px;
`;

interface TrendLineChartProps {
  data: any;
  color: string;
  unit?: string;
}

export function singularizeWord(number: number, word: string) {
  if (number === 1.0) {
    return word?.replace(/s$/, '');
  }
  return word;
}

export const CustomTooltip = ({
  active,
  payload,
  label,
  hideTooltip = false,
  unit,
}: any) => {
  if (active && payload && payload.length && !hideTooltip) {
    return (
      <TooltipContainer>
        <StyledTooltipHeading>Interval : {label}</StyledTooltipHeading>

        {payload.map((item: any, index: number) => (
          <Flex key={index} align="center" justify="space-between">
            <Flex align="center">
              Task Count:
              <StyledToopTipText>
                {unit === 'Minutes' ? splitTime(item.value / 60) : item.value}{' '}
                {unit === 'Count'
                  ? ''
                  : unit === 'Minutes'
                  ? ''
                  : singularizeWord(Number(item.value), unit)}
              </StyledToopTipText>
            </Flex>
          </Flex>
        ))}
      </TooltipContainer>
    );
  }

  return null;
};

const TrendBarChart: React.FC<TrendLineChartProps> = ({
  data = [
    {
      endDate: '2024-11-10',
      xLabelName: '7 Nov-10 Nov',
      x: '2024-11-10',
      y: 78,
      startDate: '2024-11-07',
    },
    {
      endDate: '2024-11-17',
      xLabelName: '11 Nov-17 Nov',
      x: '2024-11-17',
      y: 70,
      startDate: '2024-11-11',
    },
    {
      endDate: '2024-11-21',
      xLabelName: '18 Nov-24 Nov',
      x: '2024-11-21',
      y: 34,
      startDate: '2024-11-18',
    },
  ],
  color,
  unit,
}) => {
  const { colors } = useTheme();
  return (
    <ResponsiveContainer height="100%" width="100%">
      <BarChart
        data={data}
        margin={{ top: 20, right: 0, left: -30, bottom: -20 }}
      >
        <XAxis
          dataKey="xLabelName"
          tickLine={false}
          tick={false}
          axisLine={true}
        />
        <YAxis
          tickLine={false}
          tick={{
            fontSize: 10,
            fill: colors.text.secondary,
          }}
          axisLine={true}
          domain={[0, 'auto']}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={<CustomTooltip hideBtn unit={unit} />}
          wrapperStyle={{ outline: 'none' }}
        />
        <Bar
          dataKey={'y'}
          fill={color || colors.primary}
          stroke={color || colors.primary}
          strokeWidth={2}
          fillOpacity={1}
          barSize={16}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TrendBarChart;
