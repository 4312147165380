import { useMemo } from 'react';
import moment from 'moment';
import { createColumnHelper } from '@tanstack/react-table';
import { useGetInitiativeUpdateStatus } from '../../../apis';
import { Box, Flex } from '@chakra-ui/react';
import { updatesModalStatusOptions } from '../../../helpers/Initiatives.utils';
import { NewDataGrid } from '@devd-client/devd/components';

interface UpdatesTableProps {
  initiativeId: string;
}

export const UpdatesTable = ({ initiativeId }: UpdatesTableProps) => {
  const { data, isFetching } = useGetInitiativeUpdateStatus(initiativeId);

  const rows: any[] = useMemo(() => data?.dto || [], [data?.dto]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('summary', {
        cell: (info) => (
          <Box whiteSpace="normal" wordBreak="break-word">
            {info?.getValue()}
          </Box>
        ),
        header: 'Summary',
        enableSorting: false,
      }),

      columnHelper.accessor('status', {
        cell: (info) => {
          const status = info?.getValue();
          const statusConfig = updatesModalStatusOptions?.find(
            (option) => option?.value === status
          );
          return (
            <Flex align="center" gap={1}>
              <Box
                w="12px"
                h="12px"
                borderRadius="full"
                bg={statusConfig?.color || 'text.tertiary'}
              />
              <Box>{status?.toUpperCase()}</Box>
            </Flex>
          );
        },
        header: 'Status',
        enableSorting: false,
      }),

      columnHelper.accessor('createdAt', {
        cell: (info) => {
          const date = info.getValue();
          return date ? moment(date).format('MMM DD, YYYY') : '-';
        },
        header: 'Created At',
        enableSorting: false,
      }),

      columnHelper.accessor('updatedBy', {
        cell: (info) => info?.getValue(),
        header: 'Updated By',
        enableSorting: false,
      }),
    ],
    [data?.dto]
  );

  return (
    <NewDataGrid
      columns={columns}
      showLoader={isFetching}
      data={rows}
      maxH="50vh"
      sticky="firstCol"
    />
  );
};
