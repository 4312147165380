import {
  Box,
  Button,
  Flex,
  IconButton,
  Skeleton,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { LineChartWithReferenceLine } from './LineChartWithReferenceLine';
import ChartExpandModal from './ChartExpandModal';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { MdFullscreen } from 'react-icons/md';

interface ScenarioChartProps {
  graphData?: any;
  referenceLines?: any;
  keys?: any;
  isLoading: boolean;
}

export const ScenarioChart = ({
  graphData,
  referenceLines,
  keys,
  isLoading,
}: ScenarioChartProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Box
        rounded={'8px'}
        border={'1px solid'}
        borderColor={'text.tertiary'}
        mb={4}
      >
        <Flex align="center" gap={3} pt={4}>
          <Text fontWeight={'medium'} color={'text.gray.300'} pl={4}>
            Scope, Effort, and Projection
          </Text>
          <Tooltip label="Expand" hasArrow>
            <IconButton
              aria-label="full-screen"
              background={'transparent'}
              size="sm"
              icon={<MdFullscreen size="24" />}
              onClick={onOpen}
            />
          </Tooltip>
        </Flex>
        {isLoading && !graphData ? (
          <Flex w="100%" h="440px" justify="center" align="center">
            <Skeleton mt={8} h="80%" w="95%" />
          </Flex>
        ) : (
          <Box w="100%" h="440px">
            <LineChartWithReferenceLine
              graphData={graphData}
              referenceLines={referenceLines}
              keys={keys}
            />
          </Box>
        )}
      </Box>
      {isOpen && (
        <ChartExpandModal
          isOpen={isOpen}
          onClose={onClose}
          graphData={graphData}
          referenceLines={referenceLines}
          keys={keys}
        />
      )}
    </>
  );
};
