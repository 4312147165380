import { lazy } from 'react';
import SuspenseWrapper from '../../utils/suspenseWrapper';

export const Favourite = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/metrics').then((module) => ({
      default: module.Favourite,
    }))
  )
);

export const Throughput = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/metrics').then((module) => ({
      default: module.Throughput,
    }))
  )
);

export const CycleTime = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/metrics').then((module) => ({
      default: module.CycleTime,
    }))
  )
);

export const Quality = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/metrics').then((module) => ({
      default: module.Quality,
    }))
  )
);

export const Flow = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/metrics').then((module) => ({
      default: module.Flow,
    }))
  )
);

export const Dora = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/metrics').then((module) => ({
      default: module.Dora,
    }))
  )
);

export const Productivity = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/metrics').then((module) => ({
      default: module.Productivity,
    }))
  )
);

export const Velocity = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/metrics').then((module) => ({
      default: module.Velocity,
    }))
  )
);

export const Wellbeing = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/metrics').then((module) => ({
      default: module.Wellbeing,
    }))
  )
);

export const Process = SuspenseWrapper(
  lazy(() =>
    import('@devd-client/devd/metrics').then((module) => ({
      default: module.Process,
    }))
  )
);
