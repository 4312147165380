import { apiClient } from '@devd-client/api';

export const getInitiativeUpdates = (initiative: string): Promise<any> =>
  apiClient(`/v2/initiative/update/${initiative}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const postInvestmentUpdates = (params: any): Promise<any> =>
  apiClient(`/v2/initiative/update/${params.initiative}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });

export const getDeliverablesPage = (): Promise<any> =>
  apiClient(
    `/v2/account/org/${localStorage.getItem('orgId')}/page/Deliverables`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const addUpdateInitiative = (params: any): Promise<any> => {
  return apiClient(
    `/v2/initiative/org/${localStorage.getItem('orgId')}/update`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params),
    }
  );
};

export const initiativeArchive = (params: any): Promise<any> => {
  return apiClient(`/v2/initiative/org/${localStorage.getItem('userId')}`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      orgId: `${localStorage.getItem('orgId')}`,
      keys: params?.data,
    }),
  });
};

export const getAllocationDetail = (id: string): Promise<any> =>
  apiClient(
    `/v1/org/${localStorage.getItem('orgId')}/initiative/${id}/allocation`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

//Old endpoint
export const addUpdateAllocation = (params: any): Promise<any> => {
  return apiClient(
    `/v1/org/${localStorage.getItem('orgId')}/initiative/${
      params?.allocationId
    }/allocation/create`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params?.formattedData),
    }
  );
};

export const getInitiativeDetails = (initiative: string): Promise<any> =>
  apiClient(
    `/v2/initiative/${initiative}/org/${localStorage.getItem('orgId')}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const postInitiative = (params: any): Promise<any> => {
  return apiClient(`/v2/initiative/org/${localStorage.getItem('orgId')}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });
};

export const editInitiative = (params: any): Promise<any> => {
  return apiClient(
    `/v2/initiative/org/${localStorage.getItem('orgId')}/${params.id}`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params),
    }
  );
};

export const deleteInitiative = (params: any): Promise<any> => {
  return apiClient(
    `/v2/initiative/org/${localStorage.getItem('orgId')}/${params.id}`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};

export const getTeamMembers = (team: string): Promise<any> =>
  apiClient(
    `/v1/team/members/org/${localStorage.getItem('orgId')}/team/${team}`,
    {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getAllocationByMember = (initiativeId: string): Promise<any> =>
  apiClient(
    `/v1/org/${localStorage.getItem(
      'orgId'
    )}/initiative/${initiativeId}/member-allocation`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getMembers = (params?: {
  pageNo?: number;
  pageSize?: number;
  searchTerm?: string;
}): Promise<any> =>
  apiClient(
    `/v1/employee/org/${localStorage.getItem('orgId')}${
      params
        ? `?pageNo=${params.pageNo || 0}&pageSize=${params.pageSize || 20}${
            params.searchTerm ? `&searchTerm=${params.searchTerm}` : ''
          }`
        : ''
    }`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

//New endpoint
export const updateAllocation = (params: {
  initiativeId?: string;
  allocationList?: any;
}): Promise<any> => {
  return apiClient(
    `/v1/org/${localStorage.getItem('orgId')}/initiative/${
      params?.initiativeId
    }/team-allocation/create`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params?.allocationList),
    }
  );
};

export const getAllocation = (initiativeId: string): Promise<any> =>
  apiClient(
    `/v1/org/${localStorage.getItem(
      'orgId'
    )}/initiative/${initiativeId}/team-allocation`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const updatePhase = (params: {
  initiativeId?: string;
  phasesList?: any;
}): Promise<any> => {
  return apiClient(
    `/v1/org/${localStorage.getItem('orgId')}/initiative/${
      params?.initiativeId
    }/phase/create`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params.phasesList),
    }
  );
};

export const getPhases = (initiativeId: string): Promise<any> =>
  apiClient(
    `/v1/org/${localStorage.getItem('orgId')}/initiative/${initiativeId}/phase`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getInitiativeUpdateStatus = (initiativeId: string): Promise<any> =>
  apiClient(`/v2/initiative/update/${initiativeId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const postInitiativeUpdateStatus = (params: any): Promise<any> => {
  return apiClient(`/v2/initiative/update/${params.initiative}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params),
  });
};

export const getDeliverableData = (id: string, type: string): Promise<any> =>
  apiClient(
    `/v2/initiative/org/${localStorage.getItem(
      'orgId'
    )}/${id}/deliverable/${type}/type`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const addFavouriteDeliverable = (params: any): Promise<any> =>
  apiClient(
    `/v1/user/${localStorage.getItem('userId')}/preference/deliverable`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params.data),
    }
  );

export const deleteFavouriteDeliverable = (params: any): Promise<any> => {
  return apiClient(
    `/v1/user/${localStorage.getItem('userId')}/preference/deliverable/${
      params.data.id
    }`,
    {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};
