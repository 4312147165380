import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import {
  AppTooltip,
  splitTime,
  TestStatsItem,
} from '@devd-client/devd/components';
import moment from 'moment';
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from 'react-icons/io';
import { Link } from 'react-router-dom';

import { FiSkipForward } from 'react-icons/fi';

type Props = {
  data: any;
};

const RecentRuns = ({ data }: Props) => {
  const { colors } = useTheme();
  return (
    <Flex gap={2}>
      {data?.map((item: any, index: number) => (
        <AppTooltip
          key={index}
          content={
            <Flex direction="column">
              <Text
                fontFamily={'heading'}
                fontSize="sm"
                color="text.secondary2"
                fontWeight="medium"
              >
                Branch:{' '}
                <Text as="span" color="text.primary">
                  main
                </Text>
              </Text>

              <Text
                fontFamily={'heading'}
                fontSize="sm"
                color="text.secondary2"
                fontWeight="medium"
              >
                Date:{' '}
                <Text as="span" color="text.primary">
                  {moment(item?.date).format('lll')}
                </Text>
              </Text>

              <Text
                fontFamily={'heading'}
                fontSize="sm"
                color="text.secondary2"
                fontWeight="medium"
              >
                Duration:{' '}
                <Text as="span" color="text.primary">
                  {splitTime(item?.durationInSecs / 3600)}
                </Text>
              </Text>

              <Text
                fontFamily={'heading'}
                fontSize="sm"
                color="text.secondary2"
                fontWeight="medium"
              >
                Status:{' '}
                <Text as="span" color="text.primary">
                  {item?.status}
                </Text>
              </Text>

              <Flex gap={4} align="center">
                <TestStatsItem
                  icon={IoMdCheckmarkCircleOutline}
                  label="Passed Tests"
                  count={item?.passedTests}
                  color={colors.success}
                  percentage={(item?.passedTests / item?.totalTests) * 100}
                />

                <TestStatsItem
                  icon={IoMdCloseCircleOutline}
                  label="Failed Tests"
                  count={item?.failedTests}
                  color={colors.error}
                  percentage={(item?.failedTests / item?.totalTests) * 100}
                />

                <TestStatsItem
                  icon={FiSkipForward}
                  label="Skipped Tests"
                  count={item?.skippedTests}
                  color={colors.warning}
                  percentage={(item?.skippedTests / item?.totalTests) * 100}
                />
              </Flex>
            </Flex>
          }
          trigger="hover"
        >
          <Link to={item?.link} target="_blank" rel="noopener">
            <Box
              height="32px"
              width="32px"
              borderRadius={'full'}
              bg={
                item?.status === 'failed' ||
                item?.status === 'failure' ||
                item?.status === 'aborted'
                  ? 'error'
                  : 'success'
              }
              display="grid"
              placeItems={'center'}
              _hover={{
                transform: 'scale(1.10)',
                transition: 'all 0.2s',
                border: '1px solid',
                borderColor: 'primary',
              }}
            >
              {item?.status === 'failed' ||
              item?.status === 'failure' ||
              item?.status === 'aborted' ? (
                <IoMdCloseCircleOutline size={18} color="white" />
              ) : (
                <IoMdCheckmarkCircleOutline size={18} color="white" />
              )}
            </Box>
          </Link>
        </AppTooltip>
      ))}
    </Flex>
  );
};

export default RecentRuns;
