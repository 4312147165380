import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CustomLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
`;

export function StyledLink({
  path,
  children,
}: {
  path: string;
  children: React.ReactNode;
}) {
  return <CustomLink to={path}>{children}</CustomLink>;
}
