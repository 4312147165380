import { useMediaQuery } from '@chakra-ui/react';
import { useFetchRepo } from '../api';
import { useEffect, useState } from 'react';
import {
  durationData,
  removeEmptyQueryParams,
  useDashboardStore,
  useQueryState,
} from '@devd-client/devd/components';
import { useAuth } from '@devd-client/devd/auth';
import { useQualityCards, QUERY_KEYS } from '../graphql';
import moment from 'moment';

export const useCodeQualityDashboard = () => {
  const [isLargerThan1820] = useMediaQuery('(min-width: 1820px)');
  const [isLargeThan2120] = useMediaQuery('(min-width: 2120px)');

  const { data: repoList, isLoading: repoLoading } = useFetchRepo('quality');
  const [filterBy, setFilterBy] = useState<{
    value: string;
    label: string;
  }>({ value: 'team', label: 'Team' });
  const [repos, setRepos] = useState([]);

  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [metricType, setMetricType] = useQueryState('tab');
  const [appState, dispatch] = useAuth();

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);

  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();

  const [type, setType] = useState<string>();

  const [clickedTab, setClickedTab] = useState<string>();

  const { data, isFetching } = useQualityCards(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    filterBy.value === 'project' ? '' : selectedTeam.value,
    filterBy.value === 'team' ? [] : repos.map((el: any) => el.value)
  );

  useEffect(() => {
    if (!data) return;
    if (!clickedTab && !type) {
      setClickedTab(data[0]?.chartKey);
      setType(data[0]?.chartKey);
    }
  }, [data]);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Organisation'
        ? setTeam('Organisation')
        : setTeam(selectedTeam.value);
    }

    if (type) {
      setMetricType(type);
    }
  }, [selected, selectedTeam.value, type]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Organisation'
          ? { value: '', label: 'Organisation' }
          : { value: team, label: team }
      );

    metricType && setType(metricType);
    metricType && setClickedTab(metricType);

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom' ||
              el.duration === 'Today' ||
              el.duration === 'Month' ||
              el.duration === 'Quarter'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  return {
    filterBy,
    setFilterBy,
    selected,
    selectedTeam,
    setSelectedTeam,
    appState,
    dispatch,
    repos,
    setRepos,
    repoList,
    repoLoading,
    isLargerThan1820,
    isLargeThan2120,
    QUERY_KEYS,
    data,
    isFetching,
    type,
    setType,
    clickedTab,
    setClickedTab,
  };
};
