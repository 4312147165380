import { Flex, Skeleton, Text, TextProps, Tooltip } from '@chakra-ui/react';
import AppTooltip from '../appTooltip';
import { useMemo } from 'react';
import { truncateText } from '../shared/utils';

type Props = TextProps & {
  knowledgeArea: { repo: string; value: number }[];
  loading: boolean;
  showVertical?: boolean;
  hideMore?: boolean;
};

export const KnowledgeArea = ({
  knowledgeArea,
  loading,
  showVertical,
  hideMore,
  ...props
}: Props) => {
  const sortedRepoDistribution = useMemo(() => {
    if (knowledgeArea) {
      return knowledgeArea?.sort((a: any, b: any) => {
        if (b.value !== a.value) {
          return b.value - a.value;
        }
        return a.repo.localeCompare(b.repo);
      });
    }
    return [];
  }, [knowledgeArea]);

  return loading ? (
    <Flex direction={showVertical ? 'column' : 'row'} gap={1}>
      <Skeleton height={'16px'} width={'16px'} />
      <Skeleton height={'16px'} width={'100px'} />
    </Flex>
  ) : (
    <Flex direction={showVertical ? 'column' : 'row'} gap={2}>
      {sortedRepoDistribution &&
        sortedRepoDistribution?.slice(0, 2)?.map((item) => (
          <Tooltip
            label={item?.repo?.length > 12 ? item?.repo : ''}
            hasArrow
            isDisabled={item?.repo?.length <= 12}
            key={item?.repo}
          >
            <Flex gap={1}>
              <Text display={'inline'} fontWeight={'semibold'}>
                {item?.value}%
              </Text>
              <Text {...props} display={'inline'}>
                {truncateText(item?.repo, 12)}
              </Text>
            </Flex>
          </Tooltip>
        ))}
      {sortedRepoDistribution.length - 2 > 0 && (
        <AppTooltip
          content={
            <Flex fontFamily={'heading'} direction={'column'} gap={1.5}>
              {sortedRepoDistribution?.slice(2)?.map((item) => (
                <Flex key={item?.repo} gap={1}>
                  <Text fontWeight={'semibold'}>{item?.value}%</Text>{' '}
                  <Text>{item?.repo}</Text>
                </Flex>
              ))}
            </Flex>
          }
          placement="bottom"
          trigger="hover"
        >
          <Text textDecoration={'underline'} display={'inline'}>
            +{sortedRepoDistribution.length - 2} {!hideMore ? 'more' : ''}
          </Text>
        </AppTooltip>
      )}
    </Flex>
  );
};
