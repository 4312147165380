import { FC, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTeamsAllocation } from '../../../graphql';
import { NewDataGrid } from '@devd-client/devd/components';

interface TeamsTableProps {
  initiativeId: string;
  type: string;
}

const TeamsTable: FC<TeamsTableProps> = ({ initiativeId, type }) => {
  const { data: teamsData, isFetching: teamsLoading } = useTeamsAllocation(
    initiativeId,
    type,
    ''
  );
  const rows = useMemo(() => teamsData || [], [teamsData]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: 'Team Name',
        enableSorting: false,
      }),
      columnHelper.accessor('allocation', {
        cell: (info) =>
          info.getValue() !== null
            ? `${Number(info.getValue())?.toFixed(2)}%`
            : '-',
        header: 'Calculated Allocation',
        enableSorting: false,
      }),
      columnHelper.accessor('assignedAllocation', {
        cell: (info) =>
          info.getValue() !== null
            ? `${Number(info.getValue())?.toFixed(2)}%`
            : '-',
        header: 'Assigned Allocation',
        enableSorting: false,
      }),
      columnHelper.accessor('contributors', {
        cell: (info) => info.getValue(),
        header: 'Contributors',
        enableSorting: false,
      }),
    ],
    [teamsData]
  );

  return (
    <NewDataGrid
      columns={columns}
      showLoader={teamsLoading}
      data={rows}
      maxH="50vh"
      sticky="firstCol"
    />
  );
};

export default TeamsTable;
