export const CircleCheck = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 5.99992H11C12.6569 5.99992 14 7.34306 14 8.99992C14 10.6568 12.6569 11.9999 11 11.9999H8M2 5.99992L4.66667 3.33325M2 5.99992L4.66667 8.66659"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleCheck;
