import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  Stack,
  Switch,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { eliminateHtmlTags } from '@devd-client/devd/components';
import { areValidEmails, isValidEmail } from '../../../helpers/teams.utils';
import { Select } from 'chakra-react-select';
import { DigestTimeRangeOptions } from '../../../helpers/time-range';
import { BiInfoCircle } from 'react-icons/bi';

type Props = {
  defaultData: any;
  setSlackChannel: (value: string) => void;
  slackChannel: string;
  setEmail: (value: string) => void;
  email: string;
  setDigest: (value: boolean) => void;
  digest: boolean;
  setNotificationTime: (value: string) => void;
  notificationTime: string;
  setIssueDone: (value: boolean) => void;
  issueDone: boolean;
  setPrMerged: (value: boolean) => void;
  prMerged: boolean;
  setIssueInProgress: (value: boolean) => void;
  issueInProgress: boolean;
  setGoalExceptions: (value: boolean) => void;
  goalExceptions: boolean;
  handleSubmit: any;
};

const formatOptionLabel = ({ value, label }: any) => {
  if (value === '') {
    return <span>&nbsp;</span>;
  }
  return label;
};

const Communication = ({
  defaultData,
  setSlackChannel,
  slackChannel,
  setEmail,
  email,
  setDigest,
  digest,
  setNotificationTime,
  notificationTime,
  setIssueDone,
  issueDone,
  setPrMerged,
  prMerged,
  setIssueInProgress,
  issueInProgress,
  setGoalExceptions,
  goalExceptions,
  handleSubmit,
}: Props) => {
  const { colors } = useTheme();
  return (
    <Stack w={'40%'} spacing={4}>
      <Stack>
        <Text
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
        >
          Slack or MS Teams Channel Name
        </Text>
        <Flex align="center">
          <Icon mr={1} as={BiInfoCircle} color={colors.text.secondary} />
          <Text fontSize="xs" color="text.secondary" fontFamily="heading">
            Add DevDynamics App in this channel to get digest
          </Text>
        </Flex>
        <Input
          width={'100%'}
          placeholder="Slack or MS Teams Channel"
          value={slackChannel}
          onChange={(e) => {
            setSlackChannel(eliminateHtmlTags(e.target.value));
          }}
        />
      </Stack>

      <FormControl isInvalid={email.trim() !== '' && !areValidEmails(email)}>
        <Stack>
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.secondary"
            lineHeight="17px"
          >
            Email ID for Reports
          </Text>
          <Input
            type="email"
            placeholder="Email ID"
            value={email}
            onChange={(e) => {
              const sanitizedEmail = eliminateHtmlTags(e.target.value);
              setEmail(sanitizedEmail);
            }}
          />
          <FormErrorMessage>
            {isValidEmail(email) ? null : 'Invalid Email format'}
          </FormErrorMessage>
        </Stack>
      </FormControl>

      <Stack>
        <Text
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
        >
          Daily Digest Scheduled Time
        </Text>
        <Box w={'50%'}>
          <Select
            placeholder="Start Time"
            onChange={(e: any) => {
              setNotificationTime(e.value);
            }}
            menuPlacement="top"
            useBasicStyles
            value={{
              value: notificationTime ? notificationTime : 'Select',
              label: notificationTime ? notificationTime : 'Select',
            }}
            formatOptionLabel={formatOptionLabel}
            options={[{ value: '', label: '' }].concat(
              DigestTimeRangeOptions.map((time: any) => ({
                value: time,
                label: time,
              }))
            )}
          />
        </Box>
      </Stack>
      <Flex>
        <Button
          size="sm"
          variant="filled"
          type="button"
          onClick={() => handleSubmit()}
          isDisabled={!email && !slackChannel}
        >
          Save
        </Button>
      </Flex>
    </Stack>
  );
};

export default Communication;
