import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Calendar, TrashIcon } from '@devd-client/devd/components';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { getIn } from 'formik';
import { configs, propsConfig } from '../../helpers/Initiatives.utils';
import { OwnersSelect } from './OwnersSelect';

interface DatePickerWithConfigProps {
  id: string;
  plannedDate?: Date;
  actualDate?: Date;
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
  phaseName: string;
  onPhaseNameChange: (name: string) => void;
  onPlannedDateChange: (date: Date) => void;
  onActualDateChange: (date: Date) => void;
  onDelete: () => void;
  phaseNameFieldName: string;
  ownersFieldName: string;
  plannedPlaceholder: string;
  actualPlaceholder: string;
  plannedFieldName: string;
  actualFieldName: string;
  touched: any;
  errors: any;
  colors: any;
  selectedOwners: any;
  onOwnersChange: (owners: any) => void;
}

export const DatePickerWithConfig = ({
  id,
  plannedDate,
  actualDate,
  isFocused,
  onFocus,
  onBlur,
  phaseName,
  onPhaseNameChange,
  onPlannedDateChange,
  onActualDateChange,
  onDelete,
  plannedPlaceholder,
  actualPlaceholder,
  phaseNameFieldName,
  ownersFieldName,
  plannedFieldName,
  actualFieldName,
  touched,
  errors,
  colors,
  selectedOwners,
  onOwnersChange,
}: DatePickerWithConfigProps) => {
  return (
    <Flex gap={6} mt={2}>
      <Flex flexDirection={'column'} gap={6}>
        <Flex flexDirection={'column'} gap={1}>
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.secondary"
            lineHeight="17px"
          >
            Phase Name
          </Text>
          <Input
            value={phaseName}
            onChange={(e) => onPhaseNameChange(e.target.value)}
            placeholder="Enter phase name"
            borderColor={'#E0E0E0'}
            width={'556px'}
          />
          {getIn(touched, phaseNameFieldName) &&
            getIn(errors, phaseNameFieldName) && (
              <Text color="error" fontSize="sm">
                {getIn(errors, phaseNameFieldName)}
              </Text>
            )}
        </Flex>
        <Flex gap={4}>
          <Flex flexDirection={'column'} gap={1}>
            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary"
              lineHeight="17px"
            >
              Planned End Date
            </Text>
            <InputGroup
              width={'270px'}
              zIndex={isFocused ? 100 : 'auto'}
              onFocus={onFocus}
              onBlur={onBlur}
            >
              <SingleDatepicker
                name={plannedFieldName}
                date={plannedDate}
                onDateChange={onPlannedDateChange}
                propsConfigs={{
                  ...propsConfig,
                  inputProps: {
                    ...propsConfig.inputProps,
                    placeholder: plannedPlaceholder,
                  },
                }}
                configs={configs}
              />
              <InputRightElement pointerEvents="none">
                <Calendar
                  height="16px"
                  width="16px"
                  color={colors.text.secondary2}
                />
              </InputRightElement>
            </InputGroup>
            {getIn(touched, plannedFieldName) &&
              getIn(errors, plannedFieldName) && (
                <Text color="error" fontSize="sm">
                  {getIn(errors, plannedFieldName)}
                </Text>
              )}
          </Flex>
          <Flex flexDirection={'column'} gap={1}>
            <Text
              fontSize="sm"
              fontFamily="heading"
              fontWeight="semibold"
              color="text.secondary"
              lineHeight="17px"
            >
              Actual End Date
            </Text>
            <InputGroup
              width={'270px'}
              zIndex={isFocused ? 100 : 'auto'}
              onFocus={onFocus}
              onBlur={onBlur}
            >
              <SingleDatepicker
                name={actualFieldName}
                date={actualDate}
                onDateChange={onActualDateChange}
                propsConfigs={{
                  ...propsConfig,
                  inputProps: {
                    ...propsConfig.inputProps,
                    placeholder: actualPlaceholder,
                  },
                }}
                configs={configs}
              />
              <InputRightElement pointerEvents="none">
                <Calendar
                  height="16px"
                  width="16px"
                  color={colors.text.secondary2}
                />
              </InputRightElement>
            </InputGroup>
            {getIn(touched, actualFieldName) &&
              getIn(errors, actualFieldName) && (
                <Text color="error" fontSize="sm">
                  {getIn(errors, actualFieldName)}
                </Text>
              )}
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection={'column'} gap={1}>
        <Text
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
        >
          Select Owners
        </Text>
        <OwnersSelect
          selectedOwners={selectedOwners}
          onOwnersChange={onOwnersChange}
          placeholder="Select Owners"
        />
        {getIn(touched, ownersFieldName) && getIn(errors, ownersFieldName) && (
          <Text color="error" fontSize="sm">
            {getIn(errors, ownersFieldName)}
          </Text>
        )}
      </Flex>
      <Tooltip label="Remove Phase" placement="right" hasArrow>
        <IconButton
          variant="ghost"
          onClick={onDelete}
          aria-label="Remove"
          icon={<TrashIcon />}
          mt={'22px'}
        />
      </Tooltip>
    </Flex>
  );
};
