import {
  CycleTimeProvider,
  FlowProvider,
  ThroughputProvider,
} from '@devd-client/devd/metrics';
import { useMemo, Fragment } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';

import { useStrings } from '@devd-client/devd/metrics';
import {
  CycleTime,
  Dora,
  Favourite,
  Flow,
  Process,
  Productivity,
  Quality,
  Throughput,
  Velocity,
  Wellbeing,
} from './asyncPages';
import NotFound from '../Status/404NotFound';
import { AddMetric } from '../Dashboard/asyncPages';

export type RouteType = {
  path: string;
  label: string;
  element: JSX.Element;
};

const MetricsPage = () => {
  const location = useLocation();
  const strings = useStrings();
  const routes: RouteType[] = useMemo(() => {
    return [
      {
        path: 'favourite',
        label: `${strings.favourite}`,
        element: (
          <CycleTimeProvider>
            <Favourite />
          </CycleTimeProvider>
        ),
      },
      {
        path: 'throughput',
        label: `${strings.throughput}`,
        element: (
          <ThroughputProvider>
            <Throughput />
          </ThroughputProvider>
        ),
      },
      {
        path: 'cycle-time',
        label: `${strings.cycleTime}`,
        element: (
          <CycleTimeProvider>
            <CycleTime />
          </CycleTimeProvider>
        ),
      },
      {
        path: 'quality',
        label: `${strings.quality}`,
        element: (
          <CycleTimeProvider>
            <Quality />
          </CycleTimeProvider>
        ),
      },
      {
        path: 'flow',
        label: `${strings.flow}`,
        element: (
          <FlowProvider>
            <Flow />
          </FlowProvider>
        ),
      },
      {
        path: 'dora',
        label: `${strings.dora}`,
        element: (
          <CycleTimeProvider>
            <Dora />
          </CycleTimeProvider>
        ),
      },
      {
        path: 'productivity',
        label: `${strings.productivity}`,
        element: (
          <CycleTimeProvider>
            <Productivity />
          </CycleTimeProvider>
        ),
      },
      {
        path: 'velocity',
        label: `${strings.velocity}`,
        element: (
          <CycleTimeProvider>
            <Velocity />
          </CycleTimeProvider>
        ),
      },
      {
        path: 'wellbeing',
        label: `${strings.wellbeing}`,
        element: (
          <CycleTimeProvider>
            <Wellbeing />
          </CycleTimeProvider>
        ),
      },

      {
        path: 'process',
        label: `${strings.process}`,
        element: (
          <CycleTimeProvider>
            <Process />
          </CycleTimeProvider>
        ),
      },
      {
        path: '*',
        label: ``,
        element: <NotFound />,
      },
    ];
  }, []);

  return (
    <Routes key={location.pathname}>
      <Route
        path="/"
        element={<Navigate to={`` + routes[0]?.path} replace />}
      />
      {routes.map((route, index) => (
        <Fragment key={index}>
          <Route key={index} path={`/` + route.path} element={route.element} />
        </Fragment>
      ))}
      <Route path="favourite/add-metric" element={<AddMetric />} />
    </Routes>
  );
};

export default MetricsPage;
