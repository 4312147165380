export function HumanEffort(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_8680_181)">
        <path
          d="M9.84375 2.92969C9.84375 3.41868 10.038 3.88764 10.3838 4.23342C10.7295 4.57919 11.1985 4.77344 11.6875 4.77344C12.1765 4.77344 12.6455 4.57919 12.9912 4.23342C13.337 3.88764 13.5312 3.41868 13.5312 2.92969C13.5312 2.44069 13.337 1.97173 12.9912 1.62596C12.6455 1.28019 12.1765 1.08594 11.6875 1.08594C11.1985 1.08594 10.7295 1.28019 10.3838 1.62596C10.038 1.97173 9.84375 2.44069 9.84375 2.92969Z"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.46094 9.38269V10.3046H9.84375L10.3047 14.9139H13.0703L13.5312 10.3046H14.9141V8.92175C14.9138 8.37754 14.7758 7.84224 14.5132 7.36563C14.2505 6.88896 13.8716 6.48645 13.4117 6.19548C12.9518 5.90451 12.4258 5.73453 11.8826 5.70133C11.3394 5.66813 10.7966 5.77279 10.3047 6.00558"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.6875 5.69531V8.46094"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.08594 4.77344C1.08594 5.75142 1.47444 6.68935 2.16598 7.38087C2.85752 8.07246 3.79545 8.46094 4.77344 8.46094C5.75142 8.46094 6.68935 8.07246 7.38087 7.38087C8.07246 6.68935 8.46094 5.75142 8.46094 4.77344C8.46094 3.79545 8.07246 2.85752 7.38087 2.16598C6.68935 1.47444 5.75142 1.08594 4.77344 1.08594C3.79545 1.08594 2.85752 1.47444 2.16598 2.16598C1.47444 2.85752 1.08594 3.79545 1.08594 4.77344Z"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.35353 4.77358H4.77344V3.19287"
          stroke={props.color || '#2A2A2F'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8680_181">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
