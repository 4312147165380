import { apiClient } from '@devd-client/api';

export const verifyResetPasswordToken = (code: string): Promise<any> =>
  apiClient(`/v1/verify-reset-password-token/${code}`, {
    method: 'GET',
  });

export const resetPassword = (params: any): Promise<any> =>
  apiClient(`/v1/reset-password`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });

export const getUser = (): Promise<any> =>
  apiClient(`/v2/account/user/${localStorage.getItem('authId')}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const getOrdinalSteps = (): Promise<any> =>
  apiClient(`/v2/account/org/${localStorage.getItem('orgId')}/setup`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });


  export const getActiveOrganizations = (): Promise<any> =>
  apiClient(`/v2/admin/organizations/active`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

  export const getSupportLoginDetails = (organizationId: string): Promise<any> =>
    apiClient(`/v2/admin/organizations/${organizationId}/support-login`, {
      method: 'GET',
      headers: {
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    });
