import { useState } from 'react';
import {
  Table,
  Tbody,
  Tr,
  Td,
  Switch,
  Box,
  Text,
  Tooltip,
  Flex,
} from '@chakra-ui/react';
import {
  QUERY_KEYS,
  useAddUpdateProjectsMutation,
  useFetchProjectDetails,
} from '../api';
import { useToastHook } from '../../appToast';
import { useQueryClient } from '@tanstack/react-query';
import Loader from '../../appLoader';
import { SaveAndPreviousButton } from './SaveAndPreviousButton';

interface Project {
  key: {
    orgId: string;
    id: string;
    integration: string;
    integrationKey: string;
    integrationName: string;
  };
  active: boolean;
  lastSynced: string;
  webhookActive: boolean | null;
  createdAt: string;
  updatedAt: string;
  name: string;
  type: string | null;
  external_link: string | null;
  exclusionRule: string | null;
}

interface ProjectRowProps {
  project: Project;
  onUpdateProject: (updatedProject: Project) => void;
  integrationId: string;
}

const ProjectRow = ({
  project,
  onUpdateProject,
  integrationId,
}: ProjectRowProps) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(project.active);
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();

  const { mutate: addUpdateProjects, isLoading: addMutationLoading } =
    useAddUpdateProjectsMutation();

  const handleSwitchChange = () => {
    const updatedProject = { ...project, active: !isEnabled };

    setIsEnabled(!isEnabled);

    addUpdateProjects(
      {
        integrationId: integrationId,
        data: {
          id: project.key.id,
          active: !isEnabled,
          integrationKey: project.key.integrationKey,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.projects]);
          newToast({
            message: `Project Mapping Updated. Changes will reflect in upcoming data.`,
            status: 'success',
          });
        },
        onError: (err: any) => {
          setIsEnabled(isEnabled);
          newToast({
            message: err.message ?? 'Field mapping failed. please try again',
            status: 'error',
          });
        },
      }
    );

    onUpdateProject(updatedProject);
  };

  return (
    <Tr>
      <Td>
        <Box p={2}>
          <Text>{project.name}</Text>
        </Box>
      </Td>
      <Td textAlign="center">
        <Tooltip
          label={isEnabled ? 'Disable tracking' : 'Enable Tracking'}
          hasArrow
          placement="bottom-end"
        >
          <Box p={2}>
            <Switch isChecked={isEnabled} onChange={handleSwitchChange} />
          </Box>
        </Tooltip>
      </Td>
    </Tr>
  );
};

interface ProjectTableProps {
  integrationId: string;
}
const ProjectTable = ({ integrationId }: ProjectTableProps) => {
  const { data: projectData, isFetching } =
    useFetchProjectDetails(integrationId);
  const [updatedProjects, setUpdatedProjects] = useState<Project[]>([]);

  const onUpdateProject = (updatedProject: Project) => {
    setUpdatedProjects([...updatedProjects, updatedProject]);
  };

  return (
    <>
      <Box maxHeight={'500px'} overflowY="scroll">
        <Table maxWidth={'800px'} variant="striped">
          <Tbody maxHeight={'500px'}>
            {projectData?.dto.map((project: any, index: any) => (
              <ProjectRow
                key={index}
                project={project}
                onUpdateProject={onUpdateProject}
                integrationId={integrationId}
              />
            ))}
          </Tbody>
        </Table>
      </Box>
      {isFetching && <Loader />}
    </>
  );
};

export default ProjectTable;
