export function NotStartedStatusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="8"
        cy="8"
        r="7"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-dasharray="3 3"
      />
    </svg>
  );
}
