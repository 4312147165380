import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('This field is required.')
    .matches(/^[a-zA-Z0-9\s-_]+$/, 'Special characters are not allowed')
    .max(100, 'Initiative name must be less than 100 characters'),
  desc: Yup.string().required('This field is required.'),
  startDate: Yup.date().required('This field is required.'),
  plannedDate: Yup.date()
    .required('This field is required.')
    .min(Yup.ref('startDate'), 'End date must be after start date'),
  dateRangeStart: Yup.date().nullable(),
  dateRangeEnd: Yup.date()
    .nullable()
    .min(Yup.ref('dateRangeStart'), 'End date must be after start date'),
  issueFilterConditions: Yup.object().shape({
    conditions: Yup.array()
      .min(1, 'Issue Filter Conditions are required')
      .required('Issue Filter Conditions are required'),
  }),
});
