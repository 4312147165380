import * as Yup from 'yup';

export const validationSchema = (scope: number) =>
  Yup.object({
    count: Yup.number()
      .min(1, 'Scope must be greater than or equal to 1')
      .test(
        'max-scope',
        'Value cannot exceed the total scope',
        function (value) {
          if (!scope || isNaN(scope)) return true;
          return value === undefined ? false : value <= scope;
        }
      )
      .required('Required'),
  });
