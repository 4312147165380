export function TeamsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M12.7405 13.7939H14.8477V13.2671C14.8477 11.0108 13.2715 9.12265 11.1602 8.64355"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.58008 2.27146C9.74845 2.22813 9.92496 2.20508 10.1069 2.20508C11.2706 2.20508 12.214 3.14848 12.214 4.31222C12.214 5.47596 11.2706 6.41936 10.1069 6.41936C9.92496 6.41936 9.74845 6.39631 9.58008 6.35298"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.89244 8.52637C3.27402 8.52637 1.15137 10.649 1.15137 13.2674V13.7942H10.6335V13.2674C10.6335 10.649 8.51086 8.52637 5.89244 8.52637Z"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.8923 6.41936C7.05604 6.41936 7.99944 5.47596 7.99944 4.31222C7.99944 3.14848 7.05604 2.20508 5.8923 2.20508C4.72856 2.20508 3.78516 3.14848 3.78516 4.31222C3.78516 5.47596 4.72856 6.41936 5.8923 6.41936Z"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TeamsIcon;
