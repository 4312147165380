import { Button, Flex } from '@chakra-ui/react';
import { PageHeader } from '@devd-client/devd/components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { CreateInitiativeTabs } from '../components/CreateInitiativeTabs';

export const EditInitiative = () => {
  const navigate = useNavigate();

  const location = useLocation() as any;
  const state = location?.state;

  return (
    <Flex direction="column" width="100%" minH="100%">
      <Flex flexDirection={'column'} mb={4} gap={1}>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          variant="link"
          w={'fit-content'}
        >
          Back
        </Button>
        <PageHeader headingText="Edit Initiatives" />
      </Flex>
      <CreateInitiativeTabs isEdit initiativeState={state} />
    </Flex>
  );
};
