import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import InitiativeTracking from '../InitiativeTracking';
import Phases from '../Phases';
import Allocation from '../Allocation';
import { useEffect, useState } from 'react';

interface CreateInitiativeTabsProps {
  isEdit?: boolean;
  initiativeState?: any;
}

export const CreateInitiativeTabs = ({
  isEdit,
  initiativeState,
}: CreateInitiativeTabsProps) => {
  const [initiativeId, setInitiativeId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  const handleNextTab = () => {
    setTabIndex((prev) => prev + 1);
  };

  const handlePreviousTab = () => {
    setTabIndex((prev) => prev - 1);
  };

  useEffect(() => {
    if (isEdit) {
      if (initiativeState?.id) {
        setInitiativeId(initiativeState.id);
      }
    }
    setIsLoading(false);
  }, [initiativeState, isEdit]);

  if (isLoading && isEdit) {
    return null;
  }

  return (
    <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
      <TabList>
        <Tab
          fontSize={'sm'}
          fontWeight="medium"
          color="text.secondary"
          _selected={{
            color: 'primary',
            borderBottom: '2px solid',
            borderColor: 'primary',
          }}
        >
          Initiative Tracking
        </Tab>
        <Tab
          fontSize={'sm'}
          fontWeight="medium"
          color="text.secondary"
          _selected={{
            color: 'primary',
            borderBottom: '2px solid',
            borderColor: 'primary',
          }}
          isDisabled={!initiativeId}
        >
          Phases & Owner(s)
        </Tab>
        <Tab
          fontSize={'sm'}
          fontWeight="medium"
          color="text.secondary"
          _selected={{
            color: 'primary',
            borderBottom: '2px solid',
            borderColor: 'primary',
          }}
          isDisabled={!initiativeId}
        >
          Allocation
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <InitiativeTracking
            isEdit={isEdit}
            initiativeState={initiativeState}
            setInitiativeId={setInitiativeId}
            onNext={handleNextTab}
          />
        </TabPanel>

        <TabPanel>
          <Phases
            isEdit={isEdit}
            initiativeId={initiativeId}
            onNext={handleNextTab}
            onPrevious={handlePreviousTab}
          />
        </TabPanel>

        <TabPanel>
          <Allocation
            isEdit={isEdit}
            initiativeId={initiativeId}
            onPrevious={handlePreviousTab}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
