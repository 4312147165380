import ContributorTable from './ContributorTable';

interface ContributorTabProps {
  initiativeId: string;
  type: string;
}

export const ContributorTab = ({ initiativeId, type }: ContributorTabProps) => {
  return <ContributorTable initiativeId={initiativeId} type={type} />;
};
