import { FC, Fragment, useEffect } from 'react';
import {
  Avatar,
  Box,
  Divider,
  Flex,
  IconButton,
  Skeleton,
  Stack,
  Text,
  Button,
  Tooltip,
  useTheme,
  Spinner,
} from '@chakra-ui/react';
import { SlideBar } from '../../../appSlideBar';
import { Paginate } from '../../../appPaginate';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  AuthorIdFilter,
  CFR_CHART_KEYS,
  COMMIT_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  GOAL_METRIC_GIT_KEYS,
  GOAL_METRIC_JIRA_KEYS,
  ISSUE_CHART_KEYS,
  PR_AGEING_CHARTS,
  PR_CHART_KEYS,
  PR_HEALTH_INDEX_CHARTS,
  REVIEW_RESPONSIVENESS_INDEX_CHARTS,
  TeamFilter,
  WORK_BREAKDOWN_CHARTS,
  LOC_VS_PR_CYCLE_TIME_CHARTS,
  ISSUE_LIFECYCLE_DIST_CHARTS,
  ISSUE_AGE_CHART_KEYS,
  splitTime,
  REQUIREMENT_STABILITY_CHARTS,
} from '../../../shared/utils';
import IgnoreIcon from '../../../icons/IgnoreIcon';
import { useIgnorePrOrIssue } from '../api';
import { useQueryClient } from '@tanstack/react-query';
import { useToastHook } from '../../../appToast';
import ProgressMultiColor from '../../../progressMultiColor';
import Badge from '../Badge';

import { useNavigate } from 'react-router-dom';
import {
  useDashboardStore,
  useDetailsPageStore,
} from '../../../hooks/useDashboardStore';
import KeyValueLine from '../KeyValueLine';
import { useInView } from 'react-intersection-observer';
import setQueryParams from '../../utils/metricTable.utils';
import TestStatsItem from '../../../TestStatsItem';
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from 'react-icons/io';
import { FiSkipForward } from 'react-icons/fi';

interface TestRunSidebarProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  defaultData: any;
  heading: string;
  subHeading: string;
  isFetching: boolean;
  handlePageClick: any;
  currentChartMeta: any;
  querykey?: string;
  startDate?: string;
  endDate?: string;
  filterBy: AuthorIdFilter | TeamFilter;
  payload: any;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
}

const TestRunSidebar: FC<TestRunSidebarProps> = ({
  openDrawer,
  handleDrawerClose,
  defaultData,
  heading,
  subHeading,
  isFetching,
  handlePageClick,
  currentChartMeta,
  querykey,
  startDate,
  endDate,
  filterBy,
  payload,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) => {
  const { ref, inView } = useInView();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [newToast] = useToastHook();
  const { colors } = useTheme();
  const { mutate: ignorePrOrIssue, isLoading: ignorePrOrIssueLoading } =
    useIgnorePrOrIssue();

  useEffect(() => {
    if (inView) {
      if (hasNextPage) {
        fetchNextPage();
      }
    }
  }, [inView]);

  return (
    <SlideBar
      width={'460px'}
      openDrawer={openDrawer}
      currentChartMeta={currentChartMeta}
      startDate={startDate}
      endDate={endDate}
      filterBy={filterBy}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={heading}
      SideDrawerBodyHeight={false}
      bodyHeight={'80%'}
      sideBarSubHeading={
        <>
          <Text
            fontFamily="heading"
            fontSize="sm"
            color={'text.secondary'}
            fontWeight={'medium'}
          >
            {subHeading}
          </Text>
        </>
      }
      content={
        <Stack spacing={1}>
          {isFetching &&
            Array.from(Array(8).keys()).map((_: any, index: number) => (
              <Skeleton key={index} height={'100px'} width={'100%'} />
            ))}

          {defaultData?.data?.map((item: any, idx: number, arr: any) => (
            <Fragment key={idx}>
              <Box p={2}>
                <Stack spacing={1}>
                  <Flex justifyContent={'space-between'}>
                    {item?.name && item?.externalUrl && (
                      <Link to={`${item?.externalUrl}`}>
                        <Text
                          fontSize="md"
                          fontFamily="heading"
                          textDecoration="underline"
                          color="gray.700"
                          fontWeight="medium"
                          mb={1}
                        >
                          {item?.name}
                        </Text>
                      </Link>
                    )}
                  </Flex>

                  {item?.projectId && (
                    <KeyValueLine
                      mr={0}
                      pairKey={'Project'}
                      content={
                        <Link target="_blank" rel="noopener" to={item?.repo}>
                          <Text
                            ml={1}
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="medium"
                            color="text.secondary"
                            wordBreak={'break-all'}
                            textDecoration={'underline'}
                          >
                            {item?.projectId}
                          </Text>
                        </Link>
                      }
                    />
                  )}

                  <Flex direction={'column'}>
                    {item?.id && (
                      <KeyValueLine pairKey={'ID'} value={item?.id} />
                    )}

                    {item?.status && (
                      <KeyValueLine pairKey={'Status'} value={item?.status} />
                    )}
                    {item?.type && (
                      <KeyValueLine pairKey={'Type'} value={item?.type} />
                    )}

                    {item?.started_at && (
                      <KeyValueLine
                        pairKey={'Started At'}
                        value={moment(item?.started_at).format('lll')}
                      />
                    )}

                    {item?.completed_At && (
                      <KeyValueLine
                        pairKey={'Completed At'}
                        value={moment(item?.completed_at).format('lll')}
                      />
                    )}

                    {(item?.metricValue || item?.metricValue === 0) &&
                      currentChartMeta.chartKey !== 'TEST_RUN_COUNT' && (
                        <KeyValueLine
                          pairKey={
                            currentChartMeta.chartKey === 'TEST_SUCCESS_RATE'
                              ? 'Test Success Rate'
                              : currentChartMeta.chartKey === 'TEST_RUN_TIME'
                              ? 'Average Cycle Time'
                              : 'Metric Value'
                          }
                          value={item?.metricValue}
                        />
                      )}
                    {item?.total_tests && (
                      <KeyValueLine
                        pairKey={'Total Tests'}
                        value={item?.total_tests}
                      />
                    )}

                    <Flex gap={4} align="center">
                      <TestStatsItem
                        icon={IoMdCheckmarkCircleOutline}
                        label="Passed Tests"
                        count={item?.passed_tests}
                        color={colors.success}
                        percentage={
                          (item?.passed_tests / item?.total_tests) * 100
                        }
                      />

                      <TestStatsItem
                        icon={IoMdCloseCircleOutline}
                        label="Failed Tests"
                        count={item?.failed_tests}
                        color={colors.error}
                        percentage={
                          (item?.failed_tests / item?.total_tests) * 100
                        }
                      />

                      <TestStatsItem
                        icon={FiSkipForward}
                        label="Skipped Tests"
                        count={item?.skipped_tests}
                        color={colors.warning}
                        percentage={
                          (item?.skipped_tests / item?.total_tests) * 100
                        }
                      />
                    </Flex>
                  </Flex>
                </Stack>
              </Box>
              {idx !== arr.length - 1 && <Divider />}
            </Fragment>
          ))}
          {hasNextPage || isFetchingNextPage ? (
            <Flex height="20px" justify="center" align="center">
              <Spinner />{' '}
              <Text
                ml={2}
                fontFamily="heading"
                color="text.secondary3"
                fontSize="sm"
              >
                Loading more data
              </Text>
            </Flex>
          ) : defaultData?.data?.length > 8 ? (
            <Flex height="20px" justify="center" align="center">
              <Text fontFamily="heading" color="text.secondary3" fontSize="sm">
                No more data.
              </Text>
            </Flex>
          ) : (
            <></>
          )}

          <Button ref={ref} visibility={'hidden'}>
            hidden
          </Button>
        </Stack>
      }
    />
  );
};

export default TestRunSidebar;
