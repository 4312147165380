import { createColumnHelper } from '@tanstack/react-table';
import InitiativeCol from './InitiativeCol';
import ResourceCol from './ResourceCol';
import StatusCol from './StatusCol';
import ScopeCol from './ScopeCol';
import VelocityCol from './VelocityCol';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { InfoIcon } from '@devd-client/devd/components';
import ActionsCol from './ActionsCol';

const columnHelper = createColumnHelper<any>();

export const columns: (isLoading: boolean, show: string) => any = (
  isLoading,
  show
) => [
  columnHelper.accessor('initiative', {
    cell: (info) => (
      <InitiativeCol data={info.row.original?.detail} isLoading={isLoading} />
    ),
    header:
      show === 'INITIATIVE'
        ? 'Initiative'
        : show === 'EPIC'
        ? 'Epic'
        : 'Release',
    size: 240,
    enableSorting: false,
  }),

  columnHelper.accessor('resources', {
    cell: (info) => (
      <ResourceCol data={info.row.original?.allocation} isLoading={isLoading} />
    ),
    header: () => (
      <Flex align="center" gap={2}>
        Recent Contribution
        <Tooltip label="Based on last 4 weeks commits" hasArrow>
          <Box>
            <InfoIcon width={14} height={14} />
          </Box>
        </Tooltip>
      </Flex>
    ),
    size: 180,
    enableSorting: false,
  }),

  columnHelper.accessor('status', {
    cell: (info) => (
      <StatusCol
        calculatedStatus={info.row.original?.calculatedStatus}
        status={info.row.original?.detail?.status}
        isLoading={isLoading}
      />
    ),
    size: 180,
    header: 'Status',
    enableSorting: false,
  }),

  columnHelper.accessor('velocity', {
    cell: (info) => (
      <VelocityCol data={info.row.original?.velocity} isLoading={isLoading} />
    ),
    size: 180,
    header: 'Velocity',
    enableSorting: false,
  }),

  columnHelper.accessor('scope', {
    cell: (info) => (
      <ScopeCol data={info.row.original?.scope} isLoading={isLoading} />
    ),
    size: 300,
    header: 'Scope',
    enableSorting: false,
  }),

  columnHelper.accessor('actions', {
    cell: (info) => (
      <ActionsCol
        data={info.row.original?.detail}
        scope={info.row.original?.scope}
        isLoading={isLoading}
      />
    ),
    size: 100,
    header: 'Actions',
    enableSorting: false,
  }),
];
