import { useCallback, useEffect, useState } from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from 'recharts';
import CustomTooltip from './components/customTooltip/CustomTooltip';
import './chart.css';
import { useNavigate } from 'react-router';
import {
  CFR_CHART_KEYS,
  CONTRIBUTOR_CHART_KEYS,
  CUSTOM_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  GOAL_METRIC_GIT_KEYS,
  GOAL_METRIC_JIRA_KEYS,
  ISSUE_CHART_KEYS,
  PR_CHART_KEYS,
  QUALITY_CHART_KEYS,
  splitTime,
} from '../shared/utils';
import MetricSidebar from './components/MetricSidebar';
import moment from 'moment';
import { WELLBEING_CHART_KEYS } from '../shared/utils/index';
import { useTheme } from '@chakra-ui/react';

interface StackedComboChartProps {
  chartref?: any;
  data: any;
  keys?: any;
  chartMetadata?: any;
  showLabel?: boolean;
  selectedTeam: string;
  hideBtn?: boolean;
  slant?: boolean;
  granularSlant?: boolean;
  forDetails?: boolean;
  ticketType?: string;
  repos?: string[];
  selected: any;
  showYLabel?: boolean;
  showXLabel?: boolean;
  detailView?: boolean;
}

export const StackedComboChart = ({
  chartref,
  data,
  keys,
  chartMetadata,
  showLabel,
  selectedTeam,
  hideBtn,
  slant,
  granularSlant,
  forDetails,
  ticketType,
  repos,
  selected,
  showYLabel,
  showXLabel,
  detailView,
}: StackedComboChartProps) => {
  const { colors } = useTheme();
  const [hoveredLabel, setHoveredLabel] = useState<any>(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState<any>(null);
  const [currentChartMeta, setCurrentChartMeta] = useState<any>(null);
  const [barProps, setBarProps] = useState<any>({});
  const [selectedRepo, setSelectedRepo] = useState<string[]>([]);

  useEffect(() => {
    setBarProps(
      keys?.reduce(
        (a: any, { name }: any) => {
          a[name] = true;
          return a;
        },
        {
          hover: null,
        }
      )
    );
  }, [keys]);

  const params = new URLSearchParams(window.location.search);
  const team = params.get('team');

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  const handleClick = (label: any) => {
    const labelId = data.find(
      (el: any) => el.xLabelName === label.value
    ).xLabelId;

    navigate(`sprint/${labelId}${team && `?team=${team}`}`);
  };

  const handleMouseEnter = (label: any) => {
    setHoveredLabel(label);
  };

  const handleMouseLeave = () => {
    setHoveredLabel(null);
  };

  const renderCustomTick = (props: any) => {
    const { x, y, payload } = props;
    const { value } = payload;
    const isHovered = value === hoveredLabel;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={4}
          fill={colors.primary}
          fontSize={7.5}
          fontWeight={isHovered ? 700 : 600}
          cursor="pointer"
          textAnchor="end"
          textDecoration={'underline'}
          onMouseEnter={() => handleMouseEnter(value)}
          onMouseLeave={handleMouseLeave}
          transform="rotate(-20)"
        >
          {value}
        </text>
      </g>
    );
  };

  const handleLegendMouseEnter = (e: any) => {
    if (!barProps[e.dataKey]) {
      setBarProps({ ...barProps });
    }
  };

  const handleLegendMouseLeave = (e: any) => {
    setBarProps({ ...barProps });
  };

  const selectBar = (e: any) => {
    const clickedKey = e.value;
    if (clickedKey === 'Total Deployments') {
      setSelectedRepo([]);
    } else {
      setSelectedRepo([clickedKey]);
    }
    const updatedBarProps = keys?.reduce(
      (acc: any, { name }: any) => {
        acc[name] = name === clickedKey;
        return acc;
      },
      { hover: null }
    );
    setBarProps(updatedBarProps);
  };

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          ref={chartref}
          data={data}
          margin={{
            top: 10,
            right: 35,
            left: 10,
            bottom: showYLabel ? 0 : forDetails ? 30 : -45,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey={chartMetadata.xAxis}
            axisLine={false}
            tickLine={false}
            style={{
              fontSize: 7.5,
              fontWeight: 600,
              cursor: slant ? 'pointer' : undefined,
            }}
            angle={granularSlant || slant ? -60 : undefined}
            interval={granularSlant || slant ? 0 : undefined}
            textAnchor={granularSlant || slant ? 'end' : 'middle'}
            onClick={slant ? handleClick : undefined}
            tick={slant ? renderCustomTick : undefined}
            label={
              showXLabel
                ? {
                    value: chartMetadata.xlabel,
                    fontSize: 11,
                    fontWeight: 400,
                    position: 'insideBottom',
                    dy: detailView ? 40 : 5,
                  }
                : {}
            }
          />

          <YAxis
            orientation="left"
            style={{ fontSize: 11, fontWeight: 400 }}
            tickLine={false}
            axisLine={false}
            tickFormatter={(e) =>
              chartMetadata.ylabel === 'Hour'
                ? splitTime(e)
                : chartMetadata.dataType == 'TIME'
                ? splitTime(e / 60)
                : e
            }
            label={
              !showLabel
                ? {}
                : {
                    value: chartMetadata.ylabel,
                    angle: -90,
                    position: 'insideLeft',
                    fontSize: 11,
                    fontWeight: 400,
                    dx: -10,
                    dy: 50,
                  }
            }
          />

          <Tooltip
            content={
              <CustomTooltip
                handleModalOpen={handleModalOpen}
                setClickedData={setClickedData}
                unit={chartMetadata.zlabel}
                onClickAction={() => {
                  setCurrentChartMeta(chartMetadata);
                }}
                hideBtn={hideBtn}
                chartKey={chartMetadata.chartKey}
              />
            }
            wrapperStyle={{ outline: 'none' }}
            position={{ y: -30 }}
            allowEscapeViewBox={{ x: false, y: false }}
          />

          {showLabel && (
            <Legend
              verticalAlign="middle"
              layout="vertical"
              iconType="circle"
              iconSize={11}
              align="right"
              onClick={selectBar}
              onMouseOver={handleLegendMouseEnter}
              onMouseOut={handleLegendMouseLeave}
              wrapperStyle={{
                right: -8,
                fontSize: 11.5,
                top: 0,
                cursor: 'pointer',
              }}
            />
          )}

          {keys?.map((el: any) =>
            el.key === 'yRollingAvg' ? (
              <Line
                key={el.key}
                type="monotone"
                dataKey={el.key}
                name={el.name}
                stroke={el.color}
                dot={false}
                activeDot={false}
                strokeWidth={2}
                strokeDasharray="3 6"
              />
            ) : el.chartType === 'line_chart' ? (
              <Line
                key={el.key}
                dataKey={el.key}
                name={el.name}
                stroke={el.color}
                strokeWidth={2}
                hide={
                  barProps[el.name] !== true &&
                  chartMetadata.chartKey === 'DEPLOYMENT_FREQUENCY_DISTRIBUTION'
                }
                dot={{
                  fill: el.color,
                  stroke: el.color,
                  strokeWidth: 3,
                  r: 2,
                }}
              />
            ) : el.chartType === 'bar_chart' ? (
              <Bar
                key={el.key}
                dataKey={el.key}
                name={el.name}
                barSize={20}
                fill={el.color}
                stackId="a"
                hide={
                  barProps[el.name] !== true &&
                  chartMetadata.chartKey === 'DEPLOYMENT_FREQUENCY_DISTRIBUTION'
                }
                style={{ cursor: 'pointer' }}
                onClick={(event: any, payload: any) => {
                  handleModalOpen();
                  setCurrentChartMeta(chartMetadata);
                  setClickedData({
                    payload: {
                      startDate: event.startDate,
                      endDate: event.endDate,
                    },
                    name: event.name,
                  });
                }}
              />
            ) : (
              <></>
            )
          )}
        </ComposedChart>
      </ResponsiveContainer>
      {[
        ...PR_CHART_KEYS,
        ...CFR_CHART_KEYS,
        ...DEPLOY_CHART_KEYS,
        ...CONTRIBUTOR_CHART_KEYS,
        ...ISSUE_CHART_KEYS,
        ...GOAL_METRIC_GIT_KEYS,
        ...GOAL_METRIC_JIRA_KEYS,
        ...WELLBEING_CHART_KEYS,
        ...QUALITY_CHART_KEYS,
        ...CUSTOM_CHART_KEYS,
      ].includes(currentChartMeta?.chartKey) &&
        isOpen && (
          <MetricSidebar
            openDrawer={isOpen}
            handleDrawerClose={handleModalClose}
            heading={chartMetadata.chartTitle}
            subHeading={`${
              Array.isArray(clickedData)
                ? moment(clickedData[0]?.payload?.startDate).format('DD MMM')
                : moment(clickedData?.payload?.startDate).format('DD MMM')
            } - ${
              Array.isArray(clickedData)
                ? moment(clickedData[0]?.payload?.endDate).format('DD MMM')
                : moment(clickedData?.payload?.endDate).format('DD MMM')
            }${
              clickedData &&
              chartMetadata.chartKey === 'DEPLOYMENT_FREQUENCY_DISTRIBUTION'
                ? clickedData[clickedData.length - 1]?.name
                  ? `, ${clickedData[clickedData.length - 1]?.name} (${
                      clickedData[clickedData.length - 1]?.value
                    })`
                  : ''
                : ''
            }`}
            payload={clickedData}
            currentChartMeta={currentChartMeta}
            filterBy={{
              teamId: selectedTeam,
              type: ticketType,
              projectsFilter:
                chartMetadata.chartKey === 'DEPLOYMENT_FREQUENCY_DISTRIBUTION'
                  ? selectedRepo
                  : repos,
              sprintId: selected.sprintId,
            }}
            selectedTeam={selectedTeam}
          />
        )}
    </>
  );
};
