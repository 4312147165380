// React imports
import { FC, useEffect, useMemo, useState } from 'react';

// Chakra import
import {
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Box,
  Flex,
  Icon,
  Stack,
  Text,
  Tooltip,
  useTheme,
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { OptionBase } from 'chakra-react-select';

// React router and Utilites
import { Link } from 'react-router-dom';

// Tanstack imports
import { createColumnHelper, FilterFn, ColumnDef } from '@tanstack/react-table';

// Icons
import {
  AnnotationIcon,
  BlueBgTick,
  BookMarkIcon,
  GitCommitIcon,
  PullRequestIcon,
  LinkBroken,
  StackIcon,
  WrongIcon,
  TargetBranch,
  Arrow,
  SourceBranch,
  GitHubIcon,
  ExternalLink,
  Epic,
  Bug,
  Task,
  Story,
  useToastHook,
  IgnoreIcon,
  NoTeamIcon,
  IgnoreEntryModal,
} from '@devd-client/devd/components';

import { CheckIcon } from '@chakra-ui/icons';
import DataGrid from '../Grid-table';
import { useFetchIntegrationDetails, useIgnorePrOrIssue } from '../../apis';
import { getDaysAgo, truncateText } from '../../utils/DateFormat';
import { FilterByType, GetOptionsType } from '../../utils/metricTableTypes';
import usePrTableQuery from '../../apis/hooks/query/usePrTableQuery';
import PrFilters from '../Filters/components/PrFilters';
import { useQueryClient } from '@tanstack/react-query';

interface PRTypes {
  metric: string;
  chartKey: string;
  metricKey: string;
  sprintId: string;
  startDate: string;
  endDate: string;
  heading: string;
  firstOption: string;
  secondOption: string;
  teamId: string;
  status: string;
  setFirstOption: (value: string) => void;
  setTeamId: (value: string) => void;
  setStatus: (value: string) => void;
  setSecondOption: (value: string) => void;
  setChartKey: (value: string) => void;
  setMetric: (value: string) => void;
  setMetricKey: (value: string) => void;
  setHeading: (value: string) => void;
}

export function PRDetails({
  metric,
  chartKey,
  metricKey,
  sprintId,
  startDate,
  endDate,
  heading,
  firstOption,
  secondOption,
  teamId,
  status,
  setTeamId,
  setStatus,
  setSecondOption,
  setFirstOption,
  setChartKey,
  setMetric,
  setHeading,
  setMetricKey,
}: PRTypes) {
  const { colors } = useTheme();
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const ignoreEntryModal = useDisclosure();
  const [hideEntryId, setHideEntryId] = useState('');

  const [isIgnorePrLoading, setIsIgnorePrLoading] = useState(false);
  const { data: intDetailsData, isFetching: intergationFetching } =
    useFetchIntegrationDetails();
  const { mutate: ignorePrOrIssue, isLoading: ignorePrOrIssueLoading } =
    useIgnorePrOrIssue();
  const { data, isFetching, isFetched, isError, fetchNextPage, isLoading } =
    usePrTableQuery(startDate, endDate, metric, sprintId);
  const handlePrIgnoreOrIssue = (id: string) => {
    ignorePrOrIssue(
      {
        id,
        type: 'ignoreGit',
      },
      {
        onSuccess: () => {
          setIsIgnorePrLoading(true);
          newToast({
            message: 'The entry you ignored has been successfully processed.',
            status: 'success',
          });
          queryClient.invalidateQueries([metric]);
        },
        onError: () => {
          newToast({
            message:
              'There was an error processing your request. Please try again.',
            status: 'error',
          });
        },
      }
    );
  };
  const columnHelper = createColumnHelper<any>();
  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      columnHelper.accessor('prDetail', {
        cell: (info) => {
          return (
            <Flex gap={2}>
              <Flex direction={'column'} gap={1}>
                <PullRequestIcon
                  color={colors.primary2}
                  height={16}
                  width={16}
                />
              </Flex>
              <Stack spacing={2.5} w={'full'}>
                <Flex justify={'space-between'}>
                  <Link
                    to={`/timeline/pr/${info.getValue()?.id}`}
                    style={{
                      maxWidth: 'fit-content',
                      marginBottom: '8px',
                    }}
                  >
                    <Text
                      color="text.gray.300"
                      fontFamily="heading"
                      fontSize="sm"
                      fontWeight="semibold"
                      textDecoration={'underline'}
                    >
                      {info.getValue()?.title}
                    </Text>
                  </Link>
                  <Tooltip
                    hasArrow
                    placement="bottom-end"
                    label={`Clicking this icon will cease tracking for this entry. Are you sure?`}
                  >
                    <IconButton
                      icon={<IgnoreIcon color={'#718096'} />}
                      size={'xs'}
                      onClick={() => {
                        setHideEntryId(info.getValue()?.id);
                        ignoreEntryModal.onOpen();
                      }}
                      variant={'solid'}
                      colorScheme={colors.secondary}
                      isDisabled={ignorePrOrIssueLoading}
                      aria-label={'Ignore PR'}
                      bgColor={'white'}
                      p={1}
                      mr={-1}
                      borderRadius={'lg'}
                    />
                  </Tooltip>
                </Flex>
                <Flex>
                  <Flex w={'72%'} align="center" gap={2}>
                    {info.getValue()?.issueId ? (
                      <>
                        <Icon
                          as={
                            info.getValue()?.issueType === 'Epic'
                              ? Epic
                              : info.getValue()?.issueType === 'Bug'
                              ? Bug
                              : info.getValue()?.issueType === 'Story'
                              ? Story
                              : Task
                          }
                        />
                        <Tooltip hasArrow label="Issue ID">
                          <Link
                            to={info.getValue()?.issueUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'underline',
                              color: '#718096',
                            }}
                          >
                            {info.getValue()?.issueId}
                          </Link>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <LinkBroken fill={'white'} />
                        <Text
                          color="text.secondary2"
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="normal"
                        >
                          Issue not linked
                        </Text>
                      </>
                    )}

                    <Tooltip label="Line Change" hasArrow>
                      <Flex align="center" gap={1}>
                        <Text
                          color="#1DA44A"
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="normal"
                        >
                          +
                          {info.getValue()?.addition === 'null'
                            ? '0'
                            : info.getValue()?.addition}
                        </Text>

                        <Text
                          color="#E65100"
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="normal"
                        >
                          -
                          {info.getValue()?.deletion === 'null'
                            ? '0'
                            : info.getValue()?.deletion}
                        </Text>
                      </Flex>
                    </Tooltip>
                  </Flex>
                  <Tooltip hasArrow label="Go to PR">
                    <Flex gap={1} align={'center'}>
                      <>
                        <ExternalLink height={16} width={16} />
                        <Link
                          to={info.getValue()?.externalUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: 'underline',
                            color: '#718096',
                          }}
                        >
                          #{info.getValue()?.prNumber}
                        </Link>
                      </>
                    </Flex>
                  </Tooltip>
                </Flex>

                <Flex gap={2} align="center" alignItems={'center'}>
                  <Flex gap={1} w={'70%'}>
                    <BookMarkIcon />
                    <Tooltip hasArrow label="Repository">
                      <Text
                        color="text.secondary2"
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="normal"
                      >
                        {info.getValue()?.repo}
                      </Text>
                    </Tooltip>
                  </Flex>
                  <Flex>
                    <Tooltip
                      label={
                        <Box>
                          <Text as={'small'} fontSize={14} display={'block'}>
                            PR Size Group
                          </Text>
                        </Box>
                      }
                      hasArrow
                    >
                      <Flex gap={1}>
                        <StackIcon />
                        <Text
                          color="text.secondary2"
                          fontFamily="heading"
                          fontSize="sm"
                          fontWeight="normal"
                        >
                          {info.getValue()?.batchSize ?? '0-200'}
                        </Text>
                      </Flex>
                    </Tooltip>
                  </Flex>
                </Flex>
                <Flex gap={3} align={'center'}>
                  <Tooltip
                    hasArrow
                    label={
                      <Box>
                        <Text textAlign={'center'}>Source Branch</Text>
                        {info.getValue()?.sourceBranch?.length > 15 && (
                          <Text fontWeight={'normal'}>
                            {info.getValue()?.sourceBranch}
                          </Text>
                        )}
                      </Box>
                    }
                  >
                    <Flex gap={1}>
                      <SourceBranch />{' '}
                      <Text
                        color="text.secondary2"
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="normal"
                      >
                        {truncateText(info.getValue()?.sourceBranch, 15)}
                      </Text>
                    </Flex>
                  </Tooltip>
                  <Flex>
                    <Arrow />{' '}
                  </Flex>
                  <Tooltip
                    hasArrow
                    label={
                      <Box>
                        <Text textAlign={'center'}>Target Branch</Text>
                        {info.getValue()?.targetBranch?.length > 15 && (
                          <Text fontWeight={'normal'}>
                            {info.getValue()?.targetBranch}
                          </Text>
                        )}
                      </Box>
                    }
                  >
                    <Flex gap={1}>
                      <TargetBranch />{' '}
                      <Text
                        color="text.secondary2"
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="normal"
                      >
                        {truncateText(info.getValue()?.targetBranch, 15)}
                      </Text>
                    </Flex>
                  </Tooltip>
                </Flex>
              </Stack>
            </Flex>
          );
        },
        header: 'PR Name',
        size: 450,
      }),
      columnHelper.accessor('teams', {
        cell: (info) =>
          info.getValue()?.length > 0 ? (
            <Tooltip
              label={
                <Box>
                  {info
                    .getValue()
                    ?.slice(0, 3)
                    .map((item: string, index: number) => (
                      <Text key={index} fontSize={14}>
                        {item}
                      </Text>
                    ))}
                  {info.getValue()?.length > 3 ? (
                    <Text>+{info.getValue()?.length - 3} more</Text>
                  ) : (
                    <></>
                  )}
                </Box>
              }
              hasArrow
            >
              <AvatarGroup size="sm" w={'fit-content'}>
                {info
                  .getValue()
                  ?.slice(0, 3)
                  .map((team: any) => (
                    <Avatar key={team?.trim()} name={team?.trim()} />
                  ))}
              </AvatarGroup>
            </Tooltip>
          ) : (
            <Tooltip label="Not Added To Team" hasArrow>
              <Box w={7} height={7} mt={1}>
                <NoTeamIcon height={24} width={24} color={colors.error} />
              </Box>
            </Tooltip>
          ),
        header: 'Teams',
        size: 120,
      }),
      columnHelper.accessor('author', {
        cell: (info) => (
          <Flex direction="column" gap={2}>
            <Tooltip label={info.getValue()?.name} hasArrow>
              <Flex gap={1} align="center" w={'fit-content'}>
                <Avatar size="sm" name={info.getValue()?.name?.trim()} />
              </Flex>
            </Tooltip>

            {/* <Tooltip label="Commits" hasArrow>
              <Flex align="center" gap={2} w={'fit-content'}>
                <GitCommitIcon />
                <Text
                  color="text.secondary2"
                  fontFamily="heading"
                  fontSize="sm"
                  fontWeight="normal"
                >
                  {info.row.original?.author?.commitCount}
                </Text>
              </Flex>
            </Tooltip> */}
          </Flex>
        ),
        header: 'Author',
        size: 150,
      }),
      columnHelper.accessor('createdAt', {
        cell: (info) => (
          <Flex direction={'column'} mt={1} gap={1}>
            <Text as={'strong'} fontWeight={'semibold'}>
              {getDaysAgo(info.getValue(), 'daysAgo')}
            </Text>
            <Text
              color="text.gray.300"
              fontFamily="heading"
              fontSize="sm"
              fontWeight="normal"
            >
              {getDaysAgo(info.getValue(), 'exactDate')}
            </Text>
          </Flex>
        ),
        header: 'Created At',
        size: 250,
      }),
      columnHelper.accessor('mergedAt', {
        cell: (info) => (
          <Flex direction={'column'} mt={1} gap={1}>
            <Text as={'strong'} fontWeight={'semibold'}>
              {getDaysAgo(info.getValue(), 'daysAgo')}
            </Text>
            <Text
              color="text.gray.300"
              fontFamily="heading"
              fontSize="sm"
              fontWeight="normal"
            >
              {getDaysAgo(info.getValue(), 'exactDate')}
            </Text>
          </Flex>
        ),
        header: 'Merged At',
        size: 250,
      }),
      columnHelper.accessor('reviewersDetails', {
        cell: (info) => {
          return (
            <Stack spacing={2}>
              {info.getValue()?.contributors.length > 0 ? (
                <>
                  <Tooltip
                    label={
                      <Box>
                        <Text>Review by</Text>
                        {info
                          .getValue()
                          ?.contributors?.slice(0, 3)
                          .map((contributor: any) => (
                            <Text key={contributor.name} fontSize={14}>
                              {contributor.name}
                            </Text>
                          ))}
                        {info.getValue()?.contributors?.length > 3 ? (
                          <Text>
                            +{info.getValue()?.contributors?.length - 3} more
                          </Text>
                        ) : (
                          <></>
                        )}
                      </Box>
                    }
                    hasArrow
                  >
                    <AvatarGroup size="sm" w={'fit-content'}>
                      {info
                        .getValue()
                        ?.contributors?.slice(0, 3)
                        .map((contributor: any) => (
                          <Avatar
                            key={contributor.name}
                            src={contributor}
                            name={contributor.name?.trim()}
                          />
                        ))}
                    </AvatarGroup>
                  </Tooltip>
                  {info.getValue()?.approvedBy.length > 0 ? (
                    <Flex alignItems={'center'} gap={1}>
                      <Tooltip
                        label={
                          <Box>
                            <Text>Approved by</Text>
                            {info
                              .getValue()
                              ?.approvedBy?.slice(0, 3)
                              .map((contributor: any) => (
                                <Text key={contributor.name} fontSize={14}>
                                  {contributor.name}
                                </Text>
                              ))}
                            {info.getValue()?.approvedBy?.length > 3 ? (
                              <Text>
                                +{info.getValue()?.approvedBy?.length - 3} more
                              </Text>
                            ) : (
                              <></>
                            )}
                          </Box>
                        }
                        hasArrow
                      >
                        <AvatarGroup size="sm" w={'fit-content'}>
                          {info
                            .getValue()
                            ?.approvedBy?.slice(0, 3)
                            .map((contributor: any) => (
                              <Avatar
                                key={contributor.name}
                                src={contributor}
                                name={contributor.name?.trim()}
                              >
                                <AvatarBadge
                                  boxSize="1em"
                                  bg="green.500"
                                  border={'none'}
                                >
                                  <Icon
                                    as={CheckIcon}
                                    boxSize="0.65em"
                                    color={'white'}
                                  />
                                </AvatarBadge>
                              </Avatar>
                            ))}
                        </AvatarGroup>
                      </Tooltip>
                      <Tooltip label="Comments " hasArrow>
                        <Flex gap={1} w="fit-content" pl={1} pt={2}>
                          <AnnotationIcon />
                          <Text
                            color="text.secondary2"
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="normal"
                          >
                            {info.getValue()?.comments}
                          </Text>
                        </Flex>
                      </Tooltip>
                    </Flex>
                  ) : (
                    <>
                      <Flex
                        justifyContent={'start'}
                        alignItems={'center'}
                        gap={1}
                        pl={1}
                      >
                        <WrongIcon
                          color={'transparent'}
                          stroke={'error'}
                          h={17}
                          w={17}
                          viewBox="0 0 16 16"
                          boxSizing="border-box"
                        />
                        <Text as="small" fontSize={14}>
                          No Approval
                        </Text>
                      </Flex>
                      <Tooltip label="Comments " hasArrow>
                        <Flex gap={1} w="fit-content" pl={1}>
                          <AnnotationIcon />
                          <Text
                            color="text.secondary2"
                            fontFamily="heading"
                            fontSize="sm"
                            fontWeight="normal"
                          >
                            {info.getValue()?.comments}
                          </Text>
                        </Flex>
                      </Tooltip>
                    </>
                  )}
                </>
              ) : (
                <Flex direction={'column'}>
                  <Flex justifyContent={'start'} alignItems={'center'} gap={1}>
                    <WrongIcon
                      color={'transparent'}
                      stroke={'error'}
                      h={17}
                      w={17}
                      viewBox="0 0 16 16"
                      boxSizing="border-box"
                    />
                    <Text as="small" fontSize={14}>
                      {info.getValue() && info.row.original.mergedAt
                        ? 'Merged w/o review'
                        : 'No Review'}
                    </Text>
                  </Flex>
                  <Tooltip label="Comments " hasArrow>
                    <Flex gap={1} w="fit-content" pl={1} pt={2}>
                      <AnnotationIcon />
                      <Text
                        color="text.secondary2"
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="normal"
                      >
                        {info.getValue()?.comments}
                      </Text>
                    </Flex>
                  </Tooltip>
                </Flex>
              )}
            </Stack>
          );
        },
        header: 'Reviewer',
        size: 200,
      }),

      columnHelper.accessor('prCycleTime', {
        cell: (info) => {
          return (
            <Flex gap={2} align="center">
              <Box
                height="8px"
                width="8px"
                borderRadius="50%"
                bg={info.getValue()?.color}
              />
              <Text as={'strong'} fontWeight={'semibold'}>
                {info.getValue()?.time
                  ? info.getValue()?.time
                  : 'Not Yet Merged'}
              </Text>
            </Flex>
          );
        },
        header: 'PR Cycle Time',
        size: 200,
      }),
    ],
    []
  );

  useEffect(() => {
    if (isError) {
      newToast({
        message: `Something went wrong!`,
        status: 'error',
      });
    }
  }, [isError]);

  useEffect(() => {
    if (!isFetching && isIgnorePrLoading) {
      setIsIgnorePrLoading(false);
    }
  }, [isFetching]);

  return (
    <>
      <PrFilters
        isFetched={isFetched}
        startDate={startDate}
        endDate={endDate}
        chartKey={chartKey}
        metricKey={metricKey}
        heading={heading}
        totalDBrows={(data as any)?.totalDBRowCount ?? 0}
        firstOption={firstOption}
        secondOption={secondOption}
        teamId={teamId}
        status={status}
        setTeamId={setTeamId}
        setStatus={setStatus}
        setFirstOption={setFirstOption}
        setSecondOption={setSecondOption}
        setChartKey={setChartKey}
        setMetric={setMetric}
        setHeading={setHeading}
        setMetricKey={setMetricKey}
      />

      <DataGrid
        integration={{
          type: 'GitHub',
          active:
            intDetailsData?.dto
              ?.filter((item: any) => item?.name === 'git')
              .some((item: any) => item?.active) ?? true,
        }}
        // apply the componnet origin checks here if needed
        columns={columns}
        isFetched={isFetched}
        data={(data as any)?.data ?? []}
        totalDBrows={(data as any)?.totalDBRowCount ?? 0}
        isIgnorePrOrIssueLoading={isIgnorePrLoading}
        isFetching={isFetching}
        isError={isError}
        isLoading={isLoading}
        fetchNextPage={fetchNextPage}
        startDate={startDate}
        endDate={endDate}
      />
      {ignoreEntryModal.isOpen && (
        <IgnoreEntryModal
          {...ignoreEntryModal}
          id={hideEntryId}
          handleIgnore={handlePrIgnoreOrIssue}
        />
      )}
    </>
  );
}

export default PRDetails;
