import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export const getInitiativesGraphql = async (
  status: string,
  pageNumber: number,
  pageSize: number,
  search: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetInitiatives(
        $orgId: String!
        $status: String!
        $pageNumber: Int!
        $pageSize: Int!
        $search: String!
      ) {
        Initiatives(
          orgId: $orgId
          status: $status
          pageNumber: $pageNumber
          pageSize: $pageSize
          search: $search
        ) {
          initiativeList {
            name
            id
            desc
            link
            status
            legend {
              color
              label
            }
            plannedDate
            estimatedDate
            progress
            totalTasks
            completedTasks
            startDate
            contributionTracking
            issueFilterConditions {
              conditions {
                field
                operator
                value
              }
            }
            issueDefinition {
              issueTypes
              labels
              projects
              issues
              customField {
                name
                value
              }
              dateRange {
                startDate
                endDate
              }
            }
          }
          totalPages
          totalElements
          currentPage
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      status,
      pageNumber,
      pageSize,
      search,
    }
  );

export const getInitiativeDetailGraphql = async (
  initiative: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetInitiativeDetails(
        $orgId: String!
        $initiative: String!
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        InitiativeDetails(
          orgId: $orgId
          initiative: $initiative
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          totalTasks
          totalTasksOpen
          totalTasksInProgress
          totalTasksClosed
          totalBugs
          totalBugsFixed
          epicDetails {
            id
            progress
            ticketType
            description
            url
            startDate
            endDate
            dependencies
            totalTasks
            totalTasksOpen
            totalTasksInProgress
            totalTasksClosed
            totalBugs
            totalBugsFixed
            displayOrder
            project
            type
            completedTaskCost
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      initiative,
      pageNumber,
      pageSize,
    }
  );

export const getInitiativeTaskGraphql = async (
  initiative: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query getTaskListDetails(
        $orgId: String!
        $initiative: String!
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        AllTaskForInitiative(
          orgId: $orgId
          initiative: $initiative
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          totalPages
          currentPage
          data {
            summary
            assignee
            ticketType
            ticketUrl
            updatedDate
            status
            key
            investment
            project
          }
        }
      }
    `,
    {
      orgId: `${localStorage.getItem('orgId')}`,
      initiative,
      pageNumber,
      pageSize,
    }
  );

export const getInitiativeChangeLogGraphql = async (
  initiative: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query getTicketUpdateLog($initiative: String!) {
        TicketUpdateLog(initiative: $initiative) {
          status
          assignee
          expectedDoneDate
          timeSpent
          updatedAt
        }
      }
    `,
    {
      initiative,
    }
  );

export const getInitiativeContributorsGraphql = async (
  initiative: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetContributorsForInitiative(
        $initiative: String!
        $orgId: String!
      ) {
        ContributorsForInitiative(initiative: $initiative, orgId: $orgId) {
          initiative
          contributorActivities {
            contributorAccount
            issueCount
            team
          }
        }
      }
    `,
    {
      initiative,
      orgId: localStorage.getItem('orgId'),
    }
  );

export const getInitiativeSprintGraphql = async (
  initiative: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetSprintsForInitiative($initiative: String!, $orgId: String!) {
        SprintsForInitiative(initiative: $initiative, orgId: $orgId) {
          initiative
          sprints {
            sprintName
            sprintId
            project
            startDate
            endDate
            unplannedTasks
            commitedTasks
            completedTasks
            commitedSP
            completedSP
          }
        }
      }
    `,
    {
      initiative,
      orgId: localStorage.getItem('orgId'),
    }
  );

export const getInitiativeTeamsGraphql = async (
  initiative: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTeamsForInitiative($initiative: String!, $orgId: String!) {
        TeamsForInitiative(initiative: $initiative, orgId: $orgId) {
          initiative
          teams {
            name
            allocation
            contributors
          }
        }
      }
    `,
    {
      initiative,
      orgId: localStorage.getItem('orgId'),
    }
  );

export const teamAllocationGraphql = async (
  initiative: string,
  team: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTeamAllocationDetails(
        $initiative: String!
        $orgId: String!
        $team: String!
      ) {
        TeamAllocationDetails(
          initiative: $initiative
          orgId: $orgId
          team: $team
        ) {
          team
          email
          allocation
        }
      }
    `,
    {
      initiative,
      orgId: `${localStorage.getItem('orgId')}`,
      team,
    }
  );

export const initiativeProgressGraphql = async (
  initiativeId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetInitiativeTaskProgress($orgId: String, $initiativeId: String) {
        InitiativeTaskProgress(orgId: $orgId, initiativeId: $initiativeId) {
          taskCycleTime
          membersInvolved
          teamsInvolved
          data {
            date
            open
            closed
            inProgress
          }
        }
      }
    `,
    {
      initiativeId,
      orgId: `${localStorage.getItem('orgId')}`,
    }
  );

export const initiativeTaskDistGraphql = async (
  initiativeId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GitInitiativeTaskStatusDistribution(
        $orgId: String
        $initiativeId: String
      ) {
        InitiativeTaskStatusDistribution(
          orgId: $orgId
          initiativeId: $initiativeId
        ) {
          data {
            name
            value
          }
        }
      }
    `,
    {
      initiativeId,
      orgId: `${localStorage.getItem('orgId')}`,
    }
  );

export const getPeopleEffortHoursOverviewGraphql = async (
  initiativeId: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPeopleEffortHoursOverview(
        $orgId: String!
        $initiativeId: String!
      ) {
        PeopleEffortHoursOverview(orgId: $orgId, initiativeId: $initiativeId) {
          contributorIssueCountList {
            name
            count
          }
          avgActiveContributor
          percentEffort
        }
      }
    `,
    {
      initiativeId,
      orgId: `${localStorage.getItem('orgId')}`,
    }
  );

export const deliverableIssueTypeDistGraphql = async (
  initiativeId: string,
  distributionType: string,
  deliverableType: string,
  status: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueTypeDistribution(
        $filter: Filter!
        $distributionType: String!
      ) {
        IssueTypeDistribution(
          filter: $filter
          distributionType: $distributionType
        ) {
          key
          value
          color
        }
      }
    `,
    {
      filter: {
        orgId: localStorage.getItem('orgId'),
        status,
        initiativeId,
        deliverableType,
      },
      distributionType,
    }
  );

export const getDeliverableTableGraphql = async (
  deliverableType: string,
  status: string,
  search: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetDeliverables(
        $filter: Filter!
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        Deliverables(
          filter: $filter
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          deliverables {
            detail {
              name
              type
              id
              desc
              link
              status
              legend {
                color
                label
              }
              plannedDate
              estimatedDate
              startDate
              condition
              issueFilterConditions {
                conditions {
                  field
                  operator
                  value
                }
              }
            }
            velocity {
              data
              average
              cycleTime
            }
            scope {
              scopeChange
              totalTasks
              completedTasks
              todoData {
                totalCount
                epicCount
                taskCount
                bugCount
              }
              inProgressData {
                totalCount
                epicCount
                taskCount
                bugCount
              }
              doneData {
                totalCount
                epicCount
                taskCount
                bugCount
              }
              lastDoneDate
            }
            allocation {
              teams
              activeContributors
              effortHours
              avgAllocation
            }
            calculatedStatus {
              status {
                color
                label
              }
              projectedEndDate
            }
          }
          totalPages
          totalElements
          currentPage
        }
      }
    `,
    {
      filter: {
        orgId: localStorage.getItem('orgId'),
        status,
        deliverableType,
        searchTerm: search,
        startDate,
        endDate,
        sprintId,
        teamId,
      },
      pageNumber,
      pageSize,
    }
  );

export const getSprintDeliveryOverviewGraphql = async (
  initiativeId: string,
  deliverableType: string,
  status: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetSprintDeliveryOverview($filter: Filter!) {
        SprintDeliveryOverview(filter: $filter) {
          data
          keys {
            key
            name
            color
          }
        }
      }
    `,
    {
      filter: {
        orgId: localStorage.getItem('orgId'),
        status,
        initiativeId,
        deliverableType,
      },
    }
  );

export const getDeliverableSignalsGraphql = async (
  initiativeId: string,
  deliverableType: string,
  status: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetDeliverableSignals($filter: Filter!) {
        DeliverableSignals(filter: $filter) {
          category
          score
          message
          link
          color
          tooltip
        }
      }
    `,
    {
      filter: {
        orgId: localStorage.getItem('orgId'),
        status,
        initiativeId,
        deliverableType,
      },
    }
  );

export const getSprintMetricsWithStatus = async (
  filter: {
    orgId?: string;
    initiativeId: string;
    teamId: string;
    sprintIds: string[];
    startDate: string;
    endDate: string;
    status: string;
    type: string;
    projectsFilter: string[];
    sprintViewFilters: {
      isCompletionRate: boolean;
      isPlanningAccuracy: boolean;
    };
    deliverableType: string | null;
  },
  pageSize: number,
  pageNumber: number,
  bySp: boolean
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetSprintMetricsWithStatus(
        $filter: Filter!
        $pageSize: Int
        $pageNumber: Int
        $bySp: Boolean
      ) {
        SprintMetricsWithStatus(
          filter: $filter
          bySp: $bySp
          pageSize: $pageSize
          pageNumber: $pageNumber
        ) {
          reports {
            sprintDetail {
              orgId
              sprintId
              sprintName
              sprintDuration
              startDate
              endDate
              status
              overCommitmentRate
              scopeCreepRate
              blockedIssues
              completionRate
            }
            sprintPlanning {
              tasks {
                name
                value
              }
              planningAccuracy
            }
            sprintProgress {
              tasks {
                name
                value
              }
            }
            sprintTaskDist {
              tasks {
                name
                value
              }
            }
            sprintVelocity {
              chart
            }
          }
          currentPage
          totalPages
          chartDataState
          zs
          chartError {
            message
            link
          }
        }
      }
    `,
    {
      filter: {
        ...filter,
        orgId: localStorage.getItem('orgId'),
      },
      pageSize,
      pageNumber,
      bySp,
    }
  );

export const getContributorsAllocationGraphql = async (
  initiativeId: string,
  deliverableType: string,
  status: string,
  view: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetContributorsAllocation($filter: Filter) {
        ContributorsAllocation(filter: $filter) {
          contributor
          calculatedAllocation
          assignedAllocation
          lastActivityDate
        }
      }
    `,
    {
      filter: {
        orgId: localStorage.getItem('orgId'),
        status,
        initiativeId,
        deliverableType,
        view,
      },
    }
  );

export const getTeamsAllocationGraphql = async (
  initiativeId: string,
  deliverableType: string,
  status: string
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTeamsAllocation($filter: Filter) {
        TeamsAllocation(filter: $filter) {
          name
          allocation
          assignedAllocation
          contributors
        }
      }
    `,
    {
      filter: {
        orgId: localStorage.getItem('orgId'),
        status,
        initiativeId,
        deliverableType,
      },
    }
  );

export const getDeliverableScenarioGraphql = async (
  deliverableId: string,
  deliverableType: string,
  averageAttention: number | null,
  remainingScope: number | null,
  developerCount: number | null
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetDeliverableScenarioDto($scenarioInputDto: ScenarioInputDto) {
        DeliverableScenarioDto(scenarioInputDto: $scenarioInputDto) {
          targetDate
          projectedEndDate
          remainingDuration
          remainingEffort
          activeContributors
          averageAttention
          scope
          completedScope
          data
          keys {
            key
            name
            color
            chartType
          }
          referenceLineData
        }
      }
    `,
    {
      scenarioInputDto: {
        orgId: localStorage.getItem('orgId'),
        deliverableId,
        deliverableType,
        averageAttention,
        remainingScope,
        developerCount,
      },
    }
  );
