import { Navigate, Route, Routes, useLocation } from 'react-router';
import { useMemo } from 'react';
import {
  AddCustomMetrics,
  AddMember,
  AddTeams,
  Contributors,
  CustomMetrics,
  Dora,
  EditCustomMetrics,
  Feedback,
  IntegrationDetails,
  IntegrationGitDetails,
  IntegrationSettings,
  InvestmentCategories,
  Members,
  Notification,
  Organisation,
  SDLCMappings,
  SubsAndBilling,
  Survey,
  Teams,
  ActiveOrganizations,
} from './asyncPages';
import NotFound from '../Status/404NotFound';
import { Card, Center, Stack, Text } from '@chakra-ui/react';
import { LockIcon } from '@devd-client/devd/components';
import { AddMetric } from '../Dashboard/asyncPages';

export type RouteType = {
  path: string;
  label: string;
  element: JSX.Element;
};

export function Settings() {
  const location = useLocation();
  const routes: RouteType[] = useMemo(() => {
    return [
      {
        label: 'Integrations',
        path: 'integrations',
        element: <IntegrationSettings />,
      },
      {
        label: 'Investment categories',
        path: 'investment-categories',
        element: <InvestmentCategories />,
      },
      {
        label: 'Teams',
        path: 'teams',
        element: <Teams />,
      },
      {
        label: 'members',
        path: 'members',
        element: <Members />,
      },
      {
        label: 'Dora',
        path: 'dora',
        element: <Dora />,
      },

      {
        label: 'Subscription and Billing',
        path: 'subscription-billing',
        element: <SubsAndBilling />,
      },
      {
        label: 'organisation',
        path: 'organisation',
        element: <Organisation />,
      },
      {
        label: 'Contributors',
        path: 'contributors',
        element: <Contributors />,
      },
      {
        label: 'Feedback',
        path: 'feedback',
        element: <Feedback />,
      },
      {
        label: 'Notification',
        path: 'notification',
        element: <Notification />,
      },
      {
        label: 'Survey',
        path: 'survey',
        element: <Survey />,
      },
      {
        label: 'Custom Metrics',
        path: 'custom-metrics',
        element: <CustomMetrics />,
      },
      {
        label: 'SDLC Map',
        path: 'sdlc-map',
        element: <SDLCMappings />,
      },
      {
        label: 'Active Organizations',
        path: 'active-organizations',
        element: <ActiveOrganizations />,
      },
      {
        path: '*',
        label: '',
        icon: '',
        element: <NotFound />,
      },
    ];
  }, []);

  return (
    <Routes key={location.pathname}>
      <Route
        path="/"
        element={<Navigate to={`` + routes[0]?.path} replace />}
      />
      {routes.map((route, index) => (
        <Route
          key={index}
          path={`/` + route.path}
          element={
            localStorage.getItem('userId') ===
              'a867b5e7-ee22-4a9f-becd-b8f3aa43f753' ||
            (localStorage.getItem('role') === 'MANAGER' &&
              [
                'investment-categories',
                'dora',
                'feedback',
                'members',
                'subscription-billing',
              ].includes(route.path)) ? (
              <Center height="100%">
                <Card>
                  <Stack spacing={4} mb={2}>
                    <Center>
                      <LockIcon height={'80px'} width="300px" />
                    </Center>

                    <Text
                      fontFamily="heading"
                      fontSize="xl"
                      fontWeight="medium"
                      color="text.secondary"
                      alignSelf="center"
                    >
                      You don't have access.
                    </Text>
                  </Stack>
                </Card>
              </Center>
            ) : localStorage.getItem('role') === 'EMPLOYEE' ? (
              <Center height="100%">
                <Card>
                  <Stack spacing={4} mb={2}>
                    <Center>
                      <LockIcon height={'80px'} width="300px" />
                    </Center>

                    <Text
                      fontFamily="heading"
                      fontSize="xl"
                      fontWeight="medium"
                      color="text.secondary"
                      alignSelf="center"
                    >
                      You don't have access.
                    </Text>
                  </Stack>
                </Card>
              </Center>
            ) : (
              route.element
            )
          }
        />
      ))}
      <Route path="/integrations/:id" element={<IntegrationDetails />} />
      <Route
        path="/integrations/:id/:name"
        element={<IntegrationGitDetails />}
      />
      <Route
        path="/integrations/:id/:name/:id2"
        element={<IntegrationGitDetails />}
      />
      <Route path="/teams/add-team" element={<AddTeams />} />
      <Route path="/teams/:teamId" element={<AddTeams />} />
      <Route path="/teams/:teamId/member" element={<AddMember />} />
      <Route path="/custom-metrics/add" element={<AddMetric />} />
      <Route path="/custom-metrics/edit" element={<AddMetric />} />
    </Routes>
  );
}

export default Settings;
