import {
  Tooltip,
  useTheme,
  Skeleton,
  Flex,
  Box,
  Text,
  Divider,
  Button,
  Image,
  Spinner,
  IconButton,
} from '@chakra-ui/react';
import { FC, Fragment, useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import Chart from '../../../../charts/Chart';

import { PeriodType } from '../../../../shared/typings';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { useSizeBreakdownGql } from '../../../graphql';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { splitTime } from '../../../../shared/utils/index';
import {
  convertToSingleDecimal,
  viewSwitchObj,
} from '../../../helpers/metricCard.utils';
import { MdArrowForwardIos } from 'react-icons/md';
import { ViewSwitcher } from '../../../../viewSwitcher/index';

import { AppTooltip } from '../../../../appTooltip/index';
import { Link } from 'react-router-dom';
import { toPng } from 'html-to-image';
import Logo from '../../../../shared/assets/DD-Logo.svg';
import ImageDown from '../../../../shared/assets/image-down.svg';
import { useToastHook } from '../../../../appToast';
import { Loader } from '../../../../appLoader';
import { RxReload } from 'react-icons/rx';

type ValueType = {
  color?: string;
};

type AnchorLinkType = {
  mr?: string;
  ml?: string;
};

type CountProps = {
  color?: string;
};

export const ChartNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const Name = styled.h5`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  line-height: 12px;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-right: 5px;
`;

export const MetricLabelWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
`;

export const Count = styled.h1<CountProps>`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['4xl']};
  margin-right: 8px;
  line-height: 28px;
  min-width: max-content;
  color: ${({ color }) => color && color};
`;

export const Label = styled.h5`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ theme }) => theme.colors.text.secondary};
  min-width: max-content;
  line-height: 12px;
  letter-spacing: 0.5px;
`;

export const Value = styled.p<ValueType>`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ color }) => color || '#777'};
  min-width: max-content;
  line-height: 16px;
`;

const MetaData = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ChartBox = styled.div<any>`
  width: 100%;
  height: 350px;
  margin-top: ${({ mt }) => mt};
`;

interface ChartViewCardProps {
  data: any;
  breakdown: string;
  isLoading: boolean;
  isFetching: boolean;
  selected: PeriodType;
  setSelected: (selected: PeriodType) => void;
  selectedTeam: string;
  viewToggle: string;
  setViewToggle: (toggle: string) => void;
  repos: string[];
  handelReloadClick?: () => void;
}

const ChartViewCard: FC<ChartViewCardProps> = ({
  data,
  breakdown,
  isLoading,
  isFetching,
  selected,
  setSelected,
  selectedTeam,
  viewToggle,
  setViewToggle,
  repos,
  handelReloadClick,
}) => {
  const { colors } = useTheme();
  const [newToast] = useToastHook();

  const { data: sizeBreakdown, isLoading: sizeBreakdownLoading } =
    useSizeBreakdownGql(
      selected.startDate,
      selected.endDate,
      data?.chartMetadata?.chartKey,
      selectedTeam,
      selected?.sprintId
    );

  const [show, setShow] = useState(true);

  const ref = useRef<HTMLDivElement>(null);
  const [mergedImage, setMergedImage] = useState<string | null>(null);

  const downloadChart = useCallback(
    (chartName: string) => {
      if (ref.current === null) {
        return;
      }

      toPng(ref.current, { cacheBust: true })
        .then((dataUrl) => {
          const img = document.createElement('img') as HTMLImageElement;
          img.src = dataUrl;
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (!context) return;
            canvas.width = img.width;
            canvas.height = img.height;

            context.drawImage(img, 0, 0);

            context.font = '700 24px Inter';
            context.fillStyle = '#232B2B';
            context.textAlign = 'left';
            context.fillText(chartName, 20, 40);

            const logoImg = document.createElement('img') as HTMLImageElement;
            logoImg.src = Logo;
            logoImg.onload = () => {
              const logoWidth = logoImg.width / 4;
              const logoHeight = logoImg.height / 4;
              const logoX = canvas.width - logoWidth - 25;
              const logoY = 80;

              context.drawImage(logoImg, logoX, logoY, logoWidth, logoHeight);

              const mergedDataUrl = canvas.toDataURL('image/png');
              setMergedImage(mergedDataUrl);

              const link = document.createElement('a');
              link.download = `${chartName}.png`;
              link.href = mergedDataUrl;
              link.click();
              setShow(true);
            };
          };
          newToast({
            message: 'Chart downloaded successfully.',
            status: 'success',
          });
        })
        .catch((err) => {
          setShow(true);
          newToast({
            message: 'Something went wrong while downloading.',
            status: 'error',
          });
        });
    },
    [ref]
  );

  return (
    <Box
      w="100%"
      bg="white"
      minHeight="460px"
      borderRadius="4px"
      border="0.5px solid rgba(0, 0, 0, 0.1)"
      padding="16px"
      overflowY={'auto'}
      ref={ref}
      overflowX={'hidden'}
    >
      {isLoading || sizeBreakdownLoading ? (
        <Skeleton width="300px" height="18px" />
      ) : (
        <Flex
          w="full"
          justify="flex-end"
          // justify="space-between"
        >
          {/* <Heading>{data?.chartMetadata?.chartTitle}</Heading> */}
          {/* <Text
            color="text.primary"
            fontSize="sm"
            fontFamily="heading"
            fontWeight="normal"
          >
            {data?.highlight}
          </Text> */}
          {data?.chartDataState === 'NOT_CONFIGURED' ||
          data?.chartDataState === 'NO_INTEGRATION' ||
          data?.chartDataState === 'ERROR' ? (
            <AppTooltip
              trigger="hover"
              content={
                <Flex>
                  <Text
                    fontFamily="heading"
                    fontSize="sm"
                    color="text.primary"
                    mr={2}
                  >
                    {data?.chartError?.message}
                  </Text>
                  {data?.chartError?.link && (
                    <Link to={`${data?.chartError?.link}`}>
                      <Text
                        color="primary"
                        fontFamily="heading"
                        fontSize="sm"
                        textDecoration={'underline'}
                      >
                        Configure
                      </Text>
                    </Link>
                  )}
                </Flex>
              }
            >
              <Box>
                <Button
                  size={'sm'}
                  color={'error'}
                  border="1px"
                  borderColor={'error'}
                  backgroundColor={'white'}
                  fontFamily="heading"
                >
                  {'why no data?'}
                </Button>
              </Box>
            </AppTooltip>
          ) : data?.chartMetadata?.xlabel === 'Date' ? (
            <ViewSwitcher
              tabList={viewSwitchObj}
              currentTab={viewToggle}
              handleSwitch={setViewToggle}
              height="25px"
            />
          ) : (
            <></>
          )}
        </Flex>
      )}
      <>
        {isLoading ? (
          <Skeleton
            height="40px"
            width="100px"
            marginTop="5px"
            marginBottom="5px"
          />
        ) : breakdown === '' || breakdown === 'DEFAULT' ? (
          <MetaData>
            {typeof data?.average !== 'undefined' && data?.average !== null && (
              <MetricLabelWrapper>
                {typeof data?.average !== 'undefined' &&
                  data?.average !== null && (
                    <Count>
                      {String(data?.average)}{' '}
                      {data?.chartMetadata?.dataType === 'PERCENTAGE' && (
                        <Text fontSize={'18px'} display={'inline'}>
                          %
                        </Text>
                      )}
                    </Count>
                  )}
                <Label>
                  {data?.chartMetadata?.dataType !== 'PERCENTAGE' &&
                    data?.averageLabel}
                </Label>
                {show && (
                  <Box ml={'auto'}>
                    <Tooltip
                      hasArrow
                      aria-label="reload-metric"
                      label="Reload Metric Data"
                    >
                      <IconButton
                        isRound
                        size="sm"
                        alignContent={'end'}
                        bg="none"
                        aria-label="circle info"
                        icon={<RxReload size={'20px'} />}
                        onClick={() => handelReloadClick && handelReloadClick()}
                      />
                    </Tooltip>
                  </Box>
                )}
              </MetricLabelWrapper>
            )}

            {data?.threshold && (
              <MetricLabelWrapper>
                <Count color={data?.threshold?.color}>
                  {String(parseFloat(data?.threshold?.value).toFixed(1))}
                </Count>
                <Tooltip label={data?.threshold?.desc} hasArrow>
                  <Label>{data?.threshold?.title}</Label>
                </Tooltip>
              </MetricLabelWrapper>
            )}
            <Flex justify={'space-between'} align="center">
              {typeof data?.previousPeriodStat?.percentChange !== 'undefined' &&
              data?.previousPeriodStat?.percentChange !== null ? (
                <Tooltip
                  hasArrow
                  label={
                    data?.previousPeriodStat?.reason
                      ? `Reason: ${data?.previousPeriodStat?.reason}`
                      : ''
                  }
                  placement="bottom"
                  background={'gray.700'}
                  color={'white'}
                >
                  <Flex align="center">
                    {data?.previousPeriodStat?.percentChange !== 0 ? (
                      <>
                        {data?.previousPeriodStat?.percentChange > 0 ? (
                          <IoMdArrowDropup
                            color={
                              data?.previousPeriodStat?.improved
                                ? colors.success
                                : colors.error
                            }
                            fontSize={'24px'}
                          />
                        ) : (
                          <IoMdArrowDropdown
                            color={
                              data?.previousPeriodStat?.improved
                                ? colors.success
                                : colors.error
                            }
                            fontSize={'24px'}
                          />
                        )}
                        <Value
                          color={
                            data?.previousPeriodStat?.improved
                              ? colors.success
                              : colors.error
                          }
                        >
                          {/* {data?.previousPeriodStat?.percentChange > 0
                            ? '+'
                            : ''} */}
                          {Math.abs(data?.previousPeriodStat?.percentChange)}%
                        </Value>
                      </>
                    ) : (
                      <Value color={colors.text.secondary}>{``}</Value>
                    )}
                  </Flex>
                </Tooltip>
              ) : (
                <></>
              )}

              {!isFetching && show && data?.data?.length > 0 && (
                <Tooltip label="Download Chart" hasArrow>
                  <Button
                    variant={'unstyled'}
                    size={'xs'}
                    mx={2}
                    position={'absolute'}
                    right={8}
                    zIndex={99}
                    onClick={() => {
                      setShow(false);
                      downloadChart(data?.chartMetadata?.chartTitle ?? 'Chart');
                    }}
                  >
                    <Image w={5} h={5} src={ImageDown} />
                  </Button>
                </Tooltip>
              )}
            </Flex>
          </MetaData>
        ) : (
          <></>
        )}

        {isLoading || isFetching ? (
          <Box position={'relative'}>
            <Skeleton height="350px" width="100%" />
            <Spinner position={'absolute'} top={'45%'} left={'47%'} />
          </Box>
        ) : data?.chartDataState === 'NOT_CONFIGURED' ||
          data?.chartDataState === 'NO_INTEGRATION' ||
          data?.chartDataState === 'ERROR' ? (
          <></>
        ) : (
          <ChartBox
            mt={breakdown === '' || breakdown === 'DEFAULT' ? '10px' : '30px'}
            position={'relative'}
          >
            <Chart
              data={data?.data}
              keys={data?.keys}
              chartMetadata={data?.chartMetadata}
              showLabel={true}
              showXLabel
              showYLabel
              forDetails={true}
              detailView={true}
              selected={selected}
              selectedTeam={selectedTeam}
              repos={repos}
              granularSlant={true}
              hideBtn={['PIPELINE_RUN_TIME', 'PIPELINE_RUN_COUNT'].includes(
                data?.chartMetadata?.chartKey
              )}
              slant={
                ['SPRINT_ADDITION_RATE', 'SPRINT_COMPLETION_PERCENT'].includes(
                  data?.chartMetadata?.chartKey
                )
                  ? true
                  : false
              }
            />
          </ChartBox>
        )}
      </>

      {sizeBreakdown?.title &&
        sizeBreakdown?.breakdowns &&
        sizeBreakdown?.breakdowns?.length >= 1 && (
          <>
            <Divider />

            <Flex direction={'column'} w="full">
              <Box w="full" height="0.5px" bg="rgba(0,0,0,0.1)" my={4} />
              <Flex align="center" flexWrap="wrap" mb={3}>
                <Text
                  fontFamily={'heading'}
                  fontSize="sm"
                  color="text.primary"
                  fontWeight="medium"
                  mr={1}
                >
                  {sizeBreakdown.title}
                </Text>
                <Tooltip
                  hasArrow
                  label={sizeBreakdown.subTitle}
                  aria-label="A tooltip"
                >
                  <Box>
                    <AiOutlineInfoCircle
                      color={colors.text.primary}
                      fontSize={14}
                    />
                  </Box>
                </Tooltip>
              </Flex>

              <Flex
                w="full"
                align="center"
                gap={{ base: 1, md: 3 }}
                justify="space-between"
              >
                {sizeBreakdown?.breakdowns?.map(
                  (item: any, idx: number, arr: any) => (
                    <Fragment key={idx}>
                      <Box w="fit-content">
                        <Flex align="center" direction="column" gap={1}>
                          <Tooltip
                            placement={
                              arr?.length - 1 !== idx ? 'top' : 'top-end'
                            }
                            hasArrow
                            aria-label="subtitle"
                            label={item?.subTitle}
                          >
                            <Text
                              fontFamily="heading"
                              fontWeight="normal"
                              color="gray.700"
                              fontSize="11.5px"
                              whiteSpace={'nowrap'}
                              padding="4px 12px"
                              border="1px solid"
                              borderColor="gray.300"
                              borderRadius="999px"
                            >
                              {item?.title}
                            </Text>
                          </Tooltip>
                        </Flex>

                        <Flex justify="space-evenly">
                          <Text
                            fontFamily="heading"
                            fontWeight="medium"
                            color="text.primary"
                            fontSize="sm"
                            whiteSpace={'nowrap'}
                            textAlign={'center'}
                          >
                            {sizeBreakdown?.unit === 'minutes'
                              ? splitTime(item?.value / 60)
                              : `${convertToSingleDecimal(item?.value)}%`}
                          </Text>
                        </Flex>
                      </Box>
                      {arr?.length - 1 !== idx && (
                        <Box
                          height="1px"
                          position={'relative'}
                          w="100%"
                          mt={'-20px'}
                          background="gray.200"
                        >
                          {sizeBreakdown?.unit === 'minutes' && (
                            <Box
                              position="absolute"
                              top="-8px"
                              left="100%"
                              transform="translateX(-50%)"
                            >
                              <MdArrowForwardIos color={'#CBD5E0'} />
                            </Box>
                          )}
                        </Box>
                      )}
                    </Fragment>
                  )
                )}
              </Flex>
            </Flex>
          </>
        )}
    </Box>
  );
};

export default ChartViewCard;
