export const BlueBgTick = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group 1">
        <path
          id="Rectangle"
          d="M13.7143 0H2.28571C1.02335 0 0 1.02335 0 2.28571V13.7143C0 14.9767 1.02335 16 2.28571 16H13.7143C14.9767 16 16 14.9767 16 13.7143V2.28571C16 1.02335 14.9767 0 13.7143 0Z"
          fill="#4BADE8"
        />
        <g id="Check">
          <path
            id="Stroke 1 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1424 4.25042C12.6353 4.64471 12.7152 5.3639 12.3209 5.85678L7.74949 11.5711C7.3552 12.0639 6.63601 12.1438 6.14313 11.7495C5.65026 11.3552 5.57035 10.6361 5.96465 10.1432L10.5361 4.4289C10.9304 3.93603 11.6496 3.85612 12.1424 4.25042Z"
            fill="white"
          />
          <path
            id="Stroke 3 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.76326 7.76326C4.20958 7.31695 4.93319 7.31695 5.37951 7.76326L7.66522 10.049C8.11154 10.4953 8.11154 11.2189 7.66522 11.6652C7.21891 12.1115 6.49529 12.1115 6.04898 11.6652L3.76326 9.37951C3.31695 8.93319 3.31695 8.20958 3.76326 7.76326Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};
