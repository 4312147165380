export function EditPencilIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="edit-02" clipPath="url(#clip0_5417_8075)">
        <path
          id="Icon"
          d="M9.00001 5.00015L7.00001 3.00015M1.25 10.7502L2.94218 10.5621C3.14893 10.5392 3.2523 10.5277 3.34892 10.4964C3.43465 10.4686 3.51622 10.4294 3.59144 10.3798C3.67623 10.3239 3.74977 10.2504 3.89686 10.1033L10.5 3.50015C11.0523 2.94787 11.0523 2.05244 10.5 1.50015C9.94773 0.947867 9.0523 0.947866 8.50001 1.50015L1.89686 8.10329C1.74977 8.25038 1.67623 8.32392 1.62032 8.40871C1.57072 8.48393 1.53151 8.56551 1.50376 8.65123C1.47248 8.74785 1.46099 8.85122 1.43802 9.05797L1.25 10.7502Z"
          stroke="#626266"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5417_8075">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EditPencilIcon;
