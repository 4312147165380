import React, { FC, useMemo, useState } from 'react';
import {
  ContributorSideBar,
  DataGrid,
  Loader,
  PeriodType,
  SlideBar,
} from '@devd-client/devd/components';
import { Button, Flex, Text } from '@chakra-ui/react';
import {
  useContributorDetailGql,
  useContributorDetailInfinteGql,
} from '../../../graphql';
import { createColumnHelper } from '@tanstack/react-table';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { apiClient } from '@devd-client/api';
import moment from 'moment';

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
`;

interface SlideDrawerProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  team: string;
  selected: PeriodType;
}

const SlideDrawer: FC<SlideDrawerProps> = ({
  openDrawer,
  handleDrawerClose,
  team,
  selected,
}) => {
  const {
    data: contributorData,
    isLoading: contributorLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useContributorDetailInfinteGql(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    team,
    localStorage.getItem('role'),
    20
  );

  return (
    <ContributorSideBar
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      team={team!}
      contributorData={contributorData}
      loading={contributorLoading}
      repoTitle={false}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};

export default React.memo(SlideDrawer);
