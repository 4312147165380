import { Box, Flex, Switch, Text } from '@chakra-ui/react';
import { NewDataGrid } from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface DeveloperTableProps {
  developers: any;
  onToggleDeveloper: (developerId: string) => void;
  showCheckbox?: boolean;
  isLoading: boolean;
}

export const DeveloperTable = ({
  developers,
  onToggleDeveloper,
  isLoading,
  showCheckbox = true,
}: DeveloperTableProps) => {
  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Flex align="center" gap={3}>
            {showCheckbox ? (
              <Switch
                colorScheme="blue"
                isChecked={info.row.original.isActive}
                onChange={() => onToggleDeveloper(info.row.original.id)}
              />
            ) : null}
            <Text textDecoration={'underline'} maxW={'350px'} isTruncated>
              <Link to={`/contributors/${info.getValue()}`}>
                {info.getValue()}
              </Link>
            </Text>
          </Flex>
        ),
        header: () => <Box pl={showCheckbox ? '44px' : '0px'}>Name</Box>,
        enableSorting: false,
        size: 200,
      }),
      columnHelper.accessor('attention', {
        cell: (info) => (
          <Text fontWeight={'normal'}>
            {info.getValue() === '-' ? '-' : `${info.getValue()}%`}
          </Text>
        ),
        header: 'Attention',
        enableSorting: false,
        size: 40,
      }),
      columnHelper.accessor('nonReleaseWork', {
        cell: (info) => {
          const value = info.getValue();
          return value === 0 ? (
            <Text fontWeight={'normal'}>
              No competing development priorities
            </Text>
          ) : (
            <Box as="span">
              <Text as="span" fontWeight={'normal'}>
                {value === '-' ? '' : 'Non-Release Work '}
              </Text>
              <Box as="span" fontWeight="semibold">
                {value === '-' ? '-' : `(${value}%)`}
              </Box>
            </Box>
          );
        },
        header: 'Competing priorities (previous 4 weeks)',
        enableSorting: false,
        size: 350,
      }),
    ],
    [showCheckbox]
  );

  return (
    <NewDataGrid
      columns={columns}
      data={developers}
      showLoader={isLoading}
      maxH="35vh"
      sticky="firstCol"
      hideHeaderTooltip
    />
  );
};
