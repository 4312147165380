import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  phases: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required('Phase name is required')
        .matches(/^[a-zA-Z0-9\s-_]+$/, 'Special characters are not allowed')
        .max(100, 'Phase name must be less than 100 characters'),
      plannedDate: Yup.date().required('Planned end date is required'),
      actualDate: Yup.date(),
      owners: Yup.array()
        .of(Yup.string())
        .min(1, 'At least one owner is required'),
    })
  ),
});
