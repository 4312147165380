import { gql } from 'graphql-request';
import { graphQLClient } from '@devd-client/api';

export const prChartsGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    projectsFilter?: string[];
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPrReportDetail(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metric: String!
        $teamId: String
        $author: String
        $sprintId: String
        $timeZone: String
        $granularity: String!
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
      ) {
        PrReportDetail(
          metric: $metric
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            link
            author
            summary
            repository
            changes
            issueId
            date
            updatedAt
            attributes {
              key
              value
            }
            title
            externalLink
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metric,
      ...filter,
      granularity: '',
      pageNumber,
      pageSize,
    }
  );

export const PRChartsGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    author?: string;
    reviewer?: string;
    sprintId?: string;
    projectsFilter?: string[];
    type?: string;
    status?: string;
    prViewFilters?: {
      prReviewResponsivenessType?: string | null;
      prFeedbackResponsivenessType?: string | null;
      prSize?: string;
      age?: string;
      prIds?: string[] | null;
      searchQuery?: string | null;
      isPrLinked?: boolean | null;
      isPrWithComment?: boolean | null;
      isPrReviewedWithComment?: boolean | null;
      isLongPrCycleTime?: boolean | null;
      isMergedWithReview?: boolean | null;
      prIterations?: string | null;
    };
    orderBy?: string;
    orderColumn?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> => {
  return await graphQLClient.request(
    gql`
      query GetPRReportDetail(
        $metric: String!
        $granularity: String!
        $filter: Filter!
        $orderBy: String
        $orderColumn: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        PRReportDetail(
          metric: $metric
          granularity: $granularity
          filter: $filter
          orderBy: $orderBy
          orderColumn: $orderColumn
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            status
            prDetail {
              id
              externalUrl
              repo
              title
              issueId
              addition
              deletion
              sourceBranch
              targetBranch
              issueType
              issueUrl
              prNumber
              batchSize
            }
            teams
            author {
              avatar
              name
              commitCount
            }
            createdAt
            mergedAt
            reviewersDetails {
              contributors {
                avatar
                name
                commitCount
              }
              approvedBy {
                avatar
                name
                commitCount
              }
              comments
            }
            prCycleTime {
              color
              time
            }
            breakdowns {
              title
              icon
              value
            }
          }
          metricZeroStateDetails {
            title
            actionText
            actionNav
            description
            leftImageUrl
            topImageUrl
            bottomImageUrl
            rightImageUrl
          }
          totalPages
          currentPage
          totalCount
        }
      }
    `,
    {
      metric,
      granularity: '',
      filter: {
        startDate,
        endDate,
        orgId: localStorage.getItem('orgId'),
        teamId: filter?.teamId,
        author: filter?.author,
        reviewer: filter?.reviewer,
        sprintId: filter?.sprintId,
        project: '',
        projectsFilter: filter?.projectsFilter,
        type: filter?.type,
        status: filter?.status,
        prViewFilters: filter?.prViewFilters,
      },
      orderBy: filter?.orderBy,
      orderColumn: filter?.orderColumn,
      pageNumber,
      pageSize,
    }
  );
};

export const issueChartsGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    status?: string;
    sprintId?: string;
    projectsFilter?: string[];
    sdlcStageId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueReportDetail(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $granularity: String!
        $teamId: String
        $sprintId: String
        $author: String
        $type: String
        $status: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
        $timeZone: String
        $sdlcStageId: String
      ) {
        IssueReportDetail(
          metric: $metricType
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            sprintId: $sprintId
            author: $author
            status: $status
            type: $type
            projectsFilter: $projectsFilter
            timeZone: $timeZone
            sdlcStageId: $sdlcStageId
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );
export const issueTableChartsGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    sprintId?: string;
    author?: string;
    type?: string;
    projectsFilter?: string[];
    status?: string;
    issueViewFilters?: {
      issueType?: string | null;
      issueStatusType?: string | null;
      issueStatus?: string | null;
      issuePriority?: string | null;
      isDueDatePresent?: null | boolean;
      issueDueDate?: string | null;
      inactiveIssues?: string | null;
      backtrackIssues?: null | boolean;
      reopenedIssues?: null | boolean;
      isPriority?: null | boolean;
      isDelayed?: null | boolean;
      isLongCycleTime?: null | boolean;
      cycleTimeSpread?: string | null;
      issueAge?: string | null;
      changingRequirements?: null | boolean;
      stage?: string | null;
      assignees?: null | string[];
      issueIds?: null | string[];
    };
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueTableDetail(
        $metric: String!
        $granularity: String!
        $filter: Filter!
        $pageNumber: Int
        $pageSize: Int
      ) {
        IssueTableDetail(
          metric: $metric
          granularity: $granularity
          filter: $filter
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            issueReportDetails {
              workspace
              sprints
              investmentCat
              summary
              id
              issueType
              storyPoints
              childIssues
              linkedPRs
              externalUrl
            }
            assigneeDetails {
              current
              rest
              comments
            }
            priorityDetails {
              priority
              dueDate
            }
            statusDetails {
              status
              inStatusTime
              isBackward
            }
            createdAt
            lastActivity
            cycleTimeDetails {
              cycleTime
              color
            }
            issueStatusTimeDistribution {
              color
              status
              value
            }
            issueLeadCycleTimeDetails {
              color
              cycleTime
            }
          }
          chartError {
            message
            link
          }
          chartDataState
          totalPages
          totalCount
          currentPage
        }
      }
    `,
    {
      metric,
      granularity: '',
      filter: {
        orgId: `${localStorage.getItem('orgId')}`,
        startDate: startDate,
        endDate: endDate,
        ...filter,
      },
      pageNumber,
      pageSize,
    }
  );

export const changeFailureGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetChangeFailureDetail(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $granularity: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $timeZone: String
      ) {
        ChangeFailureDetail(
          metric: $metricType
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const DeploymentDetails = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    sprintId?: string;
    projectsFilter?: string[];
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetDeploymentDetails(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $granularity: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
        $timeZone: String
      ) {
        DeploymentDetails(
          metric: $metricType
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const WellbeingDetails = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: { teamId?: string; author?: string; sprintId?: string },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query WellBeingDetailsByType(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        WellBeingDetailsByType(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            name
            email
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const QualityDetails = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    sprintId?: string;
    projectsFilter?: string[];
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query QualityBranchDetailByType(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
        $timeZone: String
      ) {
        QualityBranchDetailByType(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            analysisId
            project
            branch
            date
            value
            expectedValue
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const VulnerabilityAlertsDetails = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query VulnerabilityAlertsDetail(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $timeZone: String
      ) {
        VulnerabilityAlertsDetail(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            summary
            repo
            package_name
            status
            severity
            affected_version
            patched_version
            updated_at
            link
          }
          totalPages
          currentPage
          totalCount
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const GoalMetricDetailsGit = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: { teamId?: string; author?: string; sprintId?: string },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetGoalMetricDetailsGit(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        GoalMetricDetailsGit(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
          isMeet: false
        ) {
          data {
            id
            link
            author
            summary
            repository
            changes
            issueId
            date
            attributes {
              key
              value
            }
            title
            externalLink
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const GoalMetricDetailsJira = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: { teamId?: string; author?: string; sprintId?: string },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetGoalMetricDetailsJira(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        GoalMetricDetailsJira(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
          isMeet: false
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const getContributorGraphql = async (
  startDate: string,
  endDate: string,
  filter: {
    teamId?: string;
    author?: string;
    projectsFilter?: string[];
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetContributorDetails(
        $orgId: String!
        $startDate: String!
        $endDate: String!
        $teamId: String!
        $sprintId: String
        $projectsFilter: [String]
        $pageNumber: Int
        $pageSize: Int
      ) {
        ContributorDetailsV2(
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            teamId: $teamId
            projectsFilter: $projectsFilter
            sprintId: $sprintId
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            name
            email
          }
          totalPages
          totalCount
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      ...filter,
      pageNumber,
      pageSize,
    }
  );
// author
export const workingAgreementGraphql = async (
  startDate: string,
  endDate: string,
  filter: { teamId?: string; author?: string; sprintId?: string }
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTeamStatsWorkingAgreementDetails(
        $orgId: String
        $startDate: String!
        $endDate: String
        $author: String!
        $sprintId: String
      ) {
        TeamStatsWorkingAgreementDetails(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            author: $author
            sprintId: $sprintId
          }
        ) {
          data {
            id
            assignee
            updatedDate
            link
            ticketType
            summary
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      ...filter,
    }
  );
// author
export const commitGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    author?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetCommitReportDetail(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metric: String!
        $author: String
        $teamId: String!
        $sprintId: String
        $granularity: String!
        $pageNumber: Int!
        $pageSize: Int!
        $timeZone: String
      ) {
        CommitReportDetail(
          metric: $metric
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            author: $author
            sprintId: $sprintId
            project: ""
            teamId: $teamId
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            link
            author
            summary
            repository
            date
            attributes {
              key
              value
            }
            title
            externalLink
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metric,
      ...filter,
      granularity: '',
      pageNumber,
      pageSize,
    }
  );

// TeamId
export const memberCountGraphql = async (filter: {
  teamId?: string;
  author?: string;
  sprintId?: string;
}): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTeamMembers(
        $orgId: String
        $teamId: String!
        $sprintId: String
      ) {
        TeamMembers(
          filter: { orgId: $orgId, teamId: $teamId, sprintId: $sprintId }
        ) {
          data {
            name
            role
            email
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      ...filter,
    }
  );

export const customMetricsGraphql = async (
  startDate: string,
  endDate: string,
  customFieldName: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query getCustomMetricDetailJira(
        $orgId: String
        $startDate: String!
        $endDate: String
        $customFieldName: String!
        $granularity: String!
        $teamId: String
        $author: String
        $sprintId: String
        $type: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        CustomMetricDetailJira(
          customFieldName: $customFieldName
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            type: $type
            project: ""
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      customFieldName,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const customBuildMetricGraphql = async (
  startDate: string,
  endDate: string,
  customFieldName: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetBuildDetailCustom(
        $orgId: String
        $startDate: String!
        $endDate: String
        $customFieldName: String!
        $granularity: String!
        $teamId: String
        $sprintId: String
        $author: String
        $type: String
        $status: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
        $timeZone: String
      ) {
        BuildDetailCustom(
          customFieldName: $customFieldName
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            sprintId: $sprintId
            author: $author
            status: $status
            type: $type
            projectsFilter: $projectsFilter
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            type
            status
            environment
            commit
            repo
            externalUrl
            updatedAt
            project
            buildTime
            pipelineName
            artifact
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      customFieldName,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );
export const customBuildStageMetricGraphql = async (
  startDate: string,
  endDate: string,
  customFieldName: string,
  filter: {
    teamId?: string;
    project?: string;
    searchTerm?: string;
    reviewer?: string;
    author?: string;
    sprintId?: string;
    sprintIds?: string[];
    authors?: string[];
    type?: string;
    status?: string;
    label?: string;
    branches?: string[];
    projectsFilter?: string[];
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetBuildStageDetailCustom(
        $orgId: String
        $startDate: String!
        $endDate: String
        $customFieldName: String!
        $granularity: String!
        $teamId: String
        $sprintId: String
        $author: String
        $type: String
        $status: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
        $timeZone: String
      ) {
        BuildStageDetailCustom(
          customFieldName: $customFieldName
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            sprintId: $sprintId
            author: $author
            status: $status
            type: $type
            projectsFilter: $projectsFilter
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            buildId
            name
            type
            status
            startedAt
            completedAt
            stageDuration
          }
          totalPages
          currentPage
          totalCount
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      customFieldName,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );
export const customIssueMetricGraphql = async (
  startDate: string,
  endDate: string,
  customFieldName: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueDetailCustom(
        $orgId: String
        $startDate: String!
        $endDate: String
        $customFieldName: String!
        $granularity: String!
        $teamId: String
        $sprintId: String
        $author: String
        $type: String
        $status: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
        $timeZone: String
      ) {
        IssueDetailCustom(
          customFieldName: $customFieldName
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            sprintId: $sprintId
            author: $author
            status: $status
            type: $type
            projectsFilter: $projectsFilter
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      customFieldName,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const issueAgeingDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    age?: string;
    type?: string;
    sprintId?: string;
    projectsFilter?: string[];
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetIssueDetailsForAge(
        $orgId: String
        $startDate: String
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $type: String
        $pageNumber: Int!
        $pageSize: Int!
        $age: String!
        $metricType: String!
        $projectsFilter: [String]
        $timeZone: String
      ) {
        IssueDetailsForAge(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            type: $type
            projectsFilter: $projectsFilter
            timeZone: $timeZone
          }
          age: $age
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            priority
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const requirementStabilityDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetRequirementStabilityDetails(
        $orgId: String
        $startDate: String
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $type: String
        $pageNumber: Int!
        $pageSize: Int!
        $timeZone: String
      ) {
        RequirementStabilityDetails(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            type: $type
            project: ""
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            subTitle
            priority
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metricType,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const PrAgeingDetailsGraphql = async (
  startDate: string,
  endDate: string,
  filter: {
    teamId?: string;
    author?: string;
    age?: string;
    projectsFilter?: string[];
    sprintId?: string;
  }
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPrAgeingDetails(
        $orgId: String
        $startDate: String
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $age: String!
        $projectsFilter: [String]
        $timeZone: String
      ) {
        PrAgeingDetails(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
            timeZone: $timeZone
          }
          age: $age
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      granularity: '',
      ...filter,
    }
  );

export const PRAgeingDetailsGraphql = async (
  startDate: string,
  endDate: string,
  filter: {
    teamId?: string;
    author?: string;
    reviewer?: string;
    sprintId?: string;
    projectsFilter?: string[];
    type?: string;
    status?: string;
    prViewFilters?: {
      prReviewResponsivenessType?: string | null;
      prFeedbackResponsivenessType?: string | null;
      prSize?: string;
      age?: string;
      prIds?: string[] | null;
      searchQuery?: string | null;
      isPrLinked?: boolean | null;
      isPrWithComment?: boolean | null;
      isPrReviewedWithComment?: boolean | null;
      isLongPrCycleTime?: boolean | null;
      isMergedWithReview?: boolean | null;
      prIterations?: string | null;
    };
    metricSpecificFilter?: {
      age?: string | null;
    };
    orderBy?: string;
    orderColumn?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> => {
  return await graphQLClient.request(
    gql`
      query GetPRAgeingDetails(
        $filter: Filter!
        $age: String!
        $pageNumber: Int!
        $pageSize: Int!
        $orderBy: String
        $orderColumn: String
      ) {
        PRAgeingDetails(
          filter: $filter
          orderBy: $orderBy
          orderColumn: $orderColumn
          age: $age
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            prDetail {
              id
              externalUrl
              title
              repo
              issueId
              addition
              deletion
              sourceBranch
              targetBranch
              issueType
              issueUrl
              prNumber
              batchSize
            }
            teams
            author {
              avatar
              name
              commitCount
            }
            createdAt
            mergedAt
            reviewersDetails {
              contributors {
                avatar
                name
                commitCount
              }
              approvedBy {
                avatar
                name
                commitCount
              }
              comments
            }
            prCycleTime {
              color
              time
            }
            breakdowns {
              title
              icon
              value
            }
          }
          totalPages
          currentPage
          totalCount
        }
      }
    `,
    {
      filter: {
        startDate,
        endDate,
        orgId: `${localStorage.getItem('orgId')}`,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        teamId: filter?.teamId,
        author: filter?.author,
        sprintId: filter?.sprintId,
        project: '',
        projectsFilter: filter?.projectsFilter,
        type: filter?.type,
        status: filter?.status,
        reviewer: filter?.reviewer,
        prViewFilters: filter?.prViewFilters,
      },
      orderBy: filter?.orderBy,
      orderColumn: filter?.orderColumn,
      age: filter?.metricSpecificFilter?.age,
      pageNumber,
      pageSize,
    }
  );
};

export const prReviewResponsivenessDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    name?: string;
    projectsFilter?: string[];
    sprintId?: string;
    timeZone?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPrReviewResponsivenessDetails(
        $orgId: String
        $startDate: String
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $granularity: String!
        $name: String!
        $projectsFilter: [String]
        $timeZone: String
      ) {
        PrReviewResponsivenessDetails(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
          name: $name
          granularity: $granularity
        ) {
          data {
            title
            subTitle
            attributes {
              key
              value
            }
            id
            link
            author
            date
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      granularity: '',
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const PRReviewResponsivenessDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    reviewer?: string;
    sprintId?: string;
    projectsFilter?: string[];
    type?: string;
    status?: string;
    prViewFilters?: {
      prReviewResponsivenessType?: string | null;
      prFeedbackResponsivenessType?: string | null;
      prSize?: string;
      age?: string;
      prIds?: string[] | null;
      searchQuery?: string | null;
      isPrLinked?: boolean | null;
      isPrWithComment?: boolean | null;
      isPrReviewedWithComment?: boolean | null;
      isLongPrCycleTime?: boolean | null;
      isMergedWithReview?: boolean | null;
      prIterations?: string | null;
    };
    metricSpecificFilter?: {
      name?: string | null;
    };
    orderBy?: string;
    orderColumn?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> => {
  return await graphQLClient.request(
    gql`
      query GetPRReviewResponsivenessDetails(
        $filter: Filter!
        $pageNumber: Int!
        $pageSize: Int!
        $name: String!
        $granularity: String!
      ) {
        PRReviewResponsivenessDetails(
          filter: $filter
          pageNumber: $pageNumber
          pageSize: $pageSize
          name: $name
          granularity: $granularity
        ) {
          data {
            status
            prDetail {
              id
              externalUrl
              repo
              title
              issueId
              addition
              deletion
              sourceBranch
              targetBranch
              issueType
              issueUrl
              prNumber
              batchSize
            }
            teams
            author {
              avatar
              name
              commitCount
            }
            createdAt
            mergedAt
            reviewersDetails {
              contributors {
                avatar
                name
                commitCount
              }
              approvedBy {
                avatar
                name
                commitCount
              }
              comments
              comments
            }
            prCycleTime {
              color
              time
            }
            breakdowns {
              title
              icon
              value
            }
          }
          totalPages
          currentPage
          totalCount
        }
      }
    `,
    {
      filter: {
        startDate,
        endDate,
        orgId: localStorage.getItem('orgId'),
        teamId: filter?.teamId,
        author: filter?.author,
        sprintId: filter?.sprintId,
        project: '',
        projectsFilter: filter?.projectsFilter,
        reviewer: filter?.reviewer,
        type: filter?.type,
        status: filter?.status,
        prViewFilters: filter?.prViewFilters,
      },
      name: filter?.metricSpecificFilter?.name,
      granularity: '',
      pageNumber,
      pageSize,
    }
  );
};

export const prHealthIndexGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    projectsFilter?: string[];
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetPrHealthIndexesDetails(
        $orgId: String
        $startDate: String!
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $granularity: String!
        $pageNumber: Int!
        $pageSize: Int!
        $type: String!
        $projectsFilter: [String]
        $timeZone: String
      ) {
        PrHealthIndexesDetails(
          metricKey: $type
          granularity: $granularity
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            link
            author
            summary
            repository
            changes
            issueId
            date
            attributes {
              key
              value
            }
            title
            externalLink
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      ...filter,
      granularity: '',
      pageNumber,
      pageSize,
    }
  );

export const PRHealthIndexGraphql = async (
  startDate: string,
  endDate: string,
  type: string,
  filter: {
    teamId?: string;
    author?: string;
    reviewer?: string;
    sprintId?: string;
    projectsFilter?: string[];
    type?: string;
    status?: string;
    prViewFilters?: {
      prReviewResponsivenessType?: string | null;
      prFeedbackResponsivenessType?: string | null;
      prSize?: string;
      age?: string;
      prIds?: string[] | null;
      searchQuery?: string | null;
      isPrLinked?: boolean | null;
      isPrWithComment?: boolean | null;
      isPrReviewedWithComment?: boolean | null;
      isLongPrCycleTime?: boolean | null;
      isMergedWithReview?: boolean | null;
      prIterations?: string | null;
    };
    metricSpecificFilter?: {
      metricKey?: string | null;
    };
    orderBy?: string;
    orderColumn?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> => {
  return await graphQLClient.request(
    gql`
      query GetPRHealthIndexesDetails(
        $metricKey: String!
        $granularity: String!
        $filter: Filter!
        $orderBy: String
        $orderColumn: String
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        PRHealthIndexesDetails(
          metricKey: $metricKey
          granularity: $granularity
          filter: $filter
          orderBy: $orderBy
          orderColumn: $orderColumn
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            status
            prDetail {
              id
              externalUrl
              repo
              title
              issueId
              addition
              deletion
              sourceBranch
              targetBranch
              issueType
              issueUrl
              prNumber
              batchSize
            }
            teams
            author {
              avatar
              name
              commitCount
            }
            createdAt
            mergedAt
            reviewersDetails {
              contributors {
                avatar
                name
                commitCount
              }
              approvedBy {
                avatar
                name
                commitCount
              }
              comments
            }
            prCycleTime {
              color
              time
            }
            breakdowns {
              title
              icon
              value
            }
          }
          totalPages
          currentPage
          totalCount
        }
      }
    `,
    {
      metricKey: filter?.metricSpecificFilter?.metricKey,
      granularity: '',
      filter: {
        startDate,
        endDate,
        orgId: localStorage.getItem('orgId'),
        teamId: filter?.teamId,
        author: filter?.author,
        sprintId: filter?.sprintId,
        project: '',
        projectsFilter: filter?.projectsFilter,
        type: filter?.type,
        status: filter?.status,
        reviewer: filter?.reviewer,
        prViewFilters: filter?.prViewFilters,
      },
      orderBy: filter?.orderBy,
      orderColumn: filter?.orderColumn,
      pageNumber,
      pageSize,
    }
  );
};

export const locVsPrCycleTimeDetailsGraphql = async (
  startDate: string,
  endDate: string,
  filter: {
    teamId?: string;
    sprintId?: string;
    lowerBoundIncl?: number;
    upperBound?: number;
  }
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetLocVsPrCycleTimeDetails(
        $orgId: String
        $startDate: String!
        $endDate: String
        $teamId: String
        $sprintId: String
        $lowerBoundIncl: Int!
        $upperBound: Int
        $timeZone: String
      ) {
        LocVsPrCycleTimeDetails(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            sprintId: $sprintId
            timeZone: $timeZone
          }
          lowerBoundIncl: $lowerBoundIncl
          upperBound: $upperBound
        ) {
          data {
            id
            link
            author
            summary
            repository
            createdAt
            updatedAt
            mergedAt
            status
            commentCount
            changes
            addition
            deletions
            files
            issueId
            prCycleTime
            codingTime
            pickupTime
            mergeTime
            reviewers
            title
            subTitle
            date
            attributes {
              key
              value
            }
            externalLink
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      ...filter,
    }
  );

export const locVsPRCycleTimeDetailsGraphql = async (
  startDate: string,
  endDate: string,
  filter: {
    teamId?: string;
    author?: string;
    reviewer?: string;
    sprintId?: string;
    projectsFilter?: string[];
    type?: string;
    status?: string;
    prViewFilters?: {
      prReviewResponsivenessType?: string | null;
      prFeedbackResponsivenessType?: string | null;
      prSize?: string;
      age?: string;
      prIds?: string[] | null;
      searchQuery?: string | null;
      isPrLinked?: boolean | null;
      isPrWithComment?: boolean | null;
      isPrReviewedWithComment?: boolean | null;
      isLongPrCycleTime?: boolean | null;
      isMergedWithReview?: boolean | null;
      prIterations?: string | null;
    };
    metricSpecificFilter?: {
      lowerBoundIncl?: number | null;
      upperBound?: number | null;
    };
    orderBy?: string;
    orderColumn?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> => {
  return await graphQLClient.request(
    gql`
      query GetLocVsPRCycleTimeDetails(
        $filter: Filter!
        $orderBy: String
        $orderColumn: String
        $lowerBoundIncl: Int!
        $upperBound: Int
        $pageNumber: Int!
        $pageSize: Int!
      ) {
        LocVsPRCycleTimeDetails(
          filter: $filter
          orderBy: $orderBy
          orderColumn: $orderColumn
          lowerBoundIncl: $lowerBoundIncl
          upperBound: $upperBound
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            prDetail {
              id
              externalUrl
              repo
              title
              issueId
              addition
              deletion
              sourceBranch
              targetBranch
              issueType
              issueUrl
              prNumber
              batchSize
            }
            teams
            author {
              avatar
              name
              commitCount
            }
            createdAt
            mergedAt
            reviewersDetails {
              contributors {
                avatar
                name
                commitCount
              }
              approvedBy {
                avatar
                name
                commitCount
              }
              comments
            }
            prCycleTime {
              color
              time
            }
            breakdowns {
              title
              icon
              value
            }
          }
          totalPages
          currentPage
          totalCount
        }
      }
    `,
    {
      filter: {
        startDate,
        endDate,
        orgId: localStorage.getItem('orgId'),
        teamId: filter?.teamId,
        sprintId: filter?.sprintId,
        type: filter?.type,
        status: filter?.status,
        reviewer: filter?.reviewer,
        author: filter?.author,
        projectsFilter: filter?.projectsFilter,
        prViewFilters: filter?.prViewFilters,
      },
      lowerBoundIncl: filter.metricSpecificFilter?.lowerBoundIncl,
      upperBound: filter.metricSpecificFilter?.upperBound,
      orderBy: filter?.orderBy,
      orderColumn: filter?.orderColumn,
      pageNumber,
      pageSize,
    }
  );
};

export const averageActiveDaysDetailGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    name?: string;
    projectsFilter?: string[];
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetAverageActiveDaysDetail(
        $orgId: String
        $startDate: String
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $projectsFilter: [String]
      ) {
        AverageActiveDaysDetail(
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
          }
        ) {
          email
          activeDays
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      startDate,
      endDate,
      ...filter,
    }
  );

export const commitReportDetailWithBreakdownGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    projectsFilter?: string[];
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetCommitReportDetailWithBreakdown(
        $orgId: String
        $startDate: String!
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $projectsFilter: [String]
        $metric: String!
        $type: String
        $timeZone: String
      ) {
        CommitReportDetailWithBreakdown(
          metric: $metric
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            projectsFilter: $projectsFilter
            type: $type
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            commitId
            link
            summary
            author
            repository
            createdAt
            title
            subTitle
            date
            externalLink
            attributes {
              key
              value
            }
            rework
            helped
            refactor
            newwork
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      ...filter,
      pageNumber,
      pageSize,
      metric,
    }
  );

export const meetingDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    author?: string;
    type?: string;
    projectsFilter?: string[];
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetMeetingDetails(
        $orgId: String
        $startDate: String!
        $endDate: String
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $metric: String!
        $type: String
        $reviewer: String
        $status: String
        $label: String
        $branches: String
        $searchTerm: String
        $authors: [String]
        $sprintIds: [String]
        $timeZone: String
      ) {
        MeetingDetails(
          metric: $metric
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            sprintIds: $sprintIds
            authors: $authors
            project: ""
            type: $type
            reviewer: $reviewer
            status: $status
            label: $label
            branches: $branches
            searchTerm: $searchTerm
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            id
            startTime
            endTime
            duration
            organizer
            attendees
            calendarOwner
          }
          totalPages
          currentPage
          totalCount
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      ...filter,
      pageNumber,
      pageSize,
      metric,
      reviewer: '',
      status: '',
      label: '',
      branches: '',
      searchTerm: '',
      authors: [],
      sprintIds: [],
    }
  );

export const issueReportDetailForRecruitCRMGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    author?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query IssueReportDetailForRecruitCRM(
        $metric: String!
        $filter: Filter!
        $pageNumber: Int
        $pageSize: Int
      ) {
        IssueReportDetailForRecruitCRM(
          metric: $metric
          filter: $filter
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            summary
            assignee
            author
            ticketType
            ticketUrl
            link
            updatedDate
            key
            status
            investment
            project
            estimate
            storyPoint
            customFieldValue
            issueStatusData {
              label
              color
              value
              actualVal
              avgValue
            }
            sprintTaskDistList {
              type
              tasks {
                name
                value
              }
            }
          }
          totalPages
          currentPage
        }
      }
    `,
    {
      metric,
      filter: {
        orgId: localStorage.getItem('orgId'),
        startDate,
        endDate,
        ...filter,
      },
      pageNumber,
      pageSize,
    }
  );

export const issueReportDetailForRecruitCRMPriorityDistributedDataGraphql =
  async (
    startDate: string,
    endDate: string,
    metric: string,
    filter: {
      author?: string;
    },
    pageNumber: number,
    pageSize: number
  ): Promise<any> =>
    await graphQLClient.request(
      gql`
        query IssueReportDetailForRecruitCRMPriorityDistributedData(
          $metric: String!
          $filter: Filter!
          $pageNumber: Int
          $pageSize: Int
        ) {
          IssueReportDetailForRecruitCRMPriorityDistributedData(
            metric: $metric
            filter: $filter
            pageNumber: $pageNumber
            pageSize: $pageSize
          ) {
            data {
              id
              summary
              assignee
              author
              ticketType
              ticketUrl
              link
              updatedDate
              key
              status
              investment
              project
              estimate
              storyPoint
              customFieldValue
              issueStatusData {
                label
                color
                value
                actualVal
                avgValue
              }
              sprintTaskDistList {
                type
                tasks {
                  name
                  value
                }
              }
            }
            totalPages
            currentPage
          }
        }
      `,
      {
        metric,
        filter: {
          orgId: localStorage.getItem('orgId'),
          startDate,
          endDate,
          ...filter,
        },
        pageNumber,
        pageSize,
      }
    );

export const documentDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    teamId?: string;
    author?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetDocumentDetails(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metricType: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $timeZone: String
      ) {
        DocumentDetails(
          metric: $metricType
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            title
            assignee
            eventType
            spaceName
            externalUrl
            updated_at
          }
          totalPages
          currentPage
          totalCount
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metricType,
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const sprintDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  filter: {
    teamId?: string;
    author?: string;
    sprintId?: string;
  },
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetSprintActivityByOrg(
        $orgId: String
        $startDate: String!
        $endDate: String
        $metric: String!
        $teamId: String
        $author: String
        $sprintId: String
        $pageNumber: Int!
        $pageSize: Int!
        $timeZone: String
      ) {
        SprintActivityByOrg(
          metric: $metric
          filter: {
            startDate: $startDate
            endDate: $endDate
            orgId: $orgId
            teamId: $teamId
            author: $author
            sprintId: $sprintId
            project: ""
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            sprintName
            sprintId
            project
            startDate
            endDate
            commitedTasks
            completedTasks
            unplannedTasks
            commitedSP
            completedSP
            sprintScopeDetails {
              sprintId
              plannedTasks
              addedTasks
              removedTasks
            }
          }
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metric,
      ...filter,
      pageNumber,
      pageSize,
    }
  );

export const testRunDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTestRunMetricDetailResponse(
        $orgId: String
        $startDate: String!
        $endDate: String!
        $metric: String!
        $pageNumber: Int
        $pageSize: Int
        $timeZone: String
      ) {
        TestRunMetricDetailResponse(
          metric: $metric
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            id
            projectId
            name
            type
            status
            repo
            total_tests
            externalUrl
            passed_tests
            failed_tests
            skipped_tests
            started_at
            completed_At
            metricValue
          }
          totalPages
          currentPage
          totalCount
          error
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metric,
      pageNumber,
      pageSize,
    }
  );

export const testCountDetailsGraphql = async (
  startDate: string,
  endDate: string,
  metric: string,
  pageNumber: number,
  pageSize: number
): Promise<any> =>
  await graphQLClient.request(
    gql`
      query GetTestDetailsResponse(
        $orgId: String
        $startDate: String!
        $endDate: String!
        $metric: String!
        $pageNumber: Int
        $pageSize: Int
        $timeZone: String
      ) {
        TestDetailsResponse(
          metric: $metric
          filter: {
            orgId: $orgId
            startDate: $startDate
            endDate: $endDate
            timeZone: $timeZone
          }
          pageNumber: $pageNumber
          pageSize: $pageSize
        ) {
          data {
            projectId
            id
            name
            testRunId
            type
            status
            started_at
            completed_At
          }
          totalPages
          currentPage
          totalCount
          error
        }
      }
    `,
    {
      orgId: localStorage.getItem('orgId'),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate,
      endDate,
      metric,
      pageNumber,
      pageSize,
    }
  );
