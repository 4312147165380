import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  doraThresholdsGql,
  getContributorDetailGraphql,
  getContributorGraphql,
  getHomePageMetricByTypeGraphql,
  getInsightCardDataGraphql,
  getSummaryByTypeGraphql,
  getWorkingAgreementDetails,
  gitThresholdsGql,
  issueThresholdsGql,
  qualityThresholdsGql,
  sprintMetricGql,
  signalsGraphql,
  metricsOverviewGraphql,
  customMetricGraphql,
  cicdPipelineGraphql,
  getCopilotAcceptanceByLanguage,
  getCopilotActiveUserCount,
  getLinesAccepted,
  getChatAcceptance,
  testSuiteGraphql,
  getContributorInfiniteGraphql,
} from './query';

import { getTeamListGraphql } from '@devd-client/devd/team-details';

export const QUERY_KEYS = {
  summaryGql: 'summaryGql',
  insightCardData: 'insightCardData',
  insightMetric: 'insightMetric',
  contributorDetail: 'contributorDetail',
  contributorGql: 'contributorGql',
  allIssueThreshold: 'allIssueThreshold',
  allGitThreshold: 'allGitThreshold',
  allDoraThreshold: 'allDoraThreshold',
  signals: 'signals',
  metricsOverview: 'metricsOverview',
  allQualityThresholds: 'allQualityThresholds',
  customMetric: 'customMetric',
  cicd: 'cicd',
  copilotAcceptance: 'copilotAcceptance',
  copilotActiveUsers: 'copilotActiveUsers',
  copilotLinesAccepted: 'copilotLinesAccepted',
  copilotChatAcceptance: 'copilotChatAcceptance',
  testSuite: 'testSuite',
};

export const useSummaryGql = (
  type: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
) => {
  return useQuery(
    [QUERY_KEYS.summaryGql, type, teamId, startDate, endDate, sprintId],
    () => getSummaryByTypeGraphql(type, teamId, startDate, endDate, sprintId),
    {
      select: (data) => data?.HomePageSummaryDetailByType,
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useInsightCardDataGql = (
  type: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
) => {
  return useQuery(
    [QUERY_KEYS.insightCardData, type, teamId, startDate, endDate, sprintId],
    () => getInsightCardDataGraphql(type, teamId, startDate, endDate, sprintId),
    {
      select: (data) => data?.HomePageInsightCardData,
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useInsightMetricGql = (
  type: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
) => {
  const queryClient = useQueryClient();
  return useQuery(
    [QUERY_KEYS.insightMetric, type, teamId, startDate, endDate, sprintId],
    () =>
      getHomePageMetricByTypeGraphql(
        type,
        teamId,
        startDate,
        endDate,
        sprintId
      ),
    {
      select: (data) => {
        return {
          ...data.HomePageMetricByType.chart,
          data: JSON.parse(data?.HomePageMetricByType?.chart?.data),
          insights: data.HomePageMetricByType.insights,
          link: data.HomePageMetricByType.link,
        };
      },

      onSuccess: () => {
        queryClient.prefetchQuery(
          ['teamList', '', startDate, endDate, sprintId, teamId, '', 0, 10],
          () =>
            getTeamListGraphql(
              '',
              startDate,
              endDate,
              sprintId,
              teamId,
              0,
              10,
              ''
            )
        );
      },

      enabled: !!startDate && !!endDate,
    }
  );
};

export const useBurnoutContributorDetail = (
  type: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
) => {
  return useQuery(
    [QUERY_KEYS.contributorDetail, type, teamId, startDate, endDate, sprintId],
    () =>
      getContributorDetailGraphql(type, teamId, startDate, endDate, sprintId),
    {
      select: (data) =>
        JSON.parse(data?.HomePageMetricByTypeDetails?.chart?.data),
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useContributorDetailGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  role: string | null
) => {
  return useQuery(
    [QUERY_KEYS.contributorGql, startDate, endDate, sprintId, teamId],
    () => getContributorGraphql(startDate, endDate, sprintId, teamId),
    {
      select: (data) => data?.ContributorDetails,
      enabled: !!startDate && !!endDate && role !== 'EMPLOYEE',
    }
  );
};

export const useContributorDetailInfinteGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  role: string | null,
  pageSize: number
) => {
  const {
    data,
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<any>(
    [QUERY_KEYS.contributorGql, startDate, endDate, sprintId, teamId],
    async ({ pageParam = 0 }) => {
      const data = await getContributorInfiniteGraphql(
        startDate,
        endDate,
        sprintId,
        teamId,
        pageParam,
        pageSize
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.ContributorDetailsV2?.data?.length < pageSize) {
          return undefined;
        } else {
          return lastPage.ContributorDetailsV2?.currentPage + 1;
        }
      },
      select: (data) => {
        return {
          ...data,
          data: data.pages.flatMap((page) => page.ContributorDetailsV2.data),
          totalDBRowCount: data.pages[0]?.ContributorDetailsV2.totalCount || 0,
        };
      },
      enabled: !!startDate && !!endDate && role !== 'EMPLOYEE',
    }
  );

  return {
    data,
    isFetching,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};

export const useWorkingAgreementDetailGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number
) => {
  return useQuery(
    [
      QUERY_KEYS.contributorGql,
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
    ],
    () =>
      getWorkingAgreementDetails(
        startDate,
        endDate,
        sprintId,
        teamId,
        pageNumber,
        pageSize
      ),
    {
      select: (data) => data?.WorkingAgreementDetails,
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useTicketCards = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  repos: string[],
  ticketType: string,
  showForecast: boolean
) => {
  return useQuery(
    [
      QUERY_KEYS.allIssueThreshold,
      startDate,
      endDate,
      sprintId,
      teamId,
      repos,
      ticketType,
      showForecast,
    ],
    () =>
      issueThresholdsGql(
        startDate,
        endDate,
        sprintId,
        teamId,
        repos,
        ticketType,
        showForecast
      ),
    {
      select: (data) => data?.AllIssueThresholds,
      enabled: !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );
};

export const useQualityCards = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  repos: string[]
) => {
  return useQuery(
    [
      QUERY_KEYS.allQualityThresholds,
      startDate,
      endDate,
      sprintId,
      teamId,
      repos,
    ],
    () => qualityThresholdsGql(startDate, endDate, sprintId, teamId, repos),
    {
      select: (data) => data?.AllQualityThresholds,
      enabled: !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );
};

export const useGitCards = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  repos: string[]
) => {
  return useQuery(
    [QUERY_KEYS.allGitThreshold, startDate, endDate, sprintId, teamId, repos],
    () => gitThresholdsGql(startDate, endDate, sprintId, teamId, repos),
    {
      select: (data) => data?.AllGitThresholds,
      enabled: !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );
};

export const useDoraCards = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string
) => {
  return useQuery(
    [QUERY_KEYS.allDoraThreshold, startDate, endDate, sprintId, teamId],
    () => doraThresholdsGql(startDate, endDate, sprintId, teamId),
    {
      select: (data) => data?.AllDoraThresholds,
      enabled: !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );
};

export const useSprintList = (
  startDate: string,
  endDate: string,
  teamId: string,
  repos: string[],
  sprintId: string,
  sprintIds: string[],
  pageSize: number,
  pageNumber: number,
  bySp: boolean,
  status: string,
  type: string
) => {
  return useQuery(
    [
      QUERY_KEYS.allDoraThreshold,
      startDate,
      endDate,
      pageSize,
      pageNumber,
      teamId,
      repos,
      sprintId,
      sprintIds,
      bySp,
      status,
      type,
    ],
    () =>
      sprintMetricGql(
        startDate,
        endDate,
        teamId,
        repos,
        sprintId,
        sprintIds,
        pageSize,
        pageNumber,
        bySp,
        status,
        type
      ),
    {
      select: (data) => {
        return {
          ...data?.SprintMetricsWithStatus,
          reports: data?.SprintMetricsWithStatus?.reports?.map((el: any) => ({
            ...el,
            sprintVelocity: {
              chart: JSON.parse(el.sprintVelocity.chart),
            },
          })),
        };
      },
      enabled: !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );
};

export const useSignalsGql = (
  teamId: string,
  startDate: string,
  endDate: string
) => {
  return useQuery(
    [QUERY_KEYS.signals, teamId, startDate, endDate],
    () => signalsGraphql(teamId, startDate, endDate),
    {
      select: (data) => data?.TopSignals,
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useMetricsOverviewGql = (
  teamId: string,
  startDate: string,
  endDate: string,
  category: string
) => {
  return useQuery(
    [QUERY_KEYS.metricsOverview, teamId, startDate, endDate, category],
    () => metricsOverviewGraphql(teamId, startDate, endDate, category),
    {
      select: (data) => data?.MetricsOverview,
      enabled: !!startDate && !!endDate && !!category,
    }
  );
};

export const useCustomMetrics = (
  startDate: string,
  endDate: string,
  metricType: string,
  breakdown: string,
  selectedTeam: string,
  sprintId: string,
  granularity: string
) => {
  return useQuery(
    [
      QUERY_KEYS.customMetric,
      startDate,
      endDate,
      metricType,
      breakdown,
      selectedTeam,
      sprintId,
      granularity,
    ],
    () =>
      customMetricGraphql(
        startDate,
        endDate,
        metricType,
        selectedTeam,
        sprintId,
        granularity
      ),
    {
      select: (data) => ({
        ...data.CustomMetrics,
        data: JSON.parse(data.CustomMetrics.data),
      }),
      enabled: !!metricType && !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );
};

export const useCicdPipelineGql = (
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  pageNumber: number,
  pageSize: number,
  repos: string[],
  type: string
) => {
  return useQuery(
    [
      QUERY_KEYS.cicd,
      teamId,
      startDate,
      endDate,
      sprintId,
      pageNumber,
      pageSize,
      repos,
      type,
    ],
    () =>
      cicdPipelineGraphql(
        teamId,
        startDate,
        endDate,
        sprintId,
        pageNumber,
        pageSize,
        repos,
        type
      ),
    {
      select: (data) => data?.PipelineReport,
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useCopilotAcceptanceByLanguage = (
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  branches: string
) => {
  return useQuery(
    [
      QUERY_KEYS.copilotAcceptance,
      teamId,
      startDate,
      endDate,
      sprintId,
      branches,
    ],
    () =>
      getCopilotAcceptanceByLanguage(
        teamId,
        startDate,
        endDate,
        sprintId,
        branches
      ),
    {
      select: (data) => ({
        ...data,
        data: JSON.parse(data?.CopilotAcceptanceByLanguage?.data),
        chartMetadata: data?.CopilotAcceptanceByLanguage?.chartMetadata,
        keys: data?.CopilotAcceptanceByLanguage?.keys,
        chartError: data?.CopilotAcceptanceByLanguage?.chartError,
        chartDataState: data?.CopilotAcceptanceByLanguage?.chartDataState,
      }),
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useCopilotActiveUserCount = (
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  granularity: string,
  breakdown: string,
  branches: string
) => {
  return useQuery(
    [
      QUERY_KEYS.copilotActiveUsers,
      teamId,
      startDate,
      endDate,
      sprintId,
      granularity,
      breakdown,
      branches,
    ],
    () =>
      getCopilotActiveUserCount(
        teamId,
        startDate,
        endDate,
        sprintId,
        granularity,
        breakdown,
        branches
      ),
    {
      select: (data) => ({
        ...data,
        data: JSON.parse(data?.CopilotActiveUserCount?.data),
        chartMetadata: data?.CopilotActiveUserCount?.chartMetadata,
        keys: data?.CopilotActiveUserCount?.keys,
        chartError: data?.CopilotActiveUserCount?.chartError,
        chartDataState: data?.CopilotActiveUserCount?.chartDataState,
      }),
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useCopilotLinesAccepted = (
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  granularity: string,
  breakdown: string,
  branches: string
) => {
  return useQuery(
    [
      QUERY_KEYS.copilotLinesAccepted,
      teamId,
      startDate,
      endDate,
      sprintId,
      granularity,
      breakdown,
      branches,
    ],
    () =>
      getLinesAccepted(
        teamId,
        startDate,
        endDate,
        sprintId,
        granularity,
        breakdown,
        branches
      ),
    {
      select: (data) => ({
        ...data,
        data: JSON.parse(data?.CopilotLineAccepted?.data),
        chartMetadata: data?.CopilotLineAccepted?.chartMetadata,
        keys: data?.CopilotLineAccepted?.keys,
        chartError: data?.CopilotLineAccepted?.chartError,
        chartDataState: data?.CopilotLineAccepted?.chartDataState,
      }),
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useCopilotChatAcceptance = (
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  granularity: string,
  breakdown: string,
  branches: string
) => {
  return useQuery(
    [
      QUERY_KEYS.copilotChatAcceptance,
      teamId,
      startDate,
      endDate,
      sprintId,
      granularity,
      breakdown,
      branches,
    ],
    () =>
      getChatAcceptance(
        teamId,
        startDate,
        endDate,
        sprintId,
        granularity,
        breakdown,
        branches
      ),
    {
      select: (data) => ({
        ...data,
        data: JSON.parse(data?.CopilotChatAcceptance?.data),
        chartMetadata: data?.CopilotChatAcceptance?.chartMetadata,
        keys: data?.CopilotChatAcceptance?.keys,
        chartError: data?.CopilotChatAcceptance?.chartError,
        chartDataState: data?.CopilotChatAcceptance?.chartDataState,
      }),
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useTestSuiteGql = (
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  pageNumber: number,
  pageSize: number,
  repos: string
) => {
  return useQuery(
    [
      QUERY_KEYS.testSuite,
      teamId,
      startDate,
      endDate,
      sprintId,
      pageNumber,
      pageSize,
      repos,
    ],
    () =>
      testSuiteGraphql(
        teamId,
        startDate,
        endDate,
        sprintId,
        pageNumber,
        pageSize,
        repos
      ),
    {
      select: (data) => data?.TestReport,
      enabled: !!startDate && !!endDate,
    }
  );
};
