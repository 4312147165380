import { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Card,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  HStack,
  useToast,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { getActiveOrganizations, getSupportLoginDetails } from '@devd-client/devd/auth';
import { useRef } from 'react';



interface Organization {
  organizationName: string;
  organizationId: string;
  subscriptionPlan: string;
  subscriptionStartDate: string;
  subscriptionEndDate: string;
}

interface LoginDetails {
    [key: string]: string;
  }

export function ActiveOrganizations() {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<string>('');
  const [loginDetails, setLoginDetails] = useState<LoginDetails | null>(null);
  const { isOpen: isWarningOpen, onOpen: onWarningOpen, onClose: onWarningClose } = useDisclosure();
  const { isOpen: isDetailsOpen, onOpen: onDetailsOpen, onClose: onDetailsClose } = useDisclosure();
  const cancelRef = useRef(null);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);


  useEffect(() => {
    const fetchOrganizations = async () => {
        setIsLoading(true);
      try {
        const data = await getActiveOrganizations();
        setOrganizations(data);
      } catch (error) {
        console.error('Error fetching organizations:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganizations();
  }, []);

  const handleShowDetails = (orgId: string) => {
    setSelectedOrgId(orgId);
    onWarningOpen();
  };

  const handleAccept = async () => {
    onWarningClose();
    setIsLoadingDetails(true);
    try {
      const data = await getSupportLoginDetails(selectedOrgId);
      setLoginDetails(data);
      onDetailsOpen();
    } catch (error) {
      toast({
        title: 'Error retrieving login details',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
        setIsLoadingDetails(false);
      }
  };

  return (
    <Card p={4}>
       {isLoading ? (
        <Center p={8}>
          <Spinner size="xl" />
        </Center>
      ) :( <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Organization Name</Th>
              <Th>Organization ID</Th>
              <Th>Subscription Plan</Th>
              <Th>Start Date</Th>
              <Th>End Date</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {organizations.map((org) => (
              <Tr key={org.organizationId}>
                <Td>{org.organizationName}</Td>
                <Td>{org.organizationId}</Td>
                <Td>{org.subscriptionPlan}</Td>
                <Td>{new Date(org.subscriptionStartDate).toLocaleDateString()}</Td>
                <Td>{new Date(org.subscriptionEndDate).toLocaleDateString()}</Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    onClick={() => handleShowDetails(org.organizationId)}
                  >
                    Show Account Detail
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    )}

      {/* Warning Dialog */}
      <AlertDialog
        isOpen={isWarningOpen}
        leastDestructiveRef={cancelRef}
        onClose={onWarningClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Warning
            </AlertDialogHeader>

            <AlertDialogBody>
              Retrieving Sensitive info, your detail will be audited
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onWarningClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" onClick={handleAccept} ml={3}>
                Accept
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Details Modal */}
      <Modal isOpen={isDetailsOpen} onClose={onDetailsClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Account Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
          {isLoadingDetails ? (
              <Center p={4}>
                <Spinner />
              </Center>
            ) : (
              loginDetails && (
                <VStack align="stretch" spacing={4}>
                {Object.entries(loginDetails).map(([email, password]) => (
                  <VStack key={email} align="stretch" spacing={2}>
                    <HStack justify="space-between">
                      <Text fontWeight="bold">Email:</Text>
                      <Text>{email}</Text>
                    </HStack>
                    <HStack justify="space-between">
                      <Text fontWeight="bold">Password:</Text>
                      <Text>{password}</Text>
                    </HStack>
                  </VStack>
                ))}
              </VStack>
              )
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

    </Card>
  );
}