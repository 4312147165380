import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Input,
  Text,
  Spinner,
  useOutsideClick,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useGetMembers } from '../../../apis';
import { useDebounce } from '@devd-client/devd/components';
import { useInView } from 'react-intersection-observer';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface DeveloperSelectProps {
  developers: any[];
  setDevelopers: (developers: any[]) => void;
  placeholder?: string;
}

export const DeveloperSelect = ({
  developers,
  setDevelopers,
  placeholder = 'Add developers...',
}: DeveloperSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(searchQuery, 500);
  const ref = useRef<HTMLDivElement>(null);
  const [pageNo, setPageNo] = useState(0);
  const { ref: bottomRef, inView } = useInView({
    threshold: 0.1,
  });
  const [allMembers, setAllMembers] = useState<any[]>([]);

  useOutsideClick({
    ref: ref,
    handler: () => {
      setIsOpen(false);
      setSearchQuery('');
    },
  });

  const { data: membersData, isLoading } = useGetMembers({
    pageNo,
    pageSize: 20,
    searchTerm: debouncedSearch,
  });

  useEffect(() => {
    if (debouncedSearch !== searchQuery) {
      setPageNo(0);
      setAllMembers([]);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (membersData?.dto) {
      if (pageNo === 0) {
        setAllMembers(membersData.dto);
      } else {
        setAllMembers((prev) => [...prev, ...membersData.dto]);
      }
    }
  }, [membersData?.dto, pageNo]);

  useEffect(() => {
    if (inView && !isLoading && membersData?.dto?.length === 20) {
      setPageNo((prev) => prev + 1);
    }
  }, [inView, isLoading, membersData?.dto?.length]);

  const selectedEmails = useMemo(
    () => developers?.map((dev) => dev?.name),
    [developers]
  );

  const filteredMembers = useMemo(() => {
    return allMembers?.filter(
      (member: any) => !selectedEmails?.includes(member?.ofcEmail)
    );
  }, [allMembers, selectedEmails]);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    setPageNo(0);
    setAllMembers([]);
  };

  const handleSelectDeveloper = (member: any) => {
    const newDeveloper = {
      name: member.ofcEmail,
      attention: '-',
      nonReleaseWork: '-',
      id: member.ofcEmail,
      isActive: true,
    };
    setDevelopers([...developers, newDeveloper]);
    setSearchQuery('');
    setIsOpen(false);
  };

  return (
    <Box position="relative" ref={ref} ml={'auto'} w="240px">
      <InputGroup>
        <Input
          placeholder={placeholder}
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          onClick={() => setIsOpen(true)}
          size="md"
          border="1px solid"
          borderColor="text.tertiary"
          _focus={{ borderColor: 'primary' }}
          pr="8px"
        />
        <InputRightElement>
          <ChevronDownIcon
            color="text.secondary2"
            transform={isOpen ? 'rotate(180deg)' : undefined}
            transition="transform 0.2s"
            cursor="pointer"
            onClick={() => setIsOpen(!isOpen)}
          />
        </InputRightElement>
      </InputGroup>

      {isOpen && (
        <Box
          position="absolute"
          top="100%"
          left={0}
          right={0}
          mt={2}
          maxH="200px"
          overflowY="auto"
          backgroundColor="white"
          borderRadius="4px"
          boxShadow="md"
          zIndex={1000}
          border="1px solid"
          borderColor="text.tertiary"
        >
          {isLoading && pageNo === 0 ? (
            <Flex justify="center" my={4}>
              <Spinner size="sm" />
            </Flex>
          ) : filteredMembers?.length === 0 ? (
            <Text
              my={4}
              color="text.secondary2"
              fontSize={'sm'}
              textAlign="center"
            >
              No results found
            </Text>
          ) : (
            <>
              {filteredMembers?.map((member: any) => (
                <Box
                  key={member.id}
                  p={2}
                  cursor="pointer"
                  _hover={{ backgroundColor: 'text.tertiary' }}
                  onClick={() => handleSelectDeveloper(member)}
                  borderBottom="1px solid"
                  borderColor="text.tertiary"
                >
                  <Text fontSize="xs" color="text.secondary2">
                    {member.ofcEmail}
                  </Text>
                </Box>
              ))}
              {isLoading && pageNo > 0 && (
                <Flex justify="center" my={4}>
                  <Spinner size="sm" />
                </Flex>
              )}
              {filteredMembers?.length >= 20 && (
                <Box ref={bottomRef} h="20px" visibility="hidden" />
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
