import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

import { SizeProp } from 'chakra-react-select/dist/types/types';
import { useEffect, useState } from 'react';
import { useDebounce } from '../hooks/useDebounce';
import { SearchIcon } from '../icons';
import { eliminateHtmlTags } from '../shared/utils';

type Props = {
  searchValue: string;
  setSearchValue: (value: string) => void;
  placeholder?: string;
  size?: SizeProp;
};

export function SearchInputBar({
  searchValue,
  setSearchValue,
  placeholder,
  size,
}: Props) {
  const [searchTerm, setSearchTerm] = useState<string>(searchValue);
  const debouncedSearchTerm = useDebounce(searchTerm);

  useEffect(() => {
    setSearchValue(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <Box w={200}>
      <Flex justify={'center'} align={'center'}>
        <InputGroup size={size || 'sm'}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon height={'14px'} width="14px" />
          </InputLeftElement>
          <Input
            id="input"
            name="input"
            size={size || 'sm'}
            placeholder={placeholder || ''}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(eliminateHtmlTags(e.target.value));
            }}
          />
        </InputGroup>
      </Flex>
    </Box>
  );
}

export default SearchInputBar;
