import { useQuery } from '@tanstack/react-query';
import {
  getInitiativeChangeLogGraphql,
  getInitiativeContributorsGraphql,
  getInitiativeDetailGraphql,
  getInitiativesGraphql,
  getDeliverableSignalsGraphql,
  getInitiativeSprintGraphql,
  getDeliverableTableGraphql,
  getInitiativeTaskGraphql,
  getInitiativeTeamsGraphql,
  getPeopleEffortHoursOverviewGraphql,
  getSprintDeliveryOverviewGraphql,
  getSprintMetricsWithStatus,
  deliverableIssueTypeDistGraphql,
  initiativeProgressGraphql,
  initiativeTaskDistGraphql,
  teamAllocationGraphql,
  getContributorsAllocationGraphql,
  getTeamsAllocationGraphql,
  getDeliverableScenarioGraphql,
} from './query';

export const QUERY_KEYS = {
  initiativeGql: 'initiativeGql',
  initiativeDetailGql: 'initiativeDetailGql',
  initiativeTaskGql: 'initiativeTaskGql',
  initiativeChangeLog: 'initiativeChangeLog',
  initiativeContributos: 'initiativeContributos',
  initiativeSprint: 'initiativeSprint',
  initiativeteams: 'initiativeteams',
  teamAllocationDetail: 'teamAllocationDetail',
  initiativeProgress: 'initiativeProgress',
  initiativeStatus: 'initiativeStatus',
  peopleEffortHoursOverview: 'peopleEffortHoursOverview',
  deliverableIssueTypeDist: 'deliverableIssueTypeDist',
  deliverableTable: 'deliverableTable',
  sprintDeliveryOverview: 'sprintDeliveryOverview',
  deliverableSignals: 'deliverableSignals',
  sprintMetricsWithStatus: 'sprintMetricsWithStatus',
  contributorsAllocation: 'contributorsAllocation',
  teamsAllocation: 'teamsAllocation',
  deliverableScenario: 'deliverableScenario',
};

export const useChangeLogGql = (initiative: string) => {
  return useQuery(
    [QUERY_KEYS.initiativeChangeLog, initiative],
    () => getInitiativeChangeLogGraphql(initiative),
    {
      select: (data) => data?.TicketUpdateLog,
      enabled: !!initiative,
    }
  );
};

export const useSprintGql = (initiative: string) => {
  return useQuery(
    [QUERY_KEYS.initiativeSprint, initiative],
    () => getInitiativeSprintGraphql(initiative),
    {
      select: (data) => data?.SprintsForInitiative,
      enabled: !!initiative,
    }
  );
};

export const useTeamsGql = (initiative: string) => {
  return useQuery(
    [QUERY_KEYS.initiativeteams, initiative],
    () => getInitiativeTeamsGraphql(initiative),
    {
      select: (data) => data?.TeamsForInitiative,
      enabled: !!initiative,
    }
  );
};

export const useTeamAllocationDetailGql = (initiative: any, team: any) => {
  return useQuery(
    [QUERY_KEYS.teamAllocationDetail, initiative, team],
    () => teamAllocationGraphql(initiative, team),
    {
      select: (data) => ({
        ...data,
      }),
      enabled: !!initiative,
    }
  );
};

export const useInitiativeProgressGql = (initiativeId: any) => {
  return useQuery(
    [QUERY_KEYS.initiativeProgress, initiativeId],
    () => initiativeProgressGraphql(initiativeId),
    {
      select: (data) => ({
        ...data.InitiativeTaskProgress,
      }),
      enabled: !!initiativeId,
    }
  );
};

export const useInitiativeTaskDistGql = (initiativeId: any) => {
  return useQuery(
    [QUERY_KEYS.initiativeStatus, initiativeId],
    () => initiativeTaskDistGraphql(initiativeId),
    {
      select: (data) => ({
        ...data.InitiativeTaskStatusDistribution,
      }),
      enabled: !!initiativeId,
    }
  );
};

export const usePeopleEffortHoursOverviewGql = (initiativeId: any) => {
  return useQuery(
    [QUERY_KEYS.peopleEffortHoursOverview, initiativeId],
    () => getPeopleEffortHoursOverviewGraphql(initiativeId),
    {
      select: (data) => ({
        ...data.PeopleEffortHoursOverview,
      }),
      enabled: !!initiativeId,
    }
  );
};

export const useDeliverableIssueTypeDistGql = (
  initiativeId: any,
  distributionType: any,
  deliverableType: string,
  status: string
) => {
  return useQuery(
    [
      QUERY_KEYS.deliverableIssueTypeDist,
      initiativeId,
      distributionType,
      deliverableType,
      status,
    ],
    () =>
      deliverableIssueTypeDistGraphql(
        initiativeId,
        distributionType,
        deliverableType,
        status
      ),
    {
      select: (data) => ({
        ...data.IssueTypeDistribution,
      }),
      enabled: !!initiativeId && !!distributionType,
    }
  );
};

export const useDeliverableTableGql = (
  deliverableType: string,
  status: string,
  search: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number
) => {
  return useQuery(
    [
      QUERY_KEYS.deliverableTable,
      deliverableType,
      status,
      search,
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
    ],
    () =>
      getDeliverableTableGraphql(
        deliverableType,
        status,
        search,
        startDate,
        endDate,
        sprintId,
        teamId,
        pageNumber,
        pageSize
      ),
    {
      select: (data) => ({
        ...data.Deliverables,
      }),
      keepPreviousData: true,
      enabled: !!deliverableType,
    }
  );
};

export const useSprintDeliveryOverviewGql = (
  initiativeId: string,
  deliverableType: string,
  status: string
) => {
  return useQuery(
    [QUERY_KEYS.sprintDeliveryOverview, initiativeId, deliverableType, status],
    () =>
      getSprintDeliveryOverviewGraphql(initiativeId, deliverableType, status),
    {
      select: (data) => ({
        ...data,
        data: JSON.parse(data?.SprintDeliveryOverview?.data),
        keys: data?.SprintDeliveryOverview?.keys,
      }),
      enabled: !!initiativeId && !!deliverableType,
    }
  );
};

export const useDeliverableSignalsGql = (
  initiativeId: any,
  deliverableType: string,
  status: string
) => {
  return useQuery(
    [QUERY_KEYS.deliverableSignals, initiativeId, deliverableType, status],
    () => getDeliverableSignalsGraphql(initiativeId, deliverableType, status),
    {
      select: (data) => data?.DeliverableSignals,
      enabled: !!initiativeId && !!deliverableType,
    }
  );
};

export const useSprintMetricsWithStatus = (
  filter: {
    initiativeId: string;
    teamId: string;
    sprintIds: string[];
    startDate: string;
    endDate: string;
    status: string;
    type: string;
    projectsFilter: string[];
    sprintViewFilters: {
      isCompletionRate: boolean;
      isPlanningAccuracy: boolean;
    };
    deliverableType: string | null;
  },
  pageSize: number,
  pageNumber: number,
  bySp: boolean
) => {
  return useQuery(
    [
      QUERY_KEYS.sprintMetricsWithStatus,
      filter.startDate,
      filter.endDate,
      pageSize,
      pageNumber,
      filter.teamId,
      filter.projectsFilter,
      filter.sprintIds,
      bySp,
      filter.status,
      filter.type,
      filter.initiativeId,
      filter.sprintViewFilters,
      filter.deliverableType,
    ],
    () => getSprintMetricsWithStatus(filter, pageSize, pageNumber, bySp),
    {
      select: (data) => {
        return {
          ...data?.SprintMetricsWithStatus,
          reports: data?.SprintMetricsWithStatus?.reports?.map((el: any) => ({
            ...el,
            sprintVelocity: {
              chart: JSON.parse(el.sprintVelocity.chart),
            },
          })),
        };
      },
      enabled: !!filter.initiativeId && !!filter.deliverableType,
      keepPreviousData: true,
    }
  );
};

export const useContributorsAllocation = (
  initiativeId: string,
  deliverableType: string,
  status: string,
  view: string
) => {
  return useQuery(
    [
      QUERY_KEYS.contributorsAllocation,
      initiativeId,
      deliverableType,
      status,
      view,
    ],
    () =>
      getContributorsAllocationGraphql(
        initiativeId,
        deliverableType,
        status,
        view
      ),
    {
      select: (data) => data?.ContributorsAllocation,
      enabled: !!initiativeId && !!deliverableType,
    }
  );
};

export const useTeamsAllocation = (
  initiativeId: string,
  deliverableType: string,
  status: string
) => {
  return useQuery(
    [QUERY_KEYS.teamsAllocation, initiativeId, deliverableType, status],
    () => getTeamsAllocationGraphql(initiativeId, deliverableType, status),
    {
      select: (data) => data?.TeamsAllocation,
      enabled: !!initiativeId && !!deliverableType,
    }
  );
};

export const useDeliverableScenarioGql = (
  deliverableId: string,
  deliverableType: string,
  averageAttention: number | null,
  remainingScope: number | null,
  developerCount: number | null
) => {
  return useQuery(
    [
      QUERY_KEYS.deliverableScenario,
      deliverableId,
      deliverableType,
      averageAttention,
      remainingScope,
      developerCount,
    ],
    () =>
      getDeliverableScenarioGraphql(
        deliverableId,
        deliverableType,
        averageAttention,
        remainingScope,
        developerCount
      ),
    {
      select: (data) => ({
        ...data?.DeliverableScenarioDto,
        data: JSON.parse(data?.DeliverableScenarioDto?.data),
        keys: data?.DeliverableScenarioDto?.keys,
        referenceLineData: JSON.parse(
          data?.DeliverableScenarioDto?.referenceLineData
        ),
      }),
      enabled: !!deliverableId && !!deliverableType,
      keepPreviousData: true,
    }
  );
};
