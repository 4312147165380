import { Button, Flex, Text } from '@chakra-ui/react';
import { TooltipQuestionIcon } from '@devd-client/devd/components';
import { AddPhase } from './AddPhase';

interface PhasesProps {
  initiativeId: string;
  isEdit?: boolean;
  onNext: () => void;
  onPrevious: () => void;
}

const Phases = ({ initiativeId, isEdit, onNext, onPrevious }: PhasesProps) => {
  return (
    <Flex flexDirection={'column'} gap={4}>
      {/* <Flex my={2} gap={2} alignItems={'center'}>
        <Button
          padding={'12px 16px'}
          border={'1px solid'}
          borderColor={'text.secondary'}
          rounded={'md'}
          bg={'white'}
          color={'text.secondary'}
          fontSize={'sm'}
          fontWeight={'medium'}
        >
          Configure Initiative Status
        </Button>
        <TooltipQuestionIcon />
      </Flex> */}
      <Flex flexDirection={'column'} gap={1}>
        <Text fontWeight={'semibold'} color={'text.gray.300'}>
          Add Phases
        </Text>
        <Text fontSize={'sm'} color={'text.secondary2'}>
          Define the key phases or milestones for your initiative
        </Text>
      </Flex>
      <AddPhase
        initiativeId={initiativeId}
        isEdit={isEdit}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </Flex>
  );
};

export default Phases;
