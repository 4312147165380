export function SettingsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="settings-02">
        <g id="Icon">
          <path
            d="M6.26369 12.9141L6.65332 13.7904C6.76914 14.0513 6.95817 14.2729 7.19747 14.4284C7.43677 14.584 7.71606 14.6668 8.00147 14.6667C8.28687 14.6668 8.56616 14.584 8.80546 14.4284C9.04476 14.2729 9.23379 14.0513 9.34961 13.7904L9.73924 12.9141C9.87794 12.6032 10.1112 12.3439 10.4059 12.1734C10.7024 12.0023 11.0455 11.9295 11.3859 11.9652L12.3392 12.0667C12.623 12.0967 12.9094 12.0438 13.1637 11.9143C13.418 11.7848 13.6292 11.5843 13.7718 11.3371C13.9146 11.0901 13.9826 10.8069 13.9677 10.522C13.9527 10.237 13.8553 9.96258 13.6874 9.73189L13.1229 8.95634C12.922 8.67813 12.8146 8.34325 12.8163 8.00004C12.8162 7.65777 12.9246 7.32427 13.1259 7.04745L13.6904 6.27189C13.8583 6.04121 13.9556 5.76675 13.9706 5.48182C13.9856 5.19689 13.9176 4.91373 13.7748 4.66671C13.6322 4.41953 13.4209 4.21903 13.1667 4.08953C12.9124 3.96002 12.626 3.90706 12.3422 3.93708L11.3889 4.03856C11.0484 4.07431 10.7054 4.00145 10.4089 3.83041C10.1136 3.65888 9.88025 3.39828 9.74221 3.08597L9.34961 2.20967C9.23379 1.94882 9.04476 1.72718 8.80546 1.57164C8.56616 1.4161 8.28687 1.33333 8.00147 1.33337C7.71606 1.33333 7.43677 1.4161 7.19747 1.57164C6.95817 1.72718 6.76914 1.94882 6.65332 2.20967L6.26369 3.08597C6.12564 3.39828 5.89227 3.65888 5.59702 3.83041C5.3005 4.00145 4.95747 4.07431 4.61702 4.03856L3.66072 3.93708C3.37694 3.90706 3.09055 3.96002 2.83627 4.08953C2.58198 4.21903 2.37073 4.41953 2.22813 4.66671C2.08535 4.91373 2.01732 5.19689 2.03231 5.48182C2.0473 5.76675 2.14466 6.04121 2.31258 6.27189L2.87702 7.04745C3.07831 7.32427 3.18671 7.65777 3.18665 8.00004C3.18671 8.34232 3.07831 8.67581 2.87702 8.95263L2.31258 9.72819C2.14466 9.95887 2.0473 10.2333 2.03231 10.5183C2.01732 10.8032 2.08535 11.0863 2.22813 11.3334C2.37088 11.5804 2.58215 11.7808 2.8364 11.9103C3.09064 12.0398 3.37697 12.0928 3.66072 12.063L4.61406 11.9615C4.9545 11.9258 5.29753 11.9986 5.59406 12.1697C5.89041 12.3407 6.12486 12.6014 6.26369 12.9141Z"
            stroke={props.color || '#2A2A2F'}
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00027 10C9.10484 10 10.0003 9.10461 10.0003 8.00004C10.0003 6.89547 9.10484 6.00004 8.00027 6.00004C6.8957 6.00004 6.00027 6.89547 6.00027 8.00004C6.00027 9.10461 6.8957 10 8.00027 10Z"
            stroke={props.color || '#2A2A2F'}
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
