export function DocIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 13L7.4333 12.8999C6.9702 12.2053 6.73866 11.858 6.43274 11.6065C6.16191 11.3839 5.84985 11.2169 5.51441 11.1151C5.1355 11 4.71807 11 3.88322 11H2.96667C2.21993 11 1.84656 11 1.56135 10.8547C1.31047 10.7268 1.10649 10.5229 0.978661 10.272C0.833336 9.98677 0.833336 9.6134 0.833336 8.86667V3.13333C0.833336 2.3866 0.833336 2.01323 0.978661 1.72801C1.10649 1.47713 1.31047 1.27316 1.56135 1.14532C1.84656 1 2.21993 1 2.96667 1H3.23334C4.72681 1 5.47355 1 6.04398 1.29065C6.54574 1.54631 6.95369 1.95426 7.20935 2.45603C7.5 3.02646 7.5 3.77319 7.5 5.26667M7.5 13V5.26667M7.5 13L7.56671 12.8999C8.0298 12.2053 8.26135 11.858 8.56727 11.6065C8.8381 11.3839 9.15016 11.2169 9.4856 11.1151C9.8645 11 10.2819 11 11.1168 11H12.0333C12.7801 11 13.1534 11 13.4387 10.8547C13.6895 10.7268 13.8935 10.5229 14.0213 10.272C14.1667 9.98677 14.1667 9.6134 14.1667 8.86667V3.13333C14.1667 2.3866 14.1667 2.01323 14.0213 1.72801C13.8935 1.47713 13.6895 1.27316 13.4387 1.14532C13.1534 1 12.7801 1 12.0333 1H11.7667C10.2732 1 9.52646 1 8.95603 1.29065C8.45426 1.54631 8.04631 1.95426 7.79065 2.45603C7.5 3.02646 7.5 3.77319 7.5 5.26667"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
