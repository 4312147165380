import { Box, Button, Text, Tooltip, useTheme } from '@chakra-ui/react';
import { useState } from 'react';
import { truncateText } from '../../../shared/utils';

export const Attendees = ({ item }: { item: any }) => {
  const [showMore, setShowMore] = useState(false);
  const { colors } = useTheme();
  return (
    <Box>
      {!showMore &&
        item?.attendees?.slice(0, 4).map((attendee: string) => (
          <Tooltip label={attendee.length > 30 ? attendee : ''} hasArrow>
            <Text
              ml={1}
              fontFamily="heading"
              fontSize="sm"
              fontWeight="medium"
              color="text.secondary"
              wordBreak={'break-all'}
              key={attendee}
            >
              {truncateText(attendee, 30)}
            </Text>
          </Tooltip>
        ))}
      {showMore &&
        item?.attendees?.map((attendee: string) => (
          <Tooltip label={attendee.length > 30 ? attendee : ''} hasArrow>
            <Text
              ml={1}
              fontFamily="heading"
              fontSize="sm"
              fontWeight="medium"
              color="text.secondary"
              wordBreak={'break-all'}
              key={attendee}
            >
              {truncateText(attendee, 30)}
            </Text>
          </Tooltip>
        ))}
      {(item?.attendees?.length === 0 || !item?.attendees) && (
        <Text
          ml={1}
          fontFamily="heading"
          fontSize="sm"
          fontWeight="medium"
          color="text.secondary"
          wordBreak={'break-all'}
        >
          No Attendees
        </Text>
      )}
      {item?.attendees?.length > 4 && (
        <Button
          onClick={() => setShowMore(!showMore)}
          mt={1}
          size={'sm'}
          variant={'link'}
          color={colors.primary}
          ml={1}
        >
          {showMore ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </Box>
  );
};
