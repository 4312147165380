import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';
import { Box, Flex, Text } from '@chakra-ui/react';

interface TreeMapChartProps {
  data: any;
}

export const TreeMapChart = ({ data }: TreeMapChartProps) => {
  if (!data) {
    return (
      <Box
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="text.secondary2">No data available</Text>
      </Box>
    );
  }

  const transformedData = Object?.values(data)?.map((item: any) => ({
    name: item?.key,
    size: parseFloat(item?.value),
    fill: item?.color,
  }));

  const CustomizedContent = (props: any) => {
    if (!props) return null;
    const { x, y, width, height, name, value } = props;
    if (x == null || y == null || width == null || height == null) return null;
    const currentItem = transformedData?.find((item) => item.name === name);
    if (!currentItem) return null;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: currentItem?.fill,
            stroke: 'white',
            strokeWidth: 2,
          }}
        />
        {width > 30 && height > 30 && (
          <>
            <text
              x={x + 8}
              y={y + height - (value > 5 ? 24 : 20)}
              textAnchor="start"
              style={{
                fill: '#fff',
                stroke: '#fff',
                strokeWidth: 0.5,
                fontSize: value > 5 ? '10px' : '8px',
                fontWeight: 400,
                fontFamily: 'Inter',
              }}
            >
              {name}
            </text>
            <text
              x={x + 8}
              y={y + height - 8}
              textAnchor="start"
              style={{
                fill: '#fff',
                stroke: '#fff',
                strokeWidth: 0.5,
                fontSize: value > 5 ? '12px' : '10px',
                fontWeight: 500,
                fontFamily: 'Inter',
              }}
            >
              {`${value || 0}%`}
            </text>
          </>
        )}
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box bg="white" p={2} boxShadow="md" borderRadius="md" border="none">
          <Flex gap={0.5} flexDirection="column" fontSize="sm">
            <Flex align="center" gap={2}>
              <Box
                w="8px"
                h="8px"
                borderRadius="full"
                bg={payload[0].payload.fill}
              />
              <Text color="text.secondary">
                {payload[0].payload.name}:{' '}
                <Text as="span" color="text.primary">
                  {payload[0].payload.size}%
                </Text>
              </Text>
            </Flex>
          </Flex>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box w="100%" h="100%">
      <ResponsiveContainer>
        <Treemap
          data={transformedData}
          dataKey="size"
          aspectRatio={4 / 3}
          stroke={'white'}
          content={<CustomizedContent />}
          isAnimationActive={false}
        >
          <Tooltip
            content={<CustomTooltip />}
            wrapperStyle={{ outline: 'none' }}
          />
        </Treemap>
      </ResponsiveContainer>
    </Box>
  );
};

export default TreeMapChart;
