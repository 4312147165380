import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
  AreaChart,
  Tooltip,
} from 'recharts';
import CustomTooltip from '../charts/components/customTooltip/CustomTooltip';

interface SprintPieChartProps {
  data: any;
  chart: string;
}

const SprintAreaChart: React.FC<SprintPieChartProps> = ({ data, chart }) => {
  return (
    <ResponsiveContainer height="100%" width="100%">
      <AreaChart
        data={data}
        margin={{ top: 10, right: 0, left: -45, bottom: 40 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#1AAEFF" stopOpacity={0.6} />
            <stop offset="95%" stopColor="#1AAEFF" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="date" tickLine={false} tick={false} axisLine={false} />
        <YAxis tickLine={false} tick={false} axisLine={false} />
        <Tooltip
          content={<CustomTooltip hideBtn />}
          wrapperStyle={{ outline: 'none' }}
        />
        <Area
          type="monotone"
          dataKey={chart}
          stroke="#1AAEFF"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
export default SprintAreaChart;
