import { Avatar, Box, Flex, Skeleton, Text, useTheme } from '@chakra-ui/react';
import { AppTooltip } from '@devd-client/devd/components';
import { NoOwnerIcon } from '@devd-client/devd/components';

type Props = {
  ownersList: string[];
  isLoading?: boolean;
};

export const InitiativeOwners = ({ ownersList, isLoading }: Props) => {
  const { colors } = useTheme();
  return (
    <Flex align="center">
      {isLoading ? (
        <>
          {[...Array(4)].map((_, index) => (
            <Skeleton
              key={index}
              w={'20px'}
              h={'20px'}
              rounded={'full'}
              ml={index === 0 ? 0 : -1.5}
              zIndex={index + 1}
            />
          ))}
        </>
      ) : ownersList?.length > 0 ? (
        <>
          {ownersList?.slice(0, 3)?.map((owner, index) => (
            <AppTooltip
              content={
                <Flex gap={1} alignItems={'center'}>
                  <Avatar name={owner} size={'xs'} />
                  <Text fontSize={'sm'} color={'text.gray.300'}>
                    {owner}
                  </Text>
                </Flex>
              }
              trigger="hover"
              key={index}
            >
              <Avatar
                name={owner}
                size={'xs'}
                ml={index === 0 ? 0 : -1.5}
                zIndex={index + 1}
              />
            </AppTooltip>
          ))}
          {ownersList?.length > 3 ? (
            <Box
              bg="#EDEDFF"
              rounded="full"
              w="22px"
              h="22px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              ml={-1.5}
              zIndex={100}
            >
              <AppTooltip
                content={
                  <Flex flexDirection={'column'} gap={1}>
                    {ownersList?.slice(3)?.map((owner, index) => (
                      <Flex gap={1} alignItems={'center'}>
                        <Avatar key={index} name={owner} size={'xs'} />
                        <Text fontSize={'sm'} color={'text.gray.300'}>
                          {owner}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                }
                trigger="hover"
              >
                <Text color="primary" fontWeight="semibold" fontSize={'11px'}>
                  +{ownersList?.length - 3}
                </Text>
              </AppTooltip>
            </Box>
          ) : null}
        </>
      ) : (
        <Flex align={'center'} gap={1}>
          <NoOwnerIcon height={14} width={14} color={colors.error} />
          <Text color="text.primary" fontWeight={'medium'} fontSize={'sm'}>
            No Owner Assigned
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
