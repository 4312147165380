import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import CustomTooltip from './components/customTooltip/CustomTooltip';
import './chart.css';
import { useCallback, useState } from 'react';
import { ISSUE_AGE_CHART_KEYS } from '../shared/utils';
import MetricSidebar from './components/MetricSidebar';
import moment from 'moment';

interface HrStackedBarChartRechartsProps {
  data: any;
  keys?: any;
  chartMetadata?: any;
  slant?: boolean;
  showLabel?: boolean;
  forDetails?: boolean;
  hideBtn?: boolean;
  selectedTeam: string;
  ticketType: any;
  queryKey?: string;
  selected: any;
  repos?: string[];
}

export const HrStackedBarChartRecharts = ({
  data,
  keys,
  chartMetadata,
  slant,
  showLabel,
  forDetails,
  hideBtn,
  selectedTeam,
  ticketType,
  queryKey,
  selected,
  repos,
}: HrStackedBarChartRechartsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [clickedData, setClickedData] = useState<any>(null);
  const [currentChartMeta, setCurrentChartMeta] = useState<any>(null);

  const params = new URLSearchParams(window.location.search);
  const team = params.get('team');

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  return (
    <>
      <ResponsiveContainer width="100%" height={'100%'}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{
            top: 10,
            right: 35,
            left: showLabel ? 10 : slant ? 108 : 10,
            bottom: forDetails ? 10 : -35,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis
            type="number"
            axisLine={false}
            tickLine={false}
            style={{ fontSize: 11, fontWeight: 500 }}
            label={{
              value: chartMetadata.xlabel,
              position: 'insideBottom',
              fontSize: 11,
              fontWeight: 500,
              dy: 10,
            }}
          />
          <YAxis
            dataKey="name"
            type="category"
            label={{
              value: chartMetadata.ylabel,
              angle: -90,
              position: 'insideLeft',
              fontSize: 11,
              fontWeight: 500,
              dx: -10,
            }}
            tickLine={false}
            style={{
              fontSize: 9,
              fontWeight: 600,
            }}
          />
          <Tooltip
            cursor={{ fill: 'transparent' }}
            content={<CustomTooltip hideBtn={hideBtn} />}
            wrapperStyle={{ outline: 'none' }}
          />
          <Legend
            verticalAlign="middle"
            layout="vertical"
            iconType="circle"
            iconSize={11}
            align="right"
            wrapperStyle={{
              right: -8,
              fontSize: 11.5,
              top: 0,
            }}
          />
          {keys?.map((key: any, index: any) => (
            <Bar
              key={index}
              dataKey={key.key}
              stackId="a"
              fill={key.color}
              barSize={20}
              style={{ cursor: 'pointer' }}
              onClick={(event: any, payload: any) => {
                handleModalOpen();
                setClickedData({ ...payload, name: event.name });
                setCurrentChartMeta(chartMetadata);
              }}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>

      {[...ISSUE_AGE_CHART_KEYS].includes(currentChartMeta?.chartKey) &&
        isOpen && (
          <MetricSidebar
            querykey={queryKey}
            openDrawer={isOpen}
            handleDrawerClose={handleModalClose}
            heading={chartMetadata.chartTitle}
            subHeading={`${moment(selected?.startDate).format(
              'DD MMM'
            )} - ${moment(selected?.endDate).format('DD MMM')}, ${
              clickedData?.name
            }`}
            payload={clickedData}
            currentChartMeta={currentChartMeta}
            filterBy={{
              teamId: selectedTeam,
              age:
                clickedData?.name === 'Age<1W'
                  ? 'week'
                  : clickedData?.name === 'Age<1M'
                  ? 'month'
                  : clickedData?.name === 'Age<3M'
                  ? 'threeMonth'
                  : '',
              type: ticketType,
              sprintId: selected.sprintId,
              projectsFilter: repos || [],
            }}
            selectedTeam={selectedTeam}
          />
        )}
    </>
  );
};
