import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import { AppTooltip } from '@devd-client/devd/components';
import { camelCaseToCapitalize } from '../../helpers/Initiatives.utils';

interface InitiativeStatusProps {
  color?: string;
  status?: string;
  progress?: string;
  label?: string;
  isLoading?: boolean;
}

export const InitiativeStatus = ({
  color,
  status,
  progress,
  label,
  isLoading,
}: InitiativeStatusProps) => {
  return (
    <>
      {isLoading ? (
        <Flex gap={2}>
          <Skeleton w={'120px'} h={'20px'} />
        </Flex>
      ) : (
        <Flex gap={2} align={'center'}>
          <AppTooltip
            content={
              <Text fontSize={'sm'} color={'text.gray.300'}>
                {camelCaseToCapitalize(label || '')}
              </Text>
            }
            trigger="hover"
          >
            <Box
              rounded={'2px'}
              border={'1px solid'}
              borderColor={color}
              padding={'2px 4px'}
              fontSize={'xs'}
              fontWeight={'medium'}
              color={color}
              backgroundColor={`${color}1A`}
            >
              {camelCaseToCapitalize(status || '')}
            </Box>
          </AppTooltip>
          {progress ? (
            <Text
              fontSize={'14px'}
              fontWeight={'semibold'}
              color={'text.gray.300'}
            >
              <Text as="span" color={'text.secondary2'}>
                Completion Rate :{' '}
              </Text>
              {Number(progress)?.toFixed(2)} %
            </Text>
          ) : null}
        </Flex>
      )}
    </>
  );
};
