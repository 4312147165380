export const WorkingAggIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M13.8141 13.8146C13.8141 14.095 13.7027 14.3639 13.5045 14.5621C13.3063 14.7603 13.0373 14.8718 12.757 14.8718H3.24269C2.96231 14.8718 2.69343 14.7603 2.49517 14.5621C2.29693 14.3639 2.18555 14.095 2.18555 13.8146V2.18605C2.18555 1.90567 2.29693 1.63679 2.49517 1.43854C2.69343 1.24028 2.96231 1.12891 3.24269 1.12891H9.14767C9.42803 1.12891 9.69693 1.24028 9.89517 1.43854L13.5045 5.04785C13.7027 5.2461 13.8141 5.51499 13.8141 5.79536V13.8146Z"
        stroke={props.color || 'black'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.58984 7.33984H11.2327"
        stroke={props.color || 'black'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.58984 11.2715H11.2327"
        stroke={props.color || 'black'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.50195 11.2103L5.38772 12.0961L6.864 10.0293"
        stroke={props.color || 'black'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.50195 7.21423L5.38772 8.1L6.864 6.0332"
        stroke={props.color || 'black'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
