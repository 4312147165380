export const DragIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="6" cy="1.99683" r="1.2" fill={props.fill || '#262628'} />
      <circle cx="6" cy="5.99683" r="1.2" fill={props.fill || '#262628'} />
      <circle cx="6" cy="9.99683" r="1.2" fill={props.fill || '#262628'} />
      <circle cx="6" cy="13.9968" r="1.2" fill={props.fill || '#262628'} />
      <circle cx="10" cy="1.99683" r="1.2" fill={props.fill || '#262628'} />
      <circle cx="10" cy="5.99683" r="1.2" fill={props.fill || '#262628'} />
      <circle cx="10" cy="9.99683" r="1.2" fill={props.fill || '#262628'} />
      <circle cx="10" cy="13.9968" r="1.2" fill={props.fill || '#262628'} />
    </svg>
  );
};

export default DragIcon;
