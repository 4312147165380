import { FC, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useContributorsAllocation } from '../../../graphql';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Text } from '@chakra-ui/react';
import { NewDataGrid } from '@devd-client/devd/components';

interface ContributorTableProps {
  initiativeId: string;
  type: string;
}

const ContributorTable: FC<ContributorTableProps> = ({
  initiativeId,
  type,
}) => {
  const { data: contributorsData, isFetching: contributorsLoading } =
    useContributorsAllocation(initiativeId, type, '', '');
  const rows = useMemo(() => contributorsData || [], [contributorsData]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('contributor', {
        cell: (info) => (
          <Text textDecoration={'underline'} maxW={'350px'} isTruncated>
            <Link to={`/contributors/${info.getValue()}`}>
              {info.getValue()}
            </Link>
          </Text>
        ),
        header: 'Contributor',
        enableSorting: false,
      }),
      columnHelper.accessor('calculatedAllocation', {
        cell: (info) =>
          info.getValue() !== null
            ? `${Number(info.getValue())?.toFixed(2)}%`
            : '-',
        header: 'Calculated Allocation',
        enableSorting: false,
      }),
      columnHelper.accessor('assignedAllocation', {
        cell: (info) =>
          info.getValue() !== null
            ? `${Number(info.getValue())?.toFixed(2)}%`
            : '-',
        header: 'Assigned Allocation',
        enableSorting: false,
      }),
      columnHelper.accessor('lastActivityDate', {
        cell: (info) => {
          const date = info.getValue();
          return date ? moment(date).format('MMM D, YYYY') : '-';
        },
        header: 'Last Activity Date',
        enableSorting: false,
      }),
    ],
    [contributorsData]
  );

  return (
    <NewDataGrid
      columns={columns}
      showLoader={contributorsLoading}
      data={rows}
      maxH="50vh"
      sticky="firstCol"
    />
  );
};

export default ContributorTable;
