import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useTheme,
} from '@chakra-ui/react';

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { SortableList } from './sortablednd';
import { countCategory, eliminateHtmlTags } from '../shared/utils';
import { ErasorIcon, SearchIcon } from '../icons';
import useContributorTableStore from '../hooks/useContributorTableStore';

interface metricListType {
  metricKey: string;
  metricName: string;
  tabs: string[] | null;
}
interface MetricState {
  metricTitle: string;
  metricKey: string;
  sequence: number;
}
export function ConfigureColumns({
  isOpen,
  onClose,
  onOpen,
  metricList,
  permanentColumns,
  defaultMetrics,
  selectedMetrics,
  setSelectedMetrics,
}: {
  metricList: metricListType[];
  permanentColumns?: string[];
  defaultMetrics: string[];
  isOpen: boolean;
  selectedMetrics: MetricState[];
  setSelectedMetrics: (selectedMetrics: MetricState[]) => void;
  onClose: () => void;
  onOpen: () => void;
}) {
  const { colors } = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [tabs, setTabs] = useState<string[]>([]);
  const [items, setItems] = useState<any[]>(
    selectedMetrics.map((selectedMetric) => selectedMetric.metricKey)
  );
  const handleRemoveMetric = useCallback((metric: any) => {
    setItems((prev) => prev.filter((item) => item !== metric));
  }, []);
  useEffect(() => {
    const allTags = metricList
      .flatMap((item: metricListType) => item.tabs || [])
      .filter(Boolean);
    const unique = [...new Set(allTags)] as string[];

    setTabs(['All', ...unique]);
  }, [metricList]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW={700} h={657} borderRadius={16} overflow={'hidden'}>
        <ModalHeader fontSize={'md'} px={4} pt={4} pb={0}>
          Configure columns
        </ModalHeader>
        <Divider w={'95%'} mx="auto" my={4} />
        <ModalCloseButton />
        <ModalBody
          mx={4}
          py={0}
          px={0}
          border={'1px solid #E4E4E4'}
          borderRadius={8}
          display={'grid'}
          gridTemplateColumns={'repeat(2, 1fr)'}
          bg={'#FBFBFB'}
          gap={1}
          overflow={'hidden'}
        >
          <Box py={5} pl={3} pr={1}>
            <Text fontSize={'sm'} fontWeight={'semibold'} mb={4}>
              Visible columns ({items.length + (permanentColumns?.length ?? 0)})
              - Drag to reorder
            </Text>

            <Stack spacing={'7px'} pr={3} overflow={'auto'} h={440} py={1}>
              <SortableList
                permanentColumns={permanentColumns}
                metricList={metricList}
                items={items}
                setItems={setItems}
                handleRemoveMetric={handleRemoveMetric}
              />
            </Stack>
          </Box>
          <Box bg={'white'} borderRadius={8} p={4} m={2} pt={3} pb={0}>
            <Box>
              <Text fontSize={'sm'} fontWeight={'semibold'}>
                Available Columns
              </Text>
              <Box mt={2} mb={3}>
                <Flex justify={'center'} align={'center'} position={'relative'}>
                  <Icon
                    position={'absolute'}
                    zIndex={10}
                    left={2}
                    bottom={'11px'}
                  >
                    <SearchIcon
                      height={20}
                      width={20}
                      color={colors.gray['300']}
                    />
                  </Icon>
                  <Input
                    id="searchValue"
                    name="searchValue"
                    borderRightRadius={0}
                    placeholder={'Search Metrics'}
                    pl={7}
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(eliminateHtmlTags(e.target.value));
                    }}
                  />
                </Flex>
              </Box>
            </Box>
            <Box>
              <Tabs
                index={tabIndex}
                onChange={(index) => setTabIndex(index)}
                w={'full'}
                variant="unstyled"
                size={'sm'}
                colorScheme="twitter"
              >
                <Text
                  color="text.secondary2"
                  fontSize={'sm'}
                  fontWeight={'semibold'}
                >
                  Categories
                </Text>
                <TabList
                  w={'full'}
                  display={'flex'}
                  flexWrap={'wrap'}
                  gap={2}
                  mt={2}
                  mb={4}
                >
                  {tabs.map((tab: string) => {
                    return (
                      <Tab
                        key={tab}
                        _selected={{
                          border: '1px solid',
                          borderColor: 'primary',
                          color: 'primary',
                        }}
                        py={1}
                        px={2}
                        borderRadius={2}
                        fontSize={'xs'}
                        fontWeight={'medium'}
                        border={'1px solid #E0E0E0'}
                        color={'text.secondary2'}
                        bg={'#FBFBFB'}
                      >
                        {tab}:{' '}
                        {tab === 'All'
                          ? metricList.length
                          : countCategory(metricList, tab)}
                      </Tab>
                    );
                  })}
                </TabList>
                <Divider />
                <Flex justify={'space-between'} mr={'10px'} mb={1}>
                  <Text
                    color={'text.secondary2'}
                    fontSize={'sm'}
                    fontWeight={'semibold'}
                    my={2}
                  >
                    {`${tabs[tabIndex]} Metrics`}
                  </Text>
                </Flex>

                <TabPanels
                  style={{
                    height: `260px`,
                    overflow: 'auto',
                  }}
                >
                  {tabs.map((tab: string) => (
                    <TabPanel pt={0} px={0}>
                      {tab === 'All'
                        ? metricList
                            ?.filter(
                              (metric: metricListType) =>
                                metric.metricName
                                  ?.toLocaleLowerCase()
                                  .includes(searchTerm.toLowerCase()) &&
                                !items.includes(metric.metricKey)
                            )
                            .map((item: any) => (
                              <Flex
                                justify="space-between"
                                key={item.metricKey}
                                py={1}
                              >
                                <Text
                                  fontSize="sm"
                                  fontWeight="normal"
                                  fontFamily="heading"
                                  w="fit-content"
                                  cursor={'pointer'}
                                  _hover={{ color: 'text.secondary3' }}
                                  onClick={() => {
                                    if (!items.includes(item.metricKey)) {
                                      setItems((prev: any) => {
                                        return [...prev, item.metricKey];
                                      });
                                    }
                                  }}
                                >
                                  {item.metricName}
                                </Text>
                              </Flex>
                            ))
                        : metricList
                            ?.filter(
                              (metric: metricListType) =>
                                metric.tabs?.includes(tab) &&
                                metric.metricName
                                  ?.toLocaleLowerCase()
                                  .includes(searchTerm.toLowerCase()) &&
                                !items.includes(metric.metricKey)
                            )
                            .map((item: any) => (
                              <Flex
                                justify="space-between"
                                key={item.metricKey}
                                py={1}
                              >
                                <Text
                                  fontSize="sm"
                                  fontWeight="normal"
                                  fontFamily="heading"
                                  cursor={'pointer'}
                                  w="fit-content"
                                  _hover={{ color: 'text.secondary3' }}
                                  onClick={() => {
                                    if (!items.includes(item.metricKey)) {
                                      setItems((prev: any) => {
                                        return [...prev, item.metricKey];
                                      });
                                    }
                                  }}
                                >
                                  {item.metricName}
                                </Text>
                              </Flex>
                            ))}
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </ModalBody>
        <Divider w={'95%'} mx="auto" my={3} />

        <ModalFooter px={4} pt={0} pb={4}>
          <Button
            fontWeight={'normal'}
            fontSize={'sm'}
            mr={3}
            variant={'unstyled'}
            display={'flex'}
            _hover={{ bg: '#FBFBFB' }}
            gap={1}
            px={1}
            borderRadius={2}
            onClick={() => setItems(defaultMetrics)}
          >
            <ErasorIcon />
            Reset to default
          </Button>
          <Button
            size={'sm'}
            variant="filled"
            onClick={() => {
              setSelectedMetrics(
                items.map((metric: string, index: number) => {
                  return {
                    sequence: index + 1,
                    metricKey: metric,
                    metricTitle:
                      metricList.find(
                        (metricItem: metricListType) =>
                          metricItem.metricKey === metric
                      )?.metricName ?? '',
                  };
                })
              );
              onClose();
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConfigureColumns;
