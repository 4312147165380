import { Button, Flex } from '@chakra-ui/react';

interface SaveAndPreviousButtonProps {
  isFirstTab?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  onSubmit?: () => void;
  isLastTab?: boolean;
  isDisabled?: boolean;
}

export const SaveAndPreviousButton = ({
  isFirstTab,
  onNext,
  onPrevious,
  onSubmit,
  isLastTab,
  isDisabled,
}: SaveAndPreviousButtonProps) => {
  return (
    <Flex gap={2} mt={6}>
      {/* <Button variant={'filled'} isDisabled={isFirstTab} onClick={onPrevious}>
        Previous
      </Button> */}
      {!isLastTab && (
        <Button
          variant={'filled'}
          isDisabled={isDisabled}
          onClick={onSubmit}
          ml="auto"
        >
          Save
        </Button>
      )}
    </Flex>
  );
};
