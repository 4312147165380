export function NotificationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="bell-03">
        <path
          id="Icon"
          d="M9.99981 12.6667C9.99981 13.7713 9.10438 14.6667 7.99981 14.6667C6.89524 14.6667 5.99981 13.7713 5.99981 12.6667M9.19748 4.15908C9.48781 3.85913 9.66648 3.45045 9.66648 3.00004C9.66648 2.07957 8.92029 1.33337 7.99981 1.33337C7.07934 1.33337 6.33315 2.07957 6.33315 3.00004C6.33315 3.45045 6.51182 3.85913 6.80214 4.15908M11.9998 7.46671C11.9998 6.54729 11.5784 5.66553 10.8282 5.0154C10.0781 4.36528 9.06068 4.00004 7.99981 4.00004C6.93895 4.00004 5.92153 4.36528 5.17139 5.0154C4.42124 5.66553 3.99981 6.54729 3.99981 7.46671C3.99981 8.98791 3.62257 10.1004 3.15186 10.8965C2.61537 11.8038 2.34713 12.2574 2.35772 12.3658C2.36984 12.4898 2.39216 12.5289 2.49271 12.6024C2.58059 12.6667 3.02215 12.6667 3.90527 12.6667H12.0944C12.9775 12.6667 13.419 12.6667 13.5069 12.6024C13.6075 12.5289 13.6298 12.4898 13.6419 12.3658C13.6525 12.2574 13.3843 11.8038 12.8478 10.8965C12.3771 10.1004 11.9998 8.98791 11.9998 7.46671Z"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
