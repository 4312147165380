import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useTheme,
  Tooltip,
  Skeleton,
  Spinner,
} from '@chakra-ui/react';
import {
  InfoCircle,
  formatMessageWithColoredPercentage,
  SignalCard,
} from '@devd-client/devd/components';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { useState } from 'react';
import CircularTabs from './CircularTabs';

interface SignalsProps {
  signalData: any;
  signalDataLoading: boolean;
  currentTab: string;
  setCurrentTab: (tab: string) => void;
  selected: any;
}

type Tab = { id: number; label: string };

const Signals = ({
  signalData,
  signalDataLoading,
  currentTab,
  setCurrentTab,
  selected,
}: SignalsProps) => {
  const { colors } = useTheme();
  const [showMore, setShowMore] = useState(false);

  const uniqueCategories = Array.from(
    new Set(signalData?.map((item: any) => item.category))
  ) as string[];

  const tabs: Tab[] = [
    { id: 0, label: 'All' },
    ...uniqueCategories.map((category, index) => ({
      id: index + 1,
      label: category,
    })),
  ];

  const filteredData =
    currentTab === 'All'
      ? signalData
      : signalData?.filter((item: any) => item.category === currentTab);

  return (
    <Flex mt={6} w="full" direction="column">
      <Flex align="center">
        <Text mr={1} fontFamily="heading" fontSize="xl" fontWeight="semibold">
          Signals
        </Text>
        <Tooltip
          hasArrow
          aria-label="signals"
          label="Signals alerts you about workflow issues that slow down your team. Spot bottlenecks early, keep development flowing smoothly."
        >
          <Box>
            <InfoCircle />
          </Box>
        </Tooltip>
      </Flex>
      <Flex w="full" align="flex-end" justify="space-between">
        <Flex>
          <CircularTabs
            tabs={tabs}
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            signalData={signalData}
          />
        </Flex>
        {filteredData?.length > 4 && (
          <Flex
            cursor="pointer"
            align="center"
            onClick={() => {
              setShowMore(!showMore);
            }}
            userSelect={'none'}
          >
            <Text color="primary2" fontFamily="heading" fontWeight="medium">
              Show {showMore ? 'Less' : 'More'}
            </Text>
            {showMore ? (
              <MdKeyboardArrowUp color={colors.primary2} />
            ) : (
              <MdKeyboardArrowDown color={colors.primary2} />
            )}
          </Flex>
        )}
      </Flex>
      {filteredData?.length === 0 && (
        <Text
          fontFamily="heading"
          fontSize="md"
          fontWeight="medium"
          color="text.secondary3"
          mt={4}
        >
          No Signals Found.
        </Text>
      )}
      <SimpleGrid mt={4} w="full" columns={4} spacing={4}>
        {signalDataLoading
          ? new Array(4).fill(0).map((_: any, index: number) => (
              <Box key={index} position={'relative'}>
                <Skeleton key={index} height="140px" minWidth="200px" />
                <Spinner position={'absolute'} top={'42%'} left={'42%'} />
              </Box>
            ))
          : filteredData
              ?.slice(0, showMore ? signalData?.length : 4)
              ?.map((item: any, index: number) => {
                return <SignalCard key={index} item={item} index={index} />;
              })}
      </SimpleGrid>
    </Flex>
  );
};

export default Signals;
