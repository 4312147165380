export function ForecastIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Graph--Streamline-Core" clip-path="url(#clip0_9821_2714)">
        <g id="graph--analytics-business-product-graph-data-chart-analysis">
          <path
            id="Vector"
            d="M1.15137 1.15234V14.8488H14.8478"
            stroke={props.color || '#4D4DFF'}
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="Vector_2"
            d="M4.3125 7.47461L6.94643 10.1085L11.1607 3.78711L14.8482 6.42104"
            stroke={props.color || '#4D4DFF'}
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_9821_2714">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ForecastIcon;
