export function FileCodeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3519_3212)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 1.75C4 0.783502 4.7835 0 5.75 0H11.3358C11.7999 0 12.245 0.184374 12.5732 0.512563L15.4874 3.42678C15.8156 3.75497 16 4.20008 16 4.66421V13.25C16 14.2165 15.2165 15 14.25 15H5.25C4.83579 15 4.5 14.6642 4.5 14.25C4.5 13.8358 4.83579 13.5 5.25 13.5H14.25C14.3881 13.5 14.5 13.3881 14.5 13.25V6H11.75C10.7835 6 10 5.2165 10 4.25V1.5H5.75C5.61193 1.5 5.5 1.61193 5.5 1.75V4.25C5.5 4.66421 5.16421 5 4.75 5C4.33579 5 4 4.66421 4 4.25V1.75ZM11.5 1.5615V4.25C11.5 4.38807 11.6119 4.5 11.75 4.5H14.4385C14.4348 4.49569 14.4308 4.4915 14.4268 4.48744L11.5126 1.57322C11.5085 1.56916 11.5043 1.56525 11.5 1.5615ZM5.71967 6.71967C5.42678 7.01256 5.42678 7.48744 5.71967 7.78033L7.18934 9.25L5.71967 10.7197C5.42678 11.0126 5.42678 11.4874 5.71967 11.7803C6.01256 12.0732 6.48744 12.0732 6.78033 11.7803L8.78033 9.78033C9.07322 9.48744 9.07322 9.01256 8.78033 8.71967L6.78033 6.71967C6.48744 6.42678 6.01256 6.42678 5.71967 6.71967ZM3.28033 7.78033C3.57322 7.48744 3.57322 7.01256 3.28033 6.71967C2.98744 6.42678 2.51256 6.42678 2.21967 6.71967L0.21967 8.71967C-0.0732233 9.01256 -0.0732233 9.48744 0.21967 9.78033L2.21967 11.7803C2.51256 12.0732 2.98744 12.0732 3.28033 11.7803C3.57322 11.4874 3.57322 11.0126 3.28033 10.7197L1.81066 9.25L3.28033 7.78033Z"
          fill={props.color || '#2A2A2F'}
        />
      </g>
      <defs>
        <clipPath id="clip0_3519_3212">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
