import { Avatar, Flex, Skeleton, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { useContributorsAllocation } from '../../../graphql';
import { Link } from 'react-router-dom';

interface EffortCardProps {
  initiativeId: string;
  type: string;
  setActiveTab: (tab: number) => void;
}

export const EffortCard = ({
  initiativeId,
  type,
  setActiveTab,
}: EffortCardProps) => {
  const navigate = useNavigate();
  const { data, isLoading } = useContributorsAllocation(
    initiativeId,
    type,
    '',
    'active'
  );
  return (
    <Flex
      maxW={'424px'}
      minW={'424px'}
      minH={'276px'}
      border={'1px solid'}
      borderColor={'text.tertiary'}
      rounded={'4px'}
      pt={4}
      px={4}
      pb={2}
      direction={'column'}
      gap={2}
    >
      <Flex justify={'space-between'} fontSize={'sm'} fontWeight={'semibold'}>
        <Flex direction={'column'}>
          <Text color={'text.gray.300'}>Recent Contributors</Text>
          <Text fontWeight={'medium'} color={'text.secondary2'} fontSize={'xs'}>
            Based on last 4 weeks commits
          </Text>
        </Flex>
        {data?.length > 5 ? (
          <Text
            fontSize={'xs'}
            fontWeight={'medium'}
            color={'primary'}
            cursor={'pointer'}
            onClick={() => setActiveTab(2)}
          >
            View All
          </Text>
        ) : null}
      </Flex>
      <Flex mt={1} direction={'column'} gap={1}>
        <Flex w={'full'}>
          <Text
            fontSize={'xs'}
            fontWeight={'medium'}
            color={'text.secondary2'}
            minW={'60%'}
            maxW={'60%'}
          >
            Contributor
          </Text>
          <Text
            fontSize={'xs'}
            fontWeight={'medium'}
            color={'text.secondary2'}
            align={'center'}
            minW={'20%'}
            maxW={'20%'}
          >
            Calculated Allocation
          </Text>
          <Text
            fontSize={'xs'}
            fontWeight={'medium'}
            color={'text.secondary2'}
            align={'center'}
            minW={'20%'}
            maxW={'20%'}
          >
            Assigned Allocation
          </Text>
        </Flex>
        {isLoading ? (
          Array(5)
            .fill(0)
            .map((_, index) => (
              <Flex
                key={`skeleton-${index}`}
                w={'full'}
                justify={'space-between'}
                align={'center'}
                p={1.5}
                borderTop={'1px solid'}
                borderColor={'text.tertiary'}
              >
                <Flex gap={1} align={'center'} minW={'60%'} maxW={'60%'}>
                  <Skeleton
                    borderRadius={'full'}
                    size={'xs'}
                    width={'24px'}
                    height={'24px'}
                  />
                  <Skeleton height={'16px'} width={'200px'} />
                </Flex>
                <Flex minW={'20%'} maxW={'20%'} justify={'center'}>
                  <Skeleton height={'14px'} width={'20px'} />
                </Flex>
                <Flex minW={'20%'} maxW={'20%'} justify={'center'}>
                  <Skeleton height={'14px'} width={'20px'} />
                </Flex>
              </Flex>
            ))
        ) : data?.length === 0 || !data ? (
          <Flex
            w={'full'}
            justify={'center'}
            align={'center'}
            p={1.5}
            borderTop={'1px solid'}
            borderColor={'text.tertiary'}
          >
            <Text fontSize={'sm'} color={'text.secondary2'}>
              No active contributor
            </Text>
          </Flex>
        ) : (
          data?.slice(0, 5)?.map((contributor: any) => (
            <Flex
              key={contributor?.contributor}
              w={'full'}
              justify={'space-between'}
              align={'center'}
              p={1.5}
              borderTop={'1px solid'}
              borderColor={'text.tertiary'}
            >
              <Flex
                gap={1}
                fontSize={'sm'}
                fontWeight={'medium'}
                color={'text.gray.300'}
                minW={'60%'}
                maxW={'60%'}
              >
                <Avatar name={contributor?.contributor} size={'xs'} />
                <Text isTruncated textDecoration={'underline'}>
                  <Link to={`/contributors/${contributor?.contributor}`}>
                    {contributor?.contributor}
                  </Link>
                </Text>
              </Flex>
              <Text
                fontSize={'xs'}
                color={'text.secondary2'}
                minW={'20%'}
                maxW={'20%'}
                align={'center'}
              >
                {contributor?.calculatedAllocation || 0}%
              </Text>
              <Text
                fontSize={'xs'}
                color={'text.secondary2'}
                minW={'20%'}
                maxW={'20%'}
                align={'center'}
              >
                {contributor?.assignedAllocation || 0}%
              </Text>
            </Flex>
          ))
        )}
      </Flex>
    </Flex>
  );
};
