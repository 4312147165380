import { Box, Button, Flex, Text, useTheme } from '@chakra-ui/react';
import {
  InfoCircle,
  IntegrationIcon,
  InvestmentIcon,
  OnboadingIcon,
  ProductivityIcon,
  QualityIcon,
  RightIcon,
  VelocityIcon,
} from '../icons';
import AppTooltip from '../appTooltip';
import { useNavigate } from 'react-router';
import {
  useDashboardStore,
  useDetailsPageStore,
} from '../hooks/useDashboardStore';
import ReactHtmlParser from 'html-react-parser';
import { formatMessageWithColoredPercentage } from '../shared/utils';

interface SignalCardProps {
  index: number;
  item: any;
}

export const SignalCard = ({ index, item }: SignalCardProps) => {
  const navigate = useNavigate();
  const { selected } = useDashboardStore();
  const { setSelected, setActive } = useDetailsPageStore();
  const { colors } = useTheme();
  return (
    <Flex
      key={index}
      padding={4}
      border="1px solid #E0E0E0"
      borderRadius={'8px'}
      minWidth="220px"
      minHeight="140px"
      direction="column"
      boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
      justify="space-between"
      _hover={{
        boxShadow: 'lg',
        transition: '0.2s all',
      }}
    >
      <Flex direction="column">
        <Flex align="center" justify="space-between">
          <Flex align="center">
            {item?.category === 'Quality' ? (
              <QualityIcon />
            ) : item?.category === 'Velocity' ? (
              <VelocityIcon />
            ) : item?.category === 'Productivity' ? (
              <ProductivityIcon />
            ) : item?.category === 'Integration' ? (
              <IntegrationIcon />
            ) : item?.category === 'Onboarding' ? (
              <OnboadingIcon />
            ) : item?.category === 'Investment Distribution' ? (
              <InvestmentIcon color="gray" />
            ) : (
              ''
            )}

            <Text
              ml={1}
              color="text.secondary2"
              fontWeight="semibold"
              fontFamily="heading"
              fontSize="sm"
            >
              {item?.category}
            </Text>
          </Flex>
          {item?.tooltip ? (
            <AppTooltip
              trigger="hover"
              placement={(index + 1) % 4 === 0 ? 'top-start' : 'bottom-start'}
              content={
                <Box
                  maxW="40vw"
                  maxH="40vh"
                  overflowY="auto"
                  pr={2}
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '2px',
                      background: 'rgba(0, 0, 0, 0.05)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'rgba(0, 0, 0, 0.1)',
                      borderRadius: '10px',
                      '&:hover': {
                        background: 'rgba(0, 0, 0, 0.2)',
                      },
                    },
                    h1: {
                      marginTop: '4px',
                      marginBottom: '2px',
                      fontWeight: 'bold',
                    },
                    ul: {
                      marginLeft: '20px',
                      listStyleType: 'disc',
                    },
                    li: {
                      padding: '3px 0 3px 0',
                    },
                  }}
                >
                  {ReactHtmlParser(item?.tooltip || '')}
                </Box>
              }
            >
              <Box>
                <InfoCircle />
              </Box>
            </AppTooltip>
          ) : (
            <Box>
              <InfoCircle />
            </Box>
          )}
        </Flex>

        <Text
          mt={5}
          color="text.primary"
          fontFamily="heading"
          fontWeight="medium"
        >
          {formatMessageWithColoredPercentage(item?.message, item?.color)}
        </Text>
      </Flex>
      <Box>
        <Button
          variant="unstyled"
          color="text.secondary2"
          fontWeight="normal"
          size="sm"
          _hover={{
            textDecoration: 'underline',
          }}
          onClick={() => {
            navigate(item?.link, {
              state: {
                headingText: item?.message,
                tooltip: item?.tooltip,
              },
            });
            window.analytics.track('Investigate button clicked', {
              properties: {
                signal: item?.message,
              },
            });
            setSelected({
              id: 6,
              startDate: selected.startDate as string,
              endDate: selected.endDate as string,
              duration: selected.sprintId ? 'Sprint' : 'Custom',
              sprintId: selected.sprintId || '',
            });
            setActive(selected.sprintId ? 'Sprint' : 'Custom');
          }}
          rightIcon={
            <RightIcon
              height="11px"
              width="11px"
              color={colors.text.secondary2}
            />
          }
        >
          {item?.category === 'Integration' ? 'Integrate' : 'Investigate'}
        </Button>
      </Box>
    </Flex>
  );
};

export default SignalCard;
