import { Box, Spinner, Text } from '@chakra-ui/react';
import { ChakraStylesConfig, Select } from 'chakra-react-select';
import { MdArrowDropDown } from 'react-icons/md';
import { eliminateHtmlTags } from '../shared/utils';

export type ISelect = {
  value: string;
  label: string;
  isdisabled?: boolean;
};

const chakraStyles: ChakraStylesConfig = {
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 9999,
    };
  },
};

export const CustomSelect = (props: any) => {
  return (
    <Box minW={props.width ? props.width : '183px'}>
      <Text
        fontWeight="semibold"
        fontSize="sm"
        lineHeight="17px"
        color="text.secondary"
        fontFamily="Inter"
        mb={props.label ? 2 : 0}
      >
        {props.label}
      </Text>
      <Select
        {...props}
        chakraStyles={props.chakraStyles ? props.chakraStyles : chakraStyles}
        isOptionDisabled={(option: any) => option.isdisabled}
        icon={props.isLoading && <Spinner />}
        useBasicStyles
        isSearchable={props.isSearchableEnabled !== false}
        onChange={(e: any) => {
          props.showLabels
            ? props.onChange(eliminateHtmlTags(e.label))
            : props.onChange(eliminateHtmlTags(e.value));
        }}
        value={props.defaultItem}
        options={props.options}
      />
    </Box>
  );
};

export default CustomSelect;
