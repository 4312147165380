export const colorStyles = {
  menuList: (styles: any) => ({
    ...styles,
    background: '#fff',
  }),

  control: (styles: any, { isHovered, isFocused }: any) => ({
    ...styles,
    border: isHovered ? '1px solid gray' : '1px solid #CBD5E0',
  }),

  singleValue: (provided: any) => ({
    ...provided,
    color: '#1A202C',
    fontSize: '14px',
    fontWeight: 400,
  }),

  option: (styles: any, { isFocused, isSelected }: any) => ({
    ...styles,
    background: isFocused ? '#dee2e6' : isSelected ? '#0095E6' : undefined,
    zIndex: 1,
    fontSize: '14px',
    fontFamily: 'Inter',
  }),
  menu: (base: any) => ({
    ...base,
    zIndex: 100,
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Inter',
    letterSpacing: '0.5px',
    color: '#718096',
  }),
};

export function isValidEmail(email: string) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return email === '' ? true : emailRegex.test(email);
}

export const areValidEmails = (emailString: string) => {
  if (!emailString.trim()) return true;

  const emails = emailString.split(',').map((email) => email.trim());

  return emails.every((email) => {
    if (!email) return true;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  });
};
