// React imports
import { FC, Fragment, useEffect, useMemo, useState } from 'react';

// Chakra import
import {
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Box,
  Flex,
  Icon,
  Stack,
  Text,
  Tooltip,
  useTheme,
  Button,
  Tag,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { OptionBase } from 'chakra-react-select';

// React router and Utilites
import { Link } from 'react-router-dom';

// Tanstack imports
import { createColumnHelper, FilterFn, ColumnDef } from '@tanstack/react-table';

// Icons
import {
  AnnotationIcon,
  BlueBgTick,
  BookMarkIcon,
  GitCommitIcon,
  PullRequestIcon,
  LinkBroken,
  StackIcon,
  WrongIcon,
  folderCode,
  Workflow,
  Currency,
  Epic,
  Bug,
  Task,
  Bullseye,
  PullRequest,
  Story,
  UserIcon,
  Flag,
  Clock,
  StarIcon,
  useToastHook,
  IgnoreIcon,
  IgnoreEntryModal,
  Incident,
  SubTask,
  Improvement,
  Defect,
} from '@devd-client/devd/components';
import DataGrid from '../Grid-table';
import { useFetchIntegrationDetails, useIgnorePrOrIssue } from '../../apis';
import { BiComment } from 'react-icons/bi';
import { getDaysAgo, truncateText } from '../../utils/DateFormat';
import usePrTableQuery from '../../apis/hooks/query/usePrTableQuery';
import { FilterByType } from '../../utils/metricTableTypes';
import PrFilters from '../Filters/components/PrFilters';
import IssueFilters from '../Filters/components/IssueFilters';
import useIssueTableQuery from '../../apis/hooks/query/useIssueTableQuery';
import IssueBarChart from './charts';
import { useQueryClient } from '@tanstack/react-query';

interface IssueTypes {
  metric: string;
  chartKey: string;
  metricKey: string;
  sprintId: string;
  startDate: any;
  endDate: any;
  heading: string;
  firstOption: string;
  secondOption: string;
  teamId: string;
  status: string;
  setFirstOption: (value: string) => void;
  setTeamId: (value: string) => void;
  setStatus: (value: string) => void;
  setSecondOption: (value: string) => void;
  setChartKey: (value: string) => void;
  setMetric: (value: string) => void;
  setMetricKey: (value: string) => void;
  setHeading: (value: string) => void;
  setTicketType: (value: string) => void;
}

export function IssueDetails({
  metric,
  chartKey,
  metricKey,
  sprintId,
  startDate,
  endDate,
  heading,
  firstOption,
  secondOption,
  teamId,
  status,
  setTeamId,
  setStatus,
  setSecondOption,
  setFirstOption,
  setChartKey,
  setMetric,
  setHeading,
  setMetricKey,
  setTicketType,
}: IssueTypes) {
  const { colors } = useTheme();
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const ignoreEntryModal = useDisclosure();
  const [hideEntryId, setHideEntryId] = useState('');
  const { data: intDetailsData, isFetching: intergationFetching } =
    useFetchIntegrationDetails();
  const [isIgnoreIssueLoading, setIsIgnoreIssueLoading] = useState(false);
  const { data, isFetching, isFetched, isError, fetchNextPage, isLoading } =
    useIssueTableQuery(startDate, endDate, metric, sprintId);
  const { mutate: ignorePrOrIssue, isLoading: ignorePrOrIssueLoading } =
    useIgnorePrOrIssue();
  const handlePrIgnoreOrIssue = (id: string) => {
    ignorePrOrIssue(
      {
        id,
        type: 'ignoreIssue',
      },
      {
        onSuccess: () => {
          setIsIgnoreIssueLoading(true);
          newToast({
            message: 'The entry you ignored has been successfully processed.',
            status: 'success',
          });
          queryClient.invalidateQueries([metric]);
        },
        onError: () => {
          newToast({
            message:
              'There was an error processing your request. Please try again.',
            status: 'error',
          });
        },
      }
    );
  };
  const columnHelper = createColumnHelper<any>();
  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      columnHelper.accessor('issueReportDetails', {
        cell: (info) => {
          return (
            <Flex direction={'column'}>
              <Flex gap={3}>
                <Tooltip
                  label={
                    <Box>
                      <Text textAlign={'center'}>Workspace</Text>
                      {info.getValue()?.workspace?.length > 15 && (
                        <Text fontWeight={'normal'}>
                          {info.getValue()?.workspace}
                        </Text>
                      )}
                    </Box>
                  }
                  hasArrow
                >
                  <Flex gap={1} justify={'center'} align={'center'}>
                    <Icon as={folderCode} />{' '}
                    <Text opacity={0.75}>
                      {truncateText(info.getValue()?.workspace, 15)}
                    </Text>
                  </Flex>
                </Tooltip>

                <Flex gap={1} justify={'center'} align={'center'}>
                  <Icon as={Workflow} />{' '}
                  {info.getValue()?.sprints?.length > 0 ? (
                    <>
                      <Tooltip
                        hasArrow
                        label={
                          <Box>
                            <Text textAlign={'center'}>Sprints</Text>
                            {info.getValue()?.sprints[0]?.length > 15 && (
                              <Text fontWeight={'normal'}>
                                {info.getValue()?.sprints[0]}
                              </Text>
                            )}
                          </Box>
                        }
                      >
                        <Text opacity={0.75}>
                          {truncateText(info.getValue()?.sprints[0], 15)}
                        </Text>
                      </Tooltip>
                      {info.getValue()?.sprints?.length > 1 && (
                        <Tooltip
                          label={
                            <Box>
                              <Text textAlign={'center'}>Sprints</Text>
                              {info
                                .getValue()
                                ?.sprints.map((sprint: any, index: number) => {
                                  return index > 0 ? (
                                    <Text key={index} fontWeight={'normal'}>
                                      {sprint}
                                    </Text>
                                  ) : (
                                    <Fragment key={index}></Fragment>
                                  );
                                })}
                            </Box>
                          }
                          hasArrow
                        >
                          <Text decoration={'underline'} opacity={0.75}>
                            +{info.getValue()?.sprints?.length - 1}
                          </Text>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <Text opacity={0.75}>Sprints not linked</Text>
                  )}
                </Flex>

                <Tooltip label="Investment Category" hasArrow>
                  <Flex gap={1} justify={'center'} align={'center'}>
                    {/* <Icon as={Currency} />{' '} */}
                    <Currency
                      color={
                        info.getValue()?.investmentCat ? '#2A2A2F' : '#FFAD00'
                      }
                    />
                    <Text opacity={0.75}>
                      {info.getValue()?.investmentCat
                        ? info.getValue()?.investmentCat
                        : 'Uncategorised'}
                    </Text>
                  </Flex>
                </Tooltip>

                <Tooltip
                  hasArrow
                  placement="bottom-end"
                  label={`Clicking this icon will cease tracking for this entry. Are you sure?`}
                >
                  <IconButton
                    icon={<IgnoreIcon color={'#718096'} />}
                    size={'xs'}
                    onClick={() => {
                      setHideEntryId(info.getValue()?.id);
                      ignoreEntryModal.onOpen();
                    }}
                    variant={'solid'}
                    colorScheme={colors.secondary}
                    isDisabled={ignorePrOrIssueLoading}
                    aria-label={'Ignore Issue'}
                    bgColor={'white'}
                    p={1}
                    mr={-1}
                    ml={'auto'}
                    borderRadius={'lg'}
                  />
                </Tooltip>
              </Flex>
              <Box my={4} fontWeight={'semibold'} maxW={'fit-content'}>
                <Link to={`/timeline/issue/${info.getValue()?.id}`}>
                  <Text
                    color="text.gray.300"
                    fontFamily="heading"
                    fontSize="sm"
                    fontWeight="semibold"
                    textDecoration={'underline'}
                  >
                    {info.getValue()?.summary}
                  </Text>
                </Link>
              </Box>
              <Flex gap={4}>
                <Tooltip label="Issue ID" hasArrow>
                  <Flex gap={1} justify={'center'} align={'center'}>
                    <Icon
                      as={
                        info.getValue()?.issueType === 'Epic'
                          ? Epic
                          : info.getValue()?.issueType === 'Bug'
                          ? Bug
                          : info.getValue()?.issueType === 'Story'
                          ? Story
                          : info.getValue()?.issueType === 'Incident'
                          ? Incident
                          : info.getValue()?.issueType === 'Subtask'
                          ? SubTask
                          : info.getValue()?.issueType === 'Improvement'
                          ? Improvement
                          : info.getValue()?.issueType === 'Defect'
                          ? Defect
                          : Task
                      }
                    />{' '}
                    <Link
                      to={info.getValue()?.externalUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: 'underline',
                        color: '#718096',
                      }}
                    >
                      {info.getValue()?.id}
                    </Link>
                  </Flex>
                </Tooltip>
                <Tooltip label="Story Points" hasArrow>
                  <Flex gap={1} justify={'center'} align={'center'}>
                    <Icon as={StackIcon} />{' '}
                    <Text opacity={0.75}>
                      {info.getValue()?.storyPoints
                        ? info.getValue()?.storyPoints
                        : 0}
                    </Text>
                  </Flex>
                </Tooltip>
                <Tooltip label="Child Issues" hasArrow>
                  <Flex gap={1} justify={'center'} align={'center'}>
                    <Icon as={Bullseye} />{' '}
                    <Text opacity={0.75}>
                      {info.getValue()?.childIssues
                        ? info.getValue()?.childIssues
                        : 0}{' '}
                      issue(s)
                    </Text>
                  </Flex>
                </Tooltip>
                <Tooltip label="Linked PRs" hasArrow>
                  <Flex gap={1} justify={'center'} align={'center'}>
                    <Icon as={PullRequest} />{' '}
                    <Text opacity={0.75}>
                      {info.getValue()?.linkedPRs?.length > 0 ? (
                        <Link
                          to={`/pr-details?chartKey=PR_UPDATED&heading=Linked%20PRs&metric=PR_UPDATED&preDefinedOptions=true&persistPassedState=true`}
                          style={{ textDecoration: 'underline' }}
                          state={{
                            prIds: info.getValue()?.linkedPRs,
                            showFilters: false,
                          }}
                        >
                          {info.getValue()?.linkedPRs?.length}
                        </Link>
                      ) : (
                        'NA'
                      )}
                    </Text>
                  </Flex>
                </Tooltip>
              </Flex>
            </Flex>
          );
        },
        header: 'Issue',
        size: 560,
      }),
      columnHelper.accessor('assigneeDetails', {
        cell: (info) => {
          return (
            <Flex
              direction={'column'}
              gap={2}
              mr={3}
              justify={'center'}
              align={'center'}
            >
              {info.getValue()?.current ? (
                <Tooltip
                  label={
                    <Box>
                      <Text textAlign={'center'}>Current Assignee</Text>
                      <Text fontWeight={'normal'}>
                        {info.getValue()?.current}
                      </Text>
                    </Box>
                  }
                  hasArrow
                >
                  <Avatar size="sm" name={info.getValue()?.current}>
                    <AvatarBadge bg="green.500" border={'none'}>
                      <Box
                        background={'#1DA44A'}
                        h={4}
                        w={4}
                        borderRadius={'50%'}
                        p={0.5}
                      >
                        <StarIcon color="white" />
                      </Box>{' '}
                    </AvatarBadge>
                  </Avatar>
                </Tooltip>
              ) : (
                <Tooltip label={'Unassigned'} hasArrow>
                  <Box>
                    <UserIcon color="grey" width={'32'} height={'32'} />
                  </Box>
                </Tooltip>
              )}

              <Tooltip
                label={
                  <Box>
                    <Text textAlign={'center'}>Other Assignees</Text>
                    {info.getValue()?.rest?.map((item: string) => (
                      <Text key={item} fontWeight={'normal'} fontSize={14}>
                        {item}
                      </Text>
                    ))}
                    {/* {info.getValue()?.rest?.length > 3 ? (
                      <Text>+{info.getValue()?.rest?.length - 3} more</Text>
                    ) : (
                      <></>
                    )} */}
                  </Box>
                }
                hasArrow
              >
                <AvatarGroup size="sm" w={'fit-content'}>
                  {info
                    .getValue()
                    ?.rest?.slice(0, 3)
                    .map((prevAssignee: any) => (
                      <Avatar key={prevAssignee} name={prevAssignee?.trim()} />
                    ))}
                </AvatarGroup>
              </Tooltip>
            </Flex>
          );
        },
        header: 'Assignee',
        size: 130,
      }),
      columnHelper.accessor('priorityDetails', {
        cell: (info) => {
          return (
            <Flex direction="column" gap={4}>
              <Flex align={'center'} gap={1}>
                <Flag />
                <Tooltip label="Priority" hasArrow>
                  <Text fontWeight={'normal'}>
                    {info.getValue()?.priority
                      ? info.getValue()?.priority
                      : 'NA'}
                  </Text>
                </Tooltip>
              </Flex>
              <Flex direction={'column'}>
                <Text fontWeight={'semibold'}>Due Date</Text>
                <Text fontWeight={'normal'} fontSize={'xs'}>
                  {info.getValue()?.dueDate
                    ? getDaysAgo(info.getValue()?.dueDate, 'DueDate')
                    : 'No Due Date'}
                </Text>
              </Flex>
            </Flex>
          );
        },
        header: 'Priority',
        size: 140,
        //    filterFn: localFilterFns.fuzzy,
      }),
      columnHelper.accessor('statusDetails', {
        cell: (info) => {
          return (
            <Flex direction="column" gap={3}>
              <Tag
                textAlign={'center'}
                maxW={'fit-content'}
                size={'md'}
                fontWeight={'semibold'}
                p={2}
                mt={info.getValue()?.inStatusTime ? 0 : 5}
              >
                {info.getValue()?.status ?? 'NA'}
              </Tag>
              {info.getValue()?.inStatusTime ? (
                <Tooltip label={'In Status Time'} hasArrow>
                  <Flex align={'center'} gap={1} maxW={'fit-content'}>
                    <Icon as={Clock} />
                    <Text fontSize={'xs'}>{info.getValue()?.inStatusTime}</Text>
                  </Flex>
                </Tooltip>
              ) : (
                <></>
              )}
            </Flex>
          );
        },
        header: 'Status',
        size: 160,
        //    filterFn: localFilterFns.fuzzy,
      }),
      columnHelper.accessor('createdAt', {
        cell: (info) => {
          return (
            <Flex direction={'column'} mt={1} gap={1}>
              <Text as={'strong'} fontWeight={'semibold'}>
                {getDaysAgo(info.getValue(), 'daysAgo')}
              </Text>
              <Text
                color="text.gray.300"
                fontFamily="heading"
                fontSize="sm"
                fontWeight="normal"
              >
                {getDaysAgo(info.getValue(), 'exactDate')}
              </Text>
            </Flex>
          );
        },
        header: 'Created At',
        size: 220,
      }),
      columnHelper.accessor('lastActivity', {
        cell: (info) => {
          return (
            <Flex direction={'column'} mt={1} gap={1}>
              <Text as={'strong'} fontWeight={'semibold'}>
                {getDaysAgo(info.getValue(), 'daysAgo')}
              </Text>
              <Text
                color="text.gray.300"
                fontFamily="heading"
                fontSize="sm"
                fontWeight="normal"
              >
                {getDaysAgo(info.getValue(), 'exactDate')}
              </Text>
            </Flex>
          );
        },
        header: 'Last Activity',
        size: 220,
      }),

      columnHelper.accessor('cycleTimeDetails', {
        cell: (info) => {
          return (
            <Flex align="center">
              <Box
                height="8px"
                width="8px"
                borderRadius="50%"
                bg={info.getValue()?.color}
                mr={info.getValue()?.cycleTime ? 2 : 0}
              />
              <Text as={'strong'} fontWeight={'semibold'}>
                {info.getValue()?.cycleTime
                  ? info.getValue()?.cycleTime
                  : 'Not yet resolved'}
              </Text>
            </Flex>
          );
        },
        header: 'Cycle Time',
        size: 200,
      }),
      columnHelper.accessor('issueLeadCycleTimeDetails', {
        cell: (info) => {
          return (
            <Flex align="center">
              <Box
                height="8px"
                width="8px"
                borderRadius="50%"
                bg={info.getValue()?.color}
                mr={info.getValue()?.cycleTime ? 2 : 0}
              />
              <Text as={'strong'} fontWeight={'semibold'}>
                {info.getValue()?.cycleTime
                  ? info.getValue()?.cycleTime
                  : 'Not yet resolved'}
              </Text>
            </Flex>
          );
        },
        header: 'Lead Time',
        size: 200,
      }),
      columnHelper.accessor('issueStatusTimeDistribution', {
        cell: (info) => {
          return (
            <Flex w={'full'} h={90} overflowY={'auto'} overflowX={'hidden'}>
              <Box h={90} w={'full'}>
                <IssueBarChart
                  data={
                    info
                      .getValue()
                      ?.map((bar: any) => ({
                        name: bar?.status,
                        value:
                          bar?.value === '' || bar?.value === null
                            ? 0
                            : parseFloat(bar?.value),
                        fill: bar?.color,
                      }))
                      ?.filter((bar: any) => bar.value !== 0) || []
                  }
                />
              </Box>
            </Flex>
          );
        },
        header: 'Status Time Distribution',
        size: 350,
      }),
    ],
    []
  );
  useEffect(() => {
    if (!isFetching && isIgnoreIssueLoading) {
      setIsIgnoreIssueLoading(false);
    }
  }, [isFetching]);
  return (
    <>
      <IssueFilters
        isFetched={isFetched}
        startDate={startDate}
        endDate={endDate}
        chartKey={chartKey}
        metricKey={metricKey}
        heading={heading}
        firstOption={firstOption}
        secondOption={secondOption}
        teamId={teamId}
        status={status}
        totalDBrows={(data as any)?.totalDBRowCount ?? 0}
        setTeamId={setTeamId}
        setStatus={setStatus}
        setFirstOption={setFirstOption}
        setSecondOption={setSecondOption}
        setChartKey={setChartKey}
        setMetric={setMetric}
        setHeading={setHeading}
        setMetricKey={setMetricKey}
        setTicketType={setTicketType}
      />
      <DataGrid
        integration={{
          type: 'Jira',
          active:
            intDetailsData?.dto
              ?.filter((item: any) => item?.name === 'jira')
              .some((item: any) => item?.active) ?? true,
        }}
        // apply the componnet origin checks here if needed
        columns={columns}
        isFetched={isFetched}
        data={(data as any)?.data ?? []}
        totalDBrows={(data as any)?.totalDBRowCount ?? 0}
        isFetching={isFetching}
        isError={isError}
        isLoading={isLoading}
        isIgnorePrOrIssueLoading={isIgnoreIssueLoading}
        fetchNextPage={fetchNextPage}
        startDate={startDate}
        endDate={endDate}
      />
      {ignoreEntryModal.isOpen && (
        <IgnoreEntryModal
          {...ignoreEntryModal}
          id={hideEntryId}
          handleIgnore={handlePrIgnoreOrIssue}
        />
      )}
    </>
  );
}

export default IssueDetails;
