export function Incident(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M104 8H24C15.1634 8 8 15.1634 8 24V104C8 112.837 15.1634 120 24 120H104C112.837 120 120 112.837 120 104V24C120 15.1634 112.837 8 104 8Z"
        fill="#97A0AF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64 44.75C53.3673 44.75 44.75 53.3673 44.75 64C44.75 74.6327 53.3673 83.25 64 83.25C74.6327 83.25 83.25 74.6327 83.25 64C83.25 53.3673 74.6327 44.75 64 44.75ZM35.125 64C35.125 48.0516 48.0516 35.125 64 35.125C79.9484 35.125 92.875 48.0516 92.875 64C92.875 79.9484 79.9484 92.875 64 92.875C48.0516 92.875 35.125 79.9484 35.125 64Z"
        fill="white"
      />
    </svg>
  );
}

export default Incident;
