import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import {
  AddMetricModal,
  DateFilter,
  durationData,
  ISelect,
  Loader,
  MetricsCard,
  PeriodType,
  removeEmptyQueryParams,
  TeamMenu,
  useDashboardStore,
  useQueryState,
} from '@devd-client/devd/components';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import moment from 'moment';
import { QUERY_KEYS, useGetCustomDashboardDetails } from '../../../api';
import AddMetricsSlidebar from '../AddMetricsSlidebar';
import NoMetricImage from '../../../../../components/src/shared/assets/no-metric.png';
import { IoIosMore } from 'react-icons/io';
import { MdOutlineEdit } from 'react-icons/md';
import UpdateDashboardModel from '../UpdateDashboardModal';

interface DetailViewProps {
  selectedItem: any;
}

const Divider = styled.hr`
  border-top: ${({ theme }) => `1.6px solid #ccc`};
  color: transparent;
`;

const CustomMetricsDetails: FC<DetailViewProps> = ({ selectedItem }) => {
  const addMetricsModal = useDisclosure();
  const createMetricsModal = useDisclosure();
  const [searchParam] = useSearchParams();
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [appState, dispatch] = useAuth();
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [dashboardId, setDashboardId] = useQueryState('id');
  const updateDashboardModal = useDisclosure();
  const [selectedDashboard, setSelectedDashboard] = useState('');

  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprintName: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Organisation'
        ? setTeam('Organisation')
        : setTeam(selectedTeam.value);
    }
  }, [selected, selectedTeam.value]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Organisation'
          ? { value: '', label: 'Organisation' }
          : { value: team, label: team }
      );

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom' ||
              el.duration === 'Today' ||
              el.duration === 'Month' ||
              el.duration === 'Quarter'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  const { data, isFetching } = useGetCustomDashboardDetails(dashboardId);

  return (
    <>
      <Stack spacing={4} w="full">
        <Flex w="full" align="flex-end" justify="space-between">
          <Flex w="full" align="center" justify="space-between">
            <Text
              fontFamily="heading"
              fontSize="md"
              ml={2}
              fontWeight="semibold"
              color="text.primary"
              display={'flex'}
              alignItems={'center'}
              gap={1}
            >
              {dashboardId}
              <Tooltip label="Rename" hasArrow>
                <IconButton
                  aria-label="edit"
                  bg="transparent"
                  size="sm"
                  color="text.secondary"
                  icon={<MdOutlineEdit size={16} />}
                  position={'static'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    updateDashboardModal.onOpen();
                    setSelectedDashboard(dashboardId || '');
                  }}
                />
              </Tooltip>
            </Text>
            <Flex align="center" gap={4}>
              <Button
                rounded={'4px'}
                bg={'transparent'}
                paddingY={'12px'}
                paddingX={'16px'}
                border={'1px solid #4D4DFF'}
                color={'#4D4DFF'}
                width={'fit-content'}
                fontSize={'14px'}
                fontWeight={'500'}
                _hover={{ bg: 'transparent' }}
                onClick={addMetricsModal.onOpen}
                fontFamily={'inter'}
              >
                Add Metrics
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Divider />
        {data?.dto?.layout?.metrics?.length > 0 && (
          <Flex>
            <Flex w="full" align="flex-end" justify="space-between">
              <Box>
                <TeamMenu
                  selectedTeam={selectedTeam}
                  handleSelectedTeam={setSelectedTeam}
                  AuthActionTypes={AuthActionTypes}
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                />
              </Box>
              <DateFilter
                getUser={getUser}
                appState={appState}
                dispatch={dispatch}
                AuthActionTypes={AuthActionTypes}
              />
            </Flex>
          </Flex>
        )}
        {(data?.dto === null || data?.dto?.layout?.metrics?.length === 0) && (
          <Box
            height={'70vh'}
            width={'full'}
            display={'flex'}
            flexDirection={'column'}
            gap={10}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Image
              src={NoMetricImage}
              alt="no-metric-added"
              h={'232px'}
              w={'272px'}
            />
            <Box
              display={'flex'}
              flexDirection={'column'}
              gap={4}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Text
                color="#2A2A2F"
                fontSize={'28px'}
                fontFamily={'inter'}
                fontWeight={600}
              >
                You haven't added any metrics yet.
              </Text>
              <Button
                rounded={'4px'}
                bg={'#4D4DFF'}
                paddingY={'8px'}
                paddingX={'12px'}
                border={'none'}
                color={'white'}
                width={'fit-content'}
                fontSize={'16px'}
                _hover={{ bg: '#4D4DFF' }}
                onClick={addMetricsModal.onOpen}
                fontFamily={'inter'}
              >
                Add Metrics
              </Button>
            </Box>
          </Box>
        )}
        <SimpleGrid columns={{ base: 2, '2xl': 2 }} spacing={6} mt={6}>
          {!isFetching &&
            data?.dto?.layout?.metrics?.map((item: any) => (
              <MetricsCard
                key={item.metric}
                metricType={item.metric}
                selected={selected}
                selectedTeam={selectedTeam.value}
                currentDashboardMetrics={data?.dto}
                queryKeys={QUERY_KEYS}
                isCustomDashboard={true}
              />
            ))}
        </SimpleGrid>
      </Stack>
      {addMetricsModal.isOpen && (
        <AddMetricModal
          isOpen={addMetricsModal.isOpen}
          onClose={() => {
            addMetricsModal.onClose();
          }}
          hideBtn={false}
          modalHeading={'Add Metrics'}
          bodyContent={false}
          name={'name'}
          queryKeys={QUERY_KEYS}
          buttonText={'Submit'}
          forTeam={false}
          forDashbaord
          handleCreateMetricsClick={() => {
            createMetricsModal.onOpen();
            addMetricsModal.onClose();
          }}
          currentDashboardMetrics={data?.dto}
        />
      )}

      {createMetricsModal.isOpen && (
        <AddMetricsSlidebar
          openDrawer={createMetricsModal.isOpen}
          handleDrawerClose={createMetricsModal.onClose}
          selectedTeam={selectedTeam.value}
          selected={selected}
        />
      )}

      {updateDashboardModal.isOpen && (
        <UpdateDashboardModel
          isOpen={updateDashboardModal.isOpen}
          onClose={updateDashboardModal.onClose}
          selectedDashboard={selectedDashboard}
          setDashboardId={setDashboardId}
        />
      )}
      {isFetching && <Loader />}
    </>
  );
};

export default CustomMetricsDetails;
