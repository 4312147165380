import { Flex, Text, useTheme, Box, Stack, Skeleton } from '@chakra-ui/react';
import moment from 'moment';
import { useMemo } from 'react';
import {
  Bar,
  ResponsiveContainer,
  BarChart,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
  Legend,
} from 'recharts';
import styled from 'styled-components';
import { splitTime, truncateText } from '../shared/utils';

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  font-family: 'Manrope';
  background-color: #fff;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  border: 0.5px solid rgba(0, 0, 0, 0.1);
`;

const CustomTooltip = ({
  active,
  payload,
  XLabel,
}: {
  active?: any;
  payload?: any;
  XLabel?: any;
}) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <Box
          fontFamily="heading"
          fontWeight="600"
          fontSize="12px"
          color="#999999"
        >
          {payload[0].payload.name}:
        </Box>
        <Box fontFamily="heading" fontWeight="600" fontSize="12px" ml="5px">
          {XLabel === 'Time'
            ? splitTime(payload[0].value / 60)
            : payload[0].value}
        </Box>
      </TooltipContainer>
    );
  }
  return null;
};

const RenderCustomTick = (props: any) => {
  const { x, y, payload } = props;
  const { value } = payload;

  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={2} fontSize={10} textAnchor="end">
        {truncateText(value, 8)}
      </text>
    </g>
  );
};

interface ChartProps {
  data: any;
  XLabel?: string;
  YLabel?: string;
}

export function VerticalBarChartShared({ data, YLabel, XLabel }: ChartProps) {
  const fallBackData = [
    { name: '', value: 10, fill: '#D3D3D3' },
    { name: '', value: 5, fill: '#D3D3D3' },
    { name: '', value: 2, fill: '#D3D3D3' },
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart layout="vertical" data={data.length > 0 ? data : fallBackData}>
        <XAxis
          type="number"
          tickLine={false}
          tick={false}
          orientation="top"
          style={{
            fontSize: 11,
            fontWeight: 500,
          }}
          label={{
            value: data?.length > 0 ? XLabel || '' : '',
            position: 'center',
            fontSize: 11,
            fontWeight: 500,
          }}
        />
        <YAxis
          dataKey="name"
          type="category"
          tick={<RenderCustomTick />}
          interval={0}
          minTickGap={5}
          width={data?.length > 0 ? 80 : 10}
          label={{
            value: data?.length > 0 ? YLabel || '' : '',
            angle: -90,
            position: 'top',
            fontSize: 11,
            fontWeight: 500,
            dx: -32,
            dy: 33,
          }}
        />
        {data.length > 0 && (
          <Tooltip
            cursor={{ fill: 'transparent' }}
            wrapperStyle={{
              border: 'none',
              outline: 'none',
            }}
            content={<CustomTooltip XLabel={XLabel} />}
          />
        )}

        <Bar dataKey="value" barSize={15} background={false}>
          {data.length > 0
            ? data.map((item: any) => <Cell key={item.name} fill={item.fill} />)
            : fallBackData.map((item: any) => (
                <Cell key={item.name} fill={item.fill} />
              ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

export default VerticalBarChartShared;
