import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import CustomSelect from '../../customSelect';
import { AddBottomViewProps } from '../helpers/bottomView.types';
import { SaveAndPreviousButton } from './SaveAndPreviousButton';

type Option = { name: string; value: string };

interface JiraFormProps {
  handleSaveClick: (
    params: AddBottomViewProps[],
    integrationId: string
  ) => void;
  data: any;
  integrationId: string;
}

const formatOptionLabel = ({ value, label }: any) =>
  value === '' ? <span>&nbsp;</span> : label;

const getSelectedValue = (fieldName: string, data: any) =>
  data?.dto
    ?.find((elem: any) => elem?.name === fieldName && elem?.value)
    ?.options?.find(
      (option: any) =>
        option?.value ===
        data?.dto?.find((elem: any) => elem?.name === fieldName)?.value
    )?.name || '';

const payloadObject = (
  fieldName: string,
  selectedValue: string,
  data: any
) => ({
  name: fieldName,
  value:
    data?.dto
      ?.find((elem: any) => elem?.name === fieldName)
      ?.options?.find((option: Option) => option.name === selectedValue)
      ?.value || '',
});

const getOptions = (fieldName: string, data: any) =>
  [{ value: '', label: '' }].concat(
    data?.dto
      ?.find((elem: any) => elem?.name === fieldName)
      ?.options?.map(({ name, value }: Option) => ({ value, label: name })) ||
      []
  );

const fieldNames = ['StoryPoint', 'Sprint', 'DevAssignee', 'QaAssignee'];

const JiraForm: FC<JiraFormProps> = ({
  handleSaveClick,
  data,
  integrationId,
}) => {
  const [selectedValues, setSelectedValues] = useState<Record<string, string>>(
    {}
  );

  useEffect(() => {
    const initialValues = fieldNames.reduce(
      (acc: Record<string, string>, fieldName) => {
        acc[fieldName] = getSelectedValue(fieldName, data);
        return acc;
      },
      {}
    );
    setSelectedValues(initialValues);
  }, [data?.dto]);

  const onSubmit = () => {
    const payload = fieldNames.map((fieldName) =>
      payloadObject(fieldName, selectedValues[fieldName], data)
    );
    handleSaveClick(payload, integrationId);
  };

  const onSelectChange = (selected: string, fieldName: string) => {
    setSelectedValues((prev) => ({ ...prev, [fieldName]: selected }));
  };

  return (
    <Stack spacing={4}>
      {fieldNames.map((fieldName) => (
        <HStack key={fieldName} spacing={6}>
          <Text
            fontSize="sm"
            fontFamily="heading"
            fontWeight="semibold"
            color="text.secondary"
            lineHeight="17px"
            minW="100px"
          >
            {fieldName}:
          </Text>
          <CustomSelect
            placeholder={`Select ${fieldName}`}
            onChange={(selected: string) => onSelectChange(selected, fieldName)}
            showLabels
            defaultItem={{
              value: selectedValues[fieldName],
              label: selectedValues[fieldName],
            }}
            formatOptionLabel={formatOptionLabel}
            options={getOptions(fieldName, data)}
          />
        </HStack>
      ))}
      <Box mt={6}>
        <SaveAndPreviousButton onSubmit={onSubmit} />
      </Box>
    </Stack>
  );
};

export default JiraForm;
