export function Improvement(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M104 8H24C15.1634 8 8 15.1634 8 24V104C8 112.837 15.1634 120 24 120H104C112.837 120 120 112.837 120 104V24C120 15.1634 112.837 8 104 8Z"
        fill="#63BA3C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M64 32C68.4183 32 72 35.5817 72 40V88C72 92.4183 68.4183 96 64 96C59.5817 96 56 92.4183 56 88V40C56 35.5817 59.5817 32 64 32Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M58.3431 34.3431C61.4673 31.219 66.5327 31.219 69.6569 34.3431L93.6569 58.3431C96.7811 61.4673 96.7811 66.5327 93.6569 69.6569C90.5327 72.7811 85.4673 72.7811 82.3431 69.6569L58.3431 45.6569C55.219 42.5327 55.219 37.4673 58.3431 34.3431Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.6569 34.3431C72.7811 37.4673 72.7811 42.5327 69.6569 45.6569L45.6569 69.6569C42.5327 72.7811 37.4673 72.7811 34.3431 69.6569C31.219 66.5327 31.219 61.4673 34.3431 58.3431L58.3431 34.3431C61.4673 31.219 66.5327 31.219 69.6569 34.3431Z"
        fill="white"
      />
    </svg>
  );
}

export default Improvement;
