export const ErasorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="eraser">
        <path
          id="Icon"
          d="M11.9997 8.66669L7.33305 4.00002M13.9997 14H5.33305M7.29122 13.3752L13.0703 7.5961C13.8623 6.80406 14.2584 6.40805 14.4067 5.95139C14.5373 5.5497 14.5373 5.11701 14.4067 4.71532C14.2584 4.25867 13.8623 3.86265 13.0703 3.07062L12.9291 2.92943C12.1371 2.1374 11.7411 1.74138 11.2844 1.593C10.8827 1.46249 10.45 1.46249 10.0484 1.593C9.59169 1.74138 9.19568 2.1374 8.40364 2.92943L2.92913 8.40395C2.13709 9.19598 1.74108 9.592 1.5927 10.0487C1.46218 10.4503 1.46218 10.883 1.5927 11.2847C1.74108 11.7414 2.13709 12.1374 2.92913 12.9294L3.37488 13.3752C3.60548 13.6058 3.72078 13.7211 3.85534 13.8035C3.97463 13.8766 4.10469 13.9305 4.24074 13.9632C4.39419 14 4.55725 14 4.88337 14H5.78273C6.10885 14 6.27191 14 6.42536 13.9632C6.56141 13.9305 6.69147 13.8766 6.81076 13.8035C6.94532 13.7211 7.06062 13.6058 7.29122 13.3752Z"
          stroke={props.color || '#626266'}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
