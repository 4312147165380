export function AllocationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_10221_15153)">
        <path
          d="M9.89648 3.2602C9.89648 3.50924 9.94554 3.75584 10.0408 3.98593C10.1361 4.21602 10.2758 4.42508 10.4519 4.60118C10.628 4.77728 10.8371 4.91697 11.0672 5.01227C11.2973 5.10757 11.5439 5.15663 11.7929 5.15663C12.042 5.15663 12.2886 5.10757 12.5186 5.01227C12.7487 4.91697 12.9578 4.77728 13.1339 4.60118C13.31 4.42508 13.4497 4.21602 13.545 3.98593C13.6403 3.75584 13.6893 3.50924 13.6893 3.2602C13.6893 3.01116 13.6403 2.76455 13.545 2.53447C13.4497 2.30438 13.31 2.09532 13.1339 1.91922C12.9578 1.74312 12.7487 1.60343 12.5186 1.50813C12.2886 1.41282 12.042 1.36377 11.7929 1.36377C11.5439 1.36377 11.2973 1.41282 11.0672 1.50813C10.8371 1.60343 10.628 1.74312 10.4519 1.91922C10.2758 2.09532 10.1361 2.30438 10.0408 2.53447C9.94554 2.76455 9.89648 3.01116 9.89648 3.2602Z"
          stroke={props.color || 'black'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.6369 9.89792V8.94969C14.6369 7.3786 13.3633 6.10498 11.7923 6.10498C10.7209 6.10498 9.7879 6.6972 9.30273 7.57212"
          stroke={props.color || 'black'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.31055 3.2602C2.31055 3.50924 2.3596 3.75584 2.4549 3.98593C2.55021 4.21602 2.6899 4.42508 2.866 4.60118C3.0421 4.77728 3.25116 4.91697 3.48124 5.01227C3.71133 5.10757 3.95793 5.15663 4.20698 5.15663C4.45602 5.15663 4.70262 5.10757 4.93271 5.01227C5.16279 4.91697 5.37185 4.77728 5.54795 4.60118C5.72405 4.42508 5.86374 4.21602 5.95905 3.98593C6.05435 3.75584 6.1034 3.50924 6.1034 3.2602C6.1034 2.75723 5.9036 2.27487 5.54795 1.91922C5.1923 1.56357 4.70994 1.36377 4.20698 1.36377C3.70401 1.36377 3.22165 1.56357 2.866 1.91922C2.51035 2.27487 2.31055 2.75723 2.31055 3.2602Z"
          stroke={props.color || 'black'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.3623 9.89792V8.94969C1.3623 7.3786 2.63592 6.10498 4.20701 6.10498C5.27833 6.10498 6.21133 6.6972 6.6965 7.57212"
          stroke={props.color || 'black'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6.10449 8.94721C6.10449 9.45017 6.30429 9.93254 6.65994 10.2882C7.01559 10.6438 7.49796 10.8436 8.00092 10.8436C8.50388 10.8436 8.98625 10.6438 9.3419 10.2882C9.69755 9.93254 9.89735 9.45017 9.89735 8.94721C9.89735 8.69817 9.8483 8.45156 9.75299 8.22148C9.65769 7.99139 9.518 7.78233 9.3419 7.60623C9.1658 7.43013 8.95674 7.29044 8.72665 7.19514C8.49657 7.09983 8.24996 7.05078 8.00092 7.05078C7.75188 7.05078 7.50527 7.09983 7.27519 7.19514C7.0451 7.29044 6.83604 7.43013 6.65994 7.60623C6.48384 7.78233 6.34415 7.99139 6.24885 8.22148C6.15354 8.45156 6.10449 8.69817 6.10449 8.94721Z"
          stroke={props.color || 'black'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.8457 14.6366C10.8457 13.0655 9.57203 11.792 8.00095 11.792C6.42986 11.792 5.15625 13.0655 5.15625 14.6366"
          stroke={props.color || 'black'}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10221_15153">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AllocationIcon;
