import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import {
  QUERY_KEYS,
  useConfiguredIssueStatus,
  useConfiguredIssueStatusMutation,
  useIssueStatuses,
} from '../api';
import { Field, Form, Formik } from 'formik';

import { useEffect, useState } from 'react';
import { useToastHook } from '../../appToast';
import { useQueryClient } from '@tanstack/react-query';
import Loader from '../../appLoader';
import { statusLabels } from '../../shared/utils';
import { SaveAndPreviousButton } from './SaveAndPreviousButton';

interface StatusMapFormProps {
  integrationId: string;
}

const initialValues = {
  issueStatusMap: {
    qa: [],
    uxDesign: [],
    development: [],
    prd: [],
    techDesign: [],
    ideation: [],
    deployment: [],
  },
};

const StatusMapForm = ({ integrationId }: StatusMapFormProps) => {
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const [initialIssueValues, setInitialIssueValues] = useState(initialValues);
  const { data: issueStatuses, isLoading: issueStatusLoading } =
    useIssueStatuses(integrationId);

  const {
    mutate: handleSubmitIssueStatus,
    isLoading: handleSubmitIssueStatusLoading,
  } = useConfiguredIssueStatusMutation();

  const {
    data: issueStatusConfigured,
    isLoading: issueStatusConfiguredLoading,
  } = useConfiguredIssueStatus(integrationId);

  useEffect(() => {
    if (
      !issueStatusConfigured?.dto ||
      !issueStatusConfigured?.dto?.issueStatusMap
    )
      return;
    setInitialIssueValues(issueStatusConfigured?.dto);
  }, [issueStatusConfigured?.dto]);

  const onSubmit = (values: any) => {
    handleSubmitIssueStatus(
      { ...values, integrationId },
      {
        onSuccess: () => {
          newToast({
            message: 'Stage Mapping done successfully!',
            status: 'success',
          });
          queryClient.invalidateQueries([QUERY_KEYS.issueStatus]);
        },
        onError: (err: any) => {
          newToast({
            message: err?.message ?? `Something went wrong!`,
            status: 'error',
          });
        },
      }
    );
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialIssueValues}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }: any) => (
          <Form>
            <Stack spacing={4}>
              {Object.keys(values.issueStatusMap).map((key, index) => (
                <HStack spacing={6} key={index}>
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                    width="150px"
                  >
                    {statusLabels[key]}:
                  </Text>
                  <Field
                    as={Select}
                    name={`issueStatusMap.${key}`}
                    placeholder="Select Status"
                    isMulti
                    useBasicStyles
                    closeMenuOnSelect={false}
                    menuPlacement="auto"
                    onChange={(selectedOption: any) => {
                      setFieldValue(
                        `issueStatusMap.${key}`,
                        selectedOption.map((item: any) => item.value)
                      );
                    }}
                    options={issueStatuses?.dto?.map((item: any) => ({
                      value: item,
                      label: item,
                    }))}
                    value={values.issueStatusMap[key]?.map((item: any) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                </HStack>
              ))}
              <Box mt={6}>
                <SaveAndPreviousButton onSubmit={() => onSubmit(values)} />
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
      {(issueStatusLoading ||
        handleSubmitIssueStatusLoading ||
        issueStatusConfiguredLoading) && <Loader />}
    </>
  );
};

export default StatusMapForm;
