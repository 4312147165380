import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import { useDeliverablesPage } from '../apis';
import { DeliverablesPage } from './devd-deliverables';

export const Deliverables = () => {
  const { data } = useDeliverablesPage();
  return (
    <>
      <MetaTags title="Deliverables | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <DeliverablesPage data={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default Deliverables;
