import {
  Box,
  Button,
  Flex,
  SkeletonText,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  EditPencilIcon,
  isHTML,
  useToastHook,
} from '@devd-client/devd/components';
import UpdateInitiativeStatusModal from '../../UpdateInitiativeModal';
import { QUERY_KEYS, usePostInitiativeUpdateStatus } from '../../../apis';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import EditInitiativeDescriptionModal from './EditInitiativeDescriptionModal';
import ReactHtmlParser from 'html-react-parser';
import { parseLinks } from '../../../helpers/Initiatives.utils';

interface OverviewProps {
  title?: string;
  label?: string;
  labelColor?: string;
  content?: string;
  readMoreBtn?: boolean;
  setActiveTab: (tab: number) => void;
  initiativeState?: any;
  isLoading?: boolean;
  type?: string;
}

export const OverviewCard = ({
  title,
  label,
  labelColor,
  content,
  readMoreBtn,
  setActiveTab,
  initiativeState,
  isLoading,
  type,
}: OverviewProps) => {
  const updateInitiativeStatusModal = useDisclosure();
  const editInitiativeDescriptionModal = useDisclosure();
  const [searchParams] = useSearchParams();
  const initiativeId = searchParams.get('id');
  const queryClient = useQueryClient();
  const [newToast] = useToastHook();

  const {
    mutate: postInitiativeUpdate,
    isLoading: postInitiativeUpdateLoading,
  } = usePostInitiativeUpdateStatus();

  const handlePostUpdateClick = (values: {
    status: string;
    summary: string;
  }) => {
    postInitiativeUpdate(
      {
        initiative: initiativeId,
        summary: values.summary,
        status: values.status,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.initiativeUpdateStatus]);
          newToast({
            message: `Initiative status updated successfully.`,
            status: 'success',
          });
          setTimeout(() => {
            updateInitiativeStatusModal.onClose();
          }, 200);
        },
        onError: () => {
          newToast({
            message: 'Something went wrong!',
            status: 'error',
          });
        },
      }
    );
  };

  return (
    <>
      {type === 'INITIATIVE' || content || isLoading ? (
        <Flex
          maxW={type === 'INITIATIVE' ? '424px' : '100%'}
          minW={type === 'INITIATIVE' ? '424px' : '100%'}
          maxH={'148px'}
          border={'1px solid'}
          borderColor={labelColor ? labelColor : 'text.tertiary '}
          rounded={'4px'}
          pt={4}
          px={4}
          pb={2}
          direction={'column'}
          gap={2}
        >
          <Flex align={'center'}>
            <Flex align={'center'} gap={1}>
              <Text
                fontSize={'sm'}
                fontWeight={'semibold'}
                color={'text.gray.300'}
              >
                {title}
              </Text>
              {type === 'INITIATIVE' ? (
                <Box
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (initiativeState) {
                      editInitiativeDescriptionModal.onOpen();
                    } else {
                      updateInitiativeStatusModal.onOpen();
                    }
                  }}
                >
                  <EditPencilIcon />
                </Box>
              ) : null}
            </Flex>
            {label ? (
              <Box
                rounded={'full'}
                bg={labelColor}
                padding={'2px 4px'}
                color={'white'}
                fontSize={'xs'}
                ml={'auto'}
              >
                {label}
              </Box>
            ) : null}
          </Flex>
          {isLoading ? (
            <SkeletonText noOfLines={readMoreBtn ? 2 : 3} />
          ) : (
            <Box
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                WebkitLineClamp: readMoreBtn ? 2 : 3,
              }}
            >
              {content ? (
                <Text color={'text.secondary2'} fontSize={'sm'}>
                  {isHTML(parseLinks(content))
                    ? ReactHtmlParser(parseLinks(content))
                    : content}
                </Text>
              ) : !content && !label && readMoreBtn ? (
                <Text color={'text.secondary2'} fontSize={'sm'}>
                  Update Latest Initiative Status
                </Text>
              ) : null}
            </Box>
          )}
          {readMoreBtn ? (
            <Button
              fontSize={'sm'}
              fontWeight={'medium'}
              color={'text.secondary2'}
              size={'sm'}
              bg={'transparent'}
              border={'1px solid'}
              borderColor={'text.tertiary '}
              rounded={'4px'}
              padding={'4px 8px'}
              w={'fit-content'}
              _hover={{ bg: 'none' }}
              onClick={() => setActiveTab(5)}
              isDisabled={!content}
              mt={'auto'}
            >
              Read More
            </Button>
          ) : null}
          {updateInitiativeStatusModal.isOpen && (
            <UpdateInitiativeStatusModal
              onSuccessAction={handlePostUpdateClick}
              headerText="Update Initiative Status"
              isOpen={updateInitiativeStatusModal.isOpen}
              onClose={updateInitiativeStatusModal.onClose}
              isSubmitLoading={postInitiativeUpdateLoading}
            />
          )}
          {editInitiativeDescriptionModal.isOpen && (
            <EditInitiativeDescriptionModal
              isOpen={editInitiativeDescriptionModal.isOpen}
              onClose={editInitiativeDescriptionModal.onClose}
              initiativeState={initiativeState}
            />
          )}
        </Flex>
      ) : null}
    </>
  );
};
