import { Button, Flex } from '@chakra-ui/react';
import { PageHeader } from '@devd-client/devd/components';
import { useNavigate } from 'react-router';
import { CreateInitiativeTabs } from '../components/CreateInitiativeTabs';

export const CreateInitiative = () => {
  const navigate = useNavigate();

  return (
    <Flex direction="column" width="100%" minH="100%">
      <Flex flexDirection={'column'} mb={4} gap={1}>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          variant="link"
          w={'fit-content'}
        >
          Back
        </Button>
        <PageHeader headingText="Create Initiatives" />
      </Flex>
      <CreateInitiativeTabs />
    </Flex>
  );
};
