import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useTheme,
} from '@chakra-ui/react';
import React from 'react';

interface ModalProps {
  isOpen: boolean;
  id: string;
  onClose: () => void;
  handleIgnore: (id: string) => void;
}

export function IgnoreEntryModal({
  isOpen,
  id,
  onClose,
  handleIgnore,
}: ModalProps) {
  const { colors } = useTheme();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'sm'} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={'xl'} color={colors.text.primary}>
          Hide Entry
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={0}>
          <Text lineHeight={'5'} fontSize={'sm'} color={colors.text.primary2}>
            This action is irreversible and will cease the tracking for this
            entry. You won't be able to undo this. Are you sure?
          </Text>
        </ModalBody>

        <ModalFooter gap={1} pt={0}>
          <Button size={'sm'} variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size={'sm'}
            variant={'error'}
            mr={3}
            onClick={() => {
              handleIgnore(id);
              onClose();
            }}
          >
            Hide
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default IgnoreEntryModal;
