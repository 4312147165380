import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';

type Props = {
  data: { [key: string]: number }[];
};

const ProgressBar = ({ data }: Props) => {
  const { todo, inProgress, done } = data[0];
  const total = todo + inProgress + done;

  const getPercentage = (value: number) => (value / total) * 100;

  return (
    <Box>
      <Flex
        w="100%"
        h="32px"
        overflow="hidden"
        bg="text.tertiary"
        position="relative"
      >
        <Tooltip label={`To Do: ${todo}`} hasArrow>
          <Box
            position="relative"
            h="100%"
            w={`${getPercentage(todo)}%`}
            bg="#DBDBFF"
          >
            {getPercentage(todo) >= 7 && (
              <Box position="absolute" top={'6px'} left={1}>
                <Text
                  color="text.primary"
                  fontFamily="heading"
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {Math.round(getPercentage(todo))}%
                </Text>
              </Box>
            )}
          </Box>
        </Tooltip>
        <Tooltip label={`In Progress: ${inProgress}`} hasArrow>
          <Box
            position="relative"
            h="100%"
            w={`${getPercentage(inProgress)}%`}
            bg="#9494FF"
          >
            {getPercentage(inProgress) >= 7 && (
              <Box position="absolute" top={'6px'} left={1}>
                <Text
                  color="white"
                  fontFamily="heading"
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {Math.round(getPercentage(inProgress))}%
                </Text>
              </Box>
            )}
          </Box>
        </Tooltip>
        <Tooltip label={`Done: ${done}`} hasArrow>
          <Box
            position="relative"
            h="100%"
            w={`${getPercentage(done)}%`}
            bg="#4D4DFF"
          >
            {getPercentage(done) >= 7 && (
              <Box position="absolute" top={'6px'} left={1}>
                <Text
                  color="white"
                  fontFamily="heading"
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  {Math.round(getPercentage(done))}%
                </Text>
              </Box>
            )}
          </Box>
        </Tooltip>
      </Flex>
    </Box>
  );
};

export default ProgressBar;
