import { Flex, Text, useTheme, Box } from '@chakra-ui/react';
import { splitTime, truncateText } from '@devd-client/devd/components';
import moment from 'moment';
import { useMemo } from 'react';
import {
  Bar,
  ResponsiveContainer,
  BarChart,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
} from 'recharts';

type Props = {
  data: any;
  showLegendOnBottom?: boolean;
};
const CustomTooltip = ({
  active,
  payload,
}: {
  active?: any;
  payload?: any;
}) => {
  if (active && payload && payload.length) {
    return (
      <Box
        outline={'none'}
        border={'1px solid #ccc'}
        padding={'3px'}
        bg={'white'}
      >
        <Text fontSize={'xs'}>{`${payload[0].payload.name}: ${splitTime(
          payload[0].value / 60
        )}`}</Text>
      </Box>
    );
  }
  return null;
};

const RenderCustomTick = (props: any) => {
  const { x, y, payload } = props;
  const { value } = payload;

  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={2} fontSize={10} textAnchor="end">
        {truncateText(value, 12)}
      </text>
    </g>
  );
};

const IssueBarChart = ({ data }: Props) => {
  return data?.length !== 0 ? (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        layout="vertical"
        data={data}
        margin={{
          bottom: -30,
          left: 25,
          right: 0,
          top: 0,
        }}
      >
        <XAxis type="number" tick={false} />
        <YAxis
          dataKey="name"
          type="category"
          tick={<RenderCustomTick />}
          interval={0}
          minTickGap={5}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          wrapperStyle={{
            border: 'none',
            outline: 'none',
          }}
          content={<CustomTooltip />}
        />
        <Bar dataKey="value" barSize={15} background={false}>
          {data.map((item: any) => (
            <Cell key={item.name} fill={item.fill} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  ) : (
    <Flex align="center" w={'full'}>
      <Text pl={20} as={'strong'} fontWeight={'semibold'}>
        NA
      </Text>
    </Flex>
  );
};

export default IssueBarChart;
