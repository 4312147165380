import {
  GridItem,
  Flex,
  Box,
  useTheme,
  IconButton,
  Text,
} from '@chakra-ui/react';

import { useState } from 'react';
import { formatMessageWithColoredPercentage } from '../shared/utils';
import { CrossIcon, InfoIcon } from '../icons';
import AppTooltip from '../appTooltip';
import ReactHtmlParser from 'html-react-parser';

type Props = {
  signalHeadingText: string;
  tooltip?: string;
};

export const ComponentBanner = ({ signalHeadingText, tooltip }: Props) => {
  const { colors } = useTheme();
  const [hideHeading, setHideHeading] = useState<boolean>(false);

  return !hideHeading ? (
    <GridItem colSpan={2}>
      <Flex
        p={2}
        mt={4}
        width="fit-content"
        align="center"
        borderRadius={'4px'}
        bg="rgba(255, 173, 0, 0.10)"
        border="1px solid"
        borderColor="warning"
      >
        <Text color="text.primary" fontFamily="heading" mr={2}>
          {formatMessageWithColoredPercentage(
            signalHeadingText,
            'text.primary'
          )}
        </Text>

        {tooltip ? (
          <AppTooltip
            trigger="hover"
            placement="right"
            content={
              <Box
                maxW="40vw"
                maxH="40vh"
                overflowY="auto"
                pr={2}
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '2px',
                    background: 'rgba(0, 0, 0, 0.05)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    '&:hover': {
                      background: 'rgba(0, 0, 0, 0.2)',
                    },
                  },
                  h1: {
                    marginTop: '4px',
                    marginBottom: '2px',
                    fontWeight: 'bold',
                  },
                  ul: {
                    marginLeft: '20px',
                    listStyleType: 'disc',
                  },
                  li: {
                    padding: '3px 0 3px 0',
                  },
                }}
              >
                {ReactHtmlParser(tooltip || '')}
              </Box>
            }
          >
            <InfoIcon />
          </AppTooltip>
        ) : null}

        <Box ml={3}>
          <IconButton
            aria-label="cross"
            size="xs"
            bg="transparent"
            onClick={() => {
              setHideHeading(true);
            }}
            icon={<CrossIcon color={colors.text.primary} />}
          />
        </Box>
      </Flex>
    </GridItem>
  ) : (
    <></>
  );
};
