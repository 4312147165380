import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import { PageHeader, useQueryState } from '@devd-client/devd/components';
import { useNavigate } from 'react-router';
import ScenarioPlannerBody from '../components/ScenarioPlanner';
import { formattedDeliverableType } from '../helpers/Initiatives.utils';

export const ScenarioPlanner = () => {
  const [deliverableId] = useQueryState('deliverableId');
  const [deliverableType] = useQueryState('deliverableType');
  const [deliverableName] = useQueryState('deliverableName');
  const navigate = useNavigate();
  const scenarioPlannerSummaryModal = useDisclosure();

  const subHeadingHtml = `
    <span style="color: #626262; font-size: 14px;">
      for <span style="color: #2A2A2F; font-weight: 500;">${deliverableName}</span>
      <span style="color: #626262; margin: 0 4px;">•</span>
      <span style="color: #626262;">${formattedDeliverableType(
        deliverableType
      )}</span>
    </span>
  `;

  return (
    <Flex direction="column" width="100%" minH="100%">
      <Flex flexDirection={'column'} mb={4} gap={1}>
        <Flex>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            variant="link"
            w={'fit-content'}
          >
            Back
          </Button>
          <Button
            variant={'custom-outline'}
            maxW={'fit-content'}
            ml={'auto'}
            onClick={() => scenarioPlannerSummaryModal.onOpen()}
          >
            View Summary
          </Button>
        </Flex>
        <PageHeader
          headingText="Scenario Planner"
          subHeadingText={subHeadingHtml}
        />
      </Flex>
      <ScenarioPlannerBody
        deliverableId={deliverableId}
        deliverableType={deliverableType}
        deliverableName={deliverableName}
        {...scenarioPlannerSummaryModal}
      />
    </Flex>
  );
};
