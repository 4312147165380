import { Box, Flex, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import {
  DataGrid,
  MetricSidebar,
  Paginate,
  PieChartShared,
  useDashboardStore,
} from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  fillColors,
  formatNumber,
} from '../../../../../../helpers/report.utils';

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
  word-break: break-all;
  cursor: pointer;

  &::after {
    content: attr(data-break-chars);
    display: inline-block;
    word-break: normal;
  }
`;

const Cell = styled.div`
  word-break: break-all;
  &::after {
    content: attr(data-break-chars);
    display: inline-block;
    word-break: normal;
  }
`;

interface ListViewProps {
  data: any;
  loading: boolean;
}

const ListView = ({ data, loading }: ListViewProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [metricName, setMetricName] = useState('');
  const [heading, setHeading] = useState('');
  const [contributor, setContributor] = useState('');
  const [count, setCount] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const rows: any[] = useMemo(() => data || [], [data]);

  const columnHelper = createColumnHelper<any>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('contributor', {
        cell: (info) => (
          <StyledLink to={`/contributors/${info.getValue()}`}>
            {info.getValue()}
          </StyledLink>
        ),
        header: 'Contributor',
        size: 120,
      }),

      columnHelper.accessor('teams', {
        cell: (info) => <Cell>{info.getValue()}</Cell>,
        header: 'Team',
        size: 100,
      }),

      columnHelper.accessor('outcomePoints', {
        cell: (info) => {
          const formattedValue = formatNumber(info.getValue());
          const isZero = formattedValue === '0';

          return (
            <Cell>
              {!isZero ? (
                <Tooltip label={'View Details'} placement="bottom-start">
                  <Text
                    textDecoration="underline"
                    cursor="pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setMetricName('OUTCOME_POINTS');
                      setContributor(info.row.original.contributor);
                      setHeading('Outcome Points');
                      setCount(
                        `${info.row.original.contributor} (${formattedValue})`
                      );
                      onOpen();
                    }}
                  >
                    {formattedValue}
                  </Text>
                </Tooltip>
              ) : (
                <Text>{formattedValue}</Text>
              )}
            </Cell>
          );
        },
        header: 'Outcome Points',
        size: 100,
      }),

      columnHelper.accessor('achievedOutcomePoints', {
        cell: (info) => {
          const formattedValue = formatNumber(info.getValue());

          const isZero = formattedValue === '0';

          return (
            <Cell>
              {!isZero ? (
                <Tooltip label={'View Details'} placement="bottom-start">
                  <Text
                    textDecoration="underline"
                    cursor="pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setMetricName('ACHIEVED_OUTCOME_POINTS');
                      setContributor(info.row.original.contributor);
                      setHeading('Achieved Outcome Points');
                      setCount(
                        `${info.row.original.contributor} (${formattedValue})`
                      );
                      onOpen();
                    }}
                  >
                    {formattedValue}
                  </Text>
                </Tooltip>
              ) : (
                <Text>{formattedValue}</Text>
              )}
            </Cell>
          );
        },
        header: 'Achieved Outcome Points',
        size: 100,
      }),

      columnHelper.accessor('timelyDeliveryPercentage', {
        cell: (info) => <Cell>{info.getValue()}</Cell>,
        header: () => 'Timely Delivery(%)',
        size: 100,
      }),

      columnHelper.accessor('sub-parReviewer', {
        cell: (info) => (
          <Flex height="180px" width="180px">
            <PieChartShared
              data={
                (info.row?.original?.subParReviewerCountForCriticalIssues !=
                  null &&
                  info.row?.original?.subParReviewerCountForOtherIssues !=
                    null &&
                  Object.entries({
                    Critical:
                      info.row?.original?.subParReviewerCountForCriticalIssues,
                    Other:
                      info.row?.original?.subParReviewerCountForOtherIssues,
                  })?.map(([key, value], index: number) => {
                    return { name: key, value, fill: fillColors[index] };
                  })) ||
                []
              }
              setMetric={setMetricName}
              setHeading={setHeading}
              setCount={setCount}
              onOpen={onOpen}
              setContributor={setContributor}
              contributor={info.row.original.contributor}
              type={'sub-parReviewer'}
            />
          </Flex>
        ),
        header: 'Sub-Par Reviewer',
        size: 150,
      }),
      columnHelper.accessor('Bug Caused By', {
        cell: (info) => (
          <Flex height="180px" width="180px">
            <PieChartShared
              data={
                (info.row.original?.bugCausedByCountForCriticalIssues != null &&
                  info.row.original?.bugCausedByCountForOtherIssues != null &&
                  Object.entries({
                    Critical:
                      info.row.original?.bugCausedByCountForCriticalIssues,
                    Other: info.row.original?.bugCausedByCountForOtherIssues,
                  })?.map(([key, value], index: number) => {
                    return { name: key, value, fill: fillColors[index] };
                  })) ||
                []
              }
              setMetric={setMetricName}
              setHeading={setHeading}
              onOpen={onOpen}
              setCount={setCount}
              setContributor={setContributor}
              contributor={info.row.original.contributor}
              type={'bugCausedBy'}
            />
          </Flex>
        ),
        header: 'Bug Caused By',
        size: 150,
      }),

      columnHelper.accessor('rankingValueAssessment', {
        cell: (info) => <Cell>{info.getValue()}</Cell>,
        header: () => 'Ranking - Value Assessment',
        size: 100,
      }),

      columnHelper.accessor('rankingWowFactor', {
        cell: (info) => <Cell>{info.getValue()}</Cell>,
        header: () => 'Ranking - Wow Factor',
        size: 100,
      }),

      columnHelper.accessor('rankingLearningHours', {
        cell: (info) => <Cell>{info.getValue()}</Cell>,
        header: () => 'Ranking - Learning Hours',
        size: 100,
      }),

      columnHelper.accessor('rankingLearningImpact', {
        cell: (info) => <Cell>{info.getValue()}</Cell>,
        header: () => 'Ranking - Learning Impact',
        size: 100,
      }),

      columnHelper.accessor('rankingFailedScripts', {
        cell: (info) => <Cell>{info.getValue()}</Cell>,
        header: () => 'Ranking - Failed Scripts',
        size: 100,
      }),

      columnHelper.accessor('incidentInProd', {
        cell: (info) => (
          <Flex align="center" height="180px" width="180px">
            {info.row?.original?.incidentReportedOnProdCritical === 0.0 &&
            info.row?.original?.incidentReportedOnProdHigh === 0.0 ? (
              '0'
            ) : (
              <PieChartShared
                data={
                  (info.row?.original?.incidentReportedOnProdCritical != null &&
                    info.row?.original?.incidentReportedOnProdHigh != null &&
                    Object.entries({
                      Critical:
                        info.row?.original?.incidentReportedOnProdCritical,
                      High: info.row?.original?.incidentReportedOnProdHigh,
                    })?.map(([key, value], index: number) => {
                      return { name: key, value, fill: fillColors[index] };
                    })) ||
                  []
                }
              />
            )}
          </Flex>
        ),
        header: 'Incident Reported - Production',
        size: 150,
      }),

      columnHelper.accessor('IncidentInTest', {
        cell: (info) => (
          <Flex align="center" height="180px" width="180px">
            {info.row?.original?.incidentReportedOnTestCritical === 0.0 &&
            info.row?.original?.incidentReportedOnTestHigh === 0.0 ? (
              '0'
            ) : (
              <PieChartShared
                data={
                  (info.row?.original?.incidentReportedOnTestCritical != null &&
                    info.row?.original?.incidentReportedOnTestHigh != null &&
                    Object.entries({
                      Critical:
                        info.row?.original?.incidentReportedOnTestCritical,
                      High: info.row?.original?.incidentReportedOnTestHigh,
                    })?.map(([key, value], index: number) => {
                      return { name: key, value, fill: fillColors[index] };
                    })) ||
                  []
                }
              />
            )}
          </Flex>
        ),
        header: 'Incident Reported - Test',
        size: 150,
      }),

      columnHelper.accessor('rankWeightage', {
        cell: (info) => {
          const formattedValue = formatNumber(info.getValue());
          return <Cell>{formattedValue}</Cell>;
        },
        header: () => 'Rank Weightage',
        size: 100,
      }),
    ],
    [data]
  );

  const { selected } = useDashboardStore();

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  return (
    <Box>
      <DataGrid
        data={rows}
        columns={columns}
        showLoader={loading}
        useMaxH
        sticky="firstCol"
      />

      {/* <Box marginLeft="-20px" my={'16px'}>
        <Paginate
          currentPage={data?.currentPage + 1}
          totalPageCount={data?.totalPages}
          pageSize={20}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box> */}
      {/* {(customFieldLoading || deleteCustomMetricLoading) && <Loader />} */}
      {isOpen && (
        <MetricSidebar
          querykey={'issueReportDetailForRecruitCRM'}
          openDrawer={isOpen}
          handleDrawerClose={onClose}
          heading={heading}
          subHeading={count}
          payload={{
            payload: {
              startDate: selected.startDate,
              endDate: selected.endDate,
            },
          }}
          currentChartMeta={{
            chartKey: metricName,
          }}
          filterBy={{
            author: contributor,
          }}
        />
      )}
    </Box>
  );
};

export default ListView;
