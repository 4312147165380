export const GitCommitIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6667 7.99999C10.6667 9.47275 9.47276 10.6667 8 10.6667C6.52724 10.6667 5.33334 9.47275 5.33334 7.99999M10.6667 7.99999C10.6667 6.52724 9.47276 5.33333 8 5.33333C6.52724 5.33333 5.33334 6.52724 5.33334 7.99999M10.6667 7.99999H14.6667M5.33334 7.99999H1.33347"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
