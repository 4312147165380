import { Box, Button, Flex, Skeleton, Text } from '@chakra-ui/react';
import {
  AppTooltip,
  Calendar,
  EditPencilIcon,
  UserIcon,
} from '@devd-client/devd/components';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  data: any;
  isLoading: boolean;
};

const StyledLink = styled(Link)`
  text-decoration: underline;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.primary};
`;

const InitiativeCol = ({ data, isLoading }: Props) => {
  const navigate = useNavigate();
  const startDate = data?.startDate
    ? moment(data.startDate).isValid()
      ? moment(data.startDate).format("DD MMM 'YY")
      : 'N/A'
    : 'N/A';
  const endDate = data?.plannedDate
    ? moment(data.plannedDate).isValid()
      ? moment(data.plannedDate).format("DD MMM 'YY")
      : 'N/A'
    : 'N/A';

  return (
    <Flex direction="column">
      {isLoading ? (
        <Skeleton height="20px" width="70%" />
      ) : (
        <Flex align="center" gap={1}>
          <StyledLink
            to={`deliverable-detail?id=${data?.id}&type=${data?.type}`}
          >
            {data?.name}
          </StyledLink>
          {data?.type === 'INITIATIVE' ? (
            <AppTooltip
              content={
                <Text fontSize="xs" color="text.secondary2">
                  Edit Initiative
                </Text>
              }
              placement="right"
              trigger="hover"
            >
              <Box
                onClick={() =>
                  navigate('/deliverables/edit', {
                    state: data,
                  })
                }
              >
                <EditPencilIcon />
              </Box>
            </AppTooltip>
          ) : null}
        </Flex>
      )}

      <Flex mt={1} mb={4} align="center" gap={1}>
        <Calendar height="16px" width="16px" />
        {isLoading ? (
          <Skeleton height="16px" width="60%" />
        ) : (
          <>
            <Text
              fontFamily="heading"
              fontWeight="normal"
              color="text.secondary2"
            >
              {startDate}
            </Text>
            <Text
              fontFamily="heading"
              fontWeight="normal"
              color="text.secondary2"
            >
              {'-'}
            </Text>{' '}
            <Text
              fontFamily="heading"
              fontWeight="normal"
              color="text.secondary2"
            >
              {endDate}
            </Text>
          </>
        )}
      </Flex>

      {isLoading ? (
        <Skeleton height="16px" width="60%" />
      ) : (
        data?.owners?.length > 0 && (
          <Flex align="center" gap={1}>
            <UserIcon height="16px" width="16px" />
            <Flex gap={1}>
              {data?.owners &&
                data?.owners?.slice(0, 1)?.map((item: string) => (
                  <Flex gap={1} key={item}>
                    <Text
                      fontFamily={'heading'}
                      color="text.secondary2"
                      fontWeight="normal"
                    >
                      {item}
                    </Text>
                  </Flex>
                ))}
              {data?.owners?.length - 1 > 0 && (
                <AppTooltip
                  content={
                    <Flex
                      fontFamily={'heading'}
                      color="text.secondary2"
                      fontWeight="normal"
                      direction={'column'}
                      gap={1.5}
                    >
                      {data?.owners?.slice(1)?.map((item: string) => (
                        <Text key={item}>{item}</Text>
                      ))}
                    </Flex>
                  }
                  placement="bottom"
                  trigger="hover"
                >
                  <Text
                    fontFamily={'heading'}
                    color="text.secondary2"
                    fontWeight="semibold"
                    textDecoration={'underline'}
                  >
                    +{data?.owners?.length - 1}
                  </Text>
                </AppTooltip>
              )}
            </Flex>
          </Flex>
        )
      )}
    </Flex>
  );
};

export default InitiativeCol;
