import { Dispatch } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '@devd-client/devd/components';

export const QUERY_KEYS = {
  initiativeUpdates: 'initiativeUpdates',
  deliverablePage: 'deliverablePage',
  initiative: 'initiative',
  initiativeArchive: 'initiativeArchive',
  allocation: 'allocation',
  allocationUpdate: 'allocationUpdate',
  updateAllocation: 'updateAllocation',
  updatePhase: 'updatePhase',
  phases: 'phases',
  initiativeDetails: 'initiativeDetails',
  initiativePost: 'initiativePost',
  teamMembers: 'teamMembers',
  members: 'members',
  initiativeUpdateStatus: 'initiativeUpdateStatus',
  deliverableData: 'deliverableData',
  allocationByMember: 'allocationByMember',
  favouriteDeliverable: 'favouriteDeliverable',
};

export const useInitiativeUpdates = (initiative: string) => {
  return useQuery(
    [QUERY_KEYS.initiativeUpdates, initiative],
    () => ENDPOINTS.getInitiativeUpdates(initiative),
    {
      select: (data) => data,
    }
  );
};

export const usePostInitiativeMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiativeUpdates],
    ENDPOINTS.postInvestmentUpdates
  );
};

export const useDeliverablesPage = () => {
  return useQuery([QUERY_KEYS.deliverablePage], ENDPOINTS.getDeliverablesPage);
};

export const useAddUpdateInitiativeMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiative],
    ENDPOINTS.addUpdateInitiative
  );
};
export const useArchiveInitiativeMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiativeArchive],
    ENDPOINTS.initiativeArchive
  );
};

export const useAllocationDetail = (id: string) => {
  return useQuery([QUERY_KEYS.allocation, id], () =>
    ENDPOINTS.getAllocationDetail(id)
  );
};

export const useAddUpdateAllocationMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.allocationUpdate],
    ENDPOINTS.addUpdateAllocation
  );
};

export const useInitiativeDetail = (initiative: string) => {
  return useQuery([QUERY_KEYS.initiativeDetails, initiative], () =>
    ENDPOINTS.getInitiativeDetails(initiative)
  );
};

export const usePostInitiative = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiativePost],
    ENDPOINTS.postInitiative
  );
};

export const useRemoveInitiative = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiativePost],
    ENDPOINTS.deleteInitiative
  );
};

export const useEditInitiative = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiativePost],
    ENDPOINTS.editInitiative
  );
};

export const useGetTeamMembers = (team: string) => {
  return useQuery([QUERY_KEYS.teamMembers, team], () =>
    ENDPOINTS.getTeamMembers(team)
  );
};

export const useGetMembers = (params?: {
  pageNo?: number;
  pageSize?: number;
  searchTerm?: string;
}) => {
  return useQuery(
    [QUERY_KEYS.members, params],
    () => ENDPOINTS.getMembers(params),
    {
      enabled: !params?.searchTerm || params.searchTerm.length >= 2,
    }
  );
};

export const useGetAllocationByMember = (initiativeId: string) => {
  return useQuery(
    [QUERY_KEYS.allocationByMember, initiativeId],
    () => ENDPOINTS.getAllocationByMember(initiativeId),
    {
      enabled: !!initiativeId,
    }
  );
};

//New endpoint
export const useUpdateAllocationMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.updateAllocation],
    ENDPOINTS.updateAllocation
  );
};

export const useGetAllocation = (initiativeName: string) => {
  return useQuery([QUERY_KEYS.allocation, initiativeName], () =>
    ENDPOINTS.getAllocation(initiativeName)
  );
};

export const useUpdatePhase = () => {
  return useCustomMutation([QUERY_KEYS.updatePhase], ENDPOINTS.updatePhase);
};

export const useGetPhases = (initiativeId: string) => {
  return useQuery([QUERY_KEYS.phases, initiativeId], () =>
    ENDPOINTS.getPhases(initiativeId)
  );
};

export const useGetInitiativeUpdateStatus = (initiativeId: string) => {
  return useQuery([QUERY_KEYS.initiativeUpdateStatus, initiativeId], () =>
    ENDPOINTS.getInitiativeUpdateStatus(initiativeId)
  );
};

export const usePostInitiativeUpdateStatus = () => {
  return useCustomMutation(
    [QUERY_KEYS.initiativeUpdateStatus],
    ENDPOINTS.postInitiativeUpdateStatus
  );
};

export const useGetDeliverableData = (id: string, type: string) => {
  return useQuery([QUERY_KEYS.deliverableData, id], () =>
    ENDPOINTS.getDeliverableData(id, type)
  );
};

export const useAddFavouriteDeliverableMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.favouriteDeliverable],
    ENDPOINTS.addFavouriteDeliverable
  );
};

export const useDeleteFavouriteDeliverableMutation = () => {
  return useCustomMutation(
    [QUERY_KEYS.favouriteDeliverable],
    ENDPOINTS.deleteFavouriteDeliverable
  );
};
