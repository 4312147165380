export function InvestmentIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_6899_177)">
        <path
          d="M7.99958 14.8478C11.7818 14.8478 14.8478 11.7818 14.8478 7.99958C14.8478 4.21742 11.7818 1.15137 7.99958 1.15137C4.21742 1.15137 1.15137 4.21742 1.15137 7.99958C1.15137 11.7818 4.21742 14.8478 7.99958 14.8478Z"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.79306 6.12694C9.73138 5.95246 9.63622 5.7938 9.51529 5.65869C9.25806 5.3713 8.88427 5.19043 8.46822 5.19043H7.38089C6.68836 5.19043 6.12695 5.75184 6.12695 6.44437C6.12695 7.03364 6.53727 7.54341 7.11293 7.66934L8.76841 8.03148C9.41332 8.17255 9.87298 8.74405 9.87298 9.40421C9.87298 10.18 9.24405 10.8095 8.46822 10.8095H7.53171C6.92007 10.8095 6.39973 10.4186 6.20689 9.87297"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 5.18991V3.78516"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 12.2134V10.8086"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6899_177">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
