import { Box } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  NewDataGrid,
  Paginate,
  useDashboardStore,
} from '@devd-client/devd/components';

import { columns } from './Columns';
import { useDeliverableTableGql } from '../../graphql';

interface InitiativeTableProps {
  search?: string;
  status?: any;
  show?: string;
}

const InitiativeTable = ({ search, status, show }: InitiativeTableProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(5);
  const [checkedItem, setCheckedItem] = useState<string[]>([]);
  const { selected, selectedTeam } = useDashboardStore();

  const { data: deliverableTableData, isFetching } = useDeliverableTableGql(
    show || '',
    status || '',
    search || '',
    selected?.startDate || '',
    selected?.endDate || '',
    selected?.sprintId || '',
    selectedTeam?.value || '',
    currentPage,
    pageSize
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [show, status, search]);

  const rows: any[] = useMemo(
    () => deliverableTableData?.deliverables || [],
    [deliverableTableData?.deliverables]
  );

  const GridColumns = useMemo(
    () => columns(isFetching, show || 'INITIATIVE'),
    [deliverableTableData?.deliverables, isFetching, show]
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  return (
    <>
      <NewDataGrid
        columns={GridColumns}
        data={rows}
        showLoader={isFetching}
        maxH={deliverableTableData?.totalPages > 1 ? '65vh' : '70vh'}
        checkedItem={checkedItem}
        setCheckedItem={setCheckedItem}
        sticky="firstCol"
      />
      <Box>
        <Paginate
          currentPage={deliverableTableData?.currentPage + 1}
          totalPageCount={deliverableTableData?.totalPages}
          pageSize={pageSize}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box>
    </>
  );
};

export default InitiativeTable;
