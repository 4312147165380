import { Flex, Text } from '@chakra-ui/react';
import { AddAllocation } from './components/AddAllocation';

interface AllocationProps {
  initiativeId: string;
  isEdit?: boolean;
  onPrevious: () => void;
}

const Allocation = ({ initiativeId, isEdit, onPrevious }: AllocationProps) => {
  return (
    <Flex flexDirection={'column'} gap={4}>
      <Flex flexDirection={'column'} gap={1}>
        <Text fontWeight={'semibold'} color={'text.gray.300'}>
          Allocation
        </Text>
        <Text fontSize={'sm'} color={'text.secondary2'}>
          Allocate resources to your initiative
        </Text>
      </Flex>

      <AddAllocation
        initiativeId={initiativeId}
        isEdit={isEdit}
        onPrevious={onPrevious}
      />
    </Flex>
  );
};

export default Allocation;
