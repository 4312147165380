import { Box, Flex } from '@chakra-ui/react';
import { DeveloperTable } from '../DeveloperTable';
import { useEffect, useMemo, useState } from 'react';
import { ScenarioChart } from '../ScenarioChart';
import { MetricDetails } from '@devd-client/devd/metric-details';
import { DeveloperSelect } from '../DeveloperTable/DeveloperSelect';
import {
  removeEmptyQueryParams,
  useQueryState,
} from '@devd-client/devd/components';

interface DetailsBottomProps {
  activeCard: string;
  excludedDevelopers: string[];
  setExcludedDevelopers: React.Dispatch<React.SetStateAction<string[]>>;
  data: any;
  graphData?: any;
  referenceLines?: any;
  keys?: any;
  contributorsAllocationLoading: boolean;
  isLoading: boolean;
  setDevelopersCount: React.Dispatch<React.SetStateAction<number | null>>;
}

const DetailsBottom = ({
  activeCard,
  excludedDevelopers,
  setExcludedDevelopers,
  data,
  graphData,
  referenceLines,
  keys,
  contributorsAllocationLoading,
  isLoading,
  setDevelopersCount,
}: DetailsBottomProps) => {
  const [developers, setDevelopers] = useState<any>();
  const [metric, setMetric] = useQueryState('metric');
  const [chartKey, setChartKey] = useQueryState('chartKey');
  const [firstOption, setFirstOption] = useQueryState('firstOption');
  const [secondOption, setSecondOption] = useQueryState('secondOption');
  const [ticketType, setTicketType] = useQueryState('ticketType');
  const [status, setStatus] = useQueryState('status');

  useEffect(() => {
    if (data) {
      const formattedDevelopers = data?.map((dev: any) => ({
        name: dev?.contributor,
        attention: dev?.calculatedAllocation,
        nonReleaseWork: 100 - dev?.calculatedAllocation,
        id: dev?.contributor,
        isActive: !excludedDevelopers?.includes(dev?.contributor),
      }));

      const manuallyAddedDevelopers =
        developers?.filter(
          (dev: any) => !data?.find((d: any) => d?.contributor === dev?.name)
        ) || [];

      const newDevelopers = [
        ...formattedDevelopers,
        ...manuallyAddedDevelopers,
      ];
      setDevelopers(newDevelopers);

      // const activeDevelopersCount = newDevelopers?.filter(
      //   (dev: any) => dev?.isActive
      // )?.length;
      // console.log(excludedDevelopers);
      // setDevelopersCount(activeDevelopersCount);
    }
  }, [data, excludedDevelopers]);

  useEffect(() => {
    if (developers) {
      const activeDevelopersCount = developers?.filter(
        (dev: any) => dev?.isActive
      )?.length;
      setDevelopersCount(activeDevelopersCount);
    }
  }, [excludedDevelopers, setDevelopersCount]);

  const handleToggleDeveloper = (developerId: string) => {
    setDevelopers((prevDevelopers: any[]) => {
      const newDevelopers = prevDevelopers?.map((dev: any) =>
        dev?.id === developerId ? { ...dev, isActive: !dev?.isActive } : dev
      );

      const activeDevelopersCount = newDevelopers?.filter(
        (dev: any) => dev?.isActive
      )?.length;
      setDevelopersCount(activeDevelopersCount);

      return newDevelopers;
    });

    setExcludedDevelopers((prevExcluded: string[]) => {
      if (prevExcluded?.includes(developerId)) {
        return prevExcluded?.filter((id) => id !== developerId);
      } else {
        return [...prevExcluded, developerId];
      }
    });
  };

  const handleAddDeveloper = (newDevelopers: any[]) => {
    setDevelopers(newDevelopers);
    const activeDevelopersCount = newDevelopers?.filter(
      (dev: any) => dev?.isActive
    )?.length;
    setDevelopersCount(activeDevelopersCount);
  };

  useEffect(() => {
    if (activeCard === 'scope') {
      setMetric('ISSUE_UPDATED');
      setChartKey('ISSUE_UPDATED');
      setTicketType('all');
      setStatus('todo');
    } else {
      setMetric('');
      removeEmptyQueryParams({ status: '' });
      removeEmptyQueryParams({ teamId: '' });
      removeEmptyQueryParams({ metric: '' });
      removeEmptyQueryParams({ chartKey: '' });
      removeEmptyQueryParams({ firstOption: '' });
      removeEmptyQueryParams({ secondOption: '' });
      removeEmptyQueryParams({ ticketType: '' });
    }
  }, [activeCard]);

  const MetricComponent = useMemo(
    () => (
      <Box>
        <MetricDetails key={metric} />
      </Box>
    ),
    [metric]
  );

  if (activeCard === 'target') {
    return (
      <ScenarioChart
        graphData={graphData}
        referenceLines={referenceLines}
        keys={keys}
        isLoading={isLoading}
      />
    );
  }

  if (activeCard === 'developers' || activeCard === 'avgAllocation') {
    return (
      <Flex direction={'column'} gap={2} mb={4}>
        {activeCard === 'developers' ? (
          <DeveloperSelect
            developers={developers || []}
            setDevelopers={handleAddDeveloper}
            placeholder="Add developers..."
          />
        ) : null}
        <DeveloperTable
          developers={developers || []}
          onToggleDeveloper={handleToggleDeveloper}
          isLoading={contributorsAllocationLoading}
          showCheckbox={activeCard === 'developers'}
        />
      </Flex>
    );
  }

  if (activeCard === 'scope') {
    return MetricComponent;
  }

  return <Flex bg="gray.100">{activeCard}</Flex>;
};

export default DetailsBottom;
