import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  allocations: Yup.array().of(
    Yup.object().shape({
      team: Yup.object().shape({
        value: Yup.string().required('Team is required'),
        label: Yup.string().required('Team is required'),
        isdisabled: Yup.boolean(),
      }),
      memberCount: Yup.number()
        .min(1, 'At least one member must be allocated')
        .required('Number of members is required'),
      members: Yup.array(),
    })
  ),
});
