import {
  Box,
  Flex,
  IconButton,
  Image,
  Skeleton,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { convertHexToRGBA } from '../../../shared/utils';
import { AlertTriangle, EliteIcon, StarIcon, Thumsup } from '../../../icons';
import AppTooltip from '../../../appTooltip';
import AITooltipLogo from '../../../shared/assets/ai-tooltip-logo.svg';
import AILogo from '../../../shared/assets/ai-icon-circle.svg';

const TopSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TagLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const TagLabel = styled.p`
  display: flex;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  letter-spacing: 0.5px;
  padding: 4px 6px;
  color: ${({ theme }) => theme.colors.success};
  background-color: ${({ color }) => color || '#f0fff4'};
  margin-right: 5px;
`;

const Heading = styled.h3`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

interface TopSectionProps {
  data: any;
  isLoading: boolean;
  openDrawer: boolean;
  setOpenDrawer: (isOpen: boolean) => void;
}

const TopSection: FC<TopSectionProps> = ({
  data,
  isLoading,
  setOpenDrawer,
  openDrawer,
}) => {
  const handleDrawerOpen = useCallback(() => {
    setOpenDrawer(true);
  }, [openDrawer]);

  return (
    <TopSectionContainer>
      {isLoading ? (
        <Skeleton width="100px" height="20px" mb={2} />
      ) : (
        <Flex>
          {data?.performance && (
            <Tooltip hasArrow label={data?.reason}>
              <Box mr={2}>
                {data?.performance === 'Elite' && (
                  <TagLabel>
                    <Box height="full" mt={-1}>
                      <EliteIcon color="Green" height="18px" width="18px" />
                    </Box>
                    <Text fontFamily={'heading'} ml={1}>
                      {data?.performance}
                    </Text>
                  </TagLabel>
                )}
                {data?.performance === 'High' && (
                  <TagLabel color={convertHexToRGBA('#0095E6' || '', 0.1)}>
                    <Box height="full" mt={'-1px'}>
                      <StarIcon width="14px" height="14px" color="#0095E6" />
                    </Box>
                    <Text color="#0095E6" fontFamily={'heading'} ml={1}>
                      {data?.performance}
                    </Text>
                  </TagLabel>
                )}
                {data?.performance === 'Medium' && (
                  <TagLabel color={convertHexToRGBA('#FF8D70' || '', 0.1)}>
                    <Box height="full" mt={'-1px'}>
                      <Thumsup height="14px" width="14px" color={'#FF8D70'} />
                    </Box>
                    <Text color={'#FF8D70'} fontFamily={'heading'} ml={1}>
                      {data?.performance}
                    </Text>
                  </TagLabel>
                )}
                {data?.performance === 'Low' && (
                  <TagLabel color={convertHexToRGBA('FF0000' || '', 0.1)}>
                    <Box height="full" mt={'-1px'}>
                      <AlertTriangle
                        height="14px"
                        width="14px"
                        color="#FF0000"
                      />
                    </Box>
                    <Text color={'red'} fontFamily={'heading'} ml={1}>
                      {data?.performance}
                    </Text>
                  </TagLabel>
                )}
              </Box>
            </Tooltip>
          )}
          {data?.tags && data?.tags?.length && (
            <TagLabels>
              {data.tags.map((item: string, idx: number) => (
                <TagLabel key={idx}>{item}</TagLabel>
              ))}
            </TagLabels>
          )}
        </Flex>
      )}

      {isLoading ? (
        <Skeleton width="200px" height="30px" />
      ) : (
        <Flex alignItems={'center'}>
          <Heading>{data?.chartMetadata?.chartTitle}</Heading>
          {data?.chartMetadata?.chartKey !== 'CUSTOM' && (
            <AppTooltip
              content={
                <Text
                  fontSize="sm"
                  color="text.secondary"
                  fontWeight="normal"
                  fontFamily="heading"
                >
                  {data?.chartMetadata?.info}{' '}
                  <Box
                    as="span"
                    display="inline-flex"
                    gap={1}
                    alignItems="center"
                  >
                    <Text
                      as="span"
                      color="blue"
                      bg="none"
                      fontSize="14"
                      display="inline"
                      borderBottom="1px solid blue"
                      fontWeight={400}
                      onClick={() => {
                        handleDrawerOpen();
                        window.analytics.track('Learn More By AI clicked', {
                          properties: {
                            metric: data?.chartMetadata?.chartTitle,
                          },
                        });
                      }}
                      cursor={'pointer'}
                    >
                      Learn More by
                    </Text>
                    <Image src={AILogo} h={5} w={5} />
                  </Box>
                </Text>
              }
              placement="right"
              trigger="hover"
            >
              <IconButton
                aria-label="ticket/open-ai-assistant"
                icon={<Image src={AITooltipLogo} w={10} />}
                _hover={{
                  transform: 'scale(1.1)',
                  transition: 'transform 0.2s ease-in-out',
                }}
                variant="unstyled"
                size={'xs'}
              />
            </AppTooltip>
          )}
        </Flex>
      )}
    </TopSectionContainer>
  );
};

export default TopSection;
