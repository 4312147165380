export const DesktopDollar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_9721_5019)">
        <path
          d="M8.86914 2.28613H1.14272C0.991166 2.28613 0.84582 2.34634 0.738657 2.4535C0.631492 2.56066 0.571289 2.70601 0.571289 2.85756V12.0004C0.571289 12.152 0.631492 12.2973 0.738657 12.4045C0.84582 12.5116 0.991166 12.5718 1.14272 12.5718H14.857C15.0085 12.5718 15.1539 12.5116 15.2611 12.4045C15.3682 12.2973 15.4284 12.152 15.4284 12.0004V10.8576"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.85672 12.5713L5.71387 15.4284"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.14258 12.5713L10.2854 15.4284"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.57129 15.4287H11.4284"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.342 3.1106C15.2751 2.92133 15.1719 2.74923 15.0407 2.60267C14.7617 2.29092 14.3563 2.09473 13.9049 2.09473H12.7254C11.9742 2.09473 11.3652 2.70371 11.3652 3.45493C11.3652 4.09414 11.8103 4.64711 12.4348 4.78371L14.2305 5.17654C14.9301 5.32957 15.4287 5.9495 15.4287 6.66559C15.4287 7.50717 14.7465 8.18997 13.9049 8.18997H12.8891C12.2255 8.18997 11.6612 7.76594 11.4519 7.1741"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3975 2.09509V0.571289"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3975 9.71424V8.19043"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.14286"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9721_5019">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
