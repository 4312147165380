import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  durationData,
  PageHeader,
  PlusIcon,
  removeEmptyQueryParams,
  useDashboardStore,
  useQueryState,
} from '@devd-client/devd/components';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import InitiativeTable from '../components/InitiativeTable';
import Toolbar from '../components/Toolbar';
import { viewSwitchObj } from '../helpers/Initiatives.utils';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useAuth } from '@devd-client/devd/auth';
import moment from 'moment';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DeliverablesPage = (data: any) => {
  const navigate = useNavigate();
  const [appState, dispatch] = useAuth();

  const [status, setStatus] = useQueryState('status');
  const [search, setSearch] = useQueryState('search');
  const [team, setTeam] = useQueryState('team');
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [periodOptions, setPeriodOptions] = useState<any>(durationData);
  const { selected, setSelected, selectedTeam, setSelectedTeam } =
    useDashboardStore();

  const [selectedOption, setSelectedOption] = useState(() => {
    const savedView = localStorage.getItem('deliverables-view');
    return savedView || 'Epic';
  });
  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    localStorage.setItem('deliverables-view', value);
    switch (value) {
      case 'Initiatives':
        setViewSwitch('INITIATIVE');
        break;
      case 'Epic':
        setViewSwitch('EPIC');
        break;
      case 'Releases':
        setViewSwitch('RELEASE');
        break;
      default:
        setViewSwitch('INITIATIVE');
    }
  };

  const [searchInput, setSearchInput] = useState<string>('');

  const [realStatus, setRealStatus] = useState<{
    value: string;
    label: string;
  }>({ value: '', label: 'All' });

  const [viewSwitch, setViewSwitch] = useState(() => {
    const savedView = localStorage.getItem('deliverables-view');
    switch (savedView) {
      case 'Initiatives':
        return 'INITIATIVE';
      case 'Epic':
        return 'EPIC';
      case 'Releases':
        return 'RELEASE';
      default:
        return viewSwitchObj[1].tab;
    }
  });

  const [show, setShow] = useQueryState('show');

  useEffect(() => {
    if (typeof realStatus.value !== 'undefined') {
      realStatus.value === ''
        ? removeEmptyQueryParams({ status: '' })
        : setStatus(realStatus.value);
    }

    if (typeof searchInput !== 'undefined') {
      searchInput === ''
        ? removeEmptyQueryParams({ search: '' })
        : setSearch(searchInput);
    }

    if (typeof viewSwitch !== 'undefined') {
      setShow(viewSwitch);
    }
  }, [realStatus.value, searchInput, viewSwitch]);

  useEffect(() => {
    status && setRealStatus({ value: status, label: status });

    search && setSearchInput(search);
  }, []);

  useEffect(() => {
    removeEmptyQueryParams({ status: '', search: '' });
    setSearchInput('');
    setRealStatus({ value: '', label: '' });
  }, [viewSwitch]);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Organisation'
        ? setTeam('Organisation')
        : setTeam(selectedTeam.value);
    }
  }, [selected, selectedTeam.value]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'Organisation'
          ? { value: '', label: 'Organisation' }
          : { value: team, label: team }
      );

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom' ||
              el.duration === 'Today' ||
              el.duration === 'Month' ||
              el.duration === 'Quarter'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  return (
    <>
      <Flex direction="column" width="100%" minH="100%">
        <PageHeader
          headingText="Deliverables"
          leftComponent={
            <Box ml={4}>
              <Menu>
                <MenuButton
                  as={Button}
                  size="sm"
                  rightIcon={<ChevronDownIcon />}
                  variant="outline"
                  borderColor="gray.200"
                  bg="white"
                  _hover={{
                    borderColor: 'primary',
                    bg: 'gray.50',
                  }}
                  w="120px"
                  fontSize="sm"
                  fontWeight={'medium'}
                  textAlign="left"
                  display="flex"
                  justifyContent="space-between"
                  px={3}
                  color="text.primary2"
                  rounded={'md'}
                >
                  {selectedOption}
                </MenuButton>
                <MenuList
                  border="1px solid"
                  borderColor="gray.200"
                  boxShadow="sm"
                  minW={'unset'}
                  w={'120px'}
                  py={1}
                  zIndex={100}
                >
                  <MenuItem
                    bg={selectedOption === 'Epic' ? '#EEF' : 'white'}
                    color={selectedOption === 'Epic' ? 'primary' : 'gray.700'}
                    _hover={{
                      bg: selectedOption === 'Epic' ? '#E6E6FF' : '#F4F4FF',
                      color: 'primary',
                    }}
                    fontSize="sm"
                    onClick={() => handleOptionChange('Epic')}
                  >
                    Epic
                  </MenuItem>
                  <MenuItem
                    bg={selectedOption === 'Initiatives' ? '#EEF' : 'white'}
                    color={
                      selectedOption === 'Initiatives' ? 'primary' : 'gray.700'
                    }
                    _hover={{
                      bg:
                        selectedOption === 'Initiatives'
                          ? '#E6E6FF'
                          : '#F4F4FF',
                      color: 'primary',
                    }}
                    fontSize="sm"
                    onClick={() => handleOptionChange('Initiatives')}
                  >
                    Initiatives
                  </MenuItem>
                  <MenuItem
                    bg={selectedOption === 'Releases' ? '#EEF' : 'white'}
                    color={
                      selectedOption === 'Releases' ? 'primary' : 'gray.700'
                    }
                    _hover={{
                      bg: selectedOption === 'Releases' ? '#E6E6FF' : '#F4F4FF',
                      color: 'primary',
                    }}
                    fontSize="sm"
                    onClick={() => handleOptionChange('Releases')}
                  >
                    Releases
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          }
          component={
            <ButtonContainer>
              {show === 'INITIATIVE' ? (
                <Button
                  variant="custom-outline"
                  size="sm"
                  onClick={() => navigate('create')}
                >
                  <Flex align={'center'} gap={1}>
                    <PlusIcon />
                    Create Initiative
                  </Flex>
                </Button>
              ) : null}
            </ButtonContainer>
          }
        />
        <Box mt={2}>
          <Toolbar
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            handleRealStatus={setRealStatus}
            realStatus={realStatus}
            searchPlaceholder={
              show === 'INITIATIVE'
                ? 'Initiative'
                : show === 'EPIC'
                ? 'Epic'
                : 'Release'
            }
            hideOptions={show !== 'INITIATIVE'}
          />
          <InitiativeTable
            search={searchInput}
            status={realStatus.value}
            show={show}
          />
        </Box>
      </Flex>
    </>
  );
};
