import { FC, useMemo, useState } from 'react';
import { Flex, Text, Button } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DataGrid } from '../../../GridTable/DataGrid';
import { SlideBar } from '../../../appSlideBar';
import { apiClient } from '@devd-client/api';
import Loader from '../../../appLoader';
import DownloadDataButton from '../../../DownloadDataButton';

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primary};
`;

interface ActiveDaysSideBarProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  team: string;
  contributorData: any;
  isFetching: boolean;
  subHeading: string;
  startDate?: string;
  endDate?: string;
}

export const ActiveDaysSideBar: FC<ActiveDaysSideBarProps> = ({
  openDrawer,
  handleDrawerClose,
  team,
  contributorData,
  isFetching,
  subHeading,
  startDate,
  endDate,
}) => {
  const rows: any[] = useMemo(
    () => contributorData?.data || [],
    [contributorData?.data]
  );

  const columnHelper = createColumnHelper<any>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('email', {
        cell: (info) => {
          return (
            <StyledLink to={`/contributors/${info?.row?.original?.email}`}>
              <Text isTruncated width="250px">
                {info?.getValue()}
              </Text>
            </StyledLink>
          );
        },
        header: 'Email',
      }),
      columnHelper.accessor('activeDays', {
        cell: (info) => info?.getValue(),
        header: 'Active Days',
      }),
    ],
    [contributorData?.data]
  );

  const [pending, setPending] = useState(false);

  const download = function (data: any) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window?.URL?.createObjectURL(blob);

    const a = document?.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute(
      'download',
      `${localStorage?.getItem('orgId')} - ${
        team === '' ? 'Organisation' : team
      }`
    );
    a.click();
  };

  const downloadCSV = async () => {
    setPending(true);
    apiClient(`/v1/csv/contributors-active-days`, {
      method: 'POST',
      headers: {
        authorization: `bearer ${localStorage?.getItem('token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orgId: localStorage.getItem('orgId'),
        startDate: startDate,
        endDate: endDate,
        teamId: team,
      }),
    })
      .then((res: any) => {
        setPending(false);
        download(res);
      })
      .catch((err) => {
        setPending(false);
      });
  };

  return (
    <SlideBar
      width={'440px'}
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={`Average Active Days`}
      SideDrawerBodyHeight={false}
      sideBarSubHeading={
        <Text
          fontFamily="heading"
          fontSize="sm"
          color={'text.secondary'}
          fontWeight={'medium'}
        >
          {subHeading}
        </Text>
      }
      content={
        <Flex flexDirection="column" height="100%">
          <Flex flex="1" minHeight="0" flexDirection="column">
            <Flex flex="1" overflowY="auto">
              <DataGrid
                showLoader={isFetching}
                columns={columns}
                data={rows}
                maxH="100%"
                sticky="none"
              />
            </Flex>
          </Flex>
          <Flex mt={2}>
            <DownloadDataButton downloadCSV={downloadCSV} pending={pending} />
          </Flex>
          {pending && <Loader />}
        </Flex>
      }
    />
  );
};

export default ActiveDaysSideBar;
