import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useTheme,
} from '@chakra-ui/react';
import {
  PageHeader,
  MetaTags,
  PageToolbar,
  TeamMenu,
  DateFilter,
  eliminateHtmlTags,
  useDebounce,
} from '@devd-client/devd/components';
import { FaCodeBranch } from 'react-icons/fa';
import { FC, useEffect, useState } from 'react';
import { AuthActionTypes, getUser } from '@devd-client/devd/auth';
import { AcceptanceByLanguage } from '../components/Copilot/AcceptanceByLanguage';
import { CopilotActiveUsers } from '../components/Copilot/CopilotActiveUsers';
import { CopilotLinesAccepted } from '../components/Copilot/CopilotLinesAccepted';
import { CopilotChatAcceptance } from '../components/Copilot/CopilotChatAcceptance';
import { useCopilotDashboard } from '../hooks/useCopilotDashboard';

export const Copilot: FC = () => {
  const { selectedTeam, setSelectedTeam, appState, dispatch } =
    useCopilotDashboard();
  const { colors } = useTheme();
  const [branches, setBranches] = useState('');

  const [searchTerm, setSearchTerm] = useState<string>(branches);
  const debouncedSearchTerm = useDebounce(searchTerm);

  useEffect(() => {
    setBranches(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <>
      <MetaTags title="Copilot | Dashboard | Devdynamics" />

      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <Box>
          <PageHeader headingText={'Copilot'} mb={'10px'} />
          <PageToolbar
            leftComp={
              <Flex align="flex-end" gap={3}>
                <TeamMenu
                  selectedTeam={selectedTeam}
                  handleSelectedTeam={setSelectedTeam}
                  AuthActionTypes={AuthActionTypes}
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                />
                {(localStorage.getItem('orgId') === 'YYUHAYDAT' || localStorage.getItem('orgId') === 'OIDPPE') && (
                  <Flex gap={1} direction="column">
                    <Text
                      fontSize="xxs"
                      fontWeight="semibold"
                      color="text.secondary"
                    >
                      Applied Only for Avg. Contributors and Avg. Lines Added
                      Metrics
                    </Text>

                    <InputGroup size={'sm'}>
                      <InputLeftElement pointerEvents="none">
                        <FaCodeBranch color={colors.text.secondary} />
                      </InputLeftElement>
                      <Input
                        id="input"
                        name="input"
                        size={'sm'}
                        placeholder={'Contributed branch'}
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(eliminateHtmlTags(e.target.value));
                        }}
                      />
                    </InputGroup>
                  </Flex>
                )}
              </Flex>
            }
            rightComp={
              <DateFilter
                getUser={getUser}
                appState={appState}
                dispatch={dispatch}
                AuthActionTypes={AuthActionTypes}
              />
            }
          />
          <AcceptanceByLanguage branches={branches} />
          <CopilotActiveUsers branches={branches} />
          <CopilotLinesAccepted branches={branches} />
          <CopilotChatAcceptance branches={branches} />
        </Box>
      </Box>
    </>
  );
};

export default Copilot;
