import { Box, Button, Divider, Flex, Text, useTheme } from '@chakra-ui/react';
import { PlusIcon, useToastHook } from '@devd-client/devd/components';
import { Formik, Form, FormikHelpers, FieldArray } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DatePickerWithConfig } from './DatePickerWithConfig';
import { validationSchema } from './validationSchema';
import { PhasesFormValues } from '../../helpers/Initiatives.types';
import {
  convertDDMMYYYYToDate,
  generateId,
  getFormattedDate,
} from '../../helpers/Initiatives.utils';
import {
  useGetDeliverableData,
  useGetPhases,
  useUpdatePhase,
} from '../../apis';

interface AddPhaseProps {
  initiativeId: string;
  isEdit?: boolean;
  onNext: () => void;
  onPrevious: () => void;
}

const getInitialValues = (
  isEdit: boolean,
  phasesData: any
): PhasesFormValues => {
  const defaultValues: PhasesFormValues = {
    phases: [],
  };

  if (!isEdit || !phasesData?.dto) {
    return defaultValues;
  }

  return {
    phases: phasesData?.dto?.map((phase: any) => ({
      id: generateId('phase'),
      name: phase.phaseName,
      plannedDate: phase.plannedEndDate
        ? new Date(phase.plannedEndDate.split('/').reverse().join('-'))
        : undefined,
      actualDate: phase.actualEndDate
        ? new Date(phase.actualEndDate.split('/').reverse().join('-'))
        : undefined,
      owners: phase.owners || [],
    })),
  };
};

export const AddPhase = ({
  initiativeId,
  isEdit,
  onNext,
  onPrevious,
}: AddPhaseProps) => {
  const { colors } = useTheme();
  const [isFocused, setIsFocused] = useState<string | null>(null);
  const { mutate: handleUpdatePhase, isLoading: handleUpdatePhaseLoading } =
    useUpdatePhase();
  const { data: initiativeDetails } = useGetDeliverableData(
    initiativeId,
    'INITIATIVE'
  );
  const { data: phasesData } = useGetPhases(initiativeId);
  const [newToast] = useToastHook();
  const formRef = useRef<any>(null);

  const formKey = useMemo(
    () =>
      `${initiativeId}-${phasesData ? JSON.stringify(phasesData) : 'empty'}`,
    [initiativeId, phasesData]
  );

  const initialValues = useMemo(
    () => getInitialValues(isEdit || false, phasesData),
    [isEdit, phasesData]
  );
  useEffect(() => {
    if (formRef.current) {
      formRef.current.resetForm({ values: initialValues });
    }
  }, [phasesData, initialValues]);

  const handleSubmit = async (
    values: PhasesFormValues,
    { setSubmitting }: FormikHelpers<PhasesFormValues>
  ) => {
    try {
      const initiativeStartDate = convertDDMMYYYYToDate(
        initiativeDetails?.dto?.detail?.startDate
      );
      const initiativePlannedDate = convertDDMMYYYYToDate(
        initiativeDetails?.dto?.detail?.plannedDate
      );

      if (!initiativeStartDate || !initiativePlannedDate) return;

      const hasInvalidDates = values.phases.some((phase) => {
        let plannedDateToCheck = phase.plannedDate;
        let actualDateToCheck = phase.actualDate;

        if (plannedDateToCheck) {
          plannedDateToCheck = new Date(plannedDateToCheck);
          plannedDateToCheck.setHours(0, 0, 0, 0);
        }
        if (actualDateToCheck) {
          actualDateToCheck = new Date(actualDateToCheck);
          actualDateToCheck.setHours(0, 0, 0, 0);
        }

        if (plannedDateToCheck) {
          if (
            plannedDateToCheck < initiativeStartDate ||
            plannedDateToCheck > initiativePlannedDate
          ) {
            return true;
          }
        }
        if (actualDateToCheck) {
          if (
            actualDateToCheck < initiativeStartDate ||
            actualDateToCheck > initiativePlannedDate
          ) {
            return true;
          }
        }
        return false;
      });

      if (hasInvalidDates) {
        newToast({
          message:
            'Phase dates must be between initiative start date and planned date',
          status: 'error',
        });
        setSubmitting(false);
        return;
      }
      const payload = values.phases.map((phase) => ({
        phaseName: phase.name,
        plannedEndDate: phase.plannedDate
          ? getFormattedDate(phase.plannedDate)
          : '',
        actualEndDate: phase.actualDate
          ? getFormattedDate(phase.actualDate)
          : '',
        owners: phase.owners,
      }));

      handleUpdatePhase(
        {
          initiativeId: initiativeId,
          phasesList: payload,
        },
        {
          onSuccess: () => {
            newToast({
              message: 'Phase updated successfully!',
              status: 'success',
            });
            onNext();
          },
          onError: (error) => {
            newToast({
              message: 'Something went wrong!',
              status: 'error',
            });
          },
        }
      );
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      key={formKey}
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, isSubmitting, errors, touched }) => (
        <Form>
          <Box>
            <FieldArray name="phases">
              {({ push: pushPhase, remove: removePhase }) => (
                <>
                  {values.phases.map((phase, phaseIndex) => (
                    <Box
                      key={phase.id}
                      mb={phaseIndex === values.phases.length - 1 ? 8 : 0}
                    >
                      <Text
                        fontSize={'sm'}
                        fontWeight={'semibold'}
                        color={'text.secondary2'}
                        mb={2}
                      >
                        Phase {phaseIndex + 1}
                      </Text>
                      <DatePickerWithConfig
                        id={phase.id}
                        plannedDate={phase.plannedDate}
                        actualDate={phase.actualDate}
                        isFocused={isFocused === phase.id}
                        onFocus={() => setIsFocused(phase.id)}
                        onBlur={() => setIsFocused(null)}
                        phaseName={phase.name}
                        onPhaseNameChange={(name) =>
                          setFieldValue(`phases.${phaseIndex}.name`, name)
                        }
                        onPlannedDateChange={(date) =>
                          setFieldValue(
                            `phases.${phaseIndex}.plannedDate`,
                            date
                          )
                        }
                        onActualDateChange={(date) =>
                          setFieldValue(`phases.${phaseIndex}.actualDate`, date)
                        }
                        onDelete={() => removePhase(phaseIndex)}
                        plannedPlaceholder="Add Planned End Date"
                        actualPlaceholder="Add Actual End Date"
                        phaseNameFieldName={`phases.${phaseIndex}.name`}
                        ownersFieldName={`phases.${phaseIndex}.owners`}
                        plannedFieldName={`phases.${phaseIndex}.plannedDate`}
                        actualFieldName={`phases.${phaseIndex}.actualDate`}
                        touched={touched}
                        errors={errors}
                        colors={colors}
                        selectedOwners={phase.owners}
                        onOwnersChange={(newOwners) =>
                          setFieldValue(
                            `phases.${phaseIndex}.owners`,
                            newOwners
                          )
                        }
                      />
                      {phaseIndex !== values.phases.length - 1 ? (
                        <Divider my={12} />
                      ) : null}
                    </Box>
                  ))}

                  <Button
                    mt={4}
                    backgroundColor="#F9F8F8"
                    leftIcon={<PlusIcon color="#2A2A2F" />}
                    border="1px solid #E0E0E0"
                    fontSize={'sm'}
                    fontWeight={'semibold'}
                    color={'text.gray.300'}
                    borderRadius="4px"
                    onClick={() =>
                      pushPhase({
                        id: generateId('phase'),
                        name: '',
                        plannedDate: undefined,
                        actualDate: undefined,
                        owners: [],
                      })
                    }
                  >
                    Add New Phase
                  </Button>
                </>
              )}
            </FieldArray>
          </Box>
          <Divider my={4} />
          <Flex gap={2}>
            <Button variant={'filled'} onClick={onPrevious}>
              Previous
            </Button>
            <Button
              type="submit"
              variant={'filled'}
              isLoading={isSubmitting || handleUpdatePhaseLoading}
              isDisabled={isSubmitting || handleUpdatePhaseLoading}
            >
              Save & Next
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
