export function Arrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 8.00004L9.33333 3.33337V6.00004H2.53333C2.34665 6.00004 2.25331 6.00004 2.182 6.03637C2.11928 6.06833 2.06829 6.11932 2.03633 6.18204C2 6.25335 2 6.34669 2 6.53337V9.46671C2 9.65339 2 9.74673 2.03633 9.81804C2.06829 9.88076 2.11928 9.93175 2.182 9.96371C2.25331 10 2.34665 10 2.53333 10H9.33333V12.6667L14 8.00004Z"
        stroke="#2A2A2F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Arrow;
