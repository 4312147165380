import {
  Box,
  Button,
  Flex,
  IconButton,
  Skeleton,
  Spinner,
  Tooltip,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { BiCalendarCheck, BiEdit } from 'react-icons/bi';
import { IoIosStar, IoIosStarOutline } from 'react-icons/io';
import {
  useAddFavouriteDeliverableMutation,
  useDeleteFavouriteDeliverableMutation,
} from '../../../apis';
import { useToastHook } from '@devd-client/devd/components';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import { useState } from 'react';

interface ActionsColProps {
  data: any;
  scope: any;
  isLoading: boolean;
}

const ActionsCol = ({ data, scope, isLoading }: ActionsColProps) => {
  const navigate = useNavigate();
  const [appState, dispatch] = useAuth();
  const [getUserLoading, setGetUserLoading] = useState(false);
  const favouriteDeliverables = JSON.parse(
    appState?.user?.dto?.favouriteDeliverables || '[]'
  );
  const isFavorite = favouriteDeliverables?.some(
    (deliverable: any) => deliverable?.id === data?.id
  );
  const [newToast] = useToastHook();
  const {
    mutate: addFavouriteDeliverableMutation,
    isLoading: addFavouriteDeliverableLoading,
  } = useAddFavouriteDeliverableMutation();
  const {
    mutate: deleteFavouriteDeliverableMutation,
    isLoading: deleteFavouriteDeliverableLoading,
  } = useDeleteFavouriteDeliverableMutation();

  const handleAddFavouriteDeliverable = () => {
    addFavouriteDeliverableMutation(
      { data: { type: data?.type, id: data?.id } },
      {
        onSuccess: () => {
          setGetUserLoading(true);
          getUser()
            .then((res: any) => {
              setGetUserLoading(false);
              dispatch({
                type: AuthActionTypes.AUTH_READY,
                payload: { ...appState.user, ...res },
              });
            })
            .catch((err: any) => {
              newToast({
                message: err?.message ?? 'Something went wrong',
                status: 'error',
              });
              setGetUserLoading(false);
            });
          newToast({
            status: 'success',
            message: 'Added to Favourites',
          });
        },
        onError: () => {
          newToast({
            status: 'error',
            message: 'Failed to add to Favourites',
          });
        },
      }
    );
  };

  const handleDeleteFavouriteDeliverable = () => {
    deleteFavouriteDeliverableMutation(
      { data: { id: data?.id } },
      {
        onSuccess: () => {
          setGetUserLoading(true);
          getUser()
            .then((res: any) => {
              setGetUserLoading(false);
              dispatch({
                type: AuthActionTypes.AUTH_READY,
                payload: { ...appState.user, ...res },
              });
            })
            .catch((err: any) => {
              newToast({
                message: err?.message ?? 'Something went wrong',
                status: 'error',
              });
              setGetUserLoading(false);
            });
          newToast({
            status: 'success',
            message: 'Removed from Favourites',
          });
        },
        onError: () => {
          newToast({
            status: 'error',
            message: 'Failed to remove from Favourites',
          });
        },
      }
    );
  };
  return (
    <Flex direction={'row'}>
      {isLoading ? (
        <Skeleton height={'20px'} width={'100%'} />
      ) : (
        <>
          {scope?.completedTasks !== scope?.totalTasks ? (
            <Tooltip hasArrow label="Plan">
              <Box>
                <IconButton
                  aria-label="plan"
                  bg="transparent"
                  onClick={() =>
                    navigate(
                      `/deliverables/scenario-planner?deliverableId=${data?.id}&deliverableType=${data?.type}&deliverableName=${data?.name}`
                    )
                  }
                  color="primary"
                  icon={<BiCalendarCheck />}
                />
              </Box>
            </Tooltip>
          ) : null}
          {data?.type === 'INITIATIVE' ? (
            <Tooltip hasArrow label="Edit">
              <IconButton
                bg="transparent"
                onClick={() =>
                  navigate('/deliverables/edit', {
                    state: data,
                  })
                }
                color="text.secondary3"
                aria-label="plan"
                icon={<BiEdit />}
              />
            </Tooltip>
          ) : null}
          <Tooltip
            hasArrow
            label={isFavorite ? 'Unmark Favorite' : 'Mark as Favorite'}
          >
            <IconButton
              bg="transparent"
              onClick={() => {
                if (isFavorite) {
                  handleDeleteFavouriteDeliverable();
                } else {
                  handleAddFavouriteDeliverable();
                }
              }}
              color="text.secondary3"
              aria-label="plan"
              gap={1}
            >
              {isFavorite ? (
                <>
                  {deleteFavouriteDeliverableLoading || getUserLoading ? (
                    <Spinner size="xs" />
                  ) : (
                    <IoIosStar />
                  )}
                </>
              ) : (
                <>
                  {addFavouriteDeliverableLoading || getUserLoading ? (
                    <Spinner size="xs" />
                  ) : (
                    <IoIosStarOutline />
                  )}
                </>
              )}
            </IconButton>
          </Tooltip>
        </>
      )}
    </Flex>
  );
};

export default ActionsCol;
