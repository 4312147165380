import { Flex, Text } from '@chakra-ui/react';
import ScopeDetails from '../../ScopeDetails';

interface ScopeCardProps {
  scopeData: any;
  isLoading?: boolean;
}

export const ScopeCard = ({ scopeData, isLoading }: ScopeCardProps) => {
  return (
    <Flex
      maxW={'424px'}
      minW={'424px'}
      minH={'276px'}
      border={'1px solid'}
      borderColor={'text.tertiary '}
      rounded={'4px'}
      pt={4}
      px={4}
      pb={2}
      direction={'column'}
      gap={2}
    >
      <Text fontSize={'sm'} fontWeight={'semibold'} color={'text.gray.300'}>
        Total Scope
      </Text>
      <ScopeDetails data={scopeData} isLoading={isLoading} />
    </Flex>
  );
};
