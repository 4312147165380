export function Bullseye(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="9" cy="9" r="7.335" stroke="#2A2A2F" strokeWidth="1.33" />
      <circle cx="9" cy="9" r="3.335" stroke="#2A2A2F" strokeWidth="1.33" />
    </svg>
  );
}

export default Bullseye;
