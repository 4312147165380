import { Box } from '@chakra-ui/react';
import { useQueryState } from '@devd-client/devd/components';
import { MetricDetails } from '@devd-client/devd/metric-details';
import { useEffect, useMemo } from 'react';

export const IssueTab = () => {
  const [chartKey, setChartKey] = useQueryState('chartKey');
  const [metric, setMetric] = useQueryState('metric');
  const [ticketType, setTicketType] = useQueryState('ticketType');
  const [tableType, setTableType] = useQueryState('tableType');

  useEffect(() => {
    if (!metric) {
      setMetric('ISSUE_UPDATED');
      setChartKey('ISSUE_UPDATED');
      setTableType('ISSUE');
      setTicketType('all');
    }
  }, []);

  const MetricComponent = useMemo(
    () => (
      <Box>
        <MetricDetails key={metric} />
      </Box>
    ),
    [metric]
  );

  return MetricComponent;
};
