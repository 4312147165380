import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { ChakraStylesConfig, Select, components } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { ErasorIcon, SearchIcon } from '../icons';
import './style.css';
import { useFetchTeamList } from './api';

const getPaddingLeft = (options: any[], label: string) => {
  return options.some((item: any) => item.label === label) ? '' : '28px';
};

const Option = (props: any) => {
  const paddingLeft = getPaddingLeft(props.options, props.label);

  return (
    <Box
      pl={paddingLeft}
      style={{
        pointerEvents:
          props.isChecked && props.label === 'All Teams' ? 'none' : 'all',
      }}
    >
      <components.Option {...props}>
        <input
          type="checkbox"
          disabled={props.isChecked && props.label === 'All Teams'}
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </Box>
  );
};

const CustomDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <SearchIcon height="16px" width="16px" />
    </components.DropdownIndicator>
  );
};

const chakraStyles: ChakraStylesConfig = {
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 9999,
      fontFamily: 'heading',
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      boxShadow: 'none',
    };
  },
  menuList: (provided, state) => {
    return {
      ...provided,
      bg: 'white',
      border: 'none',
      minHeight: '300px',
      boxShadow: 'none',
    };
  },

  option: (provided, state) => ({
    ...provided,
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),

  placeholder: (provided) => ({
    ...provided,
    color: 'text.secondary2',
  }),
};

type Props = {
  children: React.ReactNode;
  noDefault: boolean;
  appState: any;
  selectedTeams: any;
  setSelectedTeams: any;
  isChecked?: boolean;
};

export const CustomTeamFilter = ({
  noDefault,
  appState,
  children,
  selectedTeams,
  setSelectedTeams,
  isChecked = false,
}: Props) => {
  const { data: teamData } = useFetchTeamList();
  const [state, setState] = useState({ optionSelected: null });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleChange = (selected: any) => {
    const updatedSelection = selected.reduce((acc: any[], item: any) => {
      if (item.value === 'all') {
        return [item, ...acc];
      }
      return [...acc, item];
    }, []);

    setState({
      optionSelected: updatedSelection,
    });
  };

  useEffect(() => {
    setState({ optionSelected: selectedTeams });
  }, [selectedTeams]);

  const teamsOptions: any = [];

  teamData?.dto?.forEach((team: any) => {
    teamsOptions.push({
      value: team.name,
      label: team.name,
      id: team.name,
      memberCount: team.memberCount,
    });

    if (team.subTeams.length >= 1) {
      teamsOptions.push({
        value: team.name,
        label: team.name,
        memberCount: team.memberCount,
        options: team.subTeams.map((item: any) => ({
          id: team.name,
          value: item.name,
          label: item.name,
          memberCount: item.memberCount,
        })),
      });
    }
  });

  const options = noDefault
    ? teamsOptions
    : [{ value: 'all', label: 'All Teams', id: 'all' }, ...teamsOptions];

  const targetSubTeam = options?.filter(
    (team: any) =>
      team.options &&
      team.options?.some(
        (subTeam: any) => subTeam.value === appState?.user?.dto?.team
      )
  );

  const targetTeam = options?.filter(
    (el: any) =>
      el.value === appState?.user?.dto?.team ||
      el.value === targetSubTeam[0]?.value
  );

  const newOptions = [
    { value: 'all', label: 'All Teams', id: 'all' },
    ...(targetTeam?.length ? targetTeam : []),
    ...options.filter(
      (team: any) =>
        team.value !== 'all' && team.value !== targetTeam?.[0]?.value
    ),
  ];

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <button
          style={{ cursor: 'pointer', width: '100%', all: 'unset' }}
          onClick={onOpen}
        >
          {children}
        </button>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />

        <PopoverCloseButton />
        <PopoverBody height="400px">
          <Select
            classNamePrefix={'an-simple-select'}
            options={newOptions}
            isMulti
            placeholder="Search Teams"
            chakraStyles={chakraStyles}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
              GroupHeading: () => <div></div>,
              Option: (props) => <Option {...props} isChecked={isChecked} />,
              DropdownIndicator: CustomDropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            menuIsOpen
            onChange={handleChange}
            value={state.optionSelected}
            controlShouldRenderValue={false}
            isClearable={false}
            backspaceRemovesValue={false}
          />
          <Box mt={'310px'} height="1px" w="full" bg="#E0E0E0" />
          <Flex mt={1} gap={2}>
            <Button
              variant="ghost"
              fontSize="xs"
              size="sm"
              fontWeight="medium"
              fontFamily="heading"
              color="text.secondary2"
              leftIcon={<ErasorIcon />}
              onClick={() => {
                setState({ optionSelected: null });
                if (isChecked) {
                  setSelectedTeams([{ value: 'all', label: 'All Teams' }]);
                } else {
                  setSelectedTeams([]);
                }
                onClose();
              }}
            >
              Clear All
            </Button>

            <Button
              variant="filled"
              borderRadius={'4px'}
              size="sm"
              onClick={() => {
                setSelectedTeams(state.optionSelected);
                onClose();
              }}
            >
              Save
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
