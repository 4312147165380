import { FC, useEffect, useState } from 'react';
import { Flex, Tag } from '@chakra-ui/react';
import {
  DateFilter,
  eliminateHtmlTags,
  LeftElements,
  RightElements,
  SearchBox,
  SearchIconContainer,
  SearchInput,
  StyledSearchIcon,
  TeamMenu,
  ToolbarContainer,
} from '@devd-client/devd/components';

import { useSearchParams } from 'react-router-dom';
import { statusOptions } from '../../helpers/Initiatives.utils';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';

interface ToolbarProps {
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
  selectedTeam: any;
  setSelectedTeam: (selectedTeam: any) => void;
  handleRealStatus: (status: { value: string; label: string }) => void;
  realStatus: { value: string; label: string };
  searchPlaceholder?: string;
  hideOptions?: boolean;
}

const Toolbar: FC<ToolbarProps> = ({
  setSearchInput,
  selectedTeam,
  setSelectedTeam,
  handleRealStatus,
  realStatus,
  searchPlaceholder,
  hideOptions = false,
}) => {
  const [appState, dispatch] = useAuth();
  const [searchParams] = useSearchParams();

  const [search, setSearch] = useState('');
  const searchParam = searchParams.get('search');

  useEffect(() => {
    searchParam && setSearch(searchParam);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSearchInput(search);
  };

  return (
    <ToolbarContainer>
      <RightElements>
        {!hideOptions
          ? statusOptions?.map((item: any, idx: number) => (
              <Tag
                key={idx}
                mx={1}
                border="1px solid"
                borderColor={
                  realStatus.value === item.value ? 'primary' : 'text.tertiary'
                }
                borderRadius="20px"
                p={'8px 16px'}
                color={
                  realStatus.value === item.value
                    ? 'primary'
                    : 'text.secondary3'
                }
                bg={realStatus.value === item.value ? 'primary2Bg' : 'white'}
                fontWeight="medium"
                userSelect="none"
                cursor="pointer"
                transition={'0.3s all'}
                _hover={{ bg: 'primary2Bg' }}
                onClick={() =>
                  handleRealStatus({
                    value: eliminateHtmlTags(item.value),
                    label:
                      item.value === '' ? 'All' : eliminateHtmlTags(item.value),
                  })
                }
              >
                {item.label}
              </Tag>
            ))
          : null}
      </RightElements>
      <LeftElements>
        <Flex align={'end'}>
          <Flex align={'end'} gap={'12px'}>
            <TeamMenu
              selectedTeam={selectedTeam}
              handleSelectedTeam={setSelectedTeam}
              hideShowDetails={true}
            />
            <DateFilter
              appState={appState}
              dispatch={dispatch}
              getUser={getUser}
              AuthActionTypes={AuthActionTypes}
            />
          </Flex>
          <SearchBox onSubmit={handleSubmit}>
            <SearchInput
              placeholder={`Search ${searchPlaceholder}`}
              value={search}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                if (e.currentTarget.value === '')
                  setSearchInput(eliminateHtmlTags(e.currentTarget.value));
                setSearch(eliminateHtmlTags(e.currentTarget.value));
              }}
            />
            <SearchIconContainer type="submit">
              <StyledSearchIcon />
            </SearchIconContainer>
          </SearchBox>
        </Flex>
      </LeftElements>
    </ToolbarContainer>
  );
};

export default Toolbar;
