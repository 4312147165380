import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box,
} from '@chakra-ui/react';
import { eliminateHtmlTags } from '@devd-client/devd/components';
import { BiChevronDown } from 'react-icons/bi';
import { updatesModalStatusOptions } from '../../helpers/Initiatives.utils';
import { useFormik } from 'formik';
import { validationSchema } from './validationSchema';

interface UpdateInitiativeStatusModalProps {
  onSuccessAction: (values: { status: string; summary: string }) => void;
  headerText: string;
  isOpen: boolean;
  onClose: () => void;
  isSubmitLoading: boolean;
}

const UpdateInitiativeStatusModal = ({
  onSuccessAction,
  headerText,
  isOpen,
  onClose,
  isSubmitLoading,
}: UpdateInitiativeStatusModalProps) => {
  const formik = useFormik({
    initialValues: {
      status: '',
      summary: '',
    },
    validationSchema,
    onSubmit: (values) => {
      onSuccessAction(values);
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      blockScrollOnMount={false}
      returnFocusOnClose={false}
      size="md"
    >
      <ModalOverlay />
      <ModalContent borderRadius="md" shadow="xl">
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader py={4} color={'text.gray.300'}>
            {headerText}
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody py={4}>
            <VStack spacing={6} align="stretch">
              <FormControl
                isInvalid={formik.touched.summary && !!formik.errors.summary}
              >
                <FormLabel fontWeight="medium" color="text.gray.300">
                  Summary
                </FormLabel>
                <Textarea
                  name="summary"
                  value={formik.values.summary}
                  onChange={(e) => {
                    const sanitizedValue = eliminateHtmlTags(e.target.value);
                    formik.setFieldValue('summary', sanitizedValue);
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Initiative status summary"
                  minH="120px"
                  fontSize="sm"
                  borderRadius="sm"
                />
                <FormErrorMessage>{formik.errors.summary}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={formik.touched.status && !!formik.errors.status}
              >
                <FormLabel fontWeight="medium" color="text.gray.300">
                  Status
                </FormLabel>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<BiChevronDown />}
                    w="full"
                    textAlign="left"
                    variant={'outline'}
                    borderColor={
                      formik.touched.status && formik.errors.status
                        ? 'red.500'
                        : 'gray.200'
                    }
                    borderRadius={'sm'}
                  >
                    <Text fontSize="sm" color="text.gray.300">
                      {formik.values.status || 'Select status'}
                    </Text>
                  </MenuButton>
                  <MenuList shadow="lg" borderRadius="md">
                    {updatesModalStatusOptions?.map(
                      ({ value, text, color }) => (
                        <MenuItem
                          key={value}
                          onClick={() => formik.setFieldValue('status', value)}
                          fontSize="sm"
                          _hover={{ bg: 'gray.50' }}
                        >
                          <Box
                            w="10px"
                            h="10px"
                            borderRadius="full"
                            bg={color}
                            mr={1}
                          />
                          {text}
                        </MenuItem>
                      )
                    )}
                  </MenuList>
                </Menu>
                <FormErrorMessage>{formik.errors.status}</FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter gap={3}>
            <Button
              variant="ghost"
              size="sm"
              onClick={onClose}
              _hover={{ bg: 'gray.50' }}
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              size="sm"
              type="submit"
              isDisabled={isSubmitLoading}
              isLoading={isSubmitLoading}
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateInitiativeStatusModal;
