import {
  Box,
  Divider,
  Flex,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useTheme,
} from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { navConfig } from '../../constants';
import { useSidebarStore } from '../../hooks/useSidebarStore';

type Props = {
  appState: any;
};

const AppSidebar = ({ appState }: Props) => {
  const { colors } = useTheme();
  const showFeedback = appState?.user?.dto?.modules?.cycleFeedback;
  const showContributorTable = appState?.user?.dto?.modules?.contributorSearch;
  const { pathname } = useLocation();
  const [condensed, setCondensed] = useState(false);
  const [currentNav, setCurrentNav] = useState({ id: '', path: '' });
  const [currentSubNav, setCurrentSubNav] = useState({ id: '', path: '' });
  const { open, setOpen } = useSidebarStore();

  const isActive = (path: string) => {
    return pathname.split('/')[1] === path.split('?')[0];
  };

  const isSubMenuActive = (path: string) => {
    const pathRegex = new RegExp(`(^|/)${path}(/|$)`);
    return pathRegex.test(pathname);
  };

  const handleNavClick = (id: string, path: string, hasSubMenu: boolean) => {
    setOpen(true);
    setCondensed(hasSubMenu);
    setCurrentNav({ id, path });
  };

  useEffect(() => {
    navConfig.forEach((category) => {
      category.categoryItem.forEach((item) => {
        if (isSubMenuActive(item.path)) {
          setCurrentNav({ id: item.id, path: item.path });

          if (item.subMenuItems) {
            item.subMenuItems.forEach((subItem) => {
              if (isSubMenuActive(subItem.path)) {
                setCondensed(true);
                setCurrentSubNav({ id: subItem.id, path: subItem.path });
              }
            });
          }
        }
      });
    });
  }, [pathname]);

  useEffect(() => {
    navConfig.forEach((category) => {
      category.categoryItem.forEach((item) => {
        if (
          isActive(item.path) &&
          (!item.subMenuItems || item?.subMenuItems?.length === 0)
        ) {
          setCondensed(false);
        }
      });
    });
  }, [pathname]);

  const renderCategoryTitle = (title: string) => (
    <Text
      fontFamily="heading"
      fontSize="sm"
      lineHeight="20px"
      color="text.secondary2"
      my={2}
      ml={5}
    >
      {title}
    </Text>
  );

  const renderNavItem = (item: any, categoryCondensed: any) => {
    const isSelected = isActive(item.path);
    const textColor = isSelected ? colors.primary : colors.text.gray[300];
    const bgColor = isSelected ? 'primary2Bg' : '#fff';

    return (
      <Link
        to={`/${item.path}`}
        key={item.id}
        onClick={() =>
          handleNavClick(item.id, item.path, !!item.subMenuItems?.length)
        }
      >
        <Flex
          borderRadius="4px"
          py={2}
          px={categoryCondensed ? 4 : 2}
          mx={3}
          mb={1}
          justify={categoryCondensed ? 'center' : 'space-between'}
          align="center"
          bg={bgColor}
          _hover={{ bg: isSelected ? 'primary2Bg' : '#F7F7F8' }}
        >
          <Flex gap={2} align="center">
            {item.Icon && (
              <Tooltip
                hasArrow
                placement="right"
                label={item.label}
                isDisabled={!categoryCondensed}
              >
                <Box>
                  <item.Icon height={17} width={17} color={textColor} />
                </Box>
              </Tooltip>
            )}
            {!categoryCondensed && (
              <Text
                fontFamily="heading"
                fontSize="sm"
                fontWeight="medium"
                color={textColor}
              >
                {item.label}
              </Text>
            )}
          </Flex>
          {item.subMenuItems &&
            item.subMenuItems.length > 0 &&
            !categoryCondensed && <MdKeyboardArrowRight />}
        </Flex>
      </Link>
    );
  };

  const renderSubMenuItem = (subItem: any, parentId: string) => {
    const isSelected = isSubMenuActive(subItem.path);
    const textColor = isSelected ? colors.primary : colors.text.gray[300];

    return (
      <Link
        to={`/${parentId.toLowerCase()}/${subItem.path}`}
        key={subItem.id}
        onClick={() => setCurrentSubNav({ id: subItem.id, path: subItem.path })}
      >
        <Flex
          borderRadius="4px"
          py={2}
          px={4}
          justify="space-between"
          align="center"
          bg={isSelected ? 'primary2Bg' : '#fbfbfb'}
          _hover={{ bg: '#F7F7F8' }}
        >
          <Text
            fontFamily="heading"
            fontSize="xs"
            fontWeight="medium"
            color={textColor}
          >
            {subItem.label}
          </Text>
        </Flex>
      </Link>
    );
  };

  return (
    <Flex>
      <Box
        width={condensed ? '68px' : '232px'}
        minWidth={condensed ? '68px' : '232px'}
        position="relative"
      >
        <Flex
          gap={2}
          direction={'column'}
          height="100%"
          overflowY="auto"
          width={condensed ? '68px' : '232px'}
          minWidth={condensed ? '68px' : '232px'}
          py={5}
          borderRight="1px solid #E0E0E0"
          position="relative"
        >
          {navConfig.map((category) => (
            <Fragment key={category.categoryId}>
              <Flex direction="column">
                {!condensed &&
                  category.categoryTitle &&
                  category.categoryTitle !== 'SETTINGS' &&
                  renderCategoryTitle(category.categoryTitle)}
                {category.categoryItem.map((item) => {
                  if (item.id === 'Settings') return null;

                  if (item.id === 'Feedback' && !showFeedback) {
                    return null;
                  }

                  if (
                    (localStorage.getItem('role') === 'EMPLOYEE' ||
                      showContributorTable === false) &&
                    item.id === 'Contributors'
                  ) {
                    return null;
                  }

                  if (
                    item.id === 'Teams' &&
                    appState?.user?.dto?.modules?.teams === false
                  ) {
                    return null;
                  }

                  if (
                    item.id === 'Goals' &&
                    appState?.user?.dto?.modules?.agreements === false
                  ) {
                    return null;
                  }

                  if (
                    item.id === 'Investments' &&
                    appState?.user?.dto?.modules?.investment === false
                  ) {
                    return null;
                  }

                  if (
                    item.id === 'ActivityLog' &&
                    appState?.user?.dto?.modules?.activityLog === false
                  ) {
                    return null;
                  }

                  if (
                    item.id === 'SoftwareCapitalization' &&
                    appState?.user?.dto?.modules?.capitalization === false
                  ) {
                    return null;
                  }

                  if (
                    localStorage.getItem('role') === 'EMPLOYEE' &&
                    (item.id === 'Initiatives' ||
                      item.id === 'Feedback' ||
                      item.id === 'Reports' ||
                      item.id === 'Survey' ||
                      item.id === 'Investments' ||
                      item.id === 'ActivityLog' ||
                      item.id === 'Issues' ||
                      item.id === 'Pull Requests' ||
                      item.id === 'Compare Teams')
                  ) {
                    return null;
                  }
                  return renderNavItem(item, condensed);
                })}
              </Flex>
              {category.categoryId !== navConfig.length && <Divider />}
            </Fragment>
          ))}
        </Flex>
        {!open && condensed && (
          <Box
            position="absolute"
            top="50%"
            right="-10px"
            zIndex={100}
            transform="translateY(-50%)"
          >
            <Tooltip label="Expand" hasArrow>
              <Box>
                <IconButton
                  minW={0}
                  aria-label="open-close"
                  onClick={() => setOpen(!open)}
                  size={'xs'}
                  bg="white"
                  border="2px solid #E0E0E0"
                  borderRadius="4px"
                  icon={
                    <MdOutlineKeyboardArrowRight
                      size={20}
                      color={colors.text.primary}
                    />
                  }
                />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>

      {condensed && open && (
        <Box position="relative" width="200px" height="100%">
          <Stack
            height="100%"
            overflowY="auto"
            width="200px"
            bg="#FBFBFB"
            py={5}
            px={4}
            borderRight="1px solid #E0E0E0"
            position="relative"
          >
            <Text
              color="text.secondary2"
              fontFamily="heading"
              fontSize="xs"
              fontWeight="medium"
              mb={3}
              ml={4}
            >
              {currentNav.id}
            </Text>
            {navConfig.map((category) =>
              category.categoryItem.map((item) =>
                item.id === currentNav.id && item.subMenuItems
                  ? item.subMenuItems.map((subItem) => {
                      if (
                        (subItem.id === 'sdlc-map' &&
                          localStorage.getItem('orgId') !== 'SYMYCOXHO') ||
                        (subItem.id === 'active-org' &&
                          localStorage.getItem('orgId') !== 'OIDPPE')
                      ) {
                        return null;
                      }

                      return renderSubMenuItem(subItem, item.id);
                    })
                  : null
              )
            )}
          </Stack>
          {open && (
            <Tooltip label={'Collapse'} hasArrow>
              <Box
                position="absolute"
                top="50%"
                right="-10px"
                zIndex={100}
                transform="translateY(-50%)"
              >
                <IconButton
                  minW={0}
                  aria-label="open-close"
                  onClick={() => setOpen(!open)}
                  size={'xs'}
                  bg="white"
                  border="2px solid #E0E0E0"
                  borderRadius="4px"
                  icon={
                    <MdOutlineKeyboardArrowLeft
                      size={20}
                      color={colors.text.primary}
                    />
                  }
                />
              </Box>
            </Tooltip>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default AppSidebar;
