import React from 'react';

export const Thumsup = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_7754_15646)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.82113 10.9336C4.10911 11.0708 4.42409 11.1421 4.74309 11.1421H9.32546C10.3811 11.1421 11.2795 10.3732 11.4426 9.33015L11.979 5.89882C12.1416 4.8591 11.3377 3.91974 10.2853 3.91974H7.47069V1.89893C7.47069 1.12956 6.84699 0.505859 6.07762 0.505859C5.57336 0.505859 5.10848 0.778362 4.86212 1.21835L3.08154 4.39841C2.97409 4.59032 2.91766 4.8066 2.91766 5.02654L2.91765 9.69144C2.91765 10.1872 3.20267 10.6388 3.65019 10.8521L3.82113 10.9336ZM0.854018 4.55546C0.627519 4.55546 0.410296 4.64544 0.250136 4.8056C0.0899764 4.96576 -1.98e-08 5.18298 0 5.40948L3.83143e-07 9.79172C4.02943e-07 10.0183 0.0899764 10.2355 0.250136 10.3956C0.410296 10.5558 0.627518 10.6458 0.854018 10.6458L1.27965 10.6456C1.51627 10.6454 1.70804 10.4536 1.70804 10.217V4.98385C1.70804 4.87015 1.66286 4.76112 1.58245 4.68074C1.50203 4.60036 1.39298 4.55523 1.27928 4.55528L0.854018 4.55546Z"
          fill={props.color || '#FFAD00'}
        />
      </g>
      <defs>
        <clipPath id="clip0_7754_15646">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Thumsup;
