import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { NoDataFoundIcon } from '../../icons';

type Props = {
  noDataView?: React.ReactNode;
};

const NoData = ({ noDataView }: Props) => {
  const { colors } = useTheme();
  return noDataView ? (
    <Box>{noDataView}</Box>
  ) : (
    <Flex w="100%" py={10} justify="center" direction={'column'}>
      <Flex justify="center">
        <Box>
          <NoDataFoundIcon
            height={'24px'}
            width={'24px'}
            color={colors.text.secondary}
          />
        </Box>

        <Text
          ml={2}
          fontFamily="heading"
          textAlign="center"
          fontWeight="medium"
          color="text.secondary"
        >
          No result found.
        </Text>
      </Flex>
    </Flex>
  );
};

export default NoData;
