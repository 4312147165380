import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FiCalendar } from 'react-icons/fi';
import { CustomDate } from './custom-date/custom-date';
import { IoIosStar, IoIosStarOutline } from 'react-icons/io';
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';
import Months from './months/months';
import Quarters from './quarter/quarters';
import useQueryState from '../hooks/useQueryState';
import {
  useDashboardStore,
  useDetailsPageStore,
} from '../hooks/useDashboardStore';
import { Sprints } from './sprints/sprints';
import {
  useFavSprint,
  useFetchSprint,
  useRemoveFavSprint,
} from '../DayTabs/api';
import { useToastHook } from '../appToast';
import { useLocation } from 'react-router';
import { formatDateToReadableString } from '../shared/utils';

interface DateFilterProps {
  getUser?: any;
  appState?: any;
  dispatch?: any;
  AuthActionTypes?: any;
  sprintIds?: any;
  setSprintIds?: (sprintIds: any) => void;
  isDetailsPage?: boolean;
  isFullHeight?: boolean;
  isDisabled?: boolean;
}

export const DateFilter = ({
  getUser,
  appState,
  dispatch,
  AuthActionTypes,
  sprintIds,
  setSprintIds,
  isDetailsPage,
  isFullHeight,
  isDisabled = false,
}: DateFilterProps) => {
  const { mutate: handleFavSprint, isLoading: handleFavTeamLoading } =
    useFavSprint();
  const { mutate: removeFavSprint, isLoading: removeFavTeamLoading } =
    useRemoveFavSprint();
  const [newToast] = useToastHook();
  const { data, isLoading } = useFetchSprint();
  const location = useLocation();

  const dashboardStore = useDashboardStore();
  const detailsPageStore = useDetailsPageStore();

  const {
    selected,
    setSelected,
    setFilterTitle,
    filterTitle,
    active,
    setActive,
  } = isDetailsPage ? detailsPageStore : dashboardStore;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedMonthAndYear, setselectedMonthAndYear] = useState<
    string | null
  >(null);
  const [monthTitle, setMonthTitle] = useState<string | null>(null);
  const [formatedRange, setFormatedRange] = useState<string | null>();
  const [selectedQuarterAndYear, setSelectedQuarterAndYear] = useState<
    string | null
  >();
  const [selectedRange, setSelectedRange] = useState<[Date, Date] | null>(null);
  const [selectedSprintId, setSelectedSprintId] = useState<string | null>(null);
  const [selectedSprintName, setSelectedSprintName] = useState<string | null>(
    null
  );
  const [getUserLoading, setGetUserLoading] = useState(false);

  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');

  const hideButtons =
    active === 'Today' ||
    active === '7D' ||
    active === '14D' ||
    active === '1M' ||
    (active === '' && selected.duration !== 'Sprint');

  useEffect(() => {
    if (duration !== undefined) {
      setActive(duration);
    }
  }, []);

  useEffect(() => {
    if (
      selected.duration &&
      filterTitle === '' &&
      selected.duration !== 'Sprint'
    ) {
      const today = new Date().toISOString().split('T')[0];
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString().split('T')[0];
      setFilterTitle(
        `${formatDateToReadableString(
          formattedThirtyDaysAgo
        )} - ${formatDateToReadableString(today)}`
      );
    } else if (
      selected.duration &&
      filterTitle === '' &&
      selected.duration === 'Sprint'
    ) {
      setFilterTitle(selected.sprintName!);
    } else if (
      selected.duration &&
      selected.duration === 'Sprint' &&
      location.pathname !== '/sprint' &&
      selected.sprintName
    ) {
      setFilterTitle(selected.sprintName!);
    } else if (location.pathname !== '/sprint') {
      setFilterTitle(
        `${formatDateToReadableString(
          selected.startDate
        )} - ${formatDateToReadableString(selected.endDate)}`
      );
    }
  }, [selected.duration, filterTitle, setFilterTitle]);

  const handelTabChange = (newActive: string) => {
    if (newActive === 'Today') {
      const today = new Date().toISOString().split('T')[0];
      setStartDate(today);
      setEndDate(today);
      setSelected({
        ...selected,
        startDate: today,
        endDate: today,
        sprintId: '',
        sprintName: '',
        duration: 'Today',
      });
      setFilterTitle(formatDateToReadableString(today));
      setIsOpen(false);
      setSelectedSprintId('');
    } else if (newActive === '7D') {
      const today = new Date().toISOString().split('T')[0];
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split('T')[0];

      setStartDate(formattedSevenDaysAgo);
      setEndDate(today);
      setSelected({
        ...selected,
        startDate: formattedSevenDaysAgo,
        endDate: today,
        sprintId: '',
        sprintName: '',
        duration: '7D',
      });
      setFilterTitle(
        `${formatDateToReadableString(
          formattedSevenDaysAgo
        )} - ${formatDateToReadableString(today)}`
      );
      setIsOpen(false);
      setSelectedSprintId('');
    } else if (newActive === '14D') {
      const today = new Date().toISOString().split('T')[0];
      const fourteenDaysAgo = new Date();
      fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);
      const formattedFourteenDaysAgo = fourteenDaysAgo
        .toISOString()
        .split('T')[0];

      setStartDate(formattedFourteenDaysAgo);
      setEndDate(today);
      setSelected({
        ...selected,
        startDate: formattedFourteenDaysAgo,
        endDate: today,
        sprintId: '',
        sprintName: '',
        duration: '14D',
      });
      setFilterTitle(
        `${formatDateToReadableString(
          formattedFourteenDaysAgo
        )} - ${formatDateToReadableString(today)}`
      );
      setIsOpen(false);
      setSelectedSprintId('');
    } else if (newActive === '1M') {
      const today = new Date().toISOString().split('T')[0];
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString().split('T')[0];

      setStartDate(formattedThirtyDaysAgo);
      setEndDate(today);
      setSelected({
        ...selected,
        startDate: formattedThirtyDaysAgo,
        endDate: today,
        sprintId: '',
        sprintName: '',
        duration: '1M',
      });
      setFilterTitle(
        `${formatDateToReadableString(
          formattedThirtyDaysAgo
        )} - ${formatDateToReadableString(today)}`
      );
      setIsOpen(false);
      setSelectedSprintId('');
    }
    setActive(newActive);
  };

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const handelApply = () => {
    if (active === 'Custom') {
      const startDate = formatedRange?.split(' ')[0];
      const endDate = formatedRange?.split(' ')[1];
      setStartDate(startDate);
      setEndDate(endDate);
      setSelectedRange(null);

      setSelected({
        ...selected,
        startDate: startDate!,
        endDate: endDate!,
        sprintId: '',
        sprintName: '',
        duration: 'Custom',
      });
      setFilterTitle(
        `${formatDateToReadableString(
          startDate!
        )} - ${formatDateToReadableString(endDate!)}`
      );
      setActive('Custom');
    } else if (active === 'Month') {
      const month = parseInt(selectedMonthAndYear?.split('-')[0] || '1');
      const year = parseInt(selectedMonthAndYear?.split('-')[1] || '2024');
      const formattedMonth = month < 9 ? `0${month + 1}` : `${month + 1}`;
      const startDate = `${year}-${formattedMonth}-01`;
      const lastDay = new Date(year, month + 1, 0).getDate();
      const endDate = `${year}-${formattedMonth}-${lastDay}`;

      setSelected({
        ...selected,
        startDate: startDate,
        endDate: endDate,
        sprintId: '',
        sprintName: '',
        duration: 'Month',
      });
      setFilterTitle(`${months[month]}`);
      setActive('Month');
    } else if (active === 'Quarter') {
      const quarter = parseInt(selectedQuarterAndYear?.split('-')[0] || '1');
      const year = parseInt(selectedQuarterAndYear?.split('-')[1] || '2024');

      let startMonth = 1;
      let endMonth = 3;
      if (quarter === 2) {
        startMonth = 4;
        endMonth = 6;
        setFilterTitle('Q2 Apr to Jun');
      } else if (quarter === 3) {
        startMonth = 7;
        endMonth = 9;
        setFilterTitle('Q3 Jul to Sept');
      } else if (quarter === 4) {
        startMonth = 10;
        endMonth = 12;
        setFilterTitle('Q4 Oct to Dec');
      } else {
        setFilterTitle('Q1 Jan to Mar');
      }

      const formattedStartMonth =
        startMonth < 10 ? `0${startMonth}` : startMonth.toString();
      const formattedEndMonth =
        endMonth < 10 ? `0${endMonth}` : endMonth.toString();
      const startDate = `${year}-${formattedStartMonth}-01`;
      const lastDay = new Date(year, endMonth, 0).getDate();
      const endDate = `${year}-${formattedEndMonth}-${lastDay}`;

      setStartDate(startDate);
      setEndDate(endDate);
      setSelected({
        ...selected,
        startDate: startDate,
        endDate: endDate,
        sprintId: '',
        sprintName: '',
        duration: 'Quarter',
      });
      setActive('Quarter');
    }
    setIsOpen(false);
    setSelectedSprintId('');
  };

  return (
    <Box fontFamily={'heading'}>
      <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <MenuButton
          as={Button}
          onClick={() => setIsOpen(!isOpen)}
          rightIcon={<ChevronDownIcon />}
          backgroundColor={'white'}
          border={'1px solid #E0E0E0'}
          borderRadius={'4px'}
          display={'flex'}
          height={isFullHeight ? '40px' : '32px'}
          gap={'32px'}
          _hover={{ bg: 'white' }}
          _focus={{ bg: 'white' }}
          _active={{ bg: 'white' }}
          isDisabled={isDisabled}
        >
          <Text
            fontSize={isFullHeight ? '14px' : '12px'}
            fontWeight={500}
            display={'flex'}
            alignItems={'center'}
            gap={'8px'}
            color={'#718096'}
          >
            <FiCalendar />
            {filterTitle}
            {sprintIds?.length > 0 &&
              selected.duration === 'Sprint' &&
              `s (${sprintIds?.length})`}
          </Text>
        </MenuButton>
        <MenuList
          marginTop={'2px'}
          display={'flex'}
          padding={'0'}
          zIndex={'9999'}
          minWidth={'auto'}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={'16px'}
            borderRight={hideButtons ? 'none' : '1px solid #E0E0E0'}
            padding={'12px'}
            fontSize={'12px'}
            color={'#626266'}
            fontWeight={500}
            minWidth={'120px'}
          >
            <Box
              color={active === 'Today' ? '#4D4DFF' : ''}
              cursor={'pointer'}
              onClick={() => handelTabChange('Today')}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={'4px'}
              _hover={{ color: '#4D4DFF' }}
            >
              Today
            </Box>
            <Box
              color={active === '7D' ? '#4D4DFF' : ''}
              cursor={'pointer'}
              onClick={() => handelTabChange('7D')}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={'4px'}
              _hover={{ color: '#4D4DFF' }}
            >
              Last 7 Days
            </Box>
            <Box
              color={active === '14D' ? '#4D4DFF' : ''}
              cursor={'pointer'}
              onClick={() => handelTabChange('14D')}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={'4px'}
              _hover={{ color: '#4D4DFF' }}
            >
              Last 14 Days
            </Box>
            <Box
              color={
                active === '1M' ||
                (active === '' && selected.duration !== 'Sprint')
                  ? '#4D4DFF'
                  : ''
              }
              cursor={'pointer'}
              onClick={() => handelTabChange('1M')}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={'4px'}
              _hover={{ color: '#4D4DFF' }}
            >
              Last 30 Days
            </Box>
            <Box
              color={active === 'Month' ? '#4D4DFF' : ''}
              cursor={'pointer'}
              onClick={() => handelTabChange('Month')}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={'4px'}
              _hover={{ color: '#4D4DFF' }}
            >
              <Box
                display={'flex'}
                gap={'4px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                Months
                {active === 'Month' || months.includes(active) ? (
                  <RiArrowRightSLine />
                ) : (
                  <RiArrowDownSLine />
                )}
              </Box>
            </Box>
            <Box
              color={active === 'Quarter' ? '#4D4DFF' : ''}
              cursor={'pointer'}
              onClick={() => handelTabChange('Quarter')}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={'4px'}
              _hover={{ color: '#4D4DFF' }}
            >
              <Box
                display={'flex'}
                gap={'4px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                Quarter
                {active === 'Quarter' ? (
                  <RiArrowRightSLine />
                ) : (
                  <RiArrowDownSLine />
                )}
              </Box>
            </Box>
            <Box
              color={active === 'Custom' ? '#4D4DFF' : ''}
              cursor={'pointer'}
              onClick={() => handelTabChange('Custom')}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={'4px'}
              _hover={{ color: '#4D4DFF' }}
            >
              Custom Date
            </Box>
            <Box
              color={
                active === 'Sprint' ||
                (active === '' && selected.duration === 'Sprint')
                  ? '#4D4DFF'
                  : ''
              }
              cursor={'pointer'}
              onClick={() => handelTabChange('Sprint')}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={'4px'}
              _hover={{ color: '#4D4DFF' }}
            >
              <Box
                display={'flex'}
                gap={'4px'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                Sprints
                {active === 'Sprint' ||
                (active === '' && selected.duration === 'Sprint') ? (
                  <RiArrowRightSLine />
                ) : (
                  <RiArrowDownSLine />
                )}
              </Box>
              <Box
                display={
                  active === 'Sprint' ||
                  (active === '' && selected.duration === 'Sprint')
                    ? 'block'
                    : 'none'
                }
                onClick={() => {
                  if (!selected.sprintId) return;
                  selected.sprintId === appState?.user?.dto?.sprint
                    ? removeFavSprint(
                        {
                          sprint: selected.sprintId,
                        },
                        {
                          onSuccess: () => {
                            setGetUserLoading(true);
                            getUser()
                              .then((res: any) => {
                                setGetUserLoading(false);
                                dispatch({
                                  type: AuthActionTypes.AUTH_READY,
                                  payload: { ...appState.user, ...res },
                                });
                              })
                              .catch((err: any) => {
                                newToast({
                                  message:
                                    err?.message ?? 'Something went wrong',
                                  status: 'error',
                                });
                                setGetUserLoading(false);
                              });
                          },
                        }
                      )
                    : appState?.user?.dto?.sprint
                    ? newToast({
                        message: `${
                          data?.find(
                            (el: any) =>
                              el.sprintId === appState?.user.dto?.sprint
                          )?.sprintName
                        } is marked as Favourite. Remove it from favourite and try again.`,
                        status: 'error',
                      })
                    : handleFavSprint(
                        {
                          sprint: selected.sprintId,
                        },
                        {
                          onSuccess: () => {
                            setGetUserLoading(true);
                            getUser()
                              .then((res: any) => {
                                setGetUserLoading(false);
                                dispatch({
                                  type: AuthActionTypes.AUTH_READY,
                                  payload: {
                                    ...appState.user,
                                    ...res,
                                  },
                                });
                              })
                              .catch((err: any) => {
                                newToast({
                                  message:
                                    err?.message ?? 'Something went wrong',
                                  status: 'error',
                                });
                                setGetUserLoading(false);
                              });
                          },
                        }
                      );
                }}
              >
                {handleFavTeamLoading ||
                removeFavTeamLoading ||
                getUserLoading ? (
                  <Spinner size={'xs'} />
                ) : selected.sprintId === appState?.user?.dto?.sprint ? (
                  <div>
                    <IoIosStar fontSize="12px" color="#4D4DFF" />
                  </div>
                ) : (
                  <div>
                    <IoIosStarOutline fontSize="12px" color="gray" />
                  </div>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            display={hideButtons ? 'none' : 'flex'}
            flexDirection={'column'}
            fontSize={'14px'}
            fontWeight={'500'}
          >
            <Box display={active === 'Custom' ? 'block' : 'none'}>
              <CustomDate
                selectedRange={selectedRange}
                setSelectedRange={setSelectedRange}
                setFormatedRange={setFormatedRange}
              />
            </Box>
            <Box
              display={active === 'Month' ? 'block' : 'none'}
              marginBottom={'8px'}
            >
              <Months
                setselectedMonthAndYear={setselectedMonthAndYear}
                setMonthTitle={setMonthTitle}
              />
            </Box>
            <Box display={active === 'Quarter' ? 'block' : 'none'}>
              <Quarters setSelectedQuarterAndYear={setSelectedQuarterAndYear} />
            </Box>
            <Box
              display={
                active === 'Sprint' ||
                (active == '' && selected.duration === 'Sprint')
                  ? 'block'
                  : 'none'
              }
            >
              <Sprints
                selectedSprintId={selectedSprintId}
                setSelectedSprintId={setSelectedSprintId}
                setFormatedRange={setFormatedRange}
                setSelectedSprintName={setSelectedSprintName}
                appState={appState}
                sprintIds={sprintIds}
                setSprintIds={setSprintIds}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setSprintId={setSprintId}
                setSprintName={setSprintName}
                setDuration={setDuration}
                setActive={setActive}
                setIsOpen={setIsOpen}
                isDetailsPage={isDetailsPage}
              />
            </Box>
            <Box
              padding={'12px'}
              borderTop={'1px solid #E0E0E0'}
              width={'100%'}
              marginTop={'auto'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {active === 'Custom' && (
                <Box display={'flex'} alignItems={'center'} gap={2}>
                  <Box
                    height={'12px'}
                    width={'12px'}
                    backgroundColor={'#DBDBFF'}
                    borderRadius={'full'}
                  />
                  Selected Period
                </Box>
              )}
              <Box
                marginLeft={'auto'}
                display={hideButtons ? 'none' : 'flex'}
                gap={'10px'}
                width={'fit-content'}
              >
                {active !== 'Sprint' && (
                  <Box
                    color={'#4D4DFF'}
                    onClick={() => handelApply()}
                    cursor={'pointer'}
                  >
                    Apply
                  </Box>
                )}
                <Box
                  color={'#8F8F91'}
                  cursor={'pointer'}
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Box>
              </Box>
            </Box>
          </Box>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default DateFilter;
