import {
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  FormLabel,
  Flex,
  Box,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const EmailSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email.').required('Email is required.'),
});

type Props = {
  onClose: () => void;
  currentEmail: string | null | undefined;
  loader: boolean;
  onSaveAction: (values: any) => void;
};

export const FormikForm = ({
  onClose,
  currentEmail,
  loader,
  onSaveAction,
}: Props) => (
  <Formik
    initialValues={{ email: currentEmail || '' }}
    validationSchema={EmailSchema}
    enableReinitialize
    onSubmit={(values, actions) => {
      onSaveAction(values);
      actions.setSubmitting(false);
    }}
  >
    {({ isSubmitting }) => (
      <Form>
        <Field name="email">
          {({ field, form }: any) => (
            <FormControl isInvalid={form.errors.email && form.touched.email}>
              <FormLabel color={'text.secondary2'} fontWeight="medium">
                Official Email
              </FormLabel>
              <Input {...field} placeholder="Enter your official email" />
              <FormErrorMessage>{form.errors.email}</FormErrorMessage>
            </FormControl>
          )}
        </Field>

        <Flex justify={'flex-end'} mt={3} gap={3}>
          <Box>
            <Button
              variant="ghost"
              color="text.secondary2"
              _hover={{ background: 'transperant' }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
          <Button
            type="submit"
            variant="filled"
            isLoading={isSubmitting || loader}
            loadingText="Saving"
          >
            Save
          </Button>
        </Flex>
      </Form>
    )}
  </Formik>
);
