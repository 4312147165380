import { Box, Flex, Text, Tooltip, Button, Switch } from '@chakra-ui/react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {
  QUERY_KEYS,
  useDeleteMembers,
  useTeamMembers,
  useUpdateTeamMemberRole,
} from '../../../apis';
import { MdSend } from 'react-icons/md';

import {
  ConfirmButton,
  DataGrid,
  GitHubIcon,
  JiraIcon,
  UserIcon,
  useToastHook,
} from '@devd-client/devd/components';
import { useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useQueryClient } from '@tanstack/react-query';
import { CustomSelect } from '@devd-client/devd/components';
import { roles } from '../../../helpers/devd-members.utils';
import { chakraComponents, Select } from 'chakra-react-select';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Loader } from '@devd-client/devd/components';
import { apiClient } from '@devd-client/api';

type Props = {
  team: string;
  addMemberModal: any;
  setAddMemberModalOpenedFor?: (value: string) => void;
  setInitialRender?: (value: boolean) => void;
  email: string;
  setEmail: (emails: string) => void;
  handleSubmit: any;
};

const Members = ({
  team,
  addMemberModal,
  setAddMemberModalOpenedFor,
  setInitialRender,
  email,
  setEmail,
  handleSubmit: handleSubmitEmails,
}: Props) => {
  const queryClient = useQueryClient();
  const [newToast] = useToastHook();
  const [loading, setLoading] = useState(false);

  const { data, isFetching } = useTeamMembers(team);

  const { mutate: handleDeleteMember, isLoading: deleteMemberLoading } =
    useDeleteMembers();

  const { mutate: updateTeamMemberRole } = useUpdateTeamMemberRole();

  const rows: any[] = useMemo(() => data?.dto || [], [data?.dto]);

  const columnHelper = createColumnHelper<any>();

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
      '&:hover': {
        cursor: 'pointer',
      },
      width: '140px',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
      padding: '0 8px',
    }),

    dropdownIndicator: (provided: any) => ({
      ...provided,
      background: 'none',
      padding: 0,
      marginLeft: '-55px',
    }),
    menu: (provided: any) => ({
      ...provided,
      width: '120px',
    }),
    menuList: (provided: any) => ({
      ...provided,
      width: '120px',
    }),
  };

  const handleSubmit = (email: any, role: any) => {
    const payload = {
      team,
      email,
      role,
    };
    updateTeamMemberRole(payload, {
      onSuccess() {
        newToast({
          message: 'Role changed',
          status: 'success',
        });
        queryClient.invalidateQueries([QUERY_KEYS.teamMembers]);

        if (role === 'MANAGER') {
          const currentEmails = selectedManagerEmails.slice();

          if (!currentEmails.includes(email)) {
            currentEmails.push(email);
            const emailString = currentEmails.join(',');

            setSelectedManagerEmails(currentEmails);
            setEmail(emailString);
            handleSubmitEmails(emailString);
          }
        } else if (
          role !== 'MANAGER' &&
          selectedManagerEmails.includes(email)
        ) {
          const updatedEmails = selectedManagerEmails.filter(
            (item) => item !== email
          );
          const emailString = updatedEmails.join(',');

          setSelectedManagerEmails(updatedEmails);
          setEmail(emailString);
          handleSubmitEmails(emailString);
        }
      },
      onError: (err) => {
        newToast({ message: 'Something went wrong', status: 'error' });
      },
    });
  };

  const InviteTeamMember = (email: string, role: string) => {
    try {
      setLoading(true);
      apiClient(`/v2/account/invitation/${localStorage.getItem('orgId')}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          email,
          role,
        }),
      })
        .then((res: any) => {
          setLoading(false);
          newToast({
            message: 'Invitation sent successfully on invitee email.',
            status: 'success',
          });
        })
        .catch((err) => {
          setLoading(false);
          newToast({
            message:
              err?.message ??
              'Some error in sending invite. Contact DevDynamics support',
            status: 'error',
          });
        });
    } catch (err: any) {
      setLoading(false);
      newToast({
        message:
          err?.message ??
          'Some error in sending invite. Contact DevDynamics support',
        status: 'error',
      });
    }
  };

  const [selectedManagerEmails, setSelectedManagerEmails] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (email) {
      setSelectedManagerEmails(email.split(',').filter((e) => e.trim() !== ''));
    }
  }, [email]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: 'Name',
      }),

      columnHelper.accessor('email', {
        cell: (info) => info.getValue(),
        header: 'Email',
      }),

      columnHelper.accessor('accounts', {
        cell: (info) => (
          <Flex gap={4} direction={'column'}>
            {info?.getValue()?.map((item: any, index: any) => (
              <Flex align="center" key={`account-${index}`}>
                <Box height={'14px'} width={'14px'}>
                  {item?.type === 'github' ? (
                    <GitHubIcon height="14px" width="14px" />
                  ) : item?.type === 'jira' ? (
                    <JiraIcon height="14px" width="14px" />
                  ) : (
                    <UserIcon height="15px" width="15px" />
                  )}
                </Box>
                <Text
                  ml={1}
                  fontSize="xs"
                  color="text.secondary"
                  fontWeight="semibold"
                >
                  {item?.accountId}
                </Text>
              </Flex>
            ))}
          </Flex>
        ),
        header: 'Contributor Accounts',
      }),

      columnHelper.accessor('role', {
        cell: (info) => {
          const CustomDropdownIndicator = (props: any) => {
            const { selectProps } = props;
            const rotation = selectProps.menuIsOpen ? '180deg' : '0deg';
            return (
              <chakraComponents.DropdownIndicator {...props}>
                <ChevronDownIcon
                  color="gray.500"
                  transform={`rotate(${rotation})`}
                />
              </chakraComponents.DropdownIndicator>
            );
          };

          return (
            <Select
              variant="unstyled"
              chakraStyles={customStyles}
              placeholder={'Select Role'}
              menuPortalTarget={document.body}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              defaultValue={{
                value: info.getValue(),
                label: info.getValue() === 'MANAGER' ? 'Manager' : 'Employee',
              }}
              onChange={(selected: any) => {
                handleSubmit(info.row.original?.email, selected.value);
              }}
              options={roles}
            />
          );
        },
        header: 'Team Role',
      }),

      columnHelper.accessor('Actions', {
        cell: (info) => {
          const email = info.row.getValue('email') as string;
          return (
            <Flex direction="column" alignItems="flex-start">
              <Box>
                <ConfirmButton
                  showIcon
                  showLabel={true}
                  buttonText="Remove"
                  headerText="Are you sure?"
                  bodyText="Do you really want to delete this member?"
                  onSuccessAction={() =>
                    handleDeleteMember(
                      {
                        team: team,
                        email: info.row.original.email,
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries([
                            QUERY_KEYS.teamMembers,
                          ]);
                          if (info.row.original.role === 'MANAGER') {
                            const emailToRemove = info.row.original.email;

                            if (selectedManagerEmails.includes(emailToRemove)) {
                              const updatedEmails =
                                selectedManagerEmails.filter(
                                  (email) => email !== emailToRemove
                                );
                              const emailString = updatedEmails.join(',');

                              setSelectedManagerEmails(updatedEmails);
                              setEmail(emailString);
                              handleSubmitEmails(emailString);
                            }
                          }
                          newToast({
                            message: 'Member removed successfully!',
                            status: 'success',
                          });
                        },
                      }
                    )
                  }
                />
              </Box>

              {info.row.getValue('role') === 'MANAGER' ? (
                <>
                  <Tooltip label="Invite" hasArrow>
                    <Button
                      bg="transparent"
                      size="sm"
                      color="#0095E6"
                      aria-label="delete"
                      onClick={() =>
                        InviteTeamMember(info.row.getValue('email'), 'MANAGER')
                      }
                    >
                      <Box
                        as="span"
                        position="relative"
                        left="-2px"
                        bottom="4px"
                        display="flex"
                        alignItems="center"
                      >
                        <MdSend
                          style={{
                            transform: 'rotate(310deg)',
                          }}
                        />
                      </Box>
                      <span style={{ marginLeft: '2px' }}>Send Invite</span>
                    </Button>
                  </Tooltip>

                  <Flex align="center" mt={1} ml={3}>
                    <Text
                      fontSize="xs"
                      color="text.secondary"
                      fontWeight="medium"
                      mr={2}
                    >
                      Daily Digest
                    </Text>
                    <Switch
                      size="sm"
                      colorScheme="blue"
                      isChecked={selectedManagerEmails.includes(email)}
                      onChange={(e) => {
                        const newEmails = e.target.checked
                          ? [...selectedManagerEmails, email]
                          : selectedManagerEmails.filter(
                              (item) => item !== email
                            );

                        const emailString = newEmails.join(',');

                        setSelectedManagerEmails(newEmails);
                        setEmail(emailString);
                        handleSubmitEmails(emailString);
                      }}
                      sx={{
                        '& .chakra-switch__track': {
                          bg: 'gray.300',
                          _checked: {
                            bg: '#4d4dff',
                          },
                        },
                        '& .chakra-switch__thumb': {
                          bg: 'white',
                          _checked: {
                            transform:
                              'translateX(calc(100% + 1px)) !important',
                          },
                        },
                        boxShadow: 'sm',
                        borderRadius: 'full',
                        transition: 'all 0.2s',
                        cursor: 'pointer',
                      }}
                    />
                  </Flex>
                </>
              ) : info.row.getValue('role') === 'EMPLOYEE' ? (
                <Tooltip label="Invite" hasArrow>
                  <Button
                    bg="transparent"
                    size="sm"
                    color="#0095E6"
                    aria-label="delete"
                    onClick={() =>
                      InviteTeamMember(info.row.getValue('email'), 'EMPLOYEE')
                    }
                  >
                    <Box
                      as="span"
                      position="relative"
                      left="-2px"
                      bottom="4px"
                      display="flex"
                      alignItems="center"
                    >
                      <MdSend
                        style={{
                          transform: 'rotate(310deg)',
                        }}
                      />
                    </Box>
                    <span style={{ marginLeft: '2px' }}>Send Invite</span>
                  </Button>
                </Tooltip>
              ) : (
                <></>
              )}
            </Flex>
          );
        },
        header: 'Action',
      }),
    ],
    [data?.dto, selectedManagerEmails]
  );

  return (
    <Flex mt="18px" direction="column">
      <Flex mb={6} direction="column">
        <Flex align="center">
          <Text
            mr={1}
            fontFamily="heading"
            fontWeight="medium"
            color="text.primary"
          >
            Contributors (
            {rows?.filter((item: any) => item.role !== 'MANAGER')?.length})
          </Text>
          <MdKeyboardArrowRight color={'#667085'} />
        </Flex>
        <Text fontSize="sm" color="text.secondary" mt={2}>
          All activity metrics data will be filtered by the team members when
          applying the team filter.
        </Text>
      </Flex>

      <DataGrid
        page="Members"
        useMaxH={true}
        showLoader={isFetching}
        addMemberModal={addMemberModal}
        setAddMemberModalOpenedFor={setAddMemberModalOpenedFor}
        team={team}
        columns={columns}
        data={rows?.filter((item: any) => item.role !== 'MANAGER')}
        sticky="firstCol"
      />
      <Flex my={6} align="center">
        <Text
          mr={1}
          fontFamily="heading"
          fontWeight="medium"
          color="text.primary"
        >
          Managers (
          {rows?.filter((item: any) => item.role === 'MANAGER')?.length})
        </Text>
        <MdKeyboardArrowRight color={'#667085'} />
      </Flex>
      <DataGrid
        page="Managers"
        useMaxH={true}
        showLoader={isFetching}
        addMemberModal={addMemberModal}
        setAddMemberModalOpenedFor={setAddMemberModalOpenedFor}
        setInitialRender={setInitialRender}
        team={team}
        columns={columns}
        data={rows?.filter((item: any) => item.role === 'MANAGER')}
        sticky="firstCol"
      />
      {loading && <Loader />}
    </Flex>
  );
};

export default Members;
