import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightAddon,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { validationSchema } from './validationSchema';
import { useEffect, useRef } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { useToastHook } from '@devd-client/devd/components';

interface AvgAllocationCardProps {
  activeCard: 'target' | 'developers' | 'avgAllocation' | 'scope';
  setActiveCard: (
    card: 'target' | 'developers' | 'avgAllocation' | 'scope'
  ) => void;
  initialValue: number;
  averageAttention: number | null;
  setAverageAttention: (value: number) => void;
  isLoading: boolean;
}

const AvgAllocationCard = ({
  activeCard,
  setActiveCard,
  initialValue,
  averageAttention,
  setAverageAttention,
  isLoading,
}: AvgAllocationCardProps) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [newToast] = useToastHook();

  useEffect(() => {
    if (initialValue === 0 && !isLoading && !averageAttention) {
      newToast({
        message:
          '0% allocation means this deliverable cannot be completed. Please allocate some development time to plan the scenario.',
        status: 'warning',
        duration: 5000,
      });
    }
  }, [initialValue, isLoading, averageAttention]);

  const formik = useFormik({
    initialValues: {
      allocation: initialValue,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setAverageAttention(values.allocation);
    },
    enableReinitialize: true,
  });

  const handleSliderChange = (value: number) => {
    formik.setFieldValue('allocation', value);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      formik.submitForm();
    }, 500);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      formik.submitForm();
    }, 500);
  };

  return (
    <Flex
      rounded={'md'}
      minH={'100px'}
      bg={activeCard === 'avgAllocation' ? '#4d4dff05' : 'white'}
      border={'1px solid'}
      borderColor={activeCard === 'avgAllocation' ? '#4d4dff' : 'text.tertiary'}
      boxShadow={
        activeCard === 'avgAllocation'
          ? '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)'
          : 'none'
      }
      direction={'column'}
      gap={6}
      p={4}
      color={'text.primary'}
    >
      <Flex direction={'column'} gap={2}>
        <Text color={'text.gray.300'} fontWeight={'semibold'} fontSize={'sm'}>
          Average Allocation
        </Text>
        <Text fontSize={'sm'} color={'text.secondary2'}>
          On average, how much overall development attention will this
          deliverable receive?
        </Text>
      </Flex>
      <Flex direction={'column'} gap={1} mt={'auto'}>
        <FormControl
          isInvalid={!!formik.errors.allocation && formik.touched.allocation}
        >
          <Slider
            value={formik.values.allocation}
            onChange={handleSliderChange}
            min={0}
            max={100}
            step={1}
            focusThumbOnChange={false}
          >
            <SliderTrack height={'8px'} rounded={'full'}>
              <SliderFilledTrack bg={'primary'} />
            </SliderTrack>
            <SliderThumb
              boxSize={'16px'}
              border="1px solid"
              borderColor="text.secondary"
            />
          </Slider>
          <Flex align="center" gap={2} mt={2}>
            <InputGroup size="md" w={'fit-content'}>
              <Input
                placeholder="0"
                type="number"
                name="allocation"
                value={formik.values.allocation}
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                border="1px solid #E0E0E0"
                borderRadius="8px"
                borderRightRadius="0"
                borderRight="none"
                _placeholder={{ color: 'text.secondary' }}
                _focus={{
                  boxShadow: 'none',
                  border: '1px solid #E0E0E0',
                  borderRight: 'none',
                }}
                width="60px"
              />
              <InputRightAddon
                children="%"
                border="1px solid #E0E0E0"
                bg={'rgba(224, 224, 224, 0.2)'}
                borderLeftRadius="0"
                color="text.primary"
              />
            </InputGroup>
            <FormErrorMessage ml={2} fontSize={'xs'}>
              {formik.errors.allocation}
            </FormErrorMessage>
          </Flex>
        </FormControl>
        <Text fontSize={'xs'} color={'text.secondary'}>
          {formik.values.allocation === initialValue ? (
            'Average, previous 4 weeks'
          ) : formik.values.allocation > initialValue ? (
            <Flex as="span" align="center" color="primary">
              <ArrowUpIcon w={3} h={3} />
              <Text as="span" ml={1}>
                {formik.values.allocation - initialValue}% attention/week
              </Text>
            </Flex>
          ) : (
            <Flex as="span" align="center" color="primary">
              <ArrowDownIcon w={3} h={3} />
              <Text as="span" ml={1}>
                {initialValue - formik.values.allocation}% attention/week
              </Text>
            </Flex>
          )}
        </Text>
      </Flex>
      <Text
        color={'primary'}
        fontSize={'xs'}
        onClick={() => setActiveCard('avgAllocation')}
        cursor={'pointer'}
      >
        View Compating Priorities
      </Text>
    </Flex>
  );
};

export default AvgAllocationCard;
