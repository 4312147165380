export function XMLIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="-4 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.112-.024c-2.803 0-5.074 2.272-5.074 5.074v53.841c0 2.803 2.271 5.074 5.074 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.606l-18.902-20.309h-31.946z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FC7B24"
      />

      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M55.977 20.352v1h-12.799s-6.312-1.26-6.129-6.707c0 0 .208 5.707 6.004 5.707h12.924z"
          fill="#FB5C1B"
        />

        <path
          d="M37.074 0v14.561c0 1.656 1.104 5.791 6.104 5.791h12.799l-18.903-20.352z"
          opacity=".5"
          fill="#ffffff"
        />
      </g>

      <path
        d="M19.371 53.848c-.217 0-.414-.089-.541-.27l-3.727-4.97-3.745 4.97c-.126.181-.323.27-.54.27-.396 0-.721-.306-.721-.72 0-.144.037-.306.145-.432l3.889-5.131-3.619-4.826c-.09-.126-.144-.27-.144-.414 0-.343.288-.721.72-.721.217 0 .433.108.576.288l3.439 4.627 3.439-4.646c.125-.18.324-.27.54-.27.378 0 .737.306.737.721 0 .144-.035.288-.125.414l-3.619 4.808 3.889 5.149c.09.126.127.27.127.415.001.396-.323.738-.72.738zm14.455-.018c-.414 0-.738-.324-.738-.738v-9.254l-4.033 9.759c-.055.143-.2.233-.379.233-.144 0-.287-.09-.342-.234l-4.016-9.759v9.254c0 .414-.324.738-.756.738-.414 0-.738-.324-.738-.738v-10.262c0-.648.559-1.207 1.242-1.207.486 0 .99.288 1.188.756l3.438 8.373 3.457-8.373c.199-.468.686-.756 1.189-.756.684 0 1.242.558 1.242 1.207v10.263c.002.414-.322.738-.754.738zm10.546-.108h-5.456c-.594 0-1.08-.486-1.08-1.081v-10.316c0-.396.324-.721.774-.721.396 0 .72.324.72.721v10.065h5.042c.36 0 .647.288.647.648.001.396-.287.684-.647.684z"
        fill="#ffffff"
      />
    </svg>
  );
}

export default XMLIcon;
