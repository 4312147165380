import {
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { validationSchema } from './validationSchema';
import { useRef } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';

interface ScopeCardProps {
  activeCard: 'target' | 'developers' | 'avgAllocation' | 'scope';
  setActiveCard: (
    card: 'target' | 'developers' | 'avgAllocation' | 'scope'
  ) => void;
  initialValue: number;
  scope: number;
  setRemainingScope: (value: number) => void;
}

const ScopeCard = ({
  activeCard,
  setActiveCard,
  setRemainingScope,
  initialValue,
  scope,
}: ScopeCardProps) => {
  const formik = useFormik({
    initialValues: {
      count: initialValue,
    },
    validationSchema: validationSchema(scope),
    onSubmit: (values) => {
      setRemainingScope(values.count);
    },
    enableReinitialize: true,
  });
  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleInputChange = (value: number) => {
    if (value >= 0) {
      formik.setFieldValue('count', value);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        formik.submitForm();
      }, 500);
    }
  };

  const handleIncrement = () => {
    handleInputChange(formik.values.count + 1);
  };

  const handleDecrement = () => {
    handleInputChange(formik.values.count - 1);
  };

  return (
    <Flex
      rounded={'md'}
      minH={'100px'}
      bg={activeCard === 'scope' ? '#4d4dff05' : 'white'}
      border={'1px solid'}
      borderColor={activeCard === 'scope' ? '#4d4dff' : 'text.tertiary'}
      boxShadow={
        activeCard === 'scope'
          ? '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)'
          : 'none'
      }
      direction={'column'}
      gap={6}
      p={4}
      color={'text.primary'}
    >
      <Flex direction={'column'} gap={2}>
        <Text color={'text.gray.300'} fontWeight={'semibold'} fontSize={'sm'}>
          Scope
        </Text>
        <Text fontSize={'sm'} color={'text.secondary2'}>
          What is total remaining scope for the deliverable?
        </Text>
      </Flex>
      <Flex direction={'column'} gap={1} mt={'auto'}>
        <Text fontSize={'sm'} color={'text.primary'}>
          Issues
        </Text>
        <FormControl isInvalid={!!formik.errors.count && formik.touched.count}>
          <Flex align="center">
            <IconButton
              aria-label="decrease"
              icon={<FiMinus />}
              size="sm"
              height={'32px'}
              onClick={handleDecrement}
              rounded={'md'}
              bg={'white'}
              border={'1px solid'}
              borderRightRadius={'none'}
              borderColor={'text.tertiary'}
              _hover={{ bg: 'white' }}
              isDisabled={formik.values.count <= 0}
            />
            <Input
              height={'32px'}
              width={'60px'}
              rounded={'none'}
              textAlign={'center'}
              name="count"
              value={formik.values.count}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '' || /^\d+$/.test(value)) {
                  const numValue = parseInt(value) || 0;
                  handleInputChange(numValue);
                }
              }}
              _focus={{
                border: '1px solid',
                borderColor: 'text.tertiary',
                boxShadow: 'none',
              }}
              _active={{
                border: '1px solid',
                borderColor: 'text.tertiary',
                boxShadow: 'none',
              }}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.count && !!formik.errors.count}
            />
            <IconButton
              aria-label="increase"
              icon={<FiPlus />}
              size="sm"
              height={'32px'}
              onClick={handleIncrement}
              rounded={'md'}
              bg={'white'}
              border={'1px solid'}
              borderLeftRadius={'none'}
              borderColor={'text.tertiary'}
              _hover={{ bg: 'white' }}
            />
            <FormErrorMessage ml={2} fontSize={'xs'}>
              {formik.errors.count}
            </FormErrorMessage>
          </Flex>
        </FormControl>
        <Flex fontSize={'xs'} color={'text.secondary'} align={'center'} gap={1}>
          {formik.values.count === initialValue ? (
            <>Current remaining scope</>
          ) : formik.values.count > initialValue ? (
            <Flex align="center" color="primary">
              <ArrowUpIcon w={3} h={3} />
              <Text as="span" ml={1}>
                {formik.values.count - initialValue} issues
              </Text>
            </Flex>
          ) : (
            <Flex align="center" color="primary">
              <ArrowDownIcon w={3} h={3} />
              <Text as="span" ml={1}>
                {initialValue - formik.values.count} issues
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Text
        color={'primary'}
        fontSize={'xs'}
        onClick={() => setActiveCard('scope')}
        cursor={'pointer'}
      >
        View Remaining Issues
      </Text>
    </Flex>
  );
};

export default ScopeCard;
