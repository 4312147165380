import { MetaTags, ZeroStatePage } from '@devd-client/devd/components';
import { useProcessPage } from '../../api';
import ProcessPage from './devd-process';

export const Process = () => {
  const { data } = useProcessPage();

  return (
    <>
      <MetaTags title="Process | Metrics | Devdynamics" />
      {data && data?.dto?.enabled ? (
        <ProcessPage metricsData={data} />
      ) : (
        <ZeroStatePage
          data={data?.dto}
          featureAlert={data?.featureEnable === false ? true : false}
        />
      )}
    </>
  );
};

export default Process;
