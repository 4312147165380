import { Flex, Text, useTheme } from '@chakra-ui/react';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

interface DistributionBarChartProps {
  data: any;
}

const ToolTipContainer = styled.div`
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 8px;
`;

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload?.length) {
    return (
      <ToolTipContainer>
        <Flex align="center">
          <Text
            fontSize="sm"
            fontWeight="normal"
            fontFamily="heading"
            color="text.primary"
          >
            {payload[0]?.payload?.xLabelName}
          </Text>
          :&nbsp;
          <Text
            fontSize="sm"
            fontWeight="medium"
            fontFamily="heading"
            color="text.primary"
          >
            {payload[0]?.value}
          </Text>
        </Flex>
      </ToolTipContainer>
    );
  }

  return null;
};

const DistributionBarChart: React.FC<DistributionBarChartProps> = ({
  data,
}) => {
  const { colors, fonts } = useTheme();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data?.data} barGap={1} barCategoryGap={2}>
        <XAxis
          style={{
            fontSize: 10,
            fontWeight: 500,
            color: colors.text.secondary,
            fontFamily: fonts.heading,
          }}
          dataKey={data?.chartMetadata?.xAxis}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          label={{
            value: data?.chartMetadata?.ylabel,
            angle: -90,
            fontSize: 10,
            fontWeight: 500,
            color: colors.text.secondary,
            fontFamily: fonts.heading,
          }}
          axisLine={false}
          tickLine={false}
          tick={false}
          width={10}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />

        {data?.keys?.map(({ key }: { key: string }) => (
          <Bar
            key={key}
            dataKey={key}
            shape={({ x, y, width, height }: any) => (
              <g>
                <rect
                  x={x}
                  y={y}
                  width={width}
                  height={height}
                  fill={colors.primary2Bg}
                />

                <rect
                  x={x}
                  y={y - 4}
                  width={width}
                  height={2}
                  fill={colors.primary}
                />
              </g>
            )}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DistributionBarChart;
