export const rfTeamTrackingIssueOptions = [
  {
    label: 'Issue Label',
    value: 'LABEL',
  },
  {
    label: 'Issue Project',
    value: 'PROJECT',
  },
  {
    label: 'Custom Field',
    value: 'CUSTOM',
  },
];

export const rfTeamTrackingGitOptions = [
  {
    label: 'Git Repo',
    value: 'REPO',
  },
];

export const rfTeamTrackingEventOptions = [
  {
    label: 'Git Repo',
    value: 'REPO',
  },
  {
    label: 'Pipeline Name',
    value: 'PROJECT',
  },
  {
    label: 'Job Name Pattern',
    value: 'NAME_PATTERN',
  },
];
