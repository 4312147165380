import { Box, Button, Flex, HStack } from '@chakra-ui/react';
import {
  PageHeader,
  PageToolbar,
  durationData,
  useQueryState,
  SearchBox,
  SearchInput,
  eliminateHtmlTags,
  SearchIconContainer,
  StyledSearchIcon,
  TeamMenu,
  Loader,
  removeEmptyQueryParams,
  PeriodType,
  useToastHook,
  DateFilter,
  useDebounce,
  DownloadButton,
} from '@devd-client/devd/components';
import { useNavigate } from 'react-router';
import { AiOutlinePlus } from 'react-icons/ai';
import { useEffect, useMemo, useState } from 'react';
import ListView from '../components/ListView';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import { apiClient } from '@devd-client/api';
import moment from 'moment';
import { useDashboardStore } from '@devd-client/devd/components';

interface TeamDetailsPageProps {
  teamsData: any;
}

export const TeamDetailsPage: React.FC<TeamDetailsPageProps> = ({
  teamsData,
}) => {
  const navigate = useNavigate();
  const [newToast] = useToastHook();
  const helpDocLink = teamsData?.dto?.helpDoc;
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [team, setTeam] = useQueryState('team');

  const [search, setSearch] = useQueryState('search');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { selected, selectedTeam, setSelected, setSelectedTeam } =
    useDashboardStore();

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);

  const [appState, dispatch] = useAuth();
  const plan = appState?.user?.dto?.plan;
  const [teamLength, setTeamLength] = useState(0);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, setSearch]);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'Organisation'
        ? setTeam('Organisation')
        : setTeam(selectedTeam.value);
    }
  }, [selected, searchTerm, selectedTeam.value]);

  useEffect(() => {
    search && setSearchTerm(search);

    team &&
      setSelectedTeam(
        team === 'Organisation'
          ? { value: '', label: 'Organisation' }
          : { value: team, label: team }
      );

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom' ||
              el.duration === 'Today' ||
              el.duration === 'Month' ||
              el.duration === 'Quarter'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSearchTerm(search);
  };

  const [pending, setPending] = useState(false);
  const downloadCSV = async () => {
    setPending(true);
    apiClient(
      `/v1/team/async/metric/org/${localStorage?.getItem('orgId')}?startDate=${
        selected.startDate
      }&endDate=${selected.endDate}&team=${selectedTeam.value}`,
      {
        method: 'GET',
        headers: {
          authorization: `bearer ${localStorage?.getItem('token')}`,
        },
      }
    )
      .then((res: any) => {
        setPending(false);
        newToast({
          message: 'We will send you the download link via email',
          status: 'success',
        });
      })
      .catch((err) => {
        setPending(false);
        newToast({
          message:
            err.message ||
            'There is some error in downloading data. Please try again or contact support at support@devdynamics.ai',
          status: 'error',
        });
      });
  };

  const memoizedListView = useMemo(
    () => (
      <ListView
        searchTerm={debouncedSearchTerm}
        selectedTeam={selectedTeam}
        setTeamLength={setTeamLength}
        selected={selected}
      />
    ),
    [debouncedSearchTerm, selected, selectedTeam, setTeamLength]
  );

  return (
    <Flex w="full" direction="column" gap={4}>
      <PageHeader
        headingText="Teams"
        subHeadingText={teamsData?.dto?.title}
        helpDocLink={helpDocLink}
        component={
          <Flex gap={4}>
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                navigate('/compare-teams');
              }}
            >
              Compare Teams
            </Button>
            <Button
              variant="primary"
              size="sm"
              leftIcon={<AiOutlinePlus />}
              onClick={() => {
                if (
                  appState?.user?.dto?.teamAllowed &&
                  (plan === 'Free' || plan === 'Trial') &&
                  teamLength >= appState?.user?.dto?.teamAllowed
                ) {
                  newToast({
                    message:
                      'Upgrade your plan to add more teams, or contact Devdynamics support',
                    status: 'error',
                  });
                } else {
                  navigate('/settings/teams/add-team');
                }
              }}
            >
              New Team
            </Button>
          </Flex>
        }
      />

      <Flex justify="space-between" align="center" width="100%">
        <TeamMenu
          selectedTeam={selectedTeam}
          handleSelectedTeam={setSelectedTeam}
          AuthActionTypes={AuthActionTypes}
          getUser={getUser}
          appState={appState}
          dispatch={dispatch}
        />

        <Flex gap={2}>
          <PageToolbar
            rightComp={
              <HStack spacing={6}>
                <DateFilter
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                  AuthActionTypes={AuthActionTypes}
                />
              </HStack>
            }
          />

          <DownloadButton downloadCSV={downloadCSV} />
        </Flex>
      </Flex>

      {memoizedListView}

      {pending && <Loader />}
    </Flex>
  );
};
export default TeamDetailsPage;
