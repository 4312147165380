import { Flex, Skeleton, Text } from '@chakra-ui/react';
import ProgressBar from './ProgressBar';
import TaskDistribution from './TaskDistribution';

type Props = {
  data: any;
  isLoading?: boolean;
};

const ScopeDetails = ({ data, isLoading }: Props) => {
  return (
    <Flex direction="column" gap={4} h="full" w="full">
      <Flex align="center" justify="space-between">
        {isLoading ? (
          <Skeleton height="18px" width="45%" />
        ) : (
          <>
            <Text fontFamily="heading" fontSize="sm" color="text.primary">
              <Text as="span" color="text.secondary2">
                Completion
              </Text>{' '}
              <Text as="span" fontWeight={'medium'}>
                {data?.completedTasks}
              </Text>
              /
              <Text as="span" fontWeight={'medium'}>
                {data?.totalTasks}
              </Text>
            </Text>
          </>
        )}

        {/* <Text fontFamily="heading" fontSize="sm" color="text.primary">
          <Text as="span" color="text.secondary2">
            Scope Change
          </Text>{' '}
          <Tag
            bg="#E8F6ED"
            color="success"
            fontSize="sm"
            fontWeight="medium"
            fontFamily="heading"
            p={1}
            borderRadius={'4px'}
          >
            +{data?.scopeChange}
          </Tag>
        </Text> */}
      </Flex>

      {isLoading ? (
        <Skeleton height="32px" width="100%" />
      ) : (
        <ProgressBar
          data={[
            {
              todo: data?.todoData?.totalCount,
              inProgress: data?.inProgressData?.totalCount,
              done: data?.doneData?.totalCount,
            },
          ]}
        />
      )}

      {isLoading ? (
        <Skeleton height="112px" width="100%" />
      ) : (
        <TaskDistribution data={data} />
      )}
    </Flex>
  );
};

export default ScopeDetails;
