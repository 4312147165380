import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
} from '@tanstack/react-query';
import {
  averageActiveDaysDetailGraphql,
  changeFailureGraphql,
  commitGraphql,
  customMetricsGraphql,
  DeploymentDetails,
  getContributorGraphql,
  GoalMetricDetailsGit,
  GoalMetricDetailsJira,
  issueAgeingDetailsGraphql,
  issueChartsGraphql,
  memberCountGraphql,
  PrAgeingDetailsGraphql,
  prChartsGraphql,
  prHealthIndexGraphql,
  prReviewResponsivenessDetailsGraphql,
  QualityDetails,
  VulnerabilityAlertsDetails,
  requirementStabilityDetailsGraphql,
  WellbeingDetails,
  workingAgreementGraphql,
  locVsPrCycleTimeDetailsGraphql,
  commitReportDetailWithBreakdownGraphql,
  customBuildMetricGraphql,
  customBuildStageMetricGraphql,
  customIssueMetricGraphql,
  locVsPRCycleTimeDetailsGraphql,
  PRHealthIndexGraphql,
  PRAgeingDetailsGraphql,
  PRChartsGraphql,
  PRReviewResponsivenessDetailsGraphql,
  meetingDetailsGraphql,
  issueTableChartsGraphql,
  issueReportDetailForRecruitCRMGraphql,
  issueReportDetailForRecruitCRMPriorityDistributedDataGraphql,
  documentDetailsGraphql,
  sprintDetailsGraphql,
  testRunDetailsGraphql,
  testCountDetailsGraphql,
} from './query';
import {
  CFR_CHART_KEYS,
  CONTRIBUTOR_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  GOAL_METRIC_GIT_KEYS,
  GOAL_METRIC_JIRA_KEYS,
  ISSUE_CHART_KEYS,
  PR_CHART_KEYS,
  WELLBEING_CHART_KEYS,
  WORKING_AGREEMENT_KEYS,
  MEMBER_CHART_KEYS,
  COMMIT_CHART_KEYS,
  QUALITY_CHART_KEYS,
  ISSUE_AGE_CHART_KEYS,
  REQUIREMENT_STABILITY_CHARTS,
  PR_AGEING_CHARTS,
  REVIEW_RESPONSIVENESS_INDEX_CHARTS,
  PR_HEALTH_INDEX_CHARTS,
  ACTIVE_DAYS_CHARTS,
  DEPENDENCY_VULNERABILITIES_CHART_KEYS,
  LOC_VS_PR_CYCLE_TIME_CHARTS,
  WORK_BREAKDOWN_CHARTS,
  ISSUE_LIFECYCLE_DIST_CHARTS,
  MEETING_CHARTS,
  ISSUE_REPORT_DETAIL_FOR_RECRUIT_CRM_CHARTS,
  ISSUE_REPORT_DETAIL_FOR_RECRUIT_CRM_PRIORITY_DISTRIBUTED_DATA_CHARTS,
  DOCUMENT_UPDATED_CHARTS,
  SPRINT_CHARTS,
  TEST_RUN_CHARTS,
  TEST_DETAIL_CHARTS,
} from '../../shared/utils';
import { getDataByKey } from '../utils/charts.utils';

export const allMetricsList: { type: string; query: string }[] = [
  ...PR_CHART_KEYS.map((key: string) => ({ type: key, query: 'prReport' })),

  ...GOAL_METRIC_GIT_KEYS.map((key: string) => ({
    type: key,
    query: 'goalMetricDetailsGit',
  })),

  ...GOAL_METRIC_JIRA_KEYS.map((key: string) => ({
    type: key,
    query: 'goalMetricDetailsJira',
  })),

  ...ISSUE_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'issueReport',
  })),

  ...CFR_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'changeFailure',
  })),

  ...DEPLOY_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'deploymentDetails',
  })),

  ...WELLBEING_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'wellbeing',
  })),

  ...CONTRIBUTOR_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'contributors',
  })),

  ...MEMBER_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'memberCount',
  })),

  ...COMMIT_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'commitReport',
  })),

  ...WORKING_AGREEMENT_KEYS.map((key: string) => ({
    type: key,
    query: 'workingAgreement',
  })),

  ...QUALITY_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'qualityDetail',
  })),

  ...DEPENDENCY_VULNERABILITIES_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'vulnerabilityAlertsDetail',
  })),

  ...ISSUE_AGE_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'issueAgeing',
  })),

  ...REQUIREMENT_STABILITY_CHARTS.map((key: string) => ({
    type: key,
    query: 'requirementStability',
  })),

  ...PR_AGEING_CHARTS.map((key: string) => ({
    type: key,
    query: 'prAgeingDistribution',
  })),

  ...REVIEW_RESPONSIVENESS_INDEX_CHARTS.map((key: string) => ({
    type: key,
    query: 'prReviewResponsivenessDetails',
  })),

  ...PR_HEALTH_INDEX_CHARTS.map((key: string) => ({
    type: key,
    query: 'prHealthIndexDetails',
  })),

  ...LOC_VS_PR_CYCLE_TIME_CHARTS.map((key: string) => ({
    type: key,
    query: 'locVsPrCycleTimeDetails',
  })),

  ...ACTIVE_DAYS_CHARTS.map((key: string) => ({
    type: key,
    query: 'activeDays',
  })),

  ...WORK_BREAKDOWN_CHARTS.map((key: string) => ({
    type: key,
    query: 'workBreakdown',
  })),

  ...ISSUE_LIFECYCLE_DIST_CHARTS.map((key: string) => ({
    type: key,
    query: 'issueReport',
  })),

  ...MEETING_CHARTS.map((key: string) => ({
    type: key,
    query: 'meetingReport',
  })),

  ...ISSUE_REPORT_DETAIL_FOR_RECRUIT_CRM_CHARTS.map((key: string) => ({
    type: key,
    query: 'issueReportDetailForRecruitCRM',
  })),

  ...ISSUE_REPORT_DETAIL_FOR_RECRUIT_CRM_PRIORITY_DISTRIBUTED_DATA_CHARTS.map(
    (key: string) => ({
      type: key,
      query: 'issueReportDetailForRecruitCRMPriorityDistributedData',
    })
  ),

  ...DOCUMENT_UPDATED_CHARTS.map((key: string) => ({
    type: key,
    query: 'documentUpdatedDetails',
  })),

  ...SPRINT_CHARTS.map((key: string) => ({
    type: key,
    query: 'sprintDetails',
  })),

  ...TEST_RUN_CHARTS.map((key: string) => ({
    type: key,
    query: 'testRunDetails',
  })),

  ...TEST_DETAIL_CHARTS.map((key: string) => ({
    type: key,
    query: 'testCountDetails',
  })),
];

type DynamicQueryKey = string;

enum MetricsType {
  PrReport = 'prReport',
  IssueReport = 'issueReport',
  DeploymentDetails = 'deploymentDetails',
  ChangeFailure = 'changeFailure',
  GoalMetricDetailsGit = 'goalMetricDetailsGit',
  GoalMetricDetailsJira = 'goalMetricDetailsJira',
  Wellbeing = 'wellbeing',
  Contributors = 'contributors',
  CommitReport = 'commitReport',
  WorkingAgreement = 'workingAgreement',
  MemberCount = 'memberCount',
  QualityDetail = 'qualityDetail',
  VulnerabilityAlertsDetail = 'vulnerabilityAlertsDetail',
  IssueAgeing = 'issueAgeing',
  RequirementStability = 'requirementStability',
  PrAgeingDistribution = 'prAgeingDistribution',
  PrReviewResponsivenessDetails = 'prReviewResponsivenessDetails',
  PrHealthIndexDetails = 'prHealthIndexDetails',
  LocVsPrCycleTimeDetails = 'locVsPrCycleTimeDetails',
  ActiveDays = 'activeDays',
  WorkBreakdown = 'workBreakdown',
  MeetingReport = 'meetingReport',
  IssueReportDetailForRecruitCRM = 'issueReportDetailForRecruitCRM',
  IssueReportDetailForRecruitCRMPriorityDistributedData = 'issueReportDetailForRecruitCRMPriorityDistributedData',
  DocumentUpdatedDetails = 'documentUpdatedDetails',
  SprintDetails = 'sprintDetails',
  TestRunDetails = 'testRunDetails',
  TestCountDetails = 'testCountDetails',
}

export const useChartTableGql = (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: {
    [key: string]:
      | string
      | string[]
      | null
      | { [key: string]: string | string[] | null | boolean | number };
  },
  pageNumber: number,
  pageSize: number,
  detailEntity: string,
  metricTable = false,
  isQueryReady = true
) => {
  const query = allMetricsList
    .filter((item: any) => item.type === metricType)
    .map((el: any) => el.query)[0];

  let queryFn: QueryFunction<any, QueryKey, number> = ({ pageParam = 0 }) =>
    metricTable
      ? PRChartsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        )
      : prChartsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );

  switch (query) {
    case MetricsType.PrReport:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          PRChartsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = ({ pageParam = 0 }) =>
          prChartsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      }

      break;

    case MetricsType.IssueReport:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          issueTableChartsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = ({ pageParam = 0 }) =>
          issueChartsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      }
      break;

    case MetricsType.ChangeFailure:
      queryFn = ({ pageParam = 0 }) =>
        changeFailureGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;

    case MetricsType.DeploymentDetails:
      queryFn = ({ pageParam = 0 }) =>
        DeploymentDetails(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;
    case MetricsType.Wellbeing:
      queryFn = ({ pageParam = 0 }) =>
        WellbeingDetails(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;

    case MetricsType.QualityDetail:
      queryFn = ({ pageParam = 0 }) =>
        QualityDetails(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;

    case MetricsType.VulnerabilityAlertsDetail:
      queryFn = ({ pageParam = 0 }) =>
        VulnerabilityAlertsDetails(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;

    case MetricsType.GoalMetricDetailsGit:
      queryFn = ({ pageParam = 0 }) =>
        GoalMetricDetailsGit(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;
    case MetricsType.GoalMetricDetailsJira:
      queryFn = ({ pageParam = 0 }) =>
        GoalMetricDetailsJira(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;
    case MetricsType.Contributors:
      queryFn = ({ pageParam = 0 }) =>
        getContributorGraphql(startDate, endDate, filter, pageParam, pageSize);
      break;

    case MetricsType.WorkingAgreement:
      queryFn = () => workingAgreementGraphql(startDate, endDate, filter);
      break;

    case MetricsType.MemberCount:
      queryFn = () => memberCountGraphql(filter);
      break;

    case MetricsType.CommitReport:
      queryFn = ({ pageParam = 0 }) =>
        commitGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;

    case MetricsType.IssueAgeing:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          issueTableChartsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = ({ pageParam = 0 }) =>
          issueAgeingDetailsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      }
      break;

    case MetricsType.RequirementStability:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          issueTableChartsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = ({ pageParam = 0 }) =>
          requirementStabilityDetailsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      }

      break;

    case MetricsType.PrAgeingDistribution:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          PRAgeingDetailsGraphql(
            startDate,
            endDate,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = () => PrAgeingDetailsGraphql(startDate, endDate, filter);
      }
      break;

    case MetricsType.PrReviewResponsivenessDetails:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          PRReviewResponsivenessDetailsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = ({ pageParam = 0 }) =>
          prReviewResponsivenessDetailsGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      }
      break;
    case MetricsType.PrHealthIndexDetails:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          PRHealthIndexGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = ({ pageParam = 0 }) =>
          prHealthIndexGraphql(
            startDate,
            endDate,
            metricType,
            filter,
            pageParam,
            pageSize
          );
      }
      break;

    case MetricsType.LocVsPrCycleTimeDetails:
      if (metricTable) {
        queryFn = ({ pageParam = 0 }) =>
          locVsPRCycleTimeDetailsGraphql(
            startDate,
            endDate,
            filter,
            pageParam,
            pageSize
          );
      } else {
        queryFn = () =>
          locVsPrCycleTimeDetailsGraphql(startDate, endDate, filter);
      }
      break;

    case MetricsType.ActiveDays:
      queryFn = ({ pageParam = 0 }) =>
        averageActiveDaysDetailGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;

    case MetricsType.WorkBreakdown:
      queryFn = ({ pageParam = 0 }) =>
        commitReportDetailWithBreakdownGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;

    case MetricsType.MeetingReport:
      queryFn = ({ pageParam = 0 }) =>
        meetingDetailsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;

    case MetricsType.IssueReportDetailForRecruitCRM:
      queryFn = ({ pageParam = 0 }) =>
        issueReportDetailForRecruitCRMGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;

    case MetricsType.IssueReportDetailForRecruitCRMPriorityDistributedData:
      queryFn = ({ pageParam = 0 }) =>
        issueReportDetailForRecruitCRMPriorityDistributedDataGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;

    case MetricsType.DocumentUpdatedDetails: {
      queryFn = ({ pageParam = 0 }) =>
        documentDetailsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;
    }

    case MetricsType.SprintDetails: {
      queryFn = ({ pageParam = 0 }) =>
        sprintDetailsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageParam,
          pageSize
        );
      break;
    }

    case MetricsType.TestRunDetails: {
      queryFn = ({ pageParam = 0 }) =>
        testRunDetailsGraphql(
          startDate,
          endDate,
          metricType,
          pageParam,
          pageSize
        );
      break;
    }

    case MetricsType.TestCountDetails: {
      queryFn = ({ pageParam = 0 }) =>
        testCountDetailsGraphql(
          startDate,
          endDate,
          metricType,
          pageParam,
          pageSize
        );
      break;
    }

    default:
      switch (detailEntity) {
        case 'BuildStageDetailCustom': {
          queryFn = ({ pageParam = 0 }) =>
            customBuildStageMetricGraphql(
              startDate,
              endDate,
              metricType,
              filter,
              pageParam,
              pageSize
            );
          break;
        }
        case 'BuildDetailCustom': {
          queryFn = ({ pageParam = 0 }) =>
            customBuildMetricGraphql(
              startDate,
              endDate,
              metricType,
              filter,
              pageParam,
              pageSize
            );
          break;
        }
        case 'IssueDetailCustom': {
          queryFn = ({ pageParam = 0 }) =>
            customIssueMetricGraphql(
              startDate,
              endDate,
              metricType,
              filter,
              pageParam,
              pageSize
            );
          break;
        }
        default: {
          queryFn = ({ pageParam = 0 }) =>
            customMetricsGraphql(
              startDate,
              endDate,
              metricType,
              filter,
              pageParam,
              pageSize
            );
          break;
        }
      }
      break;
  }

  return useInfiniteQuery(
    [
      metricType as DynamicQueryKey,
      startDate,
      endDate,
      filter,
      pageNumber,
      pageSize,
      'metric_details',
    ],
    queryFn,
    {
      select: (data) => getPayload(query, data, metricTable),
      enabled: !!metricType && isQueryReady,
      keepPreviousData: false,
      getNextPageParam: (_lastGroup, groups) => {
        const lastPageObj: any = Object.values(_lastGroup);
        if (metricTable) {
          return groups.length;
        } else if (!lastPageObj[0]?.totalPages) {
          return undefined;
        } else {
          if (
            lastPageObj[0]?.data?.length < pageSize ||
            lastPageObj[0]?.totalPages === 1
          ) {
            return undefined;
          } else {
            return groups.length;
          }
        }
      },
      refetchOnWindowFocus: false,
    }
  );
};

const getPayload = (query: string, graphQLData: any, metricTable: boolean) => {
  switch (query) {
    case MetricsType.PrReport: {
      if (metricTable) {
        const flatData = getDataByKey(graphQLData, 'PRReportDetail');

        return {
          data: flatData,
          totalDBRowCount: graphQLData?.pages?.[0].PRReportDetail.totalCount,
        };
      } else {
        return {
          data: getDataByKey(graphQLData, 'PrReportDetail'),
        };
      }
    }

    case MetricsType.IssueReport:
      if (metricTable) {
        const flatData = getDataByKey(graphQLData, 'IssueTableDetail');

        return {
          data: flatData,
          totalDBRowCount: graphQLData?.pages?.[0].IssueTableDetail.totalCount,
        };
      } else {
        return {
          data: getDataByKey(graphQLData, 'IssueReportDetail'),
        };
      }

    case MetricsType.ChangeFailure:
      return {
        data: getDataByKey(graphQLData, 'ChangeFailureDetail'),
      };
    case MetricsType.DeploymentDetails:
      return {
        data: getDataByKey(graphQLData, 'DeploymentDetails'),
      };
    case MetricsType.Wellbeing:
      return {
        data: getDataByKey(graphQLData, 'WellBeingDetailsByType'),
      };
    case MetricsType.GoalMetricDetailsGit:
      return {
        data: getDataByKey(graphQLData, 'GoalMetricDetailsGit'),
      };
    case MetricsType.GoalMetricDetailsJira:
      return {
        data: getDataByKey(graphQLData, 'GoalMetricDetailsJira'),
      };
    case MetricsType.Contributors: {
      return {
        data: getDataByKey(graphQLData, 'ContributorDetailsV2'),
        totalDBRowCount:
          graphQLData?.pages?.[0]?.ContributorDetailsV2?.totalCount,
      };
    }
    case MetricsType.MemberCount:
      return {
        data: getDataByKey(graphQLData, 'TeamMembers'),
      };
    case MetricsType.WorkingAgreement:
      return {
        data: getDataByKey(graphQLData, 'TeamStatsWorkingAgreementDetails'),
      };
    case MetricsType.QualityDetail:
      return {
        data: getDataByKey(graphQLData, 'QualityBranchDetailByType'),
      };
    case MetricsType.VulnerabilityAlertsDetail:
      return {
        data: getDataByKey(graphQLData, 'VulnerabilityAlertsDetail'),
      };
    case MetricsType.CommitReport:
      return {
        data:
          graphQLData?.pages?.flatMap((page: any) =>
            page.CommitReportDetail.data.map((item: any) => ({
              ...item,
              id: item.attributes.find(
                (attr: any) => attr?.key === 'Related PR Id'
              )?.value,
            }))
          ) ?? [],
      };
    case MetricsType.IssueAgeing:
      if (metricTable) {
        const flatData = getDataByKey(graphQLData, 'IssueTableDetail');

        return {
          data: flatData,
          totalDBRowCount: graphQLData?.pages?.[0].IssueTableDetail.totalCount,
        };
      } else {
        return {
          data: getDataByKey(graphQLData, 'IssueDetailsForAge'),
        };
      }
    case MetricsType.RequirementStability:
      if (metricTable) {
        const flatData = getDataByKey(graphQLData, 'IssueTableDetail');

        return {
          data: flatData,
          totalDBRowCount: graphQLData?.pages?.[0].IssueTableDetail.totalCount,
        };
      } else {
        return {
          data: getDataByKey(graphQLData, 'RequirementStabilityDetails'),
        };
      }

    case MetricsType.PrAgeingDistribution: {
      if (metricTable) {
        const flatData = getDataByKey(graphQLData, 'PRAgeingDetails');

        return {
          data: flatData,
          totalDBRowCount: graphQLData?.pages?.[0].PRAgeingDetails.totalCount,
        };
      } else {
        return {
          data: getDataByKey(graphQLData, 'PrAgeingDetails'),
        };
      }
    }

    case MetricsType.PrReviewResponsivenessDetails: {
      if (metricTable) {
        const flatData = getDataByKey(
          graphQLData,
          'PRReviewResponsivenessDetails'
        );

        return {
          data: flatData,
          totalDBRowCount:
            graphQLData?.pages?.[0].PRReviewResponsivenessDetails.totalCount,
        };
      } else {
        return {
          data: getDataByKey(graphQLData, 'PrReviewResponsivenessDetails'),
        };
      }
    }

    case MetricsType.PrHealthIndexDetails: {
      if (metricTable) {
        const flatData = getDataByKey(graphQLData, 'PRHealthIndexesDetails');

        return {
          data: flatData,
          totalDBRowCount:
            graphQLData?.pages?.[0].PRHealthIndexesDetails.totalCount,
        };
      } else {
        return {
          data: getDataByKey(graphQLData, 'PrHealthIndexesDetails'),
        };
      }
    }

    case MetricsType.LocVsPrCycleTimeDetails: {
      if (metricTable) {
        const flatData = getDataByKey(graphQLData, 'LocVsPRCycleTimeDetails');

        return {
          data: flatData,
          totalDBRowCount:
            graphQLData?.pages?.[0].LocVsPRCycleTimeDetails.totalCount,
        };
      } else {
        return {
          data: getDataByKey(graphQLData, 'LocVsPrCycleTimeDetails'),
        };
      }
    }

    case MetricsType.ActiveDays:
      return {
        data:
          graphQLData?.pages?.flatMap(
            (page: any) => page?.AverageActiveDaysDetail ?? []
          ) ?? [],
      };
    case MetricsType.MeetingReport:
      return {
        data: getDataByKey(graphQLData, 'MeetingDetails'),
      };
    case MetricsType.IssueReportDetailForRecruitCRM:
      return {
        data: getDataByKey(graphQLData, 'IssueReportDetailForRecruitCRM'),
      };
    case MetricsType.IssueReportDetailForRecruitCRMPriorityDistributedData:
      return {
        data: getDataByKey(
          graphQLData,
          'IssueReportDetailForRecruitCRMPriorityDistributedData'
        ),
      };

    case MetricsType.WorkBreakdown:
      return {
        data:
          graphQLData?.pages?.flatMap((page: any) =>
            page.CommitReportDetailWithBreakdown.data?.map((item: any) => {
              const total =
                item.helped + item.newwork + item.refactor + item.rework;

              return {
                ...item,
                readings: [
                  {
                    name: 'helped',
                    value: parseFloat(
                      ((item.helped / total) * 100)?.toFixed(4)
                    ),
                    color: '#F1B813',
                  },
                  {
                    name: 'newwork',
                    value: parseFloat(
                      ((item.newwork / total) * 100)?.toFixed(4)
                    ),
                    color: '#71E03A',
                  },
                  {
                    name: 'refactor',
                    value: parseFloat(
                      ((item.refactor / total) * 100)?.toFixed(4)
                    ),
                    color: '#0095E6',
                  },
                  {
                    name: 'rework',
                    value: parseFloat(
                      ((item.rework / total) * 100)?.toFixed(4)
                    ),
                    color: '#FF5230',
                  },
                ],
              };
            })
          ) ?? [],
      };

    case MetricsType.DocumentUpdatedDetails:
      return {
        data: getDataByKey(graphQLData, 'DocumentDetails'),
      };

    case MetricsType.SprintDetails:
      return {
        data: getDataByKey(graphQLData, 'SprintActivityByOrg'),
      };

    case MetricsType.TestRunDetails: {
      return {
        data: getDataByKey(graphQLData, 'TestRunMetricDetailResponse'),
      };
    }

    case MetricsType.TestCountDetails: {
      return {
        data: getDataByKey(graphQLData, 'TestDetailsResponse'),
      };
    }

    default:
      return graphQLData?.pages?.[0].CustomMetricDetailJira
        ? {
            data: getDataByKey(graphQLData, 'CustomMetricDetailJira'),
          }
        : graphQLData?.pages?.[0].BuildDetailCustom
        ? {
            data: getDataByKey(graphQLData, 'BuildDetailCustom'),
          }
        : graphQLData?.pages?.[0].BuildStageDetailCustom
        ? {
            data: getDataByKey(graphQLData, 'BuildStageDetailCustom'),
          }
        : graphQLData?.pages?.[0].IssueDetailCustom
        ? {
            data: getDataByKey(graphQLData, 'IssueDetailCustom'),
          }
        : {
            ...graphQLData?.pages?.[0].CustomMetricDetailJira,
          };
  }
};
