import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import {
  splitTime,
  TestStatsItem,
  truncateText,
} from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { FiSkipForward } from 'react-icons/fi';
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from 'react-icons/io';
import { NavigateFunction } from 'react-router';
import RecentRuns from './RecentRuns';
import GetTestSuiteDetails from './GetTestSuiteDetails';

const columnHelper = createColumnHelper<any>();

export const columns = (navigate: NavigateFunction, colors: any) => [
  columnHelper.accessor('testSuiteDetail', {
    cell: (info) => (
      <Flex direction="column" gap={1}>
        <Text
          onClick={() =>
            navigate(
              `/dashboard/test-suite/${encodeURIComponent(
                info.row.original?.testSuiteDetail?.name
              )}?id=${info.row.original?.testSuiteDetail?.projectId}`
            )
          }
          color="primary"
          textDecoration="underline"
          fontFamily="heading"
          fontSize="md"
          cursor="pointer"
          wordBreak={'break-all'}
        >
          {info.row.original?.testSuiteDetail?.name}
        </Text>
        <GetTestSuiteDetails
          keyOfTestSuite="Project"
          value={info.row.original?.testSuiteDetail?.projectId}
          link={info.row.original?.testSuiteDetail?.repo}
        />
        <GetTestSuiteDetails
          keyOfTestSuite="Type"
          value={info.row.original?.testSuiteDetail?.type}
        />
        <GetTestSuiteDetails
          keyOfTestSuite="Env"
          value={info.row.original?.testSuiteDetail?.env || 'NA'}
        />
      </Flex>
    ),

    header: 'Test Suite',
    size: 200,
  }),
  columnHelper.accessor('testStats', {
    cell: (info) => (
      <Flex direction="column" gap={2}>
        <Flex align="center">
          <Text
            fontFamily={'heading'}
            fontSize="md"
            fontWeight="medium"
            color="text.primary"
          >
            Total:
          </Text>
          <Text
            ml={1}
            fontFamily={'heading'}
            fontSize="md"
            color="text.primary"
            fontWeight={'medium'}
          >
            {info.row.original?.testStats?.avgTotalTests}
          </Text>
        </Flex>
        <Flex gap={4} align="center">
          <TestStatsItem
            icon={IoMdCheckmarkCircleOutline}
            label="Passed Tests"
            count={info.row.original?.testStats?.avgTotalPassedTest}
            color={colors.success}
            percentage={
              (info.row.original?.testStats?.avgTotalPassedTest /
                info.row.original?.testStats?.avgTotalTests) *
              100
            }
          />

          <TestStatsItem
            icon={IoMdCloseCircleOutline}
            label="Failed Tests"
            count={info.row.original?.testStats?.avgFailedTests}
            color={colors.error}
            percentage={
              (info.row.original?.testStats?.avgFailedTests /
                info.row.original?.testStats?.avgTotalTests) *
              100
            }
          />

          <TestStatsItem
            icon={FiSkipForward}
            label="Skipped Tests"
            count={info.row.original?.testStats?.avgSkippedTests}
            color={colors.warning}
            percentage={
              (info.row.original?.testStats?.avgSkippedTests /
                info.row.original?.testStats?.avgTotalTests) *
              100
            }
          />
        </Flex>
      </Flex>
    ),
    header: 'Test Stats',
    size: 180,
  }),
  columnHelper.accessor('successRate', {
    cell: (info) => (
      <Text
        fontFamily={'heading'}
        fontSize="md"
        color="text.primary"
        fontWeight={'medium'}
      >{`${info.getValue() || 0}%`}</Text>
    ),
    header: 'Success Rate',
    size: 100,
  }),
  columnHelper.accessor('flakyTests', {
    cell: (info) => (
      <Flex direction="column">
        <Text
          fontFamily={'heading'}
          fontSize="md"
          color="text.primary"
          fontWeight={'medium'}
        >
          {info.row.original?.flakyTests?.count}
        </Text>

        <Tooltip
          label={
            <Box>
              {info.row.original?.flakyTests?.tests.map(
                (test: string, index: number) => (
                  <Text key={index} fontSize="sm" color="white">
                    {test}
                  </Text>
                )
              )}
            </Box>
          }
          hasArrow
        >
          <Text
            fontSize="sm"
            fontFamily="heading"
            color="text.secondary2"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {truncateText(info.row.original?.flakyTests?.tests?.join(','))}
          </Text>
        </Tooltip>
      </Flex>
    ),
    header: 'Flaky Tests',
    size: 100,
  }),
  columnHelper.accessor('recentRuns', {
    cell: (info) => <RecentRuns data={info.row.original?.recentRuns} />,

    header: 'Recent Runs',
    size: 180,
  }),
  columnHelper.accessor('avgTimeInSecs', {
    cell: (info) =>
      info.getValue() ? <>{splitTime(info.getValue() / 3600)}</> : 0,

    header: 'Duration',
    size: 100,
  }),
];
