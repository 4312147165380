export const RefreshIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.69758 13.1783C7.297 13.8879 9.20197 13.8494 10.8333 12.9075C13.5436 11.3427 14.4722 7.87704 12.9074 5.16672L12.7408 4.87804M3.09217 10.8335C1.52737 8.12314 2.45599 4.65746 5.16632 3.09265C6.79764 2.15081 8.70261 2.11227 10.302 2.82185M1.66211 10.8892L3.48348 11.3773L3.97151 9.5559M12.0283 6.44393L12.5163 4.62256L14.3377 5.1106"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshIcon;
