export function SprintIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8252_4359)">
        <path
          d="M11.7109 12.8916H14.7467M14.7467 12.8916L13.0602 11.2051M14.7467 12.8916L13.0602 14.5781"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 1.42285L6.31348 3.10931L8 4.79583"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.98804 3.10938C9.78228 3.10938 12.0475 5.37464 12.0475 8.16894C12.0475 10.9632 9.78228 13.2284 6.98804 13.2284H1.25391"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.46339 3.78418C2.94891 4.65843 1.92969 6.29475 1.92969 8.16911C1.92969 9.3081 2.3061 10.3592 2.94121 11.2048"
          stroke={props.color || '#2A2A2F'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8252_4359">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
