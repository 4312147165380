import { Flex, GridItem, Heading, Text } from '@chakra-ui/react';
import { useMetricsTableStore } from '@devd-client/devd/components';
import { Link, useLocation } from 'react-router-dom';
import { GetOptionsType, Option } from '../../../utils/metricTableTypes';

export default function SubHeading({
  heading,
  chartKey,
  getOptions,
}: {
  heading: string;
  chartKey: string;
  getOptions: (type: GetOptionsType) => Option[];
}) {
  const location = useLocation();
  const metricFilterBy = useMetricsTableStore();
  return !['contributors', 'deliverables'].includes(
    location.pathname.split('/')[1]
  ) ? (
    <GridItem>
      <Heading as="h4" size={'lg'}>
        {chartKey === 'PR_AGEING_DISTRIBUTION' ? 'Pull Request' : heading}
      </Heading>

      {['TOP_REVIEWER_DISTRIBUTION', 'PR_REVIEW', 'PR_REVIEWED'].includes(
        chartKey
      ) &&
        metricFilterBy.reviewer && (
          <Flex w={'full'} gap={1} ml={0.5}>
            <Text as={'strong'}>Reviewer: </Text>
            <Link to={`/contributors/${metricFilterBy.reviewer}`}>
              <Text decoration={'underline'} as={'span'}>
                {metricFilterBy.reviewer}
              </Text>
            </Link>
          </Flex>
        )}
      {chartKey === 'PR_AGEING_DISTRIBUTION' && metricFilterBy.filterAge && (
        <Flex w={'full'} gap={1} ml={0.5}>
          <Text as={'strong'}>PR Age: </Text>
          <Text as={'span'}>
            {
              getOptions('pr_age')?.find(
                (option: Option) => option.value === metricFilterBy.filterAge
              )?.label
            }
          </Text>
        </Flex>
      )}
      {chartKey === 'LOC_VS_PR_CYCLE_TIME' && metricFilterBy.prSize && (
        <Flex w={'full'} gap={1} ml={0.5}>
          <Text as={'strong'}>PR size: </Text>
          <Text as={'span'}>
            {metricFilterBy.prSize === '800' ? '800+' : metricFilterBy.prSize}
          </Text>
        </Flex>
      )}
      {chartKey === 'REVIEW_RESPONSIVENESS_INDEX' &&
        ['Review Responsiveness Index', 'Review Responsiveness'].includes(
          metricFilterBy.name ?? ''
        ) && (
          <Flex w={'full'} gap={1} ml={0.5}>
            <Text as={'strong'}>Review Response: </Text>
            <Text as={'span'}>
              {metricFilterBy.prReviewResponsivenessType === 'MORE_THAN_24HRS'
                ? 'More than 24HRS'
                : 'Less than 24HRS'}
            </Text>
          </Flex>
        )}
      {chartKey === 'REVIEW_RESPONSIVENESS_INDEX' &&
        ['Feedback Responsiveness Index', 'Feedback Responsiveness'].includes(
          metricFilterBy.name ?? ''
        ) && (
          <Flex w={'full'} gap={1} ml={0.5}>
            <Text as={'strong'}>Feedback Response: </Text>
            <Text as={'span'}>
              {metricFilterBy.prFeedbackResponsivenessType === 'MORE_THAN_24HRS'
                ? 'More than 24HRS'
                : 'Less than 24HRS'}
            </Text>
          </Flex>
        )}
      {chartKey === 'REVIEW_RESPONSIVENESS_INDEX' &&
        ['Review Completion'].includes(metricFilterBy.name ?? '') && (
          <Flex w={'full'} gap={1} ml={0.5}>
            <Text as={'strong'}>Review Feedback: </Text>
            <Text as={'span'}>
              {metricFilterBy.isPrReviewedWithComment
                ? 'With Comment'
                : 'Without Comment'}
            </Text>
          </Flex>
        )}

      {chartKey === 'ISSUE_THROUGHPUT' && metricFilterBy.filterStatus && (
        <Flex w={'full'} gap={1} ml={0.5}>
          <Text as={'strong'}>Issue Throughput: </Text>
          <Text as={'span'}>{metricFilterBy.filterStatus}</Text>
        </Flex>
      )}
      {chartKey === 'ISSUE_CYCLE_TIME_DIST' &&
        metricFilterBy.cycleTimeSpread && (
          <Flex w={'full'} gap={1} ml={0.5}>
            <Text as={'strong'}>Issue Cycle Time Age: </Text>
            <Text as={'span'}>
              {
                getOptions('cycleTimeSpread')?.find(
                  (option: Option) =>
                    option.value === metricFilterBy.cycleTimeSpread
                )?.label
              }
            </Text>
          </Flex>
        )}
      {chartKey === 'CHANGE_AGING_DISTRIBUTION' &&
        (metricFilterBy.issueAge || metricFilterBy.issueAge === '') && (
          <Flex w={'full'} gap={1} ml={0.5}>
            <Text as={'strong'}>Open Issue Age: </Text>
            <Text as={'span'}>
              {metricFilterBy.issueAge === 'week'
                ? 'Less than 1 Week'
                : metricFilterBy.issueAge === 'month'
                ? 'Less than 1 Month'
                : metricFilterBy.issueAge === 'threeMonth'
                ? 'Less than 3 Months'
                : 'More than 3 Months'}
            </Text>
          </Flex>
        )}
      {chartKey === 'ISSUE_LIFECYCLE_DIST' && metricFilterBy.stage && (
        <Flex w={'full'} gap={1} ml={0.5}>
          <Text as={'strong'}>SDLC Type: </Text>
          <Text as={'span'}>
            {
              getOptions('stage')?.find(
                (option: Option) => option.value === metricFilterBy.stage
              )?.label
            }
          </Text>
        </Flex>
      )}
      {chartKey === 'ISSUE_PROGRESSION_RATE' && metricFilterBy.filterStatus && (
        <Flex w={'full'} gap={1} ml={0.5}>
          <Text as={'strong'}>Issue Status Time: </Text>
          <Text as={'span'}>{metricFilterBy.filterStatus}</Text>
        </Flex>
      )}
    </GridItem>
  ) : (
    <></>
  );
}
