import { Flex, Box, Text } from '@chakra-ui/react';
import { AppTooltip, splitTime } from '@devd-client/devd/components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getColor } from '../../helpers/home.utils';

function RecentRuns({ info }: { info: any }) {
  return (
    <Flex
      direction="column"
      w="full"
      height="120px"
      justify="center"
      align="center"
    >
      <Flex align="center" gap={2}>
        {info.row.original?.recentRuns?.map((item: any, index: number) => (
          <AppTooltip
            key={index}
            trigger="hover"
            content={
              <Flex direction="column">
                <Text
                  fontFamily={'heading'}
                  fontSize="sm"
                  color="text.secondary2"
                  fontWeight="medium"
                >
                  Date:{' '}
                  <Text as="span" color="text.primary">
                    {moment(item?.date).format('lll')}
                  </Text>
                </Text>
                <Text
                  fontFamily={'heading'}
                  fontSize="sm"
                  color="text.secondary2"
                  fontWeight="medium"
                >
                  Duration:{' '}
                  <Text as="span" color="text.primary">
                    {splitTime(item?.durationInSecs / 3600)}
                  </Text>
                </Text>
                <Text
                  fontFamily={'heading'}
                  fontSize="sm"
                  color="text.secondary2"
                  fontWeight="medium"
                >
                  Status :{' '}
                  <Text as="span" color="text.primary">
                    {item?.status
                      ?.split('_')
                      ?.map(
                        (word: string) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                      )
                      ?.join(' ')}
                  </Text>
                </Text>
              </Flex>
            }
          >
            {item?.link ? (
              <Link to={item?.link} target="_blank" rel="noopener">
                <Box
                  cursor="pointer"
                  _hover={{
                    boxShadow: '0 0 0 3px var(--chakra-colors-primary)',
                  }}
                  transition={'all 0.3s'}
                  height="32px"
                  width="16px"
                  bg={getColor(item?.status?.toLowerCase())}
                  borderRadius="8px"
                />
              </Link>
            ) : (
              <Box
                height="32px"
                width="16px"
                bg={getColor(item?.status?.toLowerCase())}
                borderRadius="8px"
              />
            )}
          </AppTooltip>
        ))}
      </Flex>
    </Flex>
  );
}

export default RecentRuns;
