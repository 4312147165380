import { Flex, Skeleton } from '@chakra-ui/react';
import {
  AvatarGroup,
  UserCheckIcon,
  HumanEffort,
  AllocationIcon,
} from '@devd-client/devd/components';
import ResourceItem from './ResourceItem.tsx';

type Props = {
  data: any;
  isLoading: boolean;
};

const ResourceCol = ({ data, isLoading }: Props) => {
  return (
    <Flex direction="column" gap={4}>
      {isLoading ? (
        <Flex align={'center'}>
          {[...Array(3)].map((_, index) => (
            <Skeleton key={index} w={'28px'} h={'28px'} rounded={'full'} />
          ))}
        </Flex>
      ) : (
        <AvatarGroup items={data?.teams} />
      )}

      {isLoading ? (
        <Flex gap={1} direction={'column'}>
          {[...Array(2)].map((_, index) => (
            <Skeleton height="16px" width="60%" />
          ))}
        </Flex>
      ) : (
        <>
          {data?.activeContributors ? (
            <ResourceItem
              icon={UserCheckIcon}
              count={data?.activeContributors}
              text="Avg. Active Countributors"
              tooltipText="Average contributors actively contributing during the initiative's timeline."
            />
          ) : null}

          {data?.avgAllocation ? (
            <ResourceItem
              icon={AllocationIcon}
              count={data?.avgAllocation}
              text="% Avg. Allocation"
              tooltipText="Average allocation of resources over the initiative's timeline, calculated based on assigned efforts across the duration."
            />
          ) : null}
        </>
      )}
    </Flex>
  );
};

export default ResourceCol;
