export function AiAssistantIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill={props.color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_dd_2301_124)">
        <circle cx="11" cy="9" r="8" fill={props.color} />
      </g>
      <g clipPath="url(#clip0_2301_124)">
        <path
          d="M8.49996 12.3333V10.6667M8.49996 7.33332V5.66666M7.66663 6.49999H9.33329M7.66663 11.5H9.33329M11.3333 5.99999L10.7552 7.50294C10.6612 7.74735 10.6142 7.86956 10.5411 7.97235C10.4764 8.06346 10.3968 8.14305 10.3057 8.20783C10.2029 8.28092 10.0807 8.32793 9.83625 8.42193L8.33329 8.99999L9.83625 9.57805C10.0807 9.67205 10.2029 9.71905 10.3057 9.79215C10.3968 9.85693 10.4764 9.93652 10.5411 10.0276C10.6142 10.1304 10.6612 10.2526 10.7552 10.497L11.3333 12L11.9114 10.497C12.0054 10.2526 12.0524 10.1304 12.1254 10.0276C12.1902 9.93652 12.2698 9.85693 12.3609 9.79215C12.4637 9.71905 12.5859 9.67205 12.8303 9.57805L14.3333 8.99999L12.8303 8.42193C12.5859 8.32793 12.4637 8.28092 12.3609 8.20783C12.2698 8.14305 12.1902 8.06346 12.1254 7.97235C12.0524 7.86956 12.0054 7.74735 11.9114 7.50294L11.3333 5.99999Z"
          stroke="white"
          strokeWidth="0.666667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_2301_124"
          x="0"
          y="0"
          width="22"
          height="22"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_2301_124"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627 0 0 0 0 0.0942333 0 0 0 0 0.1573 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2301_124"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_2301_124"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627 0 0 0 0 0.0942333 0 0 0 0 0.1573 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2301_124"
            result="effect2_dropShadow_2301_124"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2301_124"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2301_124">
          <rect
            width="8"
            height="8"
            fill={props.color}
            transform="translate(7 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AiAssistantIcon;
