import { Box, Button, Flex, Skeleton, Text } from '@chakra-ui/react';
import {
  Breadcrumbs,
  ContributorTeams,
  isHTML,
  useQueryState,
} from '@devd-client/devd/components';
import { useGetDeliverableData } from '../apis';
import { useNavigate } from 'react-router';
import { convertToDate, parseLinks } from '../helpers/Initiatives.utils';
import { InitiativeStatus } from '../components/InitiativeStatus';
import { InitiativeTabs } from '../components/InitiativeTabs';
import { InitiativeOwners } from '../components/InitiativeOwners';
import { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'html-react-parser';

export const DetailsInitiatives = () => {
  const [searchId] = useQueryState('id');
  const [type] = useQueryState('type');
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  const { data, isLoading } = useGetDeliverableData(
    decodeURIComponent(searchId),
    decodeURIComponent(type)
  );

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(textRef.current).lineHeight
      );
      const height = textRef.current.scrollHeight;
      setIsTruncated(height > lineHeight * 1);
    }
  }, [data?.dto?.detail?.desc]);

  const initiativeState = {
    ...data?.dto?.detail,
    startDate: convertToDate(data?.dto?.detail?.startDate),
    plannedDate: convertToDate(data?.dto?.detail?.plannedDate),
  };

  return (
    <Flex direction="column" width="100%" minH="100%">
      <Breadcrumbs />

      <Flex gap={1} mt={2}>
        <Box mb={3} maxW={'85%'}>
          {isLoading ? (
            <Skeleton w={'220px'} h={'24px'} mb={1} />
          ) : (
            <Text
              fontFamily="heading"
              fontSize="xl"
              fontWeight="semibold"
              color="text.gray.300"
            >
              {data?.dto?.detail?.name}
            </Text>
          )}
          {isLoading ? (
            <Skeleton w={'180px'} h={'16px'} />
          ) : (
            <Text
              fontFamily="heading"
              fontSize="sm"
              fontWeight="normal"
              color="text.secondary2"
            >
              <Box
                as="span"
                display="inline"
                noOfLines={isExpanded ? undefined : 1}
                ref={textRef}
              >
                {isHTML(parseLinks(data?.dto?.detail?.desc))
                  ? ReactHtmlParser(parseLinks(data?.dto?.detail?.desc))
                  : data?.dto?.detail?.desc}
              </Box>
              {data?.dto?.detail?.desc && isTruncated && (
                <Button
                  as="span"
                  variant="link"
                  size="sm"
                  color="text.secondary2"
                  onClick={() => setIsExpanded(!isExpanded)}
                  ml={isExpanded ? 1 : 0}
                  display="inline"
                  verticalAlign="baseline"
                  height="auto"
                  padding={0}
                  cursor="pointer"
                >
                  {isExpanded ? 'Show Less' : 'Show More'}
                </Button>
              )}
            </Text>
          )}
        </Box>
        <Flex ml="auto" gap={2}>
          {data?.dto?.scope?.completedTasks !== data?.dto?.scope?.totalTasks ? (
            <Button
              size="sm"
              variant="custom-outline"
              onClick={() => {
                navigate(
                  `/deliverables/scenario-planner?deliverableId=${data?.dto?.detail?.id}&deliverableType=${data?.dto?.detail?.type}&deliverableName=${data?.dto?.detail?.name}`
                );
              }}
            >
              Scenario Planner
            </Button>
          ) : null}
          {data?.dto?.detail?.type === 'INITIATIVE' ? (
            <Button
              size="sm"
              variant="custom-outline"
              onClick={() =>
                navigate('/deliverables/edit', {
                  state: initiativeState,
                })
              }
            >
              Edit Initiative
            </Button>
          ) : null}
        </Flex>
      </Flex>

      <Flex mt={4} gap={8}>
        <Flex direction={'column'} gap={1}>
          <Text fontSize="sm" color="text.secondary2" fontWeight={'medium'}>
            Teams
          </Text>
          <ContributorTeams
            teams={data?.dto?.allocation?.teams}
            hideMore
            smallFont
            loading={isLoading}
          />
        </Flex>
        <Flex direction={'column'} gap={1}>
          <Text fontSize="sm" color="text.secondary2" fontWeight={'medium'}>
            Owners
          </Text>
          <InitiativeOwners
            ownersList={data?.dto?.detail?.owners}
            isLoading={isLoading}
          />
        </Flex>
        <Flex direction={'column'} gap={1}>
          <Text fontSize="sm" color="text.secondary2" fontWeight={'medium'}>
            Status
          </Text>
          <InitiativeStatus
            color={data?.dto?.calculatedStatus?.status?.color}
            label={data?.dto?.calculatedStatus?.status?.label}
            status={data?.dto?.detail?.status}
            progress={data?.dto?.calculatedStatus?.completionRate}
            isLoading={isLoading}
          />
        </Flex>
      </Flex>
      <InitiativeTabs
        initiativeState={initiativeState}
        scopeData={data?.dto?.scope}
        isLoadingInitiativeState={isLoading}
      />
    </Flex>
  );
};

export default DetailsInitiatives;
