import { useEffect, useState } from 'react';
import { useSprintMetricsWithStatus } from '../../../graphql';
import {
  Loader,
  SlideBar,
  SprintTableShared,
  useDashboardStore,
  useQueryState,
} from '@devd-client/devd/components';
import {
  Box,
  Button,
  Flex,
  List,
  ListIcon,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface SprintTabProps {
  initiativeId: string;
}

export const SprintTab = ({ initiativeId }: SprintTabProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { selected, selectedTeam } = useDashboardStore();
  const slideDrawer = useDisclosure();
  const [blockedIssues, setBlockedIssues] = useState<any>([]);
  const [firstOption, setFirstOption] = useQueryState('firstOption');
  const [filterType, setFilterType] = useState(firstOption ?? 'all');
  const [type] = useQueryState('type');
  const { data, isFetching } = useSprintMetricsWithStatus(
    {
      initiativeId: initiativeId,
      teamId: '',
      sprintIds: [],
      startDate: selected?.startDate,
      endDate: selected?.endDate,
      status: '',
      type: 'all',
      projectsFilter: [],
      sprintViewFilters: {
        isCompletionRate: filterType === 'isCompletionRate',
        isPlanningAccuracy: filterType === 'isPlanningAccuracy',
      },
      deliverableType: type || null,
    },
    20,
    0,
    false
  );

  useEffect(() => {
    setFilterType(firstOption ?? 'all');
  }, [firstOption]);

  return (
    <Flex direction={'column'} gap={2}>
      <Box ml={'auto'}>
        <Text fontSize={'xs'} fontWeight={'medium'} color={'text.secondary2'}>
          Filter By
        </Text>

        <Menu>
          <MenuButton
            as={Button}
            size="sm"
            rightIcon={<ChevronDownIcon />}
            variant="outline"
            borderColor="gray.200"
            bg="white"
            _hover={{
              borderColor: 'blue.500',
              bg: 'gray.50',
            }}
            w="180px"
            fontSize="sm"
            fontWeight={'medium'}
            textAlign={'left'}
          >
            {filterType === 'all' && 'All'}
            {filterType === 'isCompletionRate' && 'Completion Rate'}
            {filterType === 'isPlanningAccuracy' && 'Planning Accuracy'}
          </MenuButton>
          <MenuList
            border="1px solid"
            borderColor="gray.200"
            boxShadow="sm"
            minW={'unset'}
            w={'180px'}
            py={1}
            zIndex={100}
          >
            <MenuItem
              bg={filterType === 'all' ? 'blue.50' : 'white'}
              _hover={{
                bg: filterType === 'all' ? 'blue.100' : 'gray.50',
              }}
              fontSize="sm"
              onClick={() => setFilterType('all')}
            >
              All
            </MenuItem>
            <MenuItem
              bg={filterType === 'isCompletionRate' ? 'blue.50' : 'white'}
              _hover={{
                bg: filterType === 'isCompletionRate' ? 'blue.100' : 'gray.50',
              }}
              fontSize="sm"
              onClick={() => setFilterType('isCompletionRate')}
            >
              Completion Rate
            </MenuItem>
            <MenuItem
              bg={filterType === 'isPlanningAccuracy' ? 'blue.50' : 'white'}
              _hover={{
                bg:
                  filterType === 'isPlanningAccuracy' ? 'blue.100' : 'gray.50',
              }}
              fontSize="sm"
              onClick={() => setFilterType('isPlanningAccuracy')}
            >
              Planning Accuracy
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <SprintTableShared
        data={data}
        loading={isFetching}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        team={selectedTeam?.value}
        slideDrawer={slideDrawer}
        setBlockedIssues={setBlockedIssues}
        maxH={'26rem'}
      />
      {slideDrawer.isOpen && (
        <SlideBar
          openDrawer={slideDrawer.isOpen}
          handleDrawerClose={slideDrawer.onClose}
          width={'440px'}
          sideBarHeading={'Blocked Issues'}
          sideBarSubHeading=""
          content={
            <>
              <Box p="4">
                <List spacing={3}>
                  {blockedIssues.map((item: any) => (
                    <ListItem key={item}>
                      <ListIcon as={MdCheckCircle} color="success" />
                      {item}
                    </ListItem>
                  ))}
                </List>
              </Box>
            </>
          }
        />
      )}
      {isFetching && <Loader />}
    </Flex>
  );
};
