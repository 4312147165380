export function NoOwnerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_10167_15031)">
        <path
          d="M7.99972 9.143C9.57768 9.143 10.8569 7.86381 10.8569 6.28585C10.8569 4.7079 9.57768 3.42871 7.99972 3.42871C6.42177 3.42871 5.14258 4.7079 5.14258 6.28585C5.14258 7.86381 6.42177 9.143 7.99972 9.143Z"
          stroke={props.color || '#626266'}
          stroke-width="1.14286"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.11914 13.6007C3.62913 12.7636 4.34589 12.0717 5.20052 11.5917C6.05515 11.1115 7.0189 10.8594 7.99914 10.8594C8.97938 10.8594 9.94313 11.1115 10.7978 11.5917C11.6524 12.0717 12.3691 12.7636 12.8792 13.6007"
          stroke={props.color || '#626266'}
          stroke-width="1.14286"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.99888 15.4289C12.1016 15.4289 15.4275 12.1031 15.4275 8.00035C15.4275 3.89766 12.1016 0.571777 7.99888 0.571777C3.8962 0.571777 0.570312 3.89766 0.570312 8.00035C0.570312 12.1031 3.8962 15.4289 7.99888 15.4289Z"
          stroke={props.color || '#626266'}
          stroke-width="1.14286"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10167_15031">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NoOwnerIcon;
