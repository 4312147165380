export function Clock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99967 6.33325V8.99992L9.66634 9.99992M7.99967 3.33325C4.87006 3.33325 2.33301 5.87031 2.33301 8.99992C2.33301 12.1295 4.87006 14.6666 7.99967 14.6666C11.1293 14.6666 13.6663 12.1295 13.6663 8.99992C13.6663 5.87031 11.1293 3.33325 7.99967 3.33325ZM7.99967 3.33325V1.33325M6.66634 1.33325H9.33301M13.5523 3.72795L12.5523 2.72795L13.0523 3.22795M2.44702 3.72795L3.44702 2.72795L2.94702 3.22795"
        stroke="#2A2A2F"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
