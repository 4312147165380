import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import {
  Loader,
  PasswordInput,
  useToastHook,
} from '@devd-client/devd/components';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useResetPassword, useVerifyToken } from '../../api';
import { useAuth } from '../../context/devd-auth';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [appState] = useAuth();
  const [resetSuccess, setResetSuccess] = useState(false);
  const [newToast] = useToastHook();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const {
    isLoading: verifyTokenLoading,
    error,
    isError,
  } = useVerifyToken(code || '');
  const { mutate: handleResetPassword, isLoading: resetPasswordLoading } =
    useResetPassword();

  if (appState.user) {
    navigate('/');
  }

  useEffect(() => {
    if (!resetSuccess) return;
    newToast({
      message: 'Reset Password is done successfully! Login with new password.',
      status: 'success',
    });
    setTimeout(() => {
      navigate('/account/login');
    }, 2000);
  }, [resetSuccess]);

  return (
    <>
      <Stack my={4}>
        <>
          {isError ? (
            <Text
              fontFamily="heading"
              fontSize="sm"
              color="text.secondary"
              fontWeight="normal"
              lineHeight="22px"
              textAlign="center"
            >
              Link is expired or invalid.
            </Text>
          ) : resetSuccess ? (
            <Text
              fontFamily="heading"
              fontSize="sm"
              color="text.secondary"
              fontWeight="normal"
              lineHeight="22px"
              textAlign="center"
            >
              Reset Password is done successfully! Login with new password.
            </Text>
          ) : (
            <></>
          )}
        </>
      </Stack>
      {!isError && !verifyTokenLoading && !resetSuccess && (
        <>
          <Text
            fontFamily="heading"
            fontSize="sm"
            color="text.secondary"
            fontWeight="normal"
            lineHeight="22px"
            textAlign="center"
          >
            Enter strong password that are at least 12 characters long and
            include a mix of uppercase and lowercase letters, numbers, and
            symbols.
          </Text>
          <Box>
            <Formik
              initialValues={{ password: '', confirmPassword: '' }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                await handleResetPassword(
                  { token: code, password: values.password },
                  {
                    onSuccess: (data) => {
                      setResetSuccess(true);
                    },
                    onError: (error: any) => {
                      newToast({
                        message: error?.message || 'Something went wrong.',
                        status: 'error',
                      });
                    },
                  }
                );
                setSubmitting(false);
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .min(12, 'Password must be at least 12 characters.')
                  .max(128, 'Password must be lower than 128 characters.')
                  .matches(
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?!.*?\b(?:password|admin|user)\b).*$/,
                    'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
                  )
                  .required('Password is required.'),

                confirmPassword: Yup.string()
                  .oneOf([Yup.ref('password')], 'Passwords must match.')
                  .required('Confirm Password is required.'),
              })}
            >
              {(props) => {
                const { isSubmitting, handleSubmit } = props;
                return (
                  <Stack as="form" spacing={4} onSubmit={handleSubmit as any}>
                    <PasswordInput
                      name="password"
                      label="Password"
                      placeholder="Password"
                    />

                    <PasswordInput
                      name="confirmPassword"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                    />

                    <Button
                      isDisabled={isSubmitting}
                      variant="tertiary"
                      w="full"
                      fontSize="sm"
                      type="submit"
                    >
                      Reset Password
                    </Button>
                  </Stack>
                );
              }}
            </Formik>
          </Box>
        </>
      )}

      {(verifyTokenLoading || resetPasswordLoading) && <Loader />}
    </>
  );
};

export default ResetPassword;
