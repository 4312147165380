import { Box, Text } from '@chakra-ui/react';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Area,
  Tooltip,
} from 'recharts';
import moment from 'moment';
import { useState } from 'react';

interface LineChartWithReferenceLineProps {
  graphData?: any;
  referenceLines?: any;
  keys?: any;
}

export const LineChartWithReferenceLine = ({
  graphData: data,
  referenceLines,
  keys,
}: LineChartWithReferenceLineProps) => {
  const [hiddenLines, setHiddenLines] = useState<string[]>([]);

  const toggleLine = (key: string) => {
    setHiddenLines((prev) =>
      prev?.includes(key) ? prev?.filter((k) => k !== key) : [...prev, key]
    );
  };

  const CustomLegend = ({ keys }: { keys: any[] }) => (
    <div
      style={{
        position: 'absolute',
        top: '-25px',
        right: 0,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '12px',
        padding: '0 20px',
        maxWidth: '70%',
      }}
    >
      {keys?.map((entry) => {
        const isDashed = entry?.chartType === 'dashed_line_chart';
        const isHidden = hiddenLines?.includes(entry?.key);

        return (
          <div
            key={entry?.key}
            style={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              opacity: isHidden ? 0.5 : 1,
            }}
            onClick={() => toggleLine(entry?.key)}
          >
            <div
              style={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: isDashed ? 'transparent' : entry?.color,
                marginRight: '4px',
                border: isDashed ? `1.5px dashed ${entry?.color}` : 'none',
                position: 'relative',
                flexShrink: 0,
              }}
            >
              {isDashed && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    backgroundColor: entry?.color,
                    opacity: 0.5,
                  }}
                />
              )}
            </div>
            <span
              style={{
                color: '#666666',
                fontSize: '11.5px',
                lineHeight: '1.2',
              }}
            >
              {entry?.name}
            </span>
          </div>
        );
      })}
    </div>
  );

  const CustomReferenceLabel = ({ viewBox, value }: any) => {
    const referenceLine = referenceLines?.find(
      (line: any) => line?.label === value
    );

    const yOffset =
      value === 'Today'
        ? -15
        : value === 'Target'
        ? -40
        : value === 'Projection'
        ? -65
        : 0;

    const dateStr = `: ${moment(referenceLine?.date).format('DD MMM')}`;

    return (
      <g transform={`translate(${viewBox.x},${viewBox.y + yOffset})`}>
        <rect
          x={value === 'Projection' ? '-55' : '-45'}
          y="-12"
          width={value === 'Projection' ? '110' : '90'}
          height="24"
          rx="6"
          fill={referenceLine?.bgColor}
          strokeWidth="1"
          filter="url(#shadow)"
        />

        <text
          x={'0'}
          y="4"
          textAnchor="middle"
          fill={referenceLine?.color}
          fontSize="12px"
        >
          {value}
          {dateStr}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const filteredPayload = payload?.filter((entry: any) => {
        const isCurrentData = !entry?.dataKey?.includes('predicted');
        const futureDataWithoutAreaKey =
          entry?.name !== 'predictedCumulativeIssueOutflow';

        if (
          entry?.payload?.cumulativeIssueInflow ||
          entry?.payload?.cumulativeIssueOutflow ||
          entry?.payload?.issueClosedCount
        ) {
          return isCurrentData;
        }

        if (entry?.name === 'predictedCumulativeIssueOutflow') {
          return futureDataWithoutAreaKey;
        }

        return true;
      });

      return (
        <Box
          bg="white"
          p={3}
          boxShadow="md"
          borderRadius="md"
          border="1px solid"
          borderColor="text.tertiary"
        >
          <Text fontSize="sm" color="gray.600" mb={2}>
            {moment(label).format('DD MMM YYYY')}
          </Text>
          {filteredPayload?.map((entry: any) => (
            <Box key={entry?.name} display="flex" alignItems="center" mb={1}>
              <Box w="2" h="2" borderRadius="full" bg={entry?.color} mr={2} />
              <Text fontSize="sm" color="gray.600">
                {entry?.name}: {entry?.value}
              </Text>
            </Box>
          ))}
        </Box>
      );
    }
    return null;
  };

  const getWeeklyTicks = () => {
    if (!data || data?.length === 0) return [];

    const startDate = data[0]?.date;
    const dataEndDate = data[data?.length - 1]?.date;
    const targetDate =
      referenceLines?.find((line: any) => line?.label === 'Target')?.date || 0;
    const endDate = targetDate > dataEndDate ? targetDate : dataEndDate;

    const ticks = [startDate];
    let currentDate = moment(startDate).startOf('week').valueOf();

    while (currentDate <= endDate) {
      if (currentDate >= startDate) {
        ticks.push(currentDate);
      }
      currentDate = moment(currentDate).add(1, 'weeks').valueOf();
    }
    ticks.push(endDate);
    return ticks;
  };

  return (
    <Box w="100%" h="100%" position="relative">
      <ResponsiveContainer width="100%" height={'100%'}>
        <ComposedChart
          data={data || []}
          margin={{ top: 100, right: 20, left: 5, bottom: 20 }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={'#E0E0E0'}
            vertical={false}
          />
          <XAxis
            dataKey="date"
            tickFormatter={(timestamp) => moment(timestamp).format('DD MMM')}
            stroke={'#8F8F91'}
            fontSize={'12px'}
            interval="preserveStartEnd"
            minTickGap={10}
            scale="time"
            type="number"
            domain={[
              data?.[0]?.date,
              Math.max(
                data?.[data?.length - 1]?.date,
                referenceLines?.find((line: any) => line?.label === 'Target')
                  ?.date || 0
              ),
            ]}
            ticks={getWeeklyTicks()}
            tickSize={8}
            dy={10}
          />
          <YAxis
            tickLine={false}
            stroke={'#8F8F91'}
            fontSize={'12px'}
            domain={[0, 'auto']}
            label={{
              value: 'Issue Count',
              angle: -90,
              position: 'insideLeft',
              offset: 12,
              style: {
                fontSize: '12px',
                fontWeight: 'medium',
                fill: '#8F8F91',
                textAnchor: 'middle',
              },
            }}
          />
          <YAxis
            yAxisId="issueClosed"
            orientation="right"
            tickLine={false}
            stroke={'#8F8F91'}
            fontSize={'12px'}
            domain={[0, 'auto']}
            label={{
              value: 'Issue Closed Count',
              angle: -90,
              position: 'insideRight',
              offset: 12,
              style: {
                fontSize: '12px',
                fontWeight: 'medium',
                fill: '#8F8F91',
                textAnchor: 'middle',
              },
            }}
          />
          <Tooltip
            content={<CustomTooltip />}
            wrapperStyle={{
              outline: 'none',
            }}
          />
          {hiddenLines?.includes('predictedCumulativeIssueOutflow') ? null : (
            <Area
              type="linear"
              dataKey="predictedCumulativeIssueOutflow"
              fill="#6BDAAE1A"
              stroke="none"
            />
          )}
          {keys?.map((el: any) => {
            const isIssueClosed = [
              'predictedIssueClosedCount',
              'issueClosedCount',
            ].includes(el.key);

            if (hiddenLines.includes(el.key)) return null;

            return (
              <Line
                key={el.key}
                type={
                  ['cumulativeIssueInflow', 'cumulativeIssueOutflow'].includes(
                    el.key
                  )
                    ? 'stepAfter'
                    : 'linear'
                }
                dataKey={el.key}
                name={el.name}
                stroke={el.color}
                strokeWidth={2}
                yAxisId={isIssueClosed ? 'issueClosed' : undefined}
                strokeDasharray={
                  el.chartType === 'dashed_line_chart' ? '5 5' : undefined
                }
                dot={false}
              />
            );
          })}

          {referenceLines?.map((line: any) => (
            <ReferenceLine
              key={line?.label}
              x={line?.date}
              stroke={line?.color}
              label={{
                value: line?.label,
                position: 'top',
                content: CustomReferenceLabel,
              }}
              isFront={true}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
      <CustomLegend keys={keys} />
    </Box>
  );
};
