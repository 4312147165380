export function DeliverablesIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.60254 1.12891H4.97694V4.50331H1.60254V1.12891Z"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.60254 11.0068H4.97694V14.3812H1.60254V11.0068Z"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0205 1.12891H14.3949V4.50331H11.0205V1.12891Z"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2217 10.5176L14.3989 12.6948L12.2217 14.8721"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3982 12.6914H4.97656"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.021 2.81445H4.97363"
        stroke={props.color || '#2A2A2F'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.4565 4.52148L4.97461 11.0034"
        stroke={props.color || '#2A2A2F'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
