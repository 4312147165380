export const CircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4775_666)">
        <path
          d="M7.99992 5.33325V7.99992M7.99992 10.6666H8.00659M1.33325 5.68175V10.3181C1.33325 10.4812 1.33325 10.5627 1.35167 10.6394C1.368 10.7074 1.39494 10.7725 1.43149 10.8321C1.47272 10.8994 1.53037 10.957 1.64567 11.0723L4.9275 14.3542C5.0428 14.4695 5.10045 14.5271 5.16773 14.5683C5.22738 14.6049 5.29241 14.6318 5.36043 14.6482C5.43716 14.6666 5.51869 14.6666 5.68175 14.6666H10.3181C10.4812 14.6666 10.5627 14.6666 10.6394 14.6482C10.7074 14.6318 10.7725 14.6049 10.8321 14.5683C10.8994 14.5271 10.957 14.4695 11.0723 14.3542L14.3542 11.0723C14.4695 10.957 14.5271 10.8994 14.5683 10.8321C14.6049 10.7725 14.6318 10.7074 14.6482 10.6394C14.6666 10.5627 14.6666 10.4812 14.6666 10.3181V5.68175C14.6666 5.51869 14.6666 5.43716 14.6482 5.36043C14.6318 5.29241 14.6049 5.22738 14.5683 5.16773C14.5271 5.10045 14.4695 5.0428 14.3542 4.9275L11.0723 1.64567C10.957 1.53037 10.8994 1.47272 10.8321 1.43149C10.7725 1.39494 10.7074 1.368 10.6394 1.35167C10.5627 1.33325 10.4812 1.33325 10.3181 1.33325H5.68175C5.51869 1.33325 5.43716 1.33325 5.36043 1.35167C5.29241 1.368 5.22738 1.39494 5.16773 1.43149C5.10045 1.47272 5.0428 1.53037 4.9275 1.64567L1.64567 4.9275C1.53037 5.0428 1.47272 5.10045 1.43149 5.16773C1.39494 5.22738 1.368 5.29241 1.35167 5.36043C1.33325 5.43716 1.33325 5.51869 1.33325 5.68175Z"
          stroke={props.color || '#626266'}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4775_666">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CircleIcon;
