import { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Checkbox,
  Input,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Flex,
  Skeleton,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import { useGetAllocationByMember, useGetTeamMembers } from '../../../apis';
import { Member } from '../../../helpers/Initiatives.types';

interface AddMembersModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (members: Member[]) => void;
  team: string;
  selectedMembers: Member[];
  maxAllowedMembers: number;
  initiativeId: string;
  otherTeamAllocations: Array<{
    team: string;
    members: Member[];
  }>;
}

const AddMembersModal = ({
  isOpen,
  onClose,
  onSubmit,
  team,
  selectedMembers,
  maxAllowedMembers,
  initiativeId,
  otherTeamAllocations,
}: AddMembersModalProps) => {
  const { data, isLoading } = useGetTeamMembers(team);
  const { data: allocationByMember } = useGetAllocationByMember(initiativeId);
  const [localMembers, setLocalMembers] = useState<Member[]>([]);

  useEffect(() => {
    if (data?.dto) {
      const transformedMembers = data?.dto?.map((teamMember: any) => {
        const existingMember = selectedMembers?.find(
          (selected) => selected?.id === teamMember?.email
        );
        const existingAllocation = allocationByMember?.dto?.find(
          (allocation: any) => allocation.member === teamMember?.email
        );

        return {
          id: teamMember?.email,
          name: teamMember?.name,
          allocation: existingMember?.allocation || '',
          selected: !!existingMember,
          existingAllocation: existingAllocation?.allocation || 0,
        };
      });
      setLocalMembers(transformedMembers);
    }
  }, [data, selectedMembers, allocationByMember]);

  const handleCheckboxChange = (id: string) => {
    setLocalMembers((members) =>
      members.map((member) =>
        member.id === id ? { ...member, selected: !member.selected } : member
      )
    );
  };

  const handleSelectAll = (isChecked: boolean) => {
    setLocalMembers((members) =>
      members.map((member) => ({
        ...member,
        selected: isChecked,
      }))
    );
  };

  const handleAllocationChange = (id: string, value: string) => {
    setLocalMembers((members) =>
      members.map((member) =>
        member.id === id ? { ...member, allocation: value } : member
      )
    );
  };

  const hasInvalidAllocation = () => {
    return localMembers?.some((member) => {
      if (!member?.selected) return false;

      const newAllocation = Number(member?.allocation) || 0;

      if (newAllocation < 0 || newAllocation > 100) return true;

      const otherTeamsAllocation = otherTeamAllocations
        ?.filter((teamAlloc) => teamAlloc?.team !== team)
        ?.reduce((total, teamAlloc) => {
          const memberInTeam = teamAlloc?.members?.find(
            (m) => m.id === member.id
          );
          return (
            total +
            (memberInTeam?.allocation ? Number(memberInTeam?.allocation) : 0)
          );
        }, 0);

      const existingMember = selectedMembers?.find(
        (selected) => selected?.id === member?.id
      );

      const currentTeamExistingAllocation = existingMember
        ? Number(existingMember?.allocation) || 0
        : 0;

      const totalAllocation =
        newAllocation + otherTeamsAllocation - currentTeamExistingAllocation;

      return totalAllocation > 100;
    });
  };

  const isAllSelected =
    localMembers?.length > 0 &&
    localMembers?.every((member) => member?.selected === true);

  const getSelectedCount = () =>
    localMembers?.filter((member) => member?.selected)?.length;

  const handleSubmit = () => {
    const selectedMembers = localMembers?.filter((member) => member?.selected);
    onSubmit(selectedMembers);
    onClose();
  };

  const getErrorMessage = () => {
    if (getSelectedCount() > maxAllowedMembers) {
      return "To select more members, increase the 'No. of Members' value in the allocation form";
    }

    const overAllocatedMember = localMembers?.find((member) => {
      if (!member?.selected) return false;
      const newAllocation = Number(member?.allocation) || 0;

      const otherTeamsAllocation = otherTeamAllocations
        .filter((teamAlloc) => teamAlloc.team !== team)
        .reduce((total, teamAlloc) => {
          const memberInTeam = teamAlloc.members.find(
            (m) => m.id === member.id
          );
          return (
            total +
            (memberInTeam && memberInTeam.selected
              ? Number(memberInTeam.allocation) || 0
              : 0)
          );
        }, 0);

      const existingMember = selectedMembers.find(
        (selected) => selected.id === member.id
      );

      const totalAllocation = newAllocation + otherTeamsAllocation;

      return totalAllocation > 100;
    });

    if (overAllocatedMember) {
      const otherTeamsAllocation = otherTeamAllocations
        .filter((teamAlloc) => teamAlloc.team !== team)
        .reduce((total, teamAlloc) => {
          const memberInTeam = teamAlloc.members.find(
            (m) => m.id === overAllocatedMember.id
          );
          return (
            total +
            (memberInTeam && memberInTeam.selected
              ? Number(memberInTeam.allocation) || 0
              : 0)
          );
        }, 0);

      const remainingAllocation = 100 - otherTeamsAllocation;

      return `Maximum available allocation for ${
        overAllocatedMember.name?.trim() || overAllocatedMember.id
      } is ${remainingAllocation}%`;
    }

    if (hasInvalidAllocation()) {
      return 'Allocation percentage must be between 0 and 100';
    }

    return null;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent maxH="544px" maxW="600px">
        <ModalHeader>Add Members</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text mb={4} mt={-5} color="text.secondary2" fontSize="sm">
            Assign contributors to the initiative, and set their time allocation
            (%).
          </Text>

          <Box
            position="relative"
            maxH="388px"
            overflowY="auto"
            borderWidth="1px"
            borderColor="#E0E0E0"
            borderRadius="lg"
          >
            {isLoading ? (
              <Table variant="simple" size="sm" bg="white">
                <Thead position="sticky" top={0} bg="#FBFBFB" zIndex={1}>
                  <Tr>
                    <Th
                      color="text.secondary2"
                      fontSize="xs"
                      fontWeight="medium"
                      borderRight="1px solid #E0E0E0"
                      pl={4}
                      py={3}
                      w="70%"
                      textTransform="none"
                    >
                      <Checkbox
                        isChecked={false}
                        spacing={3}
                        color="primary"
                        size="lg"
                      >
                        <Text
                          color="text.secondary2"
                          fontSize="xs"
                          fontWeight="medium"
                        >
                          Member
                        </Text>
                      </Checkbox>
                    </Th>
                    <Th
                      color="text.secondary2"
                      fontSize="xs"
                      fontWeight="medium"
                      pl={4}
                      py={3}
                      w="30%"
                      textTransform="none"
                    >
                      Allocation (%)
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {[...Array(5)].map((_, index) => (
                    <Tr key={index}>
                      <Td borderRight="1px solid #E0E0E0">
                        <Skeleton height="20px" width="60%" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" width="90%" />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Table
                variant="simple"
                size="sm"
                bg="white"
                style={{ tableLayout: 'fixed' }}
              >
                <Thead position="sticky" top={0} bg="#FBFBFB" zIndex={1}>
                  <Tr>
                    <Th
                      color="text.secondary2"
                      fontSize="xs"
                      fontWeight="medium"
                      borderRight="1px solid #E0E0E0"
                      pl={4}
                      py={3}
                      w="70%"
                      textTransform="none"
                    >
                      <Checkbox
                        isChecked={isAllSelected}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                        spacing={3}
                        color="primary"
                        size="lg"
                      >
                        <Text
                          color="text.secondary2"
                          fontSize="xs"
                          fontWeight="medium"
                        >
                          Member
                        </Text>
                      </Checkbox>
                    </Th>
                    <Th
                      color="text.secondary2"
                      fontSize="xs"
                      fontWeight="medium"
                      pl={4}
                      py={3}
                      w="30%"
                      textTransform="none"
                    >
                      Allocation (%)
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {localMembers?.map((member) => (
                    <Tr key={member?.id}>
                      <Td pl={4} py={2} borderRight="1px solid #E0E0E0">
                        <Checkbox
                          isChecked={member?.selected}
                          onChange={() => handleCheckboxChange(member?.id)}
                          spacing={3}
                          color="primary"
                          size="lg"
                        >
                          <Text
                            color="text.gray.300"
                            fontSize="sm"
                            isTruncated
                            maxW="320px"
                          >
                            {member?.name?.trim() ? member?.name : member?.id}
                          </Text>
                        </Checkbox>
                      </Td>
                      <Td pl={4} py={2}>
                        <InputGroup size="sm">
                          <Input
                            placeholder="0"
                            value={member?.allocation}
                            onChange={(e) =>
                              handleAllocationChange(member?.id, e.target.value)
                            }
                            type="number"
                            border="1px solid #E0E0E0"
                            borderRadius="2px"
                            borderRightRadius="0"
                            borderRight="none"
                            bg="#F9F8F8"
                            _placeholder={{ color: 'text.secondary2' }}
                            _focus={{
                              boxShadow: 'none',
                              border: '1px solid #E0E0E0',
                              borderRight: 'none',
                            }}
                            textAlign="right"
                          />
                          <InputRightAddon
                            children="%"
                            bg="#F9F8F8"
                            border="1px solid #E0E0E0"
                            borderLeft="none"
                            borderLeftRadius="0"
                            color="text.secondary2"
                            paddingLeft="0"
                          />
                        </InputGroup>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </Box>

          <Flex w={'100%'} align={'center'}>
            {getErrorMessage() && (
              <Text color="error" fontSize="sm" ml={2} maxW="70%">
                {getErrorMessage()}
              </Text>
            )}
            <Button
              variant={'filled'}
              onClick={handleSubmit}
              ml="auto"
              my={3}
              isDisabled={
                getSelectedCount() > maxAllowedMembers ||
                hasInvalidAllocation() ||
                !!getErrorMessage()
              }
            >
              Add {getSelectedCount()} Members
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddMembersModal;
