import {
  Box,
  Stack,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import {
  QUERY_KEYS,
  useAddUpdateCfrMutation,
  useAddUpdateReleaseMutation,
  useCfr,
  useCustomfield,
  useRelease,
} from './apis';
import {
  Loader,
  MetaTags,
  PageHeader,
  useToastHook,
} from '@devd-client/devd/components';
import styled from 'styled-components';
import CfrForm from './components/cfr-form';
import { AddCfrProps } from './helpers/dora.types';
import { useQueryClient } from '@tanstack/react-query';
import ReleaseForm from './components/release-form';

const Container = styled.div`
  width: 100%;
`;

export const Dora = () => {
  const { data: cfrData, isLoading: cfrLoading } = useCfr();

  const { data: releaseData, isLoading: releaseLoading } = useRelease();

  const { data: customFieldData, isLoading: customFieldLoading } =
    useCustomfield();

  const [newToast] = useToastHook();
  const queryClient = useQueryClient();

  const { mutate: addUpdateCfr, isLoading: addLoading } =
    useAddUpdateCfrMutation();
  const { mutate: addUpdateRelease, isLoading: addMutationLoading } =
    useAddUpdateReleaseMutation();

  const handleSaveClick = (cfrData: AddCfrProps) => {
    addUpdateCfr(
      {
        cfrData,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.cfr]);
          newToast({
            message: `You have changed failure rate.`,
            status: 'success',
          });
          setTimeout(() => {
            newToast({
              message:
                'Now, You have to update Change Failure Detection in Team details',
              status: 'info',
            });
          }, 2000);
        },
        onError: (err: any) => {
          newToast({
            status: 'error',
            message: err?.message ?? `Some error in updating change failure`,
          });
        },
      }
    );
  };

  const handleReleaseSaveClick = (releaseData: AddCfrProps) => {
    addUpdateRelease(
      {
        releaseData,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([QUERY_KEYS.release]);
          newToast({
            message: `Deployment details have been updated.`,
            status: 'success',
          });
          setTimeout(() => {
            newToast({
              message:
                'Now, You have to update Deployment Detection in Team details',
              status: 'info',
            });
          }, 2000);
        },
        onError: (err: any) => {
          newToast({
            status: 'error',
            message:
              err?.message ?? `Some error in updating Deployment detection`,
          });
        },
      }
    );
  };

  return (
    <>
      <MetaTags title="DORA | Settings | Devdynamics" />
      <Container>
        <Box>
          <PageHeader headingText="DORA" mb={'20px'} />
          {cfrData && releaseData ? (
            <Tabs>
              <TabList>
                <Tab
                  color="text.secondary3"
                  _selected={{
                    color: 'primary',
                    borderBottom: '2px solid',
                    borderColor: 'primary',
                  }}
                >
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                  >
                    {'Deployment'}
                  </Text>
                </Tab>

                <Tab
                  color="text.secondary3"
                  _selected={{
                    color: 'primary',
                    borderBottom: '2px solid',
                    borderColor: 'primary',
                  }}
                >
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                  >
                    {'Change Failure'}
                  </Text>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack
                    p={6}
                    border="0.5px solid rgba(0,0,0,0.1)"
                    borderRadius="4px"
                    spacing={6}
                  >
                    <Stack spacing={0}>
                      <Text
                        fontFamily="heading"
                        fontSize="2xl"
                        fontWeight="bold"
                        color="text.primary"
                      >
                        Deployment
                      </Text>
                      <Text
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="normal"
                        color="text.secondary"
                      >
                        We can capture Deployment data from a dedicated branch
                        in your Git implementation. You can also send us CI, CD
                        and Failure events by calling our Notify API below.
                        Please contact us on chat to get the $org_api_key for
                        your organisation.
                      </Text>
                    </Stack>
                    <ReleaseForm
                      handleReleaseSaveClick={handleReleaseSaveClick}
                      data={releaseData}
                      customFieldData={customFieldData}
                    />
                  </Stack>
                </TabPanel>

                <TabPanel>
                  <Stack
                    p={6}
                    border="0.5px solid rgba(0,0,0,0.1)"
                    borderRadius="4px"
                    spacing={6}
                  >
                    <Stack spacing={0}>
                      <Text
                        fontFamily="heading"
                        fontSize="2xl"
                        fontWeight="bold"
                        color="text.primary"
                      >
                        Change Failure
                      </Text>
                      <Text
                        fontFamily="heading"
                        fontSize="sm"
                        fontWeight="normal"
                        color="text.secondary"
                      >
                        Please specify the values for the issue fields which
                        qualify as in Incident event.
                      </Text>
                    </Stack>
                    <CfrForm
                      handleSaveClick={handleSaveClick}
                      data={cfrData}
                      customFieldData={customFieldData}
                    />
                  </Stack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <Loader />
          )}
        </Box>
        {(addMutationLoading ||
          cfrLoading ||
          releaseLoading ||
          addLoading ||
          customFieldLoading) && <Loader />}
      </Container>
    </>
  );
};

export default Dora;
