import { Flex, Text, useTheme } from '@chakra-ui/react';
import { AppTooltip } from '@devd-client/devd/components';

type Props = {
  icon: any;
  count: number | string;
  text: string;
  tooltipText?: string;
};

const ResourceItem = ({ icon: Icon, count, text, tooltipText }: Props) => {
  const { colors } = useTheme();
  return (
    <>
      {tooltipText ? (
        <AppTooltip trigger="hover" label={tooltipText}>
          <Flex gap={1} align="center">
            <Icon color={colors.text.primary} />

            <Text
              borderBottom={'2px dashed'}
              borderColor="text.secondary2"
              color="text.secondary2"
              fontWeight="normal"
            >
              <Text fontSize="sm" as="span">
                {count}
              </Text>{' '}
              <Text as="span" fontSize="xxs">
                {text}
              </Text>
            </Text>
          </Flex>
        </AppTooltip>
      ) : (
        <Flex gap={1} align="center">
          <Icon color={colors.text.primary} />

          <Text
            borderBottom={'2px dashed'}
            borderColor="text.secondary2"
            color="text.secondary2"
            fontWeight="normal"
          >
            <Text fontSize="sm" as="span">
              {count}
            </Text>{' '}
            <Text as="span" fontSize="xxs">
              {text}
            </Text>
          </Text>
        </Flex>
      )}
    </>
  );
};

export default ResourceItem;
